<template>
  <div class="flex items-center -my-12 h-screen justify-center">
    <div class="text-center pt-5 pb-4">
      <h1>404</h1>
      <h2>The page you're looking for is not here!</h2>
      <p class="my-6 max-w-xl m-auto">This page could not be found. You may have been redirected to this link by accident or this page does not longer exist. Please go to the home to continue your browsing.</p>
      <router-link to="/queue" class="inline btn text primary">Go To Home</router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: 'not-found'
};
</script>
