<template>
  <div v-if="!replyToTweetUrl || replyToTweetUrl.length <= 0" class="w-full p-6 text-center">
    <p>Please provide a valid tweet url</p>
  </div>
  <div v-else-if="isLoading" class="flex w-full items-center justify-center p-6">
    <span class="loading loading-lg"><inline-svg src="/img/icons/loading.svg"></inline-svg></span>
  </div>
  <div v-else-if="error" class="w-full p-6 text-center">
    <p class="text-badge-error">{{ error }}</p>
  </div>
  <div v-else class="flex w-full flex-col">
    <div class="grid grid-cols-12 gap-3">
      <div class="col-span-2 flex flex-col md:col-span-1">
        <div class="flex w-full justify-center">
          <a
            :href="`https://twitter.com/${originalTweetInfo.username}`"
            target="_blank"
            tabindex="-1"
            class=""
          >
            <img
              :alt="`${originalTweetInfo.userDisplayName}'s profile picture`"
              :src="originalTweetInfo.userProfilePictureURL"
              class="avatar-img"
            />
          </a>
        </div>
        <div class="flex h-full w-full justify-center">
          <span class="h-full border-r-2 border-gray-80" />
        </div>
      </div>
      <div class="col-span-10 space-y-3 pb-3 md:col-span-11">
        <div class="flex items-center gap-2">
          <a
            class="avatar-name"
            :href="`https://twitter.com/${originalTweetInfo.username}`"
            target="_blank"
            tabindex="-1"
            >{{ originalTweetInfo.userDisplayName }}</a
          >
          <a
            class="avatar-username"
            :href="'https://twitter.com/' + originalTweetInfo.username"
            target="_blank"
            tabindex="-1"
            >@{{ originalTweetInfo.username }}</a
          >
        </div>
        <div
          class="max-w-lg whitespace-pre-wrap break-words text-lg leading-6"
          v-html="formatTweet(originalTweetInfo.text)"
          dir="auto"
          data-cy="tweet-preview-text"
        />
        <div class="parentMetrics mb-4 mt-2 flex items-center justify-between text-gray-80">
          <span class="flex items-center gap-1">
            <inline-svg src="img/icons/new/twitter-reply.svg" class="text-gray-50" />
            <p v-if="originalTweetMetrics.replies && originalTweetMetrics.replies !== 0">
              {{ originalTweetMetrics.replies }}
            </p>
          </span>
          <span class="flex items-center gap-1">
            <inline-svg src="img/icons/new/twitter-retweet.svg" />
            <p v-if="originalTweetMetrics.retweets && originalTweetMetrics.retweets !== 0">
              {{ originalTweetMetrics.retweets }}
            </p>
          </span>
          <span class="flex items-center gap-1">
            <inline-svg src="img/icons/new/twitter-like.svg" />
            <p v-if="originalTweetMetrics.likes && originalTweetMetrics.likes !== 0">
              {{ originalTweetMetrics.likes }}
            </p>
          </span>
          <span class="flex items-center gap-1">
            <inline-svg src="img/icons/new/twitter-views.svg" />
            <p v-if="originalTweetMetrics.impressions && originalTweetMetrics.impressions !== 0">
              {{ originalTweetMetrics.impressions }}
            </p>
          </span>
        </div>
      </div>
    </div>
    <div
      v-for="(tweet, idx) in initialTweetsValue"
      :key="tweet.guid"
      class="grid grid-cols-12 gap-3"
    >
      <div class="col-span-2 flex justify-center md:col-span-1">
        <img :src="userProfile.photoURL" :alt="userProfile.name" class="avatar-img" />
      </div>
      <div class="col-span-10 flex items-center gap-2 md:col-span-11">
        <span class="avatar-name">{{ userProfile.name }}</span>
        <span class="avatar-username">@{{ userProfile.username }}</span>
      </div>
      <div class="col-span-11 col-start-2 space-y-4">
        <div
          class="word-break whitespace-pre-wrap break-words text-base"
          v-html="formatTweet(tweet.status)"
          dir="auto"
        />
        <div class="border rounded-lg p-1" v-for="(mediaFile, i) in tweet.mediaFile" :key="i">
          <img
            v-if="['image', 'gif'].includes(mediaFile.type)"
            :src="mediaFile.url"
            class="w-full rounded-lg object-cover object-center"
          />
          <video v-if="mediaFile.type === 'video'" :src="mediaFile.url" class="w-full rounded-lg" />
        </div>
        <tweet-display
          v-if="hasThreadFinisherTweet && tweets.length - 1 === idx"
          :username="userProfile.username"
          :userDisplayName="userProfile.name"
          :tweet="tweets[0].status"
          :profilePictureURL="userProfile.photoURL"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { store } from '@/store';
  import { mapGetters, mapState } from 'vuex';
  import controller from '@/controller';
  import { validateLink } from '@/util/validateLink';
  import TweetDisplay from '@/components/TweetDisplay.vue';
  import { formatTweet } from '@/util/formatTweet';
  import NewThreadMixin from '@/views/Mixins/NewThreadMixin.vue';

  export default {
    name: 'reply-preview',
    props: {
      tweets: {
        type: Array,
        default() {
          return [];
        },
        description: 'Tweet media',
      },
      hasThreadFinisherTweet: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        originalTweetInfo: null,
        originalTweetMetrics: null,
        error: null,
        isLoading: false,
        initialTweetsValue: [this.emptyTweet(0)],
      };
    },
    components: {
      TweetDisplay,
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser' }),
      ...mapState(['replyToTweetUrl']),
    },
    methods: {
      async fetchOriginalTweetData(tweetUrl) {
        this.isLoading = true;
        this.error = null;

        if (!tweetUrl || tweetUrl.length <= 0) {
          this.isLoading = false;
          return;
        }

        const { tweetId, isValid, error } = validateLink(tweetUrl);
        if (!isValid) {
          this.isLoading = false;
          this.error = error;
          return;
        }

        try {
          const res = await controller.getTweet(tweetId, this.currentUser, this.userProfile.uid);
          const user = res.includes.users[0];
          this.originalTweetInfo = {
            username: user.username,
            userDisplayName: user.name,
            userProfilePictureURL: user.profile_image_url,
            userTwitterId: user.id,
            text: res.data.text,
            createdAt: new Date(res.data.created_at),
            id: res.data.id,
          };
          this.originalTweetMetrics = {
            likes: this.roundUpValue(res.data.public_metrics.like_count),
            replies: this.roundUpValue(res.data.public_metrics.reply_count),
            retweets: this.roundUpValue(res.data.public_metrics.retweet_count),
            bookmarks: this.roundUpValue(res.data.public_metrics.bookmark_count),
            impressions: this.roundUpValue(res.data.public_metrics.impression_count),
          };
          this.$emit('updateParentOriginalTweetInfo', this.originalTweetInfo);
        } catch (error) {
          if (error.response && error.response.status === 404) {
            this.error = 'Tweet not found';
          } else {
            this.error = 'Tweet link invalid';
          }
        }
        this.isLoading = false;
      },
      formatTweet(status) {
        const sendUsDMButtonRegex = /https:\/\/twitter\.com\/messages\/compose\?recipient_id=\d+$/;
        if (sendUsDMButtonRegex.test(status.trim())) {
          return formatTweet(status.replace(sendUsDMButtonRegex, ''));
        }
        return this.correctSpecialCharacters(formatTweet(status));
      },
      roundUpValue(value) {
        switch (true) {
          case value >= 1_000_000:
            return Math.ceil(value / 100_000) / 10 + 'M';

          case value >= 1_000:
            return Math.ceil(value / 100) / 10 + 'K';

          default:
            return value;
        }
      },
      resetStats() {
        this.originalTweetInfo = null;
        this.originalTweetMetrics = null;
        this.error = null;
        this.isLoading = false;
        store.commit('setReplyToTweetUrl', '');
      },
      correctSpecialCharacters(text) {
        const replacements = {
          '&amp;': '&',
          '&lt;': '<',
          '&gt;': '>',
        };

        return text.replace(/&(amp|lt|gt);/g, (match) => {
          return replacements[match] || match;
        });
      },
    },
    beforeDestroy() {
      this.resetStats();
    },
    mixins: [NewThreadMixin],
    watch: {
      replyToTweetUrl(newVal) {
        this.fetchOriginalTweetData(newVal);
      },
      tweets(newVal) {
        this.initialTweetsValue = newVal;
      },
    },
  };
</script>

<style scoped>
  .parentMetrics {
    max-width: 520px;
  }
</style>
