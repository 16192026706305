<template>
  <fade-transition>
    <div
      v-if="visible"
      class="alert"
      :class="[`alert-${type}`, `${radius}`, { 'alert-dismissible': dismissible }]"
      role="alert"
    >
      <template v-if="!dismissible">
        <div
          class="flex h-10 w-10 items-center justify-center rounded-full bg-ds-button-secondary-background"
        >
          <inline-svg src="/img/icons/warning.svg" class="w-6 h-6" v-if="type === 'warning'"></inline-svg>
          <inline-svg src="/img/icons/star.svg" class="w-6 h-6 text-success-100" v-else-if="type === 'evergreen'"></inline-svg>
          <inline-svg
            src="/img/icons/infoAlert.svg"
            fill="none"
            class="h-6 w-6 stroke-ds-button-icon"
            v-else
          ></inline-svg>
        </div>
        <div class="flex-1 text-lg ml-2 font-main-font">
          <slot></slot>
        </div>
      </template>
      <template v-else>
        <template v-if="icon || $slots.icon">
          <slot name="icon">
            <span class="alert-icon" data-notify="icon">
              <i :class="icon"></i>
            </span>
          </slot>
        </template>
        <span class="alert-text">
          <slot></slot>
        </span>
        <slot name="dismiss-icon">
          <button type="button"
                  class="btn color-black close bg-dark focus:bg-dark hover:bg-dark"
                  data-dismiss="alert"
                  aria-label="Close"
                  @click="dismissAlert">
            <span aria-hidden="true">
              <inline-svg class="text text-primary" style="margin: 0; color: gray;" src="/img/icons/close.svg"></inline-svg>
            </span>
          </button>
        </slot>
      </template>
    </div>
  </fade-transition>
</template>
<script>
  import { FadeTransition } from 'vue2-transitions';

  export default {
    name: 'base-alert',
    components: {
      FadeTransition
    },
    props: {
      type: {
        type: String,
        default: 'default',
        description: 'Alert type'
      },
      radius: {
        type: String,
        default: 'rounded',
        description: 'Alert corner radius'
      },
      dismissible: {
        type: Boolean,
        default: false,
        description: 'Whether alert is dismissible (closeable)'
      },
      icon: {
        type: String,
        default: '',
        description: 'Alert icon to display'
      },
      isOrangeBackground: {
        type: Boolean,
        default: false,
        description: 'Whether alert has orange background'
      }
    },
    data() {
      return {
        visible: true
      };
    },
    methods: {
      dismissAlert() {
        this.visible = false;
        this.$emit('dismiss');
      },
    },
  };
</script>
