<script>
  import { trialPeriodCalculator } from '@/../functions/src/util/trialPeriodCalculator';
  import { mapGetters } from 'vuex';
  export default {
    name: 'trial-period-mixin',
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
      isTrialPeriodOver() {
        return trialPeriodCalculator(this.userProfile).daysLeft === 0;
      },
    },
  };
</script>