/*
 * Helper to format followers and following counts.
 * If value is +1000 it shows a K, if its bigger than 1 million it shows M
 * Source: https://stackoverflow.com/a/9462382/9675299
 */
export function countFormatter(num, digits) {
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
  ];

  const regex = /\.0+$|(\.[0-9]*[1-9])0+$/;

  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }

  return (num / si[i].value).toFixed(digits).replace(regex, '$1') + si[i].symbol;
}
