import BaseInput from '@/components/Inputs/BaseInput.vue';
import BaseDropdown from '@/components/BaseDropdown.vue';
import Card from '@/components/Cards/Card.vue';
import Modal from '@/components/Modal.vue';
import StatsCard from '@/components/Cards/StatsCard.vue';
import BaseButton from '@/components/BaseButton.vue';
import NewButton from '../components/Buttons/NewButton.vue';
import Badge from '@/components/Badge.vue';
import RouteBreadcrumb from '@/components/Breadcrumb/RouteBreadcrumb';
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue';
import BaseSwitch from '@/components/BaseSwitch.vue';
import BaseRadio from "@/components/Inputs/BaseRadio";
import BaseProgress from "@/components/BaseProgress";
import BasePagination from "@/components/BasePagination";
import BaseAlert from "@/components/BaseAlert";
import BaseNav from "@/components/Navbar/BaseNav";
import BaseHeader from '@/components/BaseHeader';
import Tooltip from '@/components/Tooltip.vue';
import InfoTooltip from '@/components/InfoTooltip.vue';
import UpgradeToPremiumPopUp from '@/components/UpgradeToPremiumPopUp.vue';
import UpgradeToPremiumLink from '@/components/UpgradeToPremiumLink.vue';
import NewAlert from '@/components/NewAlert.vue';
import BaseModal from '@/components/BaseModal.vue';
import BaseToggle from '@/components/BaseToggle.vue';
import BaseSelect from '@/components/Inputs/BaseSelect.vue';
import BaseTextInput from '@/components/Inputs/BaseTextInput.vue';
import BaseNumberInput from '@/components/Inputs/BaseNumberInput.vue';
import NewBaseCheckbox from '@/components/Inputs/NewBaseCheckbox.vue';
import NewBaseRadio from '@/components/Inputs/NewBaseRadio.vue';
import NewDropDown from '@/components/NewDropDown.vue';

import { Input, Popover } from 'element-ui';
/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component(Badge.name, Badge);
    Vue.component(BaseAlert.name, BaseAlert);
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(BaseCheckbox.name, BaseCheckbox);
    Vue.component(BaseHeader.name, BaseHeader);
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(BaseDropdown.name, BaseDropdown);
    Vue.component(BaseNav.name, BaseNav);
    Vue.component(BasePagination.name, BasePagination);
    Vue.component(BaseProgress.name, BaseProgress);
    Vue.component(BaseRadio.name, BaseRadio);
    Vue.component(BaseSwitch.name, BaseSwitch);
    Vue.component(Card.name, Card);
    Vue.component(Modal.name, Modal);
    Vue.component(StatsCard.name, StatsCard);
    Vue.component(RouteBreadcrumb.name, RouteBreadcrumb);
    Vue.component(Input.name, Input);
    Vue.component(Tooltip.name, Tooltip);
    Vue.component(InfoTooltip.name, InfoTooltip);
    Vue.component(UpgradeToPremiumPopUp.name, UpgradeToPremiumPopUp);
    Vue.component(UpgradeToPremiumLink.name, UpgradeToPremiumLink);
    Vue.component(NewButton.name, NewButton);
    Vue.component(NewAlert.name, NewAlert);
    Vue.component(BaseModal.name, BaseModal);
    Vue.component(BaseToggle.name, BaseToggle);
    Vue.component(BaseSelect.name, BaseSelect);
    Vue.component(BaseTextInput.name, BaseTextInput);
    Vue.component(BaseNumberInput.name, BaseNumberInput);
    Vue.component(NewBaseCheckbox.name, NewBaseCheckbox);
    Vue.component(NewBaseRadio.name, NewBaseRadio);
    Vue.component(NewDropDown.name, NewDropDown);
    Vue.use(Popover);
  },
};

export default GlobalComponents;
