export function getStripePricesForUser(userProfile, prices) {
  const premiumPrice =
    userProfile.subscriptionInterval === 'month'
      ? `$${prices.premiumMonthlyPrice}/month`
      : `$${prices.premiumYearlyPrice}/year`;

  const standardPrice =
    userProfile.subscriptionInterval === 'month'
      ? `$${prices.standardMonthlyPrice}/month`
      : `$${prices.standardYearlyPrice}/year`;

  return { premiumPrice, standardPrice };
}

export function getPricesForUser(userProfile, prices, parityDiscount) {
  const isMonthlySubscription = userProfile.subscriptionInterval === 'month';

  const plans = prices.filter(
    (price) => price.subscription.interval === (isMonthlySubscription ? 'month' : 'year')
  );
  const pricesForUser = {};

  plans.forEach((plan) => {
    const planData = plan.product_title.toLowerCase().split(' ');
    planData.pop();
    const planName = planData.join('_');

    let net = plan.price.net;
    let tax = plan.price.tax;

    if (parityDiscount) {
      const discount = Number(parityDiscount);
      const amountOff = (discount / 100) * net;
      const taxPercentage = (100 * tax) / net;
      net = (net - amountOff).toFixed(2);
      tax = ((taxPercentage / 100) * net).toFixed(2);
    }

    pricesForUser[planName] = `${net} ${plan.currency} + VAT ${tax} ${plan.currency}/month`;
  });

  return pricesForUser;
}
