<script>
  import lodash from 'lodash';
  import { mapGetters } from 'vuex';

  export default {
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
      isUserConnectedToInstagram() {
        return lodash.get(this.userProfile, 'integrations.instagram.tokenInfo.accessToken', null);
      },
      numberOfHashtags() {
        const caption = lodash.get(this, 'instagramCaption', '');
        return caption ? (caption.match(/#/g) || []).length : 0;
      },
      numberOfMentions() {
        const caption = lodash.get(this, 'instagramCaption', '');
        return caption ? (caption.match(/@/g) || []).length : 0;
      },
      validationMessage() {
        let message = '';
        const caption = lodash.get(this, 'instagramCaption', '');

        if (
          caption &&
          caption.length &&
          (this.shareOnInstagram ||
            this.isTweetshotMode === false ||
            this.instagramAutoShareEnabled === true ||
            this.isShareOnInstagramEnabled)
        ) {
          if (this.numberOfHashtags > 30) {
            message += 'Only up to 30 hashtags';
          }

          if (this.numberOfMentions > 20) {
            if (message) {
              message += ' and 20 mentions';
            } else {
              message += 'Only up to 20 mentions';
            }
          }

          return message ? `${message} are allowed.` : '';
        } else {
          return '';
        }
      },
    },
  };
</script>
