import { isEnvProd } from '@/config';

export async function initMatomo(store) {
  const loggedInUser = store.getters.getUserProfile;
  const isUserLoggedIn = loggedInUser && loggedInUser.uid;

  if (isUserLoggedIn && isEnvProd) {
    loadMatomo();
  }
}

function loadMatomo() {
  var _mtm = (window._mtm = window._mtm || []);
  _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
  var d = document,
    g = d.createElement('script'),
    s = d.getElementsByTagName('script')[0];
  g.async = true;
  g.src =
    'https://hypefury.com/wp-content/plugins/matomo/app/../../../uploads/matomo/container_ph92j3VK.js';
  s.parentNode.insertBefore(g, s);
}
