<template>
  <div v-if="tweet.poll">
    <ul class="m-0 mt-4 list-none space-y-1 p-0">
      <li
        class="card-child-bg rounded-lg px-3 py-2 text-sm"
        v-for="(option, i) in tweet.poll.options"
        :key="i"
      >
        {{ option }}
      </li>
    </ul>
    <div class="pt-4 text-sm" v-if="!tweet.published">
      Poll will run for
      <span v-if="tweet.poll.duration.days > 0"> {{ tweet.poll.duration.days }}d </span>
      <span v-if="tweet.poll.duration.hours > 0"> {{ tweet.poll.duration.hours }}h </span>
      <span v-if="tweet.poll.duration.minutes > 0"> {{ tweet.poll.duration.minutes }}m </span>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'poll-options',
    props: {
      tweet: {
        type: Object,
        required: true,
      },
    },
  };
</script>
