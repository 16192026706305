export function initAcute(store, loadScript) {
  const loggedInUser = store.getters.getUserProfile;

  if (!loggedInUser || !loggedInUser.uid) return;

  window.actSettings = {
    token: '3549679e06917233364d1fe0bf684475aa7494dee289c79b2721d961e7377497',
    user: {
      id: loggedInUser.uid,
      email: loggedInUser.email,
      name: `${loggedInUser.name} (${loggedInUser.username})`,
      avatarURL: loggedInUser.photoURL,
      created: loggedInUser.createdAt().toISOString(),
      mrr: loggedInUser.mrr,
    },
    widget: {
      type: 'modal',
    },
  };

  loadScript('https://assets.getacute.io/assets/widget.js')
    .then(() => {
      store.commit('setIsAcuteReady', true);
      document.dispatchEvent(new Event('DOMContentLoaded'));
    });
}
