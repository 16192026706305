<template>
  <portal to="isLoading">
    <div class="overlay" v-if="isLoading">
      <div class="loader fixed">
        <span class="loading loading-lg"
          ><inline-svg src="/img/icons/loading.svg"></inline-svg
        ></span>
      </div>
    </div>
  </portal>
</template>

<script>
  import controller from '@/controller';
  import { getPricesForUser } from '@/util/getPricesForUser';
  import { mapGetters } from 'vuex';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import CustomerCountryMixin from '@/views/Mixins/CustomerCountryMixin.vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import { v4 as publicIpv4 } from 'public-ip';
  import CustomerStatusMixin from '../views/Mixins/CustomerStatusMixin.vue';
  import lodash from 'lodash';

  const fb = require('@/firebase');

  export default {
    data() {
      return {
        isLoading: false,
        unsubscribe: null,
      };
    },
    name: 'upgrade-to-next-plan-pop-up',
    computed: {
      ...mapGetters({
        currentUser: 'getCurrentUser',
        userProfile: 'getUserProfile',
        parentUserProfile: 'getParentUserProfile',
      }),
    },
    props: {
      content: {
        type: String,
        default: null,
      },
      show: {
        type: Boolean,
        default: false,
      },
      nextPlan: {
        type: Object,
      },
    },
    methods: {
      close() {
        this.$emit('close');
      },
      async upgradeUserToNextPlan(newPriceInfo = null) {
        try {
          this.parentUserProfile.isStripeCustomer
            ? await controller.upgradeStripeUserToSelectedPlan(this.currentUser, this.nextPlan.name)
            : await controller.upgradeUserToSelectedPlan(
                this.currentUser,
                newPriceInfo,
                this.nextPlan.name
              );
          this.isLoading = true;

          await new Promise((resolve) => {
            this.unsubscribe = fb.usersCollection.doc(this.userProfile.uid).onSnapshot((doc) => {
              const customerStatus = lodash.get(doc.data(), 'customerStatus');
              if (customerStatus === this.nextPlan.name) {
                const message = `Upgraded to ${this.nextPlan.label} successfully!`;
                this.$notify({ type: 'success', message });
                this.unsubscribe();
                this.isLoading = false;
                resolve();
              }
            });
          });
        } catch (error) {
          console.error(error);
          this.swalModal({
            title: 'Sorry',
            text: 'An error occurred while upgrading your account.',
            type: 'error',
          });
          this.isLoading = false;
        }
      },
      async confirmUserForUpgrade() {
        if (this.isUserNotSubscribed(this.parentUserProfile.customerStatus)) {
          const content =
            this.content ||
            `Grab a subscription to the ${this.nextPlan.label} plan and unlock this and a ton of other powerful features!`;
          return this.swalModal({
            title: `Unlock this feature`,
            html: content,
            type: 'info',
            confirmButtonText: 'Go to Billing',
            cancelButtonText: 'No, thanks',
            showCancelButton: true,
            onClose: this.close,
            preConfirm: () => {
              this.$router.push('/billing');
              return true;
            },
          });
        }

        try {
          this.isLoading = true;
          await this.getCustomerCountry();
          const customerIp = await publicIpv4();
          const pricesFromAPI = await controller.getPlansPrices(this.currentUser, customerIp);

          this.isLoading = false;

          const prices = getPricesForUser(this.parentUserProfile, pricesFromAPI);

          let newPriceInfo = null;

          let priceComparisonToShow = prices[this.parentUserProfile.customerStatus];

          const content =
            this.content ||
            `Upgrade to the ${this.nextPlan.label} plan to unlock a ton of powerful features!`;

          this.swalModal({
            title: `Unlock this feature`,
            html:
              content +
              `<br>You will be billed <b>${
                prices[this.nextPlan.name]
              }</b> instead of ${priceComparisonToShow}.`,
            type: 'info',
            confirmButtonText: 'Yes, I want the best plan!',
            cancelButtonText: "No, thanks, I'm good",
            showCancelButton: true,
            allowOutsideClick: false,
            onClose: this.close,
            preConfirm: async () => {
              if (this.userProfile.customerStatus === 'none') {
                this.$router.push('/billing');
                return true;
              }
              Swal.getConfirmButton().textContent = 'Upgrading...';
              Swal.getCloseButton().disabled = true;
              await this.upgradeUserToNextPlan(newPriceInfo);
              return true;
            },
          });
        } finally {
          this.isLoading = false;
        }
      },
    },
    watch: {
      async show(val) {
        if (val) {
          this.confirmUserForUpgrade();
        }
      },
    },
    mixins: [SwalModalMixin, CustomerCountryMixin, CustomerStatusMixin],
  };
</script>
