<template>
  <div class="flex flex-col gap-6">
    <div v-for="(tweet, idx) in tweets" :key="tweet.guid" class="space-y-2">
      <div class="flex items-center gap-2">
        <img :src="userProfile.photoURL" :alt="userProfile.name" class="avatar-img" />
        <span class="avatar-name">{{ userProfile.name }}</span>
        <span class="avatar-username">@{{ userProfile.username }}</span>
      </div>
      <div class="ml-13">
        <div
          class="word-break whitespace-pre-wrap break-words text-base"
          v-html="formatTweet(tweet.status)"
          dir="auto"
        />
        <div v-if="hasPoll(tweet)" class="tweet-container poll-container-new mt-2">
          <div v-for="(pollOption, i) in pollOptions(tweet)" :key="`poll-option-${i}`" class="poll-question mb-2 border-none">
            <div class="poll-option-preview flex w-full items-center justify-between p-1">
              <div class="flex items-center gap-x-2">
                <hr class="poll-border bg-gray-100 dark-mode:bg-dark-mode-10" />
                <input
                  :ref="`poll-${i}`"
                  :placeholder="`Option ${i + 1}${i > 1 ? ' (optional)' : ''}`"
                  disabled
                  :value="pollOption"
                  maxlength="25"
                  class="form-control simple"
                />
              </div>
              <div class="poll-reach-preview">0%</div>
            </div>
          </div>
          <div class="space-x-1 text-base text-gray-100 dark-mode:text-dark-mode-10">
            <span>0 votes</span>
            <span>•</span>
            <span>{{ pollOptionsTime(tweet) }}</span>
          </div>
        </div>
        <div v-if="containsSendUsDMButton(tweet.status)">
          <button
            class="border outline-none mx-auto mt-2 flex w-full items-center justify-center gap-x-2 rounded-full border-main-color-100 px-3 py-2 font-bold text-main-color-100"
          >
            <inline-svg src="img/icons/send-us-dm-preview.svg" />
            Send us a private message
          </button>
        </div>
        <div class="grid grid-cols-3 gap-2 pt-2">
          <div
            class="border rounded-lg border-gray-10 p-1"
            v-for="(mediaFile, i) in tweet.mediaFile"
            :key="i"
          >
            <img
              v-if="['image', 'gif'].includes(tweet.mediaFile[i].type)"
              :src="tweet.mediaFile[i].url"
              class="h-full rounded-lg object-cover object-center"
            />
            <video
              v-if="tweet.mediaFile[i].type === 'video'"
              :src="tweet.mediaFile[i].url"
              class="h-full w-full rounded-lg"
            />
          </div>
        </div>
        <tweet-display
          v-if="tweet.quoteTweetData"
          :username="tweet.quoteTweetData.username"
          :userDisplayName="tweet.quoteTweetData.userDisplayName"
          :tweet="tweet.quoteTweetData.text"
          :profilePictureURL="tweet.quoteTweetData.userProfilePictureURL"
        />

        <tweet-display
          v-if="hasThreadFinisherTweet && tweets.length - 1 === idx"
          :username="userProfile.username"
          :userDisplayName="userProfile.name"
          :tweet="tweets[0].status"
          :profilePictureURL="userProfile.photoURL"
        />
      </div>
    </div>
    <div v-if="isSendDMButtonInserted">
      <hr />
      <p class="mx-auto mt-4">
        For the button to be displayed, "Allow DMs from everyone" must be enabled in your 𝕏
        settings.
      </p>
    </div>
  </div>
</template>

<script>
  import TweetDisplay from '@/components/TweetDisplay.vue';
  import { formatTweet } from '@/util/formatTweet';

  export default {
    name: 'TweetThreadPreview',
    components: { TweetDisplay },
    props: {
      tweets: {
        type: Array,
        default() {
          return [];
        },
        description: 'Tweet media',
      },
      userProfile: {
        type: Object,
        default() {
          return {};
        },
        description: 'Tweet media',
      },
      hasThreadFinisherTweet: {
        type: Boolean,
        required: true,
      },
    },
    computed: {
      isSendDMButtonInserted() {
        const sendUsDMButtonRegex = /https:\/\/twitter\.com\/messages\/compose\?recipient_id=\d+/;
        return this.tweets.some((tweet) => {
          return sendUsDMButtonRegex.test(tweet.status);
        });
      },
    },
    methods: {
      pollOptionsTime(tweet) {
        if (tweet.poll && tweet.poll.options.length) {
          const duration = tweet.poll.duration;
          if (duration.days) {
            return `${duration.days} day${duration.days > 1 ? 's' : ''} left`;
          } else if (duration.hours) {
            return `${duration.hours} hour${duration.hours > 1 ? 's' : ''} left`;
          } else if (duration.minutes) {
            return `${duration.minutes} minute${duration.minutes > 1 ? 's' : ''} left`;
          }
        }
        return null;
      },
      hasPoll(tweet) {
        return (
          tweet.poll && tweet.poll.options && tweet.poll.options.length && tweet.poll.options[0]
        );
      },
      pollOptions(tweet) {
        return tweet.poll.options.filter((option) => option);
      },
      containsSendUsDMButton(status) {
        const sendUsDMButtonRegex = /https:\/\/twitter\.com\/messages\/compose\?recipient_id=\d+/;
        return sendUsDMButtonRegex.test(status);
      },
      formatTweet(status) {
        const sendUsDMButtonRegex = /https:\/\/twitter\.com\/messages\/compose\?recipient_id=\d+$/;
        if (sendUsDMButtonRegex.test(status.trim())) {
          return formatTweet(status.replace(sendUsDMButtonRegex, ''));
        }
        return formatTweet(status);
      },
    },
  };
</script>
