var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.link ? 'a' : 'button',_vm._g(_vm._b({tag:"component",staticClass:"btn",class:{
    'px-4 py-2.5 text-base md:px-6 md:py-4 md:text-lg':
      _vm.size === 'lg' && !_vm.icon && _vm.type !== 'custom',
    'px-4 py-2 text-base': _vm.size === 'md' && !_vm.icon && _vm.type !== 'custom',
    'px-3 py-1.5 text-sm': _vm.size === 'sm' && !_vm.icon && _vm.type !== 'custom',
    'px-3 py-1 text-xs': _vm.size === 'xs' && !_vm.icon && _vm.type !== 'custom',
    'flex h-10 w-10 items-center justify-center': _vm.size === 'lg' && _vm.icon,
    'flex h-8 w-8 items-center justify-center': _vm.size === 'md' && _vm.icon,
    'flex h-5 w-5 items-center justify-center': _vm.size === 'sm' && _vm.icon,
    'flex h-4 w-4 items-center justify-center': _vm.size === 'xs' && _vm.icon,
    'btn-primary': _vm.type === 'primary',
    'btn-primary rounded-left': _vm.type === 'rounded-left',
    'btn-primary rounded-right': _vm.type === 'rounded-right',
    'btn-secondary': _vm.type === 'secondary',
    'btn-success': _vm.type === 'success',
    'btn-light': _vm.type === 'light',
    'btn-transparent': _vm.type === 'transparent',
    'btn-danger': _vm.type === 'danger',
    'btn-transparent-gray': _vm.type === 'transparent-gray',
    'btn-dropdown': _vm.type === 'dropdown',
    'btn-dropdown-full': _vm.type === 'dropdown-full',
    'btn-dropdown-full primary': _vm.type === 'dropdown-full-primary',
    'btn-dropdown-full secondary': _vm.type === 'dropdown-full-secondary',
    'btn-custom': _vm.type === 'custom',
    'btn-bordered': _vm.type === 'bordered',
    'btn-underline': _vm.type === 'underline',
    inactive: _vm.inactive,
    'btn-absolute': _vm.absolute,
    'btn-highlight': _vm.highlighted,
  }},'component',_vm.$attrs,false),_vm.$listeners),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }