const firebase = require('firebase/compat/app')
require('firebase/compat/firestore');
const fb = require('@/firebase');
const moment = require('moment');

const daoGrowthProgram = {};

daoGrowthProgram.addUsers = (growthProgramId, users, role) => {
  return fb.growthProgramsCollection.doc(growthProgramId).update({
    [role]: firebase.firestore.FieldValue.arrayUnion(...users),
  });
};

daoGrowthProgram.deleteUser = (growthProgramId, userId, role) => {
  const userRef = fb.usersCollection.doc(userId);
  return fb.growthProgramsCollection.doc(growthProgramId).update({
    [role]: firebase.firestore.FieldValue.arrayRemove(userRef),
  });
};

daoGrowthProgram.deleteGrowthProgram = (growthProgramId) => {
  return fb.growthProgramsCollection.doc(growthProgramId).delete();
};

daoGrowthProgram.getGrowthPrograms = () => {
  return fb.growthProgramsCollection.orderBy('date', 'desc').get();
};

daoGrowthProgram.saveGrowthProgram = (growthProgram) => {
  return fb.growthProgramsCollection.add(growthProgram);
};

daoGrowthProgram.addCustomerToCustomersTweetsFinished = (growthProgramId, selectedUser) => {
  return fb.growthProgramsCollection.doc(growthProgramId).update({
    customersTweetsFinished: firebase.firestore.FieldValue.arrayUnion(
      fb.usersCollection.doc(selectedUser.uid)
    ),
  });
};

daoGrowthProgram.removeCustomerFromCustomersTweetsFinished = (growthProgramId, selectedUser) => {
  return fb.growthProgramsCollection.doc(growthProgramId).update({
    customersTweetsFinished: firebase.firestore.FieldValue.arrayRemove(
      fb.usersCollection.doc(selectedUser.uid)
    ),
  });
};

daoGrowthProgram.getUserGrowthProgramThreads = (userDoc, growthProgramRef) => {
  return fb.threadsCollection
    .where('deleted', '==', false)
    .where('user', '==', userDoc.ref)
    .where('time', '==', null)
    .where('growthProgram', '==', growthProgramRef)
    .get();
};

daoGrowthProgram.addGrowthProgramRole = (userId, growthProgramId, role) => {
  const permissionToAdd = role === 'writer' ? { isWriter: true } : { isBooster: true };
  return fb.usersCollection.doc(userId).update({
    [`growthProgramsRoles.${growthProgramId}`]: firebase.firestore.FieldValue.arrayUnion(role),
    ...permissionToAdd,
  });
};

daoGrowthProgram.deleteGrowthProgramInfo = (growthProgramId, userId) => {
  return fb.usersCollection.doc(userId).update({
    [`growthProgramsRoles.${growthProgramId}`]: firebase.firestore.FieldValue.delete(),
  });
};

daoGrowthProgram.deleteGrowthProgramRole = (growthProgramId, userId, role) => {
  return fb.usersCollection.doc(userId).update({
    [`growthProgramsRoles.${growthProgramId}`]: firebase.firestore.FieldValue.arrayRemove(role),
  });
};

daoGrowthProgram.getSelectedGrowthProgramWriters = (growthProgramId) => {
  return fb.usersCollection
    .where(`growthProgramsRoles.${growthProgramId}`, 'array-contains', 'writer')
    .get();
};

daoGrowthProgram.removeGrowthProgramPermission = (userId, role) => {
  const permissionToRemove = role === 'writer' ? { isWriter: false } : { isBooster: false};
  return fb.usersCollection.doc(userId).update(permissionToRemove);
};

daoGrowthProgram.updateGrowthProgramName = (userId, value) => {
  return fb.usersCollection.doc(userId).update({
    growthProgramName: value,
  });
};

daoGrowthProgram.confirmGrowthProgramPublishing = (growthProgramId) => {
  return fb.growthProgramsCollection.doc(growthProgramId).update({
    isPublishConfirmed: true,
  });
};

daoGrowthProgram.getCurrentGrowthProgram = () => {
  const nextMonthDate = (moment().add(1, 'month')).toDate();
  return fb.growthProgramsCollection
    .where('month', '==', nextMonthDate.getMonth() + 1)
    .where('year', '==', nextMonthDate.getFullYear())
    .get()
    .then((res) => res.docs[0]);
};

export default daoGrowthProgram;