import controller from '@/controller';
import moment from 'moment';

export const getInspirationTweets = async function (currentUser, userId) {
  const inspirationTweets = (() => {
    const inspirationTweetsRaw = localStorage.getItem(`inspiration_tweets.${userId}`);
    if (!inspirationTweetsRaw) return null;
    return JSON.parse(inspirationTweetsRaw);
  })();

  if (!inspirationTweets || inspirationTweets.tweets.some((tweet) => !tweet.id)) {
    const inspirationTweets = await fetchAndSaveInspirationTweets(currentUser, userId);
    return inspirationTweets;
  }

  if (moment(inspirationTweets.time).isBefore(moment().subtract(7, 'days'))) {
    await fetchAndSaveInspirationTweets(currentUser, userId);
  }

  return inspirationTweets.tweets;
};

async function fetchAndSaveInspirationTweets(currentUser, userId) {
  const tweets = (await controller.getInspirationTweets(currentUser, userId)).tweets;

  localStorage.setItem(
    `inspiration_tweets.${userId}`,
    JSON.stringify({ tweets, time: new Date() })
  );

  return tweets;
}

export const updateInspirationTweetInLocalStorage = (
  inspirationTweets,
  inspirationTweet,
  userId
) => {
  const time = JSON.parse(localStorage.getItem(`inspiration_tweets.${userId}`)).time;

  const newInspirationTweets = inspirationTweets.map((tweet) => {
    return tweet.id === inspirationTweet.id ? inspirationTweet : tweet;
  });

  localStorage.setItem(
    `inspiration_tweets.${userId}`,
    JSON.stringify({ tweets: newInspirationTweets, time })
  );
};
