<template>
  <div
    class="gif-selector-wrapper p-4"
    :style="!isSmallScreen ? 'width: 416px; height: 452px' : ''"
  >
    <div class="gif-selector" :class="{ loading: isLoading }">
      <div class="gif-selector-top">
        <div class="gif-selector-input">
          <input
            type="text"
            placeholder="Search Tenor"
            class="form-control gif-selector-search"
            v-model="currentQuery"
            @input="processSearch"
          />
        </div>
      </div>
      <div class="gif-selector-main">
        <div class="gif-selector-search-results">
          <div v-for="(row, rowIndex) in gifRows" :key="rowIndex" class="gif-selector-gif-row">
            <div v-for="gif in row" :key="gif.id" class="gif-selector-gif-wrapper">
              <div data-cy="gif-selector-gif" class="gif-selector-gif">
                <a
                  href="#"
                  class="gif-selector-select-anchor"
                  @click.prevent="chooseGif(gif.media[0].tinygif.url)"
                >
                  <img
                    class="gif-selector-image"
                    :src="gif.media[0].nanogif.url"
                    :data-gif-selector-gif="gif.media[0].tinygif.url"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  import '@/assets/css/gif-selector.css';
  import { httpRoutesURL } from '@/config';
  import controller from '@/controller';
  import { mapGetters } from 'vuex';
  import BreakpointsMixin from '@/views/Mixins/BreakpointsMixin.vue';

  export default {
    name: 'NewGifSelector',
    mixins: [BreakpointsMixin],
    props: {
      index: Number,
      disabled: {
        type: Boolean,
        default: false,
      },
      columns: {
        type: Number,
        default: 3,
      },
      rowsEnabled: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        currentQuery: '',
        matchingGifs: {},
        trending: {},
        isLoading: false,
        options: {
          baseUrl: httpRoutesURL + '/tenor',
        },
      };
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      gifRows() {
        const gifs =
          this.matchingGifs && this.matchingGifs.results && this.matchingGifs.results.length > 0
            ? this.matchingGifs.results
            : this.trending.results || [];

        if (!this.rowsEnabled) {
          return [gifs];
        }

        const rows = [];
        for (let i = 0; i < gifs.length; i += this.columns) {
          rows.push(gifs.slice(i, i + this.columns));
        }
        return rows;
      },
    },
    methods: {
      close() {
        this.$emit('close-gif-selector');
      },
      setIsLoading(val) {
        this.isLoading = val;
      },
      async loadTrending() {
        this.setIsLoading(true);
        const response = await controller.getTrendingGifs(this.currentUser);
        this.setIsLoading(false);

        return response;
      },
      async search(query) {
        this.setIsLoading(true);
        const response = await controller.searchGifs(this.currentUser, query);
        this.setIsLoading(false);

        return response;
      },
      chooseGif(url) {
        this.$emit('selected-gif', url, this.index);
        this.$emit('close-gif-selector');
      },
      processSearch: _.debounce(async function () {
        this.matchingGifs = await this.search(this.currentQuery);
      }, 200),
      async loadInitialData() {
        if (this.trending && this.trending.results) {
          return;
        }

        this.trending = await this.loadTrending();
      },
    },
    async mounted() {
      await this.loadInitialData();
    },
  };
</script>
