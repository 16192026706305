<template>
  <div
    class="styled-scrollbars overscroll-behavior-contain flex h-full flex-col justify-start gap-y-6 overflow-y-auto"
  >
    <div ref="focusTextAreaContainer">
      <vue-tribute :options="tributeOptions">
        <textarea
          class="form-control grow simple my-1 min-h-16"
          ref="focus-mode-textarea"
          v-model="focusModeStatus"
          @input="updateTweets"
          placeholder="Tip: You can add multiple tweets by pressing enter three times."
          dir="auto"
          autofocus
        />
      </vue-tribute>
    </div>
  </div>
</template>
<script>
  import { adjustTextAreaHeight } from '@/util/adjustTextAreaHeight';
  import TaggingMixin from '@/views/Mixins/TaggingMixin.vue';
  import ThreadMixin from '@/views/Mixins/ThreadMixin.vue';

  export default {
    name: 'NewFocusModeComposer',
    data() {
      return {
        focusTextAreaHeight: 80,
        tweets: [this.emptyTweet(0)],
      };
    },
    props: {
      tweetsFromParent: {
        type: Array,
        default: () => [],
      },
      composerStatus: {
        type: String,
        required: true,
        validator: (value) => {
          return ['FILLING', 'IDLE'].includes(value);
        },
      },
    },
    methods: {
      adjustTextAreaHeight() {
        this.focusTextAreaHeight = adjustTextAreaHeight(
          this.$refs['focus-mode-textarea'],
          this.$refs.focusTextAreaContainer,
          this.focusTextAreaHeight,
        );
      },
      onInputChange() {
        this.$emit('input-changed');
      },
    },
    watch: {
      focusModeStatus: function () {
        this.$emit('status-updated', this.tweets);
      },
      tweetsFromParent: {
        handler: function (tweets) {
          this.tweets = tweets;
          if (this.tweets.some((tweet) => tweet.status) && this.composerStatus === 'FILLING') {
            this.focusModeStatus = tweets.map((tweet) => tweet.status).join('\n\n\n');
            this.$nextTick(() => {
              this.adjustTextAreaHeight();
            });
            this.$emit('composer-status-updated', 'IDLE');
          }
          if (tweets.length === 1 && tweets[0].status === '') {
            this.focusModeStatus = '';
          }
        },
        deep: true,
        immediate: true,
      },
    },
    updated() {
      this.adjustTextAreaHeight();
    },
    mixins: [TaggingMixin, ThreadMixin],
  };
</script>
