<script>
  export default {
    name: 'arrow-down-icon',
    props: {
      width: {
        type: [String, Number],
        default: '9',
      },
      height: {
        type: [String, Number],
        default: '6',
      },
    },
  };
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 9 6"
    fill="none"
  >
    <path d="M1 1.25L4.5 4.75L8 1.25" stroke-width="1.5" stroke-linecap="round" />
  </svg>
</template>

<style lang="scss" scoped>
  .dropdown-menu .dropdown-item svg {
    stroke: var(--dropdown-title);
  }

  .avatar svg {
    stroke: var(--color-primary);
  }

  svg {
    stroke: var(--primary-blue);
  }
  .composer-dropdown svg {
    stroke: #ffffff;
  }
</style>
