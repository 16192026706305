var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"relative",class:{
    tag: _vm.type === 'tag',
    checkbox: _vm.type === 'checkbox',
    disabled: _vm.disabled,
  }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],class:{
      tag: _vm.type === 'tag',
      checkbox: _vm.type === 'checkbox',
    },attrs:{"type":"checkbox","disabled":_vm.disabled},domProps:{"value":_vm.inputValue,"checked":Array.isArray(_vm.model)?_vm._i(_vm.model,_vm.inputValue)>-1:(_vm.model)},on:{"change":function($event){var $$a=_vm.model,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.inputValue,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.model=$$a.concat([$$v]))}else{$$i>-1&&(_vm.model=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.model=$$c}}}}),_c('span',[_vm._v(_vm._s(_vm.label))])])
}
var staticRenderFns = []

export { render, staticRenderFns }