<script>
  export default {
    name: 'base-toggle',
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      size: {
        type: String,
        default: 'sm',
        validator(value) {
          return ['lg', 'sm'].includes(value);
        },
      },
      stateShouldRelyOnValue: {
        type: Boolean,
        default: false,
        description:
          'If true, the state of the toggle will be dependent on the value prop. If false, the state will be independent of the value prop.',
      },
    },
    data() {
      return {
        enabled: this.value,
      };
    },
    watch: {
      value() {
        this.enabled = this.value;
      },
    },
    methods: {
      handleClick() {
        // This is a hack and changing it will break the toggle in a lot of features so i've added this flag to handle the cases where we need the toggle to be dependent on the value prop
        if (!this.stateShouldRelyOnValue) {
          this.enabled = !this.enabled;
        }
        this.$emit('input', this.enabled);
      },
    },
  };
</script>

<template>
  <button
    @click.stop="handleClick"
    class="focus:outline-none relative inline-flex flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out"
    :class="{
      'bg-main-color-100': enabled,
      'bg-ds-outline-secondary': !enabled,
      'h-5 w-9': size === 'sm',
      'h-28 w-42': size === 'lg',
    }"
    role="switch"
  >
    <span
      aria-hidden="true"
      class="shadow transition pointer-events-none inline-block h-4 w-4 transform rounded-full duration-200 ease-in-out"
      :class="{
        'translate-x-4': enabled && size === 'sm',
        'translate-x-3.5': enabled && size === 'lg',
        'translate-x-0': !enabled,
        'h-4 w-4': size === 'sm',
        'h-6 w-6': size === 'lg',
        'bg-white': enabled,
        'bg-ds-button-controls-background': !enabled,
      }"
    ></span>
  </button>
</template>
