const userId = localStorage.getItem('currentUserId');

export const setUnfinishedPost = function (
  tweets,
  isFavorite,
  shareOnInstagram,
  hasThreadFinisherTweet,
  wasThreadFinisherAdded
) {
  return localStorage.setItem(
    `unfinished_post_${userId}`,
    JSON.stringify({
      tweets: tweets.map((tweet) => ({ ...tweet })),
      isFavorite,
      shareOnInstagram,
      hasThreadFinisherTweet,
      wasThreadFinisherAdded,
    })
  );
};

export const setUnfinishedEditedPost = function (thread) {
  return localStorage.setItem(`unfinished_edited_post_${userId}`, JSON.stringify(thread));
};

export const getUnfinishedPost = function () {
  const newUnfinishedPost = localStorage.getItem(`unfinished_post_${userId}`);
  if (newUnfinishedPost) {
    return JSON.parse(newUnfinishedPost);
  }
  return JSON.parse(localStorage.getItem('unfinished_posts'));
};

export const getUnfinishedEditedPost = function () {
  const newUnfinishedEditedPost = localStorage.getItem(`unfinished_edited_post_${userId}`);
  if (newUnfinishedEditedPost) {
    return JSON.parse(newUnfinishedEditedPost);
  }
  return JSON.parse(localStorage.getItem('unfinished_edited_posts'));
};

export const removeUnfinishedPost = function () {
  localStorage.removeItem('unfinished_posts');
  return localStorage.removeItem(`unfinished_post_${userId}`);
};

export const removeUnfinishedEditedPost = function () {
  localStorage.removeItem('unfinished_edited_posts');
  return localStorage.removeItem(`unfinished_edited_post_${userId}`);
};