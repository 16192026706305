<script>
  import Swal from 'sweetalert2/dist/sweetalert2';
  import { mapGetters } from 'vuex';

  export default {
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      defaultOptions() {
        const theme = localStorage.getItem('theme');
        return {
          showCloseButton: true,
          focusConfirm: true,
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Ok',
          closeButtonHtml:
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 18L18 6M6 6l12 12" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>',
          animation: false,
          customClass: {
            container: `modal show mode--${theme}`,
            popup: 'modal-dialog',
            header: 'modal-header',
            content: 'modal-body',
            actions: 'modal-actions actions confirm space-y-3 lg:space-y-0',
            confirmButton: 'button primary-button small-button',
            cancelButton: 'button secondary-button small-button',
          },
        };
      },
    },
    name: 'swal-modal-mixin',
    methods: {
      swalModal(options) {
        return Swal.fire({
          ...this.defaultOptions,
          ...options,
        });
      },
      chainMultipleSwalModals(modalsOptions) {
        return Swal.mixin({
          ...this.defaultOptions,
        }).queue(
          modalsOptions.map((modalOptions) => ({
            ...modalOptions,
          }))
        );
      },
    },
    watch: {
      userProfile(userProfile) {
        if (userProfile == null) return;
        this.defaultOptions.customClass.container =
          'modal show mode-- ' + userProfile.settings.theme;
      },
    },
  };
</script>
