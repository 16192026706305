<script>
  import lodash from 'lodash';
  import { mapGetters } from 'vuex';

  export default {
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
      isUserConnectedToThreads() {
        return !!lodash.get(this.userProfile, 'integrations.threads.tokenInfo', false);
      },
    },
  };
</script>
