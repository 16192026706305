import twitterText from 'twitter-text';
import { v4 as uuidv4 } from 'uuid';
import he from 'he';

export function formatTweet(status) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const doesTweetContainUrl = urlRegex.test(status);
  if (doesTweetContainUrl) {
    const { result, urls } = replaceLinksWithPlaceholders(status);
    let content = twitterText.htmlEscape(result);
    urls.forEach(({ id, url }) => {
      content = content.replace(id, url);
    });
    return he.decode(twitterText.autoLink(content, { target: '_blank', tabindex: '-1' }));
  }
  const content = twitterText.htmlEscape(status);
  return he.decode(twitterText.autoLink(content, { target: '_blank', tabindex: '-1' }));
}

function replaceLinksWithPlaceholders(status) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const urls = [];

  const result = status.replace(urlRegex, function (url) {
    const id = uuidv4();
    urls.push({ id, url });
    return id;
  });
  return { result, urls };
}
