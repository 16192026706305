<script>
  import firebase from 'firebase/compat/app';
  import 'firebase/compat/auth';

  export default {
    methods: {
      logout() {
        firebase
          .auth()
          .signOut()
          .then(() => {
            this.$store.dispatch('unsubscribeFromFirestoreListeners');
            document.cookie = `twitterUserId=hypefury; expires=Thu, 18 Dec 2013 12:00:00 UTC`;
            localStorage.clear();
            location.reload();
          });
      },
    },
  };
</script>
