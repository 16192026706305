const moment = require('moment');

const PLANS_CHANGES_DEPLOYED_AT = '2024-04-01';

const BASE_LIMITS = {
  dailyAutoDMs: 0,
  weeklySales: 0,
  managedAccounts: 0,
  categories: 0,
  feed: {
    watchedUsers: 1,
    watchedSearchTerms: 1,
  },
  scheduling: {
    unit: 'days',
    value: 2,
  },
  canPostAsLinkedInPage: false,
  drafts: 2,
  history: {
    unit: 'days',
    value: 7,
  },
  // new and old fields are used to differentiate between accounts that subscribed before and after the plan changes.
  additionalAccounts: {
    new: 0,
    old: 0,
  },
  autoDMRetargetingList: {
    listSize: 0,
    numberOfLists: 0,
  },
};

const PREMIUM_LIMITS = {
  dailyAutoDMs: 500,
  weeklySales: 3,
  managedAccounts: 3,
  categories: 10,
  feed: {
    watchedUsers: 100,
    watchedSearchTerms: 10,
  },
  scheduling: {
    unit: 'months',
    value: 3,
  },
  canPostAsLinkedInPage: true,
  drafts: 500,
  history: {
    unit: 'months',
    value: 6,
  },
  additionalAccounts: {
    new: 4,
    old: Infinity,
  },
  autoDMRetargetingList: {
    listSize: 200,
    numberOfLists: 5,
  },
};

const PLANS = {
  none: {
    label: 'Free',
    limits: {
      ...BASE_LIMITS,
    },
  },
  basic: {
    label: 'Basic',
    isParityDiscountAllowed: false,
    isSubscriptionPlan: true,
    limits: {
      ...BASE_LIMITS,
    },
  },
  standard: {
    label: 'Starter',
    isParityDiscountAllowed: true,
    isSubscriptionPlan: true,
    limits: {
      ...BASE_LIMITS,
      dailyAutoDMs: 200,
      weeklySales: 1,
      categories: 5,
      feed: {
        watchedUsers: 30,
        watchedSearchTerms: 5,
      },
      scheduling: {
        unit: 'month',
        value: 1,
      },
      canPostAsLinkedInPage: true,
      drafts: 50,
      history: {
        unit: 'month',
        value: 1,
      },
      additionalAccounts: {
        new: 0,
        old: 2,
      },
      autoDMRetargetingList: {
        listSize: 150,
        numberOfLists: 1,
      },
    },
  },
  premium: {
    label: 'Creator',
    isParityDiscountAllowed: true,
    isSubscriptionPlan: true,
    limits: {
      ...BASE_LIMITS,
      ...PREMIUM_LIMITS,
    },
  },
  business: {
    label: 'Business',
    isParityDiscountAllowed: false,
    isSubscriptionPlan: true,
    limits: {
      ...BASE_LIMITS,
      dailyAutoDMs: 650,
      weeklySales: Infinity,
      managedAccounts: 5,
      categories: Infinity,
      feed: {
        watchedUsers: Infinity,
        watchedSearchTerms: 20,
      },
      scheduling: {
        value: Infinity,
      },
      canPostAsLinkedInPage: true,
      drafts: 1000,
      history: {
        value: Infinity,
      },
      additionalAccounts: {
        new: 9,
        old: Infinity,
      },
      autoDMRetargetingList: {
        listSize: 1000,
        numberOfLists: 10,
      },
    },
  },
  agency: {
    label: 'Agency',
    isParityDiscountAllowed: false,
    isSubscriptionPlan: true,
    limits: {
      ...BASE_LIMITS,
      dailyAutoDMs: 800,
      weeklySales: Infinity,
      managedAccounts: 20,
      categories: Infinity,
      feed: {
        watchedUsers: Infinity,
        watchedSearchTerms: 50,
      },
      scheduling: {
        value: Infinity,
      },
      canPostAsLinkedInPage: true,
      drafts: Infinity,
      history: {
        value: Infinity,
      },
      additionalAccounts: {
        new: 14,
        old: Infinity,
      },
      autoDMRetargetingList: {
        listSize: Infinity,
        numberOfLists: Infinity,
      },
    },
  },
  trial: {
    label: 'Trial',
    limits: {
      ...BASE_LIMITS,
      ...PREMIUM_LIMITS,
      dailyAutoDMs: 0,
    },
  },
};

const _getAllowedPlans = (
  minimumPlanAllowed,
  // Since trial is not a real plan and is allowed for most of the features,
  // it's better that we add a flag for it so that we can disable it at will.
  isTrialAllowed = true
) => {
  const plansNames = Object.keys(PLANS);
  const minimumPlanAllowedIndex = plansNames.indexOf(minimumPlanAllowed);

  if (minimumPlanAllowedIndex === -1) {
    throw new Error(
      `Invalid minimumPlanAllowed: ${minimumPlanAllowed}. Allowed values are: ${plansNames.join(
        ', '
      )}`
    );
  }

  const allowedPlans = plansNames.filter((status, index) => {
    if (status === 'trial') {
      return isTrialAllowed;
    }

    return index >= minimumPlanAllowedIndex;
  });

  return allowedPlans;
};

const _isPlanAllowed = (userPlan, minimumPlanAllowed, isTrialAllowed = true) => {
  const allowedPlans = _getAllowedPlans(minimumPlanAllowed, isTrialAllowed);
  return allowedPlans.includes(userPlan);
};

const getBasicAndAbovePlansNames = (isTrialAllowed) => _getAllowedPlans('basic', isTrialAllowed);
const getStandardAndAbovePlansNames = (isTrialAllowed) =>
  _getAllowedPlans('standard', isTrialAllowed);
const getPremiumAndAbovePlansNames = (isTrialAllowed) =>
  _getAllowedPlans('premium', isTrialAllowed);

const getAllPlanNames = () => Object.keys(PLANS);
const getSubscriptionPlansNames = () => {
  const plans = Object.entries(PLANS);
  return plans.filter(([_, plan]) => plan.isSubscriptionPlan).map(([name]) => name);
};

const isUserPlanBasicOrAbove = (userPlan, isTrialAllowed = true) =>
  _isPlanAllowed(userPlan, 'basic', isTrialAllowed);
const isUserPlanStandardOrAbove = (userPlan, isTrialAllowed = true) =>
  _isPlanAllowed(userPlan, 'standard', isTrialAllowed);
const isUserPlanPremiumOrAbove = (userPlan, isTrialAllowed = true) =>
  _isPlanAllowed(userPlan, 'premium', isTrialAllowed);

const isUserSubscribed = (userPlan) => PLANS[userPlan].isSubscriptionPlan;
const isUserNotSubscribed = (userPlan) => !PLANS[userPlan].isSubscriptionPlan;
const isUserPlanBasicOrBelow = (userPlan, isTrialAllowed = true) =>
  !_isPlanAllowed(userPlan, 'standard', isTrialAllowed);
const isUserPlanStandardOrBelow = (userPlan, isTrialAllowed = true) =>
  !_isPlanAllowed(userPlan, 'premium', isTrialAllowed);

const getPlansAboveCurrentPlan = (userPlan) => {
  const plans = Object.entries(PLANS);
  const plansNames = plans.map(([name]) => name);

  // For trial we use premium because it's has the same rank as trial and trial is the last in the list.
  const userPlanIndex =
    userPlan === 'trial' ? plansNames.indexOf('premium') : plansNames.indexOf(userPlan);

  if (userPlanIndex === -1) {
    throw new Error(`Invalid user plan: ${userPlan}. Allowed values are: ${plansNames.join(', ')}`);
  }

  return (
    plans
      .slice(userPlanIndex + 1)
      // We exclude trial because it's not a real plan and Basic because we haven't implemented it yet.
      .filter(([n]) => !['trial', 'basic'].includes(n))
      .map(([name, plan]) => ({
        ...plan,
        name,
      }))
  );
};

const getPlansBelowCurrentPlan = (userPlan) => {
  const plans = Object.entries(PLANS);
  const plansNames = plans.map(([name]) => name);

  const userPlanIndex = userPlan === 'trial' ? plansNames.indexOf('premium') : plansNames.indexOf(userPlan);

  if (userPlanIndex === -1) {
    throw new Error(`Invalid user plan: ${userPlan}. Allowed values are: ${plansNames.join(', ')}`);
  }

  return plans
    .slice(0, userPlanIndex)
    .filter(([name]) => !['trial', 'basic'].includes(name))
    .map(([name, plan]) => ({
      ...plan,
      name,
    }));
};

const didUserSubscribeBeforePlansChanges = ({
  shouldForceOldLimits,
  subscriptionFirstDate,
  customerStatus,
  created_at,
}) => {
  if (shouldForceOldLimits) {
    return true;
  }

  const dateToUse = subscriptionFirstDate || created_at;

  return (
    isUserSubscribed(customerStatus) &&
    dateToUse &&
    moment(dateToUse.toDate()).isBefore(PLANS_CHANGES_DEPLOYED_AT)
  );
};

const getPlanLabel = (plan) => {
  return PLANS[plan].label;
};

module.exports = {
  PLANS,
  PLANS_CHANGES_DEPLOYED_AT,
  isUserPlanBasicOrAbove,
  isUserPlanStandardOrAbove,
  isUserPlanPremiumOrAbove,
  isUserSubscribed,
  isUserNotSubscribed,
  isUserPlanBasicOrBelow,
  isUserPlanStandardOrBelow,
  getBasicAndAbovePlansNames,
  getStandardAndAbovePlansNames,
  getPremiumAndAbovePlansNames,
  getSubscriptionPlansNames,
  getPlansAboveCurrentPlan,
  getPlansBelowCurrentPlan,
  didUserSubscribeBeforePlansChanges,
  getPlanLabel,
  getAllPlanNames,
};
