import amplitude from 'amplitude-js';
import lodash from 'lodash';
import moment from 'moment';
import { amplitudeAPIKey } from '@/config';
import { store } from '@/store';
import { isUserSubscribed } from '@/../functions/src/util/customerStatus.cjs';

const functions = {};

function getUserProfile() {
  const userProfile = store.getters.getUserProfile;
  return userProfile;
}

function _logEvent(eventName) {
  const userProfile = getUserProfile();
  const { eventProperties, userProperties } = !userProfile
    ? {}
    : (() => {
        const customerStatus = userProfile.customerStatus;

        const eventProperties = {
          autoRT: userProfile.autoRT,
          currentlyScheduledPostsCount: userProfile.currentlyScheduledPostsCount,
          daysSinceFirstSubscription: userProfile.subscriptionFirstDate
            ? moment().diff(userProfile.subscriptionFirstDate.toDate(), 'days')
            : null,
          daysSinceJoined: moment().diff(
            userProfile.created_at ? userProfile.created_at.toDate() : new Date(),
            'days'
          ),
          daysSinceQueueLastUpdated: userProfile.queueLastUpdatedAt
            ? moment().diff(userProfile.queueLastUpdatedAt.toDate(), 'days')
            : null,
          followersCount: userProfile.followersCount,
          isGumroadConnected: !lodash.isNil(userProfile.integrations.gumroad),
          isLinkedInConnected: !lodash.isNil(userProfile.integrations.linkedin),
          isFacebookConnected:
            !lodash.isNil(userProfile.integrations.facebook) &&
            !lodash.isNil(userProfile.integrations.facebook.page.id),
          subscriptionInterval: userProfile.subscriptionInterval,
          timezone: userProfile.timezone,
        };

        const userProperties = {
          accountType: customerStatus,
          activatedSubscription:
            userProfile.subscriptionFirstDate != null && isUserSubscribed(customerStatus),
          activatedPremiumSubscription:
            userProfile.subscriptionFirstDate != null &&
            userProfile.subscriptionTierName === 'Premium',
          activatedStandardSubscription:
            userProfile.subscriptionFirstDate != null &&
            userProfile.subscriptionTierName === 'Standard',
          activatedBasicSubscription:
            userProfile.subscriptionFirstDate != null &&
            userProfile.subscriptionTierName === 'Basic',
          'cancel subscription':
            userProfile.subscriptionEndDate != null && isUserSubscribed(customerStatus),
        };

        return { eventProperties, userProperties };
      })();

  amplitude.getInstance().setUserProperties(userProperties);
  amplitude.getInstance().logEvent(eventName, eventProperties);
}

functions._initAndReturn = () => {
  amplitude.getInstance().init(amplitudeAPIKey, lodash.get(getUserProfile(), 'uid'));
  return functions;
};

functions.addAnAccountToRetweet = () => {
  _logEvent('Auto-Retweeter: Add an account to retweet');
};

functions.removeAnAccountToRetweet = () => {
  _logEvent('Auto-Retweeter: Remove an account to retweet');
};

functions.closeComposerWithoutScheduling = () => {
  // amplitude.getInstance().logEvent('Close Composer Without Scheduling');
};

functions.connectGumroad = () => {
  _logEvent('Connect Gumroad');
};

functions.connectInstagram = () => {
  _logEvent('Connect Instagram');
};

functions.copyInspirationTweet = () => {
  _logEvent('Copy Inspiration Tweet');
};

functions.createPostCategory = () => {
  _logEvent('Create Post Category');
};

functions.createRecurrentPost = () => {
  _logEvent('Create Recurrent Post');
};

functions.scheduleDraft = () => {
  _logEvent('Schedule Draft');
};

functions.scheduleGumroadSale = () => {
  _logEvent('Schedule Gumroad Sale');
};

functions.scheduleInstashot = () => {
  _logEvent('Schedule Instashot');
};

functions.scheduleRetweet = () => {
  _logEvent('Schedule Retweet');
};

functions.scheduleThread = () => {
  _logEvent('Schedule Thread');
};

functions.scheduleTweet = () => {
  _logEvent('Schedule Tweet');
};

functions.uploadCSV = () => {
  _logEvent('Upload CSV');
};

functions.visitApp = () => {
  _logEvent('Visit App');
};

functions.visitQueuePage = () => {
  _logEvent('Visit Queue Page');
};

functions.visitEvergreenPostsPage = () => {
  _logEvent('Visit Evergreen Posts Page');
};

functions.visitDraftsPage = () => {
  _logEvent('Visit Drafts Page');
};

functions.visitRecurrentPostCalendarPage = () => {
  _logEvent('Visit Recurrent Post Calendar Page');
};

functions.visitHistoryPage = () => {
  _logEvent('Visit History Page');
};

functions.visitGumroadSalesPage = () => {
  _logEvent('Visit Gumroad Sales Page');
};

functions.visitSettingsPage = () => {
  _logEvent('Visit Settings Page');
};

functions.visitBillingPage = () => {
  _logEvent('Visit Billing Page');
};

functions.visitMarketplacePage = () => {
  _logEvent('Visit Marketplace Page');
};

functions.visitToolboxAutoRetweeterPage = () => {
  _logEvent('Visit Toolbox Auto-Retweeter Page');
};

functions.visitMentionsPage = () => {
  _logEvent('Visit Mentions Page');
};

functions.visitAutoplugsMetricsPage = () => {
  _logEvent('Visit Autoplugs Metrics Page');
};

functions.replyToAMention = () => {
  _logEvent('Reply To A Mention');
};

functions.ignoreMention = () => {
  _logEvent('Ignore Mention');
};

functions.likeMention = () => {
  _logEvent('Like Mention');
};

functions.retweetMention = () => {
  _logEvent('Retweet Mention');
};

functions.quoteTweetAMention = () => {
  _logEvent('Quote tweet a Mention');
};

functions.generateQuote = () => {
  _logEvent('Generate Quote');
};

functions.scheduleTweetWithGeneratedQuote = () => {
  _logEvent('Schedule tweet with unchanged generated quote');
};

functions.engagementBuilder = {};

functions.engagementBuilder.ignoreTweet = () => {
  _logEvent('Engagement Builder: Ignore Tweet');
};

functions.engagementBuilder.likeTweet = () => {
  _logEvent('Engagement Builder: Like Tweet');
};

functions.engagementBuilder.retweet = () => {
  _logEvent('Engagement Builder: Retweet');
};

functions.engagementBuilder.replyToATweet = () => {
  _logEvent('Engagement Builder: Reply to a Tweet');
};

functions.engagementBuilder.quoteTweet = () => {
  _logEvent('Engagement Builder: Quote Tweet');
};

functions.engagementBuilder.settings = (eventName) => {
  _logEvent(`[nUI] Engagement Builder: Settings: ${eventName}`);
};

functions.scheduleThreadFromHistory = () => {
  _logEvent('Schedule Thread From History');
};

functions.scheduleThreadFromEvergreenPage = () => {
  _logEvent('Schedule Thread From Evergreen Page');
};

functions.featureReminder = {};

functions.featureReminder.show = (eventName) => {
  _logEvent(`Feature Reminder: ${eventName} - Show`);
};

functions.featureReminder.clickIgnore = (eventName) => {
  _logEvent(`Feature Reminder: ${eventName} - Click Ignore`);
};

functions.featureReminder.followHypefuryTwitterAccountClickFollow = () => {
  _logEvent('Feature Reminder: followHypefuryOnTwitter — Click Follow');
};

functions.featureReminder.gumroadSalesInactivityVisitGumroadSalesPage = () => {
  _logEvent('Feature Reminder: gumroadSalesInactivity — Visit Gumroad Sales Page');
};

functions.featureReminder.gumroadNotConnectedVisitSettingsPage = () => {
  _logEvent('Feature Reminder: gumroadNotConnected — Visit Settings Page');
};

functions.featureReminder.linkedInNotConnectedVisitSettingsPage = () => {
  _logEvent('Feature Reminder: linkedInNotConnected — Visit Settings Page');
};

functions.featureReminder.instagramNotConnectedVisitSettingsPage = () => {
  _logEvent('Feature Reminder: instagramNotConnected — Visit Settings Page');
};

functions.featureReminder.facebookNotConnectedVisitSettingsPage = () => {
  _logEvent('Feature Reminder: facebookNotConnected — Visit Settings Page');
};

functions.featureReminder.evergreenRetweetsNotEnabledVisitSettingsPage = () => {
  _logEvent('Feature Reminder: evergreenRetweetsNotEnabled — Visit Settings Page');
};

functions.featureReminder.useFocusModeOpenFocusMode = () => {
  _logEvent('Feature Reminder: useFocusMode — Open Focus Mode');
};

functions.featureReminder.autoplugNotEnabledShowAutoplugModal = () => {
  _logEvent('Feature Reminder: autoplugNotEnabled — Show Autoplug Modal');
};

functions.featureReminder.autoRetweetsNotEnabledShowConditionalRetweetsModal = () => {
  _logEvent('Feature Reminder: autoRetweetsNotEnabled — Show Conditional Retweets Modal');
};

functions.featureReminder.addAccountManagerVisitSettingsPage = () => {
  _logEvent('Feature Reminder: addAccountManager — Visit Settings Page');
};

functions.featureReminder.pinTabToBrowserClickDisplayTutorial = () => {
  _logEvent('Feature Reminder: pinTabToBrowser - Click Display Tutorial');
};

functions.featureReminder.visitMarketplaceClickLink = () => {
  _logEvent('Feature Reminder: visitMarketplace - Click Link');
};

functions.featureReminder.promoteHypefury = () => {
  _logEvent('Feature Reminder: promoteHypefury - Accept');
};

functions.featureReminder.upgradeToYearlyUpgraded = () => {
  _logEvent('Feature Reminder: upgradeToYearly - Upgraded to yearly plan');
};

functions.featureReminder.enableNotificationsClickEnable = () => {
  _logEvent('Feature Reminder: enableNotifications - Click Enable');
};

functions.inspirationTweets = {};

functions.inspirationTweets.setTweetAsEvergreen = () => {
  _logEvent('Inspiration Tweets: Set Tweet as Evergreen');
}

functions.inspirationTweets.updateTweetCategories = () => {
  _logEvent('Inspiration Tweets: Update Tweet Categories');
}

functions.inspirationTweets.retweetNow = () => {
  _logEvent('Inspiration Tweets: Retweet Now');
}

functions.inspirationTweets.repostTweet = () => {
  _logEvent('Inspiration Tweets: Re-post Tweet');
}

functions.timeBasedSales = {};

functions.timeBasedSales.createASale = () => {
  _logEvent('[nUI] Time-based Sales: Create a sale');
}

functions.timeBasedSales.scheduleSale = () => {
  _logEvent('[nUI] Time-based Sales: Create a sale: Add sale');
}

functions.timeBasedSales.deleteSale = () => {
  _logEvent('Time-based Sales: Delete Sale');
}

functions.timeBasedSales.cancelSale = () => {
  _logEvent('Time-based Sales: Cancel Sale');
}

functions.timeBasedSales.duplicateSale = () => {
  _logEvent('Time-based Sales: Duplicate Sale');
}

functions.timeBasedSales.visitPage = () => {
  _logEvent('Time-based Sales: Visit Page');
}

functions.ghostwriting = {};

functions.ghostwriting.queueTweet = () => {
  _logEvent('Ghostwriting: Queue Tweet')
};

functions.ghostwriting.validateTweet = () => {
  _logEvent('Ghostwriting: Validate Tweet')
};

functions.ghostwriting.rejectTweet = () => {
  _logEvent('Ghostwriting: Reject Tweet')
};

functions.deleteAccount = () => {
  _logEvent('Delete Account');
};

functions.instagramPost = {};

functions.instagramPost.createPost = () => {
  _logEvent('Instagram Post: Create Post');
}

functions.linkedInPost = {};

functions.linkedInPost.createPost = () => {
  _logEvent('LinkedIn Post: Create Post');
}

functions.facebookPost = {};

functions.facebookPost.createPost = () => {
  _logEvent('Facebook Post: Create Post');
};

functions.threadsPost = {};

functions.threadsPost.createPost = () => {
  _logEvent('Threads Post: Create Post');
};

functions.visitToolboxTwitterArchiveUploaderPage = () => {
  _logEvent('Visit Twitter Archive Uploader Page');
};

functions.uploadTwitterArchive = () => {
  _logEvent('Upload Twitter Archive');
};

functions.scheduleTweetsFromTwitterArchive = () => {
  _logEvent('Schedule tweets from uploaded Twitter Archive');
};

functions.updateUserSubscription = {};

functions.updateUserSubscription.updateToPremium = () => {
  _logEvent('Update User Subscription: updateToPremium - Updated to Premium plan');
};

functions.updateUserSubscription.updateToYearlyPlan = () => {
  _logEvent('Update User Subscription: updateToYearlyPlan - Updated to yearly plan');
};

functions.newUIAlert = {};

functions.newUIAlert.enabled = (userId, username) => {
  amplitude.getInstance().setUserProperties({
    'User ID': userId,
    username,
  });
  amplitude.getInstance().logEvent('New UI enabled from alert');
};

functions.newUIAlert.alertHidden = (userId, username) => {
  amplitude.getInstance().setUserProperties({
    'User ID': userId,
    username,
  });
  amplitude.getInstance().logEvent('New UI alert hidden');
};

functions.newUIEnabled = (userId, username, hasSignedUpFromMobile) => {
  amplitude.getInstance().setUserProperties({
    'User ID': userId,
    username,
    hasSignedUpFromMobile,
  });
  amplitude.getInstance().logEvent('New UI enabled');
};

functions.newUIDisabled = (userId, username, hasSignedUpFromMobile) => {
  amplitude.getInstance().setUserProperties({
    'User ID': userId,
    username,
    hasSignedUpFromMobile,
  });
  amplitude.getInstance().logEvent('New UI disabled');
};

functions.general = {};

functions.general.sidebar = (buttonName) => {
  _logEvent(`[nUI] General: Sidebar: ${buttonName}`);
}

functions.general.composerButton = () => {
  _logEvent(`[nUI] General: Composer button`);
}

functions.composer = (eventName) => {
  _logEvent(`[nUI] Composer: ${eventName}`);
}

functions.recurrentPosts = (eventName) => {
  _logEvent(`[nUI] Recurrent posts: ${eventName}`);
}

functions.powerups = (eventName) => {
  _logEvent(`[nUI] Powerups: ${eventName}`);
}

functions.autoDMs = (eventName) => {
  _logEvent(`[nUI] Auto-DMs: ${eventName}`);
}

functions.tweetToVideo = (eventName) => {
  _logEvent(`[nUI] Tweet-to-video: ${eventName}`);
}

functions.gumroadSales = (eventName) => {
  _logEvent(`[nUI] Gumroad sales: ${eventName}`);
}

functions.analytics = (eventName) => {
  _logEvent(`[nUI] Analytics: ${eventName}`);
}

functions.history = (eventName) => {
  _logEvent(`[nUI] History: ${eventName}`);
}

functions.evergreenQueue = (eventName) => {
  _logEvent(`[nUI] Evergreen queue: ${eventName}`);
}

export default functions;
