<template>
  <div class="side-nav space-y-8">
    <div
      v-if="userAffiliateDetailsLoaded && showAffiliateProgram"
      class="affiliate-program-container rounded-2xl p-4 py-3 space-y-2"
    >
      <div class="flex items-center justify-between">
        <span class="text-base font-medium"> Affiliate Program </span>
        <span
          v-if="userAffiliateDetails"
          class="cursor-pointer hover:text-main-color-100"
          @click="
            $copyText(userAffiliateDetails.referralLink);
            $notify({
              type: 'success',
              message: 'Your affiliate FirstPromoter link has been copied!',
            });
            $eventStore.general.sidebar('Copy affiliate link');
          "
        >
          <inline-svg src="/img/icons/copy.svg" class="icon" />
        </span>
      </div>
      <div v-if="userAffiliateDetails" class="flex items-center justify-between">
        <span class="text-secondary">
          Earning balance: {{ currencySymbol }}{{ userAffiliateDetails.earningsBalance }}
        </span>
        <a
          class="hover:text-main-color-100"
          @click="$eventStore.general.sidebar('Visit Firstpromoter')"
          href="https://hypefury.firstpromoter.com/"
          target="_blank"
        >
          <inline-svg src="/img/icons/settings.svg" class="icon" />
        </a>
      </div>
      <div v-else class="text-secondary">
        <span
          v-if="isCreatingAffiliateAccount"
        >
          Joining...
        </span>
        <a v-else @click="createAffiliateAccount">
          Click here to join
        </a>
      </div>
    </div>
    <div
      v-if="setupProgress < 100"
      class="app-sidebar-setup mb-10 flex cursor-pointer items-center justify-between rounded-full bg-gray-10 px-4 py-2"
      @click="showSetupProgress"
    >
      <span data-cy="sidebar-progress" class="text-base font-medium leading-none"
        >Set up {{ setupProgress }}%</span
      >
      <CircularProgress :value="setupProgress" strokeColor="#28AF87" :strokeWidth="4" :radius="8">
        <template v-slot:default>
          <b></b>
        </template>
      </CircularProgress>
    </div>
    <div class="grid gap-8">
      <div>
        <span class="uppercase text-gray-100 font-medium text-sm block px-4 mb-4">Grow</span>
        <div class="grid gap-4">
          <slot name="links">
            <sidebar-item
              v-for="(link, index) in sidebarLinks"
              :key="link.name + index"
              :link="link"
            >
              <sidebar-item
                v-for="(subLink, index) in link.children"
                :key="subLink.name + index"
                :link="subLink"
              >
              </sidebar-item>
            </sidebar-item>
          </slot>
        </div>
      </div>
      <div>
        <span class="uppercase text-gray-100 font-medium text-sm block px-4 mb-4">Engage</span>
        <div class="grid gap-4">
          <slot name="engage-links">
            <sidebar-item
              v-for="(link, index) in sidebarLinks"
              :key="link.name + index"
              :link="link"
            >
              <sidebar-item
                v-for="(subLink, index) in link.children"
                :key="subLink.name + index"
                :link="subLink"
              >
              </sidebar-item>
            </sidebar-item>
          </slot>
        </div>
      </div>
      <div>
        <span class="uppercase text-gray-100 font-medium text-sm block px-4 mb-4">Earn</span>
        <div class="grid gap-4">
          <slot name="earn-links">
            <sidebar-item
              v-for="(link, index) in sidebarLinks"
              :key="link.name + index"
              :link="link"
            >
              <sidebar-item
                v-for="(subLink, index) in link.children"
                :key="subLink.name + index"
                :link="subLink"
              >
              </sidebar-item>
            </sidebar-item>
          </slot>
        </div>
      </div>
      <div>
        <span class="uppercase text-gray-100 font-medium text-sm block px-4 mb-4">Track</span>
        <div class="grid gap-4">
          <slot name="track-links">
            <sidebar-item
              v-for="(link, index) in sidebarLinks"
              :key="link.name + index"
              :link="link"
            >
              <sidebar-item
                v-for="(subLink, index) in link.children"
                :key="subLink.name + index"
                :link="subLink"
              >
              </sidebar-item>
            </sidebar-item>
          </slot>
        </div>
      </div>
      <div v-if="userProfile.isGhostwriter">
        <span class="uppercase text-gray-100 font-medium text-sm block px-4 mb-4">Ghostwriting</span>
        <div class="grid gap-2">
          <slot name="ghostwriting-links">
            <sidebar-item
              v-for="(link, index) in sidebarLinks"
              :key="link.name + index"
              :link="link"
            >
              <sidebar-item
                v-for="(subLink, index) in link.children"
                :key="subLink.name + index"
                :link="subLink"
              >
              </sidebar-item>
            </sidebar-item>
          </slot>
        </div>
      </div>
      <!-- <div>
        <span class="uppercase text-gray-100 font-medium text-sm block mb-8">DM CRM</span>
        <div class="grid gap-2">
          <div class="p-2 rounded-lg bg-dark-mode-70 inline-flex items-center justify-center">
            <div class="w-full flex items-center gap-3">
              <span class="text-base font-medium">DM CRM</span>
              <div class="flex items-center gap-2">
                <new-feature-badge />
                <badge type="primary">Beta</badge>
              </div>
            </div>
          </div>
          <slot name="dm-crm-links"></slot>
        </div>
      </div> -->
      <!-- <div v-if="userProfile.isGhostwriter">
        <span class="uppercase text-gray-100 font-medium text-sm block mb-8">Ghostwriting</span>
        <div class="grid gap-2">
          <slot name="ghostwriting-clients"></slot>
        </div>
      </div> -->
      <!-- <div>
        <span class="uppercase text-gray-100 font-medium text-sm block mb-8">Toolbox</span>
        <div class="grid gap-2">
          <slot name="toolbox-links">
            <sidebar-item
              v-for="(link, index) in sidebarLinks"
              :key="link.name + index"
              :link="link"
            >
              <sidebar-item
                v-for="(subLink, index) in link.children"
                :key="subLink.name + index"
                :link="subLink"
              >
              </sidebar-item>
            </sidebar-item>
          </slot>
        </div>
      </div> -->
      <!-- <div>
        <span class="uppercase text-gray-100 font-medium text-sm block px-4 mb-8">Account</span>
        <div class="grid gap-2">
          <slot name="account-links"></slot>
        </div>
      </div> -->
      <!-- <div>
        <span class="uppercase text-gray-100 font-medium text-sm block mb-8">Community</span>
        <div class="grid gap-2">
          <div class="nav-item nav-item-hover">
            <router-link to="/marketplace" class="p-2 rounded-lg bg-dark-mode-70 inline-flex items-center justify-center">
              <span class="text-base font-medium">Grow faster?</span>
            </router-link>
          </div>
          <div class="nav-item nav-item-hover">
            <a href="https://hypefury.firstpromoter.com/" target="_blank" class="p-2 rounded-lg bg-dark-mode-70 inline-flex items-center justify-center">
              <span class="text-base font-medium">Affiliate program</span>
            </a>
          </div>
          <div class="nav-item nav-item-hover">
            <a
              @click="openFeatureRequestModal"
              href="javascript:;"
              class="p-2 rounded-lg bg-dark-mode-70 inline-flex items-center justify-center"
              :class="{ 'disabled': !isAcuteReady }"
            >
              <span class="text-base font-medium">Request a feature</span>
            </a>
          </div>
          <div class="nav-item nav-item-hover">
            <a
              href="javascript:;"
              class="p-2 rounded-lg bg-dark-mode-70 inline-flex items-center justify-center beamer-button"
              :class="{ 'disabled': !isBeamerReady }"
            >
              <div class="nav-link-img">
                <inline-svg src="/img/icons/speakerphone.svg" />
              </div>
              <span class="text-base font-medium">What's new</span>
            </a>
          </div>
        </div>
        <div class="nav-item nav-item-hover" v-if="isMobile">
          <a href="javascript:;" class="p-2 rounded-lg bg-dark-mode-70 inline-flex items-center justify-center" @click="openCrispChat">
            <div class="nav-link-img">
              <inline-svg src="/img/icons/chat.svg" />
            </div>
            <span class="text-base font-medium">Chat with support</span>
          </a>
        </div>
      </div> -->
    </div>
    <upgrade-to-premium-pop-up
      :show="showUpgradeToPremiumPopUp"
      :content="`Upgrade to the ${getPlanLabel(
        'premium'
      )} plan to add more accounts and unlock ton of other powerful features!`"
      :contentForNonSubscribers="`Subscribe to the ${getPlanLabel(
        'premium'
      )} plan to add more additional accounts
        and unlock a ton of other powerful features!`"
      @continue-operation="continuePremiumFeature"
      @close="showUpgradeToPremiumPopUp = false"
    />
  </div>
</template>

<script>
/* global $crisp */
import axios from 'axios';
import lodash from 'lodash';
import moment from 'moment';
import Vue from 'vue';
import VueAuthenticate from 'vue-authenticate';
import VueAxios from 'vue-axios';
import { mapGetters, mapState } from 'vuex';
import { twitterLoginRedirectURI, httpRoutesURL } from '@/config';
import controller from '@/controller';
import dao from '@/dao';
import CreateAffiliateAccountMixin from '@/views/Mixins/CreateAffiliateAccountMixin';
import TrialPeriodMixin from '@/views/Mixins/TrialPeriodMixin';
import { switchToProfile } from '@/util/switchToProfile';
import GetHypefurySubscriptionMixin from '@/views/Mixins/GetHypefurySubscriptionMixin';
import MobileMixin from '../../views/Mixins/MobileMixin.vue';
import CircularProgress from "easy-circular-progress";
import CustomerStatusMixin from '../../views/Mixins/CustomerStatusMixin.vue';

export default {
  data() {
    return {
      followersDiffSinceLastWeek: null,
      hasTwitterAuthBeenInitialized: false,
      gumroadRevenueGeneratedByHypefury: null,
      showUpgradeToPremiumPopUp: false,
    };
  },
  name: 'sidebar',
  components: {
    CircularProgress,
  },
  props: {
    title: {
      type: String,
      default: 'Creative Tim',
      description: 'Sidebar title'
    },
    shortTitle: {
      type: String,
      default: 'CT',
      description: 'Sidebar short title'
    },
    logo: {
      type: String,
      default: '/logo.svg',
      description: 'Sidebar app logo'
    },
    backgroundColor: {
      type: String,
      default: 'vue',
      validator: value => {
        let acceptedValues = [
          '',
          'vue',
          'blue',
          'green',
          'orange',
          'red',
          'primary'
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
      description:
        'Sidebar background color (vue|blue|green|orange|red|primary)'
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description:
        "List of sidebar links as an array if you don't want to use components for these."
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        'Whether sidebar should autoclose on mobile when clicking an item'
    },
    gumroadRevenue: {
      type: Number
    },
    currencySymbol: {
      type: String
    },
    userFollowers: {
      type: String
    },
    userName: {
      type: String
    },
    userPhoto: {
      type: String
    },
    setupProgress: {
      type: Number,
      default: 0,
    },
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  methods: {
    openCrispChat() {
      $crisp && $crisp.push(['do', 'chat:open']);
    },
    connectAdditionalAccountIfAllowed() {
      const customerStatus = this.parentUserProfile.customerStatus;
      if (this.isUserPlanBasicOrBelow(customerStatus)) {
        this.displayGetHypefurySubscriptionPopUp(
          'Connect additional accounts',
          'connect additional accounts'
        );
      } else if (this.isUserPlanStandardOrBelow && this.secondaryAccountsCount >= 2) {
        this.showUpgradeToPremiumPopUp = true;
      } else {
        this.connectAdditionalAccount();
      }
    },
    async connectAdditionalAccount() {
      if (!this.hasTwitterAuthBeenInitialized) {
        Vue.use(VueAuthenticate, {
          bindRequestInterceptor: function () {
            this.$http.interceptors.request.use((config) => {
              if (this.isAuthenticated()) {
                config.headers['Authorization'] = [
                  this.options.tokenType, this.getToken()
                ].join(' ');
              } else {
                // Do nothing (default behavior of vue-authentication: remove the Authorization header)
              }
              return config;
            });
          },
          providers: {
            twitter: {
              name: 'twitter',
              url: `${httpRoutesURL}/integrations/auth/twitter`,
              authorizationEndpoint: 'https://api.twitter.com/oauth/authenticate',
              redirectUri: `${twitterLoginRedirectURI}/`,
              oauthType: '1.0',
            },
          },
        });
        Vue.use(VueAxios, axios);

        this.hasTwitterAuthBeenInitialized = true;
      }

      try {
        const result = await this.$auth.authenticate('twitter');
        const data = result.data;
        const newUserUsername = data.profile.screen_name;
        const newUserTwitterId = data.profile.id_str;

        if (
          lodash.size(lodash.get(this, 'parentUserProfile.parentsCustomerStatus', {})) > 0 &&
          this.isUserNotSubscribed(this.parentUserProfile.customerStatus)
        ) {
           this.swalModal({
            title: 'Error',
            text: `@${this.parentUserProfile.username} is a secondary account, you cannot connect more accounts to it.`,
            type: 'error',
          });
          return;
        }

        if (this.userProfiles[this.currentUser.uid].twitterId === newUserTwitterId ||
          this.userProfile.twitterId === newUserTwitterId) {
          this.swalModal({
            title: 'Please change accounts',
            text: `You are trying to add the currently connected account (@${newUserUsername}).
            Please switch accounts from twitter.com and try again.`,
            type: 'error',
          });
          return;
        }

        if (this.otherConnectedUserProfiles.map(p => p.twitterId).includes(newUserTwitterId)) {
          this.swalModal({
            title: 'Woops',
            text: `@${newUserUsername} is already connected!`,
            type: 'warning',
          });
          return;
        }

        // vueauth_token token is set by vue-authenticate to remember the currently logged in user
        // We don't need this behavior + it breaks axios by replacing all the Authentication header
        // value with the value from this vueauth_token.
        localStorage.removeItem('vue-authenticate.vueauth_token');

        try {
          const hypefuryAccount = await controller.doesAccountExistInHypefury(this.currentUser,
            data.access_token,
            data.access_token_secret);

          const isTrialAllowed = false;
          if (
            hypefuryAccount.isUserParentAccount === true &&
            this.isUserPlanBasicOrBelow(hypefuryAccount.customerStatus, isTrialAllowed)
          ) {
            this.swalModal({
              title: 'Error',
              text: `@${newUserUsername} is a parent account, you cannot connect it to another parent account.`,
              type: 'error',
            });
            return;
          }

          if (
            lodash.get(this.userProfile, 'parentsCustomerStatus', {})[hypefuryAccount.id]
          ) {
            this.swalModal({
              title: 'Error',
              text: `@${newUserUsername} is this account's parent, you cannot add it as a child account.`,
              type: 'error',
            });
            return;
          }

          await dao.userProfile.addAdditionalTwitterAccount(
            this.currentUser.uid,
            data.access_token,
            data.access_token_secret,
            lodash.omit(data.profile, ['entities', 'status.entities', 'status.place']),
          );

          this.swalModal({
            title: 'Account successfully connected',
            text: `You will be switched to @${newUserUsername} now.`,
            type: 'success',
          }).then(() => {
            this.switchToProfile(hypefuryAccount.id);
          });
        } catch (error) {
          const isAccountNotConnectedToHypefury = error.response.status === 404;
          if (isAccountNotConnectedToHypefury) {
            this.swalModal({
              title: 'Sorry',
              text: `@${newUserUsername} is not a Hypefury user yet. Please log into Hypefury with the @${newUserUsername} account and try again.`,
              type: 'warning',
            });
          } else {
            throw error;
          }
        }
      } catch (error) {
        this.swalModal({
          title: 'Sorry',
          text: `An error has occurred while connecting your additional Twitter account.`,
          type: 'warning',
        });
      }
    },
    continuePremiumFeature() {
      this.connectAdditionalAccount();
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    openFeatureRequestModal() {
      window.Acute('openModal')
    },
    toggleMenu(event){
      event.currentTarget.classList.toggle('open');
    },
    openMenuForRoute(routePath) {
      const routes = this.$router.options.routes[1].children;
      const matchingRoute = routes.find((route) => route.path === routePath);

      if (matchingRoute) {
        const matchingRouteMenu = matchingRoute.meta && matchingRoute.meta.menu;

        if (matchingRouteMenu) {
          const menuRef = this.$refs[`${matchingRouteMenu}-menu`];
          if (menuRef) {
            if (!menuRef.classList.contains('open')) {
              menuRef.classList.add('open');
            }
          }
        }
      }
    },
    switchToProfile(userId) {
      switchToProfile(userId);
    },
    showSetupProgress() {
      this.$eventStore.general.sidebar('Show setup progress');
      this.$emit('showSetupProgress', true)
    }
  },
  async mounted() {
    this.openMenuForRoute(this.$route.path);

    this.$sidebar.isMinimized = this.$sidebar.breakpoint < window.innerWidth;
    this.minimizeSidebar()

    this.followersDiffSinceLastWeek = this.userProfile.followersDiffSinceLastWeek;
    this.gumroadRevenueGeneratedByHypefury = await (async () => {
      const sales = await dao.gumroadSales.getSalesOfCurrentMonth(this.userProfile.uid);
      const amountOfSales = sales.docs.map((saleDoc) => {
        const sale = saleDoc.data();
        const totalSales = sale.totalSales || 0;
        const priceAfterDiscount = sale.price - sale.amountOff;
        return totalSales * priceAfterDiscount / 100;
      });
      return lodash.sum(amountOfSales);
    })();
  },
    mixins: [
      CreateAffiliateAccountMixin,
      GetHypefurySubscriptionMixin,
      TrialPeriodMixin,
      MobileMixin,
      CustomerStatusMixin,
    ],
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
  computed: {
    ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile', parentUserProfile: 'getParentUserProfile' }),
    ...mapState([
      'userProfiles',
      'isAcuteReady',
      'isBeamerReady',
      'userAffiliateDetails',
      'userAffiliateDetailsLoaded'
    ]),
    month() {
      return moment().format('MMMM');
    },
    showAffiliateProgram() {
      return moment
        .duration(moment().diff(this.userProfile.created_at.toDate()))
        .asWeeks() > 1;
    },
    canUserConnectAdditionalAccounts() {
      if (!this.parentUserProfile) return false;
      const customerStatus = this.parentUserProfile.customerStatus;
      return (
        (this.isUserPlanPremiumOrAbove(customerStatus) && this.secondaryAccountsCount < 5) ||
        // TODO: Check this one in the limits PR
        (['standard', 'trial', 'none', 'basic'].includes(customerStatus) &&
          this.secondaryAccountsCount < 2)
      );
    },
    otherConnectedUserProfiles() {
      return Object.values(this.userProfiles).filter((profile) => {
        return profile.uid !== this.userProfile.uid;
      });
    },
    secondaryAccountsCount() {
      const managedAccountsCount = lodash.get(this, 'parentUserProfile.additionalAccounts', [])
        .filter((account) => account.type === 'managed').length;
      return Object.keys(this.userProfiles).length - (managedAccountsCount + 1);
    },
    shouldShowAddAccountButton() {
      return this.secondaryAccountsCount < 5;
    },
  },
    watch: {
      '$route.path': {
        handler: function () {
          if (!this.$sidebar.isMinimized) {
            this.minimizeSidebar();
          }
        },
        deep: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .disabled {
    pointer-events: none;
    color: var(--color-secondary);
  }

  .affiliate-program-container .icon {
    opacity: 1;
    height: 16px;
    width: 16px;
    @include lg {
      opacity: 0;
    }
  }

  .affiliate-program-container:hover .icon {
    opacity: 1;
  }

  .mode--dark {
    .affiliate-program-container,
    .app-sidebar-setup {
      @apply bg-dark-mode-60;
    }
  }

  .mode--light {
    .affiliate-program-container,
    .app-sidebar-setup {
      @apply bg-main-color-50;
    }
  }
</style>
