<template>
  <tooltip :content="content" :footer="footer">
    <div
      class="block w-4 h-4 ml-2"
      :class="{
        'text-dark-mode-15': level == 'info',
        'text-warning': level == 'alert'
      }"
    >
      <!-- info.svg -->
      <inline-svg v-if="level == 'info'" src="/img/icons/info.svg" />
      <inline-svg v-if="level == 'alert'" src="/img/icons/alert.svg" />
    </div>
  </tooltip>
</template>

<script>

  export default {
    name: 'info-tooltip',
    computed: {
      color() {
        return this.type === 'error' ? 'rgb(245,101,101)' : 'currentColor';
      },
    },
    props: {
      content: {
        type: String,
        default: 'Tooltip Content',
      },
      footer: {
        type: String,
        default: '',
      },
      type: {
        type: String,
      },
      level: {
        type: String,
        default: 'info'
      }
    }
  };
</script>
<style></style>
