const moment = require('moment');

class RecurrentPostSlot {
  slotType = 'recurrent';

  constructor(time, recurrentPost, thread) {
    this.time = time;
    this.midnight = moment(time).startOf('day').toISOString();
    this.recurrentPost = recurrentPost;
    this.thread = thread;
  }

  isEmpty() {
    return false;
  }
}

export { RecurrentPostSlot };