<template>
  <div class="timeline" :class="{[`timeline-${type}`]: type}">
    <slot></slot>
  </div>
</template>
<script>
  export default {
    name: 'time-line',
    props: {
      type: {
        type: String,
        default: ''
      }
    }
  };
</script>
<style lang="scss">
.timeline {
  border-radius: 12px;
  position: relative;
}

.timeline-block:last-child {

  .timeline-step {
    border: 0px;
  }
}

.timeline-one-side {
  .timeline-block {
    display: flex;
    flex-direction: row;
  }
}
</style>
