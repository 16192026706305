<template>
  <div class="space-y-6">
    <div class="flex w-full items-center justify-between gap-x-3">
      <div class="flex items-center gap-x-1">
        <new-button icon @click="$emit('close')" type="transparent" data-cy="drafts-close">
          <inline-svg
            src="/img/icons/new/modal-collapse.svg"
            class="hidden h-6 rotate-180 transform text-main-color-100 lg:inline"
          />
          <inline-svg src="/img/icons/new/x.svg" class="inline text-gray-80 lg:hidden" />
        </new-button>

        <h1>Drafts</h1>
      </div>

      <new-button type="custom" @click="confirmScheduleAllDrafts">
        Schedule All
        <inline-svg src="/img/icons/schedule-icon.svg" class="ml-2 self-start stroke-current" />
      </new-button>
    </div>

    <template v-if="drafts.length === 0">
      <p class="my-4" v-if="userProfile.draftPostCount > 0">Your drafts will load in a moment!</p>
      <p class="my-4" v-else>
        You don't have any drafts. Draft a thread or a tweet to see it appear here!
      </p>
    </template>
    <div class="divide-y space-y-6 divide-ds-outline-secondary" v-if="show">
      <draft-card
        v-for="draft in drafts"
        :key="draft.id"
        :draft="draft"
        :threadToEdit="threadToEdit"
        :selectedDraft="selectedDraft"
        @edit-draft="editDraft"
        @reset-composer="$emit('reset-composer')"
        @choose-time="$emit('choose-time', $event)"
        @schedule-on-next-free-slot="$emit('schedule-on-next-free-slot', $event)"
      />
    </div>
  </div>
</template>

<script>
  import DraftCard from '@/components/Drafts/DraftCard.vue';
  import { store } from '../../store';
  import lodash from 'lodash';
  import { mapGetters, mapState } from 'vuex';
  import dao from '../../dao';
  import { countTweetLength } from '@/../functions/src/util/countTweetLength';
  import SwalModalMixin from '../../views/Mixins/SwalModalMixin.vue';
  import { buildPostFromFirestore } from '../../util/buildPostFromFirestore';
  import BreakpointsMixin from '@/views/Mixins/BreakpointsMixin.vue';
  import TweetCharactersLimitMixin from '../../views/Mixins/TweetCharactersLimitMixin.vue';
  const fb = require('@/firebase');

  export default {
    name: 'NewComposerDrafts',
    props: {
      threadToEdit: {
        type: Object,
        default: null,
      },
      show: {
        type: Boolean,
        default: false,
      },
      selectedDraft: {
        type: String,
        default: '',
      },
    },
    mixins: [SwalModalMixin, BreakpointsMixin, TweetCharactersLimitMixin],
    computed: {
      ...mapGetters({
        userProfile: 'getUserProfile',
      }),
      ...mapState(['schedule', 'draftPostsFromCursor']),
      drafts: {
        cache: false,
        get() {
          return lodash.uniqBy(
            lodash
              .flatten(this.draftPostsFromCursor)
              // Remove recurrent and scheduled/pending/rejected ghostwriting posts from drafts
              .filter(
                (d) =>
                  !d.data().isRecurrentPost &&
                  (d.data().source !== 'ghostwriting' ||
                    (d.data().source === 'ghostwriting' && d.data().ghostwritingStatus === null))
              )
              .map((d) => buildPostFromFirestore(d)),
            'id'
          );
        },
      },
    },
    components: {
      DraftCard,
    },
    data() {
      return {
        isLoadingMorePosts: false,
      };
    },
    methods: {
      isBottomOfElement(event) {
        const element = event.target;
        return element.scrollHeight - element.scrollTop < element.clientHeight + 400;
      },
      onScroll(event) {
        if (this.isBottomOfElement(event) && !this.isLoadingMorePosts) {
          this.loadMorePosts();
        }
      },
      loadMorePosts() {
        this.isLoadingMorePosts = true;
        const lastRetrievedDoc = lodash.last(lodash.last(this.draftPostsFromCursor));
        // if lastRetrievedDoc is undefined that means that the cursor has reached the end of the
        // documents for the query.
        if (lastRetrievedDoc) {
          this.fetchDrafts(lastRetrievedDoc);
        }
      },
      fetchDrafts(lastRetrievedDoc) {
        let query = fb.threadsCollection
          .where('deleted', '==', false)
          .where('user', '==', fb.usersCollection.doc(this.userProfile.uid))
          .where('time', '==', null)
          .orderBy('created_at', 'desc');

        if (lastRetrievedDoc) {
          query = query.startAfter(lastRetrievedDoc);
        }
        const cursorCount = this.draftPostsFromCursor.length;
        const unsubscribe = query.limit(10).onSnapshot((snapshot) => {
          this.isLoadingMorePosts = false;
          const retrievedDocs = snapshot.docs;
          store.commit('addDraftPostsFromCursor', {
            cursorCount,
            posts: retrievedDocs,
          });
        });
        store.dispatch('addFirestoreListener', unsubscribe);
      },
      async fetchAllDrafts() {
        const allDraftsDocs = await fb.threadsCollection
          .where('deleted', '==', false)
          .where('user', '==', fb.usersCollection.doc(this.userProfile.uid))
          .where('time', '==', null)
          .orderBy('created_at', 'desc')
          .get();

        const allDrafts = allDraftsDocs.docs.map((draftDoc) => buildPostFromFirestore(draftDoc));

        return allDrafts;
      },
      confirmScheduleAllDrafts() {
        this.$eventStore.composer('Drafts: Schedule all');
        const self = this;
        self.swalModal({
          title: `Schedule All`,
          html: 'Are you sure you want to schedule all your drafts?',
          type: 'question',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          preConfirm: () => {
            if (this.userProfile.draftPostCount >= 20) {
              self.showSecondScheduleAllDraftsAlert();
            } else {
              self.scheduleDrafts(this.drafts);
            }
          },
        });
      },
      async showSecondScheduleAllDraftsAlert() {
        const self = this;
        const allDrafts = await self.fetchAllDrafts();
        self.swalModal({
          title: `Schedule All`,
          html: `You have ${this.userProfile.draftPostCount} drafts. Are you really sure you want to schedule them all at once? This could take some time.`,
          type: 'question',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          preConfirm: () => {
            self.scheduleDrafts(allDrafts);
          },
        });
      },
      async scheduleDrafts(drafts) {
        try {
          const areTweetsValid = (tweets) =>
            tweets.every((t) => countTweetLength(t.status) <= this.tweetCharactersLimit);

          const draftsToSchedule = drafts.filter((draft) => areTweetsValid(draft.tweets));

          let previousTimeSlot = null;
          const scheduleDraftPromises = draftsToSchedule.map(async (draft) => {
            const time = previousTimeSlot
              ? this.schedule.getNextTimeSlot(previousTimeSlot)
              : this.schedule.getNextTimeSlot();

            previousTimeSlot = time;

            const thread = draft.toScheduledThread(time);
            const canAddPosts = await dao.posts.canAddMorePostsInSlot(
              this.userProfile.uid,
              time.toDate()
            );

            if (canAddPosts) {
              return thread.update(this.currentUser, this.userProfile);
            } else {
              this.$notify({
                type: 'warning',
                message: "Sorry, you can't add more than 2 posts per slot.",
              });
            }
          });

          await Promise.all(scheduleDraftPromises);

          const message = `${
            draftsToSchedule.length === 1 ? 'Thread' : 'Threads'
          } successfully scheduled!`;
          this.$notify({ type: 'success', message });
        } catch (error) {
          this.swalModal({
            title: 'Sorry',
            text: `An error has occurred while scheduling the drafts.`,
            type: 'warning',
          });
        }
      },
      editDraft(draft) {
        this.$emit('edit-draft', draft);
        if (!this.isDesktop) {
          this.$emit('close');
        }
      },
    },
    watch: {
      drafts: function (val) {
        if (val.length < this.draftPostsFromCursor[0].length && !this.isLoadingMorePosts) {
          this.loadMorePosts();
        }
      },
      show(val, old) {
        const isFirstTimeShown = !old && val && !this.drafts.length;
        if (isFirstTimeShown) {
          this.fetchDrafts();
        }
      },
    },
  };
</script>
