<template>
  <div class="new-badge">
    NEW
  </div>
</template>
<script>
export default {
  name: 'new-feature-badge'
}
</script>
<style>
.new-badge {
  @apply rounded-sm text-white font-bold text-center;
  display: inline-block;
  padding: 2px 4px;
  line-height: 1;
  font-size: 0.5rem;
  text-align: center;
  vertical-align: baseline;
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #FF3D51;
  --tw-gradient-to: #FF4980;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(99 102 241 / 0));
}
</style>
