import { v1 as uuidv1 } from 'uuid';
import { getImageInformation } from '@/util/getImageInformation';
const firebase = require('@/firebase');
const mime = require('mime-types');

const storage = {};

storage.deleteOldMedia = (media) => {
  if (!media) return;

  return Promise.all(
    media.flatMap((mediaArray) => {
      return mediaArray.map((mediaName) => {
        if (mediaName) {
          return firebase.storageRoot.child(mediaName).delete();
        } else return;
      });
    })
  );
};

storage.uploadNewMedia = async (media) => {
  const getImageThumbnail = async (file) => {
    const image = new Image();
    image.src = URL.createObjectURL(file);
    await image.decode();

    const canvas = document.createElement('canvas');
    if (canvas.width < canvas.height) {
      canvas.width = 64;
      canvas.height = (64 * image.height) / image.width;
    } else {
      canvas.height = 64;
      canvas.width = (64 * image.width) / image.height;
    }
    canvas.getContext('2d').drawImage(image, 0, 0, canvas.width, canvas.height);
    const blob = await (await fetch(canvas.toDataURL())).blob();
    return new File([blob], 'thumbnail.png', { type: 'image/png' });
  };
  const getVideoThumbnail = (file) => {
    return new Promise((resolve) => {
      const video = document.createElement('video');
      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);

      video.onloadeddata = async () => {
        const canvas = document.createElement('canvas');
        if (canvas.width < canvas.height) {
          canvas.width = 64;
          canvas.height = (64 * video.videoHeight) / video.videoWidth;
        } else {
          canvas.height = 64;
          canvas.width = (64 * video.videoWidth) / video.videoHeight;
        }
        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
        video.pause();
        const blob = await (await fetch(canvas.toDataURL())).blob();
        return resolve(new File([blob], 'thumbnail.png', { type: 'image/png' }));
      };
    });
  };
  const downscaleImage = async (file, divider = 2) => {
    const imageInfo = await getImageInformation(file);
    const extension = mime.extension(media.type);

    const canvas = document.createElement('canvas');
    canvas.width = imageInfo.imageWidth / divider;
    canvas.height = imageInfo.imageHeight / divider;
    canvas.getContext('2d').drawImage(imageInfo.image, 0, 0, canvas.width, canvas.height);

    const blob = await (await fetch(canvas.toDataURL())).blob();
    const image = new File([blob], `downscaled.${extension}`, { type: file.type });

    // We keep dividing the image size until it's smaller than 5MB.
    if (image.size > 5 * 1024 * 1024) {
      return downscaleImage(file, divider + 1);
    } else {
      return image;
    }
  };

  const extension = mime.extension(media.type);
  const fileName = uuidv1();
  const mediaRef = firebase.storageRoot.child(`${fileName}.${extension}`);
  const thumbnailRef = firebase.storageRoot.child(`thumbnail-${fileName}.png`);
  const file = new File([media], `${fileName}.${extension}`, { type: media.type });
  const metadata = {
    contentType: media.type,
  };

  let thumbnailFile = null;
  let thumbnailFileResponse = null;
  let fileResponse = null;

  if (metadata.contentType.includes('image')) {
    try {
      thumbnailFile = await getImageThumbnail(media);
      thumbnailFileResponse = await thumbnailRef.put(thumbnailFile);

      if (!media.type.includes('gif') && media.size > 5 * 1024 * 1024) {
        const downscaledImage = await downscaleImage(media);
        fileResponse = await mediaRef.put(downscaledImage, metadata);
      } else {
        fileResponse = await mediaRef.put(file, metadata);
      }
    } catch (error) {
      console.error(error);
    }
  } else {
    thumbnailFile = await getVideoThumbnail(media);
    thumbnailFileResponse = await thumbnailRef.put(thumbnailFile);
    fileResponse = await mediaRef.put(file, metadata);
  }
  return Promise.resolve({
    file: fileResponse,
    ...(thumbnailFileResponse ? { thumbnail: thumbnailFileResponse.metadata.name } : {}),
  });
};

storage.uploadCSVFile = (file, metadata) => {
  const mediaRef = firebase.storageRoot.child(
    `csv-uploads/${metadata.userId}-${metadata.fileName}`
  );
  return mediaRef.put(file, { customMetadata: metadata });
};

storage.uploadTweetshotBackground = (background, isTemp, userId) => {
  const path = isTemp
    ? `tweetshot-background/${userId}/tweetshot-background-temp.jpg`
    : `tweetshot-background/${userId}/tweetshot-background.jpg`;
  const tweetshotBackgroundRef = firebase.storageRoot.child(path);
  return tweetshotBackgroundRef.put(background);
};

storage.doesTweetshotExist = async (userId, postId) => {
  const postData = (await firebase.threadsCollection.doc(postId).get()).data();
  const doesTweetshotExist = Boolean(Array.isArray(postData.tweetshots) && postData.tweetshots.length > 0);
  return doesTweetshotExist;
};

export default storage;