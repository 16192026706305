<script>
  export default {
    name: 'emoji-icon',
    props: {
      width: {
        type: String,
        default: '20px',
      },
      height: {
        type: String,
        default: '20px',
      },
    },
  };
</script>

<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.50002 18.3334H12.5C16.6667 18.3334 18.3334 16.6667 18.3334 12.5V7.50002C18.3334 3.33335 16.6667 1.66669 12.5 1.66669H7.50002C3.33335 1.66669 1.66669 3.33335 1.66669 7.50002V12.5C1.66669 16.6667 3.33335 18.3334 7.50002 18.3334Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.9167 8.125C13.607 8.125 14.1667 7.56536 14.1667 6.875C14.1667 6.18464 13.607 5.625 12.9167 5.625C12.2263 5.625 11.6667 6.18464 11.6667 6.875C11.6667 7.56536 12.2263 8.125 12.9167 8.125Z"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.08331 8.125C7.77367 8.125 8.33331 7.56536 8.33331 6.875C8.33331 6.18464 7.77367 5.625 7.08331 5.625C6.39296 5.625 5.83331 6.18464 5.83331 6.875C5.83331 7.56536 6.39296 8.125 7.08331 8.125Z"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7 11.0833H13C13.4167 11.0833 13.75 11.4166 13.75 11.8333C13.75 13.9083 12.075 15.5833 10 15.5833C7.925 15.5833 6.25 13.9083 6.25 11.8333C6.25 11.4166 6.58333 11.0833 7 11.0833Z"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<style lang="scss" scoped>
  svg {
    stroke: var(--primary-blue);
  }
</style>
