export const getImageInformation = (file) => {
  const image = document.createElement('img');
  image.src = URL.createObjectURL(file);
  return new Promise((resolve) => {
    image.onload = function () {
      window.URL.revokeObjectURL(image.src);
      resolve({
        imageHeight: image.height,
        imageWidth: image.width,
        image,
      });
    };
  });
};
