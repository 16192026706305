var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-container",class:{
    radio: _vm.type === 'radio',
    button: _vm.type === 'button',
    tag: _vm.type === 'tag',
    dark: _vm.type === 'dark',
    active: _vm.checked,
    inactive: !_vm.checked,
    disabled: _vm.disabled,
    sm: _vm.size === 'sm',
    md: _vm.size === 'md',
  },on:{"click":function($event){return _vm.onClick($event)}}},[_c('input',{ref:"input",attrs:{"type":"radio","name":_vm.name,"disabled":_vm.disabled},domProps:{"checked":_vm.checked,"value":_vm.value},on:{"focus":function($event){return _vm.$emit('focus', $event)},"blur":function($event){return _vm.$emit('blur', $event)}}}),(_vm.$slots.default)?_c('label',[_vm._t("default")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }