<script>
  import CustomerStatusMixin from './CustomerStatusMixin.vue';
  import SwalModalMixin from './SwalModalMixin.vue';

  export default {
    name: 'get-hypefury-subscription-mixin',
    methods: {
      displayGetHypefurySubscriptionPopUp(title, feature) {
        this.swalModal({
          title,
          html: `The ability to ${feature} is one of the many
            perks of being a Hypefury ${this.getPlanLabel('standard')} subscriber.<br>
            Do you want to get a Hypefury subscription now
            and benefit from a bunch of other possibilities to grow your account?`,
          type: 'info',
          confirmButtonText: `Yes, I want to get a ${this.getPlanLabel(
            'standard'
          )} Hypefury subscription!`,
          cancelButtonText: 'No, thanks',
          showCancelButton: true,
          allowOutsideClick: false,
          onClose: this.close,
          preConfirm: () => {
            this.$router.push('/billing');
            return true;
          },
        });
      },
    },
    mixins: [SwalModalMixin, CustomerStatusMixin],
  };
</script>
