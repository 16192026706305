import moment from 'moment';
import 'moment-timezone';

export function getFormattedDayFromTime(time, timezone) {
  let day = 'today';

  const midnightOfToday = moment.tz(timezone).startOf('day');
  const midnightOfTime = time.startOf('day');
  if (midnightOfToday.diff(midnightOfTime, 'day') === 0) {
    day = 'today';
  } else if (midnightOfToday.diff(midnightOfTime, 'day') === -1) {
    day = 'tomorrow';
  } else if (midnightOfToday.diff(midnightOfTime, 'day') === 1) {
    day = 'yesterday';
  } else {
    day = time.format('dddd');
    day += ` ${time.format('MMMM Do')}`;
  }
  return day;
}
