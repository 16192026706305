<script>
  import controller from '@/controller';
  import lodash from 'lodash';
  import { mapGetters } from 'vuex';
  import moment from 'moment';

  export default {
    name: 'linked-in-mixin',
    data() {
      return {
        selectedLinkedInIds: [],
      }
    },
    methods: {
      handleLinkedInCheckboxChange(event) {
        if (this.selectedLinkedInIds.length === 0) {
          this.$nextTick(() => {
            this.selectedLinkedInIds = [this.userProfile.integrations.linkedin.id];
          });
        } else if (this.userProfile.integrations.linkedin.id !== event.target.value) {
          this.$nextTick(() => {
            this.selectedLinkedInIds = this.selectedLinkedInIds.filter(
              (id) => id === event.target.value || id === this.userProfile.integrations.linkedin.id
            );
          });
        }
      },
    },
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile', currentUser: 'getCurrentUser' }),
      isUserConnectedToLinkedIn() {
        return lodash.get(this.userProfile, 'integrations.linkedin.tokenInfo', false)
      },
      doesUserHaveUpdatedLinkedInPermissions() {
        return lodash.get(this.userProfile, 'integrations.linkedin.hasUpdatedPermissions', false);
      },
      linkedInTokenInfo() {
        if (this.isUserConnectedToLinkedIn) {
          const now = moment();
          const refreshTokenExpiresIn = lodash.get(this.userProfile, 'integrations.linkedin.tokenInfo.refreshTokenExpiresIn');
          const accessTokenExpiresIn = lodash.get(this.userProfile, 'integrations.linkedin.tokenInfo.expiresIn');
          const tokenExpiresDate = refreshTokenExpiresIn ? moment(refreshTokenExpiresIn.toDate()) : moment(accessTokenExpiresIn.toDate());
          const diff = tokenExpiresDate.diff(now, 'days');
          const isExpired = tokenExpiresDate.isSameOrBefore(now);

          return {
            expiresInDays: !isExpired ? diff : 0,
            isExpired,
          };
        }
        return null;
      },
      userLinkedInData() {
        if (this.userProfile.integrations.linkedin) {
          return {
            profilePictureURL: this.userProfile.integrations.linkedin.profilePictureURL,
            name: this.userProfile.integrations.linkedin.name,
            headline: this.userProfile.integrations.linkedin.headline,
          };
        }
        return {
          profilePictureURL: '/img/linkedin_profile_photo_placeholder.png',
          name: this.userProfile.name,
          headline: `@${this.userProfile.username}`,
        };
      },
      linkedInPages() {
        const pagesData = this.userProfile.customerStatus === 'standard' ?
          [] :
          lodash.get(this, 'userProfile.integrations.linkedin.pages', []);
        const linkedInData = lodash.get(this.userProfile, 'integrations.linkedin');
        return linkedInData ? [{ id: linkedInData.id, name: linkedInData.name }, ...pagesData] : [];
      },
    },
  };
</script>
