<template>
  <base-modal @close="$emit('close')" title="No slots found">
    <div class="p-8">
      <p>
        No scheduled slots found. To continue posting,
        <button @click="goToSchedule" class="text-main-color-100">please create some slots</button>.
      </p>
    </div>
  </base-modal>
</template>

<script>
  export default {
    name: 'EmptyScheduleAlertModal',
    methods: {
      goToSchedule() {
        this.$router.push('/queue#schedule');
        this.$emit('close');
      },
    },
  };
</script>
