<template>
  <div data-cy="composer-facebook" class="overscroll-behavior-contain mb-13 lg:mb-0">
    <new-alert class="mb-6" v-if="!isFacebookPageConnected && !isAnyFacebookGroupSelected">
      You can schedule a Facebook post but it won't be published unless you
      <router-link class="text-main-color-100 underline" to="/settings#facebook"
        >connect your Facebook page or group to Hypefury</router-link
      >
    </new-alert>

    <div class="" ref="container">
      <div class="space-y-2">
        <div class="flex items-center gap-4">
          <img :src="userProfile.photoURL" :alt="userProfile.name" class="avatar-img" />
          <span class="avatar-name">{{ userProfile.name }}</span>
        </div>
        <div class="ml-13">
          <base-input alternative>
            <textarea
              dir="auto"
              class="form-control simple min-h-16 text-base"
              ref="facebookText"
              rows="3"
              v-model="facebookText"
              maxlength="63206"
              @input="didUserEditFacebookText = true"
              @focus="onFocus"
              @blur="$emit('blurred')"
              @keyup="resize"
              @keydown.ctrl.enter="$emit('pressed-ctrl-enter')"
              @keydown.meta.enter="$emit('pressed-ctrl-enter')"
              id="facebookText"
              data-cy="composer-fb-input"
            />

            <portal-target
              name="facebook_post_media"
              multiple
              class="thread-preview focus--hide space-y-2 p-2 pb-0"
            />

            <portal-target name="tweetshot-preview" />
          </base-input>
          <portal-target :name="`facebook-actions-${tweet.guid}`" v-if="isDesktop" class="px-2.5" />
        </div>
      </div>
    </div>
    <portal :to="`facebook-actions-${tweet.guid}`">
      <div class="mt-2 flex items-center justify-end gap-x-3 lg:gap-6">
        <tooltip data-cy="compose-upload-media" :content="uploadContainerTooltipContent">
          <template v-if="isPostOfTypeFacebook">
            <upload-container
              class="my-4"
              :tweet="tweet"
              portalTargetName="facebook_post_media"
              :canAddMedia="canAddMedia"
              :canAddMoreVideos="canAddMoreVideos"
              postType="facebook"
              :clearMediaSelectionAtIndexParent="clearMediaSelectionAtIndex"
              :showFacebookBlock="true"
              :uploadingMedia="uploadingMedia"
            />
          </template>
          <template v-else>
            <upload-container
              v-for="tweetItem in tweets"
              :key="tweetItem.count"
              :tweet="tweetItem"
              class="my-4"
              portalTargetName="facebook_post_media"
              :canAddMedia="canAddMedia"
              :canAddMoreVideos="canAddMoreVideos"
              :clearMediaSelectionAtIndexParent="clearMediaSelectionAtIndex"
              :showFacebookBlock="true"
              :hideInput="true"
              :uploadingMedia="uploadingMedia"
            />
          </template>
        </tooltip>
        <tooltip
          v-if="isPostOfTypeFacebook"
          data-cy="compose-tweetshots"
          :content="
            tweet.mediaFile.length > 0 ? 'Cannot post tweetshots with images' : 'Tweetshots'
          "
          class="block"
        >
          <div>
            <new-button
              type="custom"
              @click="$emit('show-tweetshot-modal')"
              :disabled="tweet.mediaFile.length > 0"
            >
              <inline-svg class="h-5 w-5 text-main-color-100" src="/img/icons/tweetshot.svg" />
            </new-button>
          </div>
        </tooltip>
        <emoji-picker v-if="isDesktop" @selected-emoji="addEmoji($event)" />
      </div>
    </portal>
  </div>
</template>

<script>
  import lodash from 'lodash';
  import { mapGetters } from 'vuex';
  import EmojiPicker from '@/components/Threads/EmojiPicker.vue';
  import UploadContainer from '@/components/UploadContainer.vue';
  import FacebookMixin from '@/views/Mixins/FacebookMixin.vue';
  import UploadContainerMixin from '@/views/Mixins/UploadContainerMixin.vue';
  import BreakpointsMixin from '@/views/Mixins/BreakpointsMixin.vue';

  export default {
    components: {
      UploadContainer,
      EmojiPicker,
    },
    name: 'facebook',
    props: {
      isMobileKeyboardVisible: {
        type: Boolean,
        default: false,
      },
      isPostOfTypeFacebook: {
        type: Boolean,
        default: false,
      },
      tweetshotContent: {
        type: Array,
        default: () => [],
      },
      tweet: {
        type: Object,
        default: null,
      },
      tweets: {
        type: Array,
        default: () => [],
      },
      tweetsCombinedStatuses: {
        type: String,
        default: '',
      },
      isVisible: {
        type: Boolean,
        default: false,
      },
      originalThread: {
        type: Object,
        default: null,
      },
      uploadingMedia: Array,
    },
    data() {
      return {
        facebookText: '',
        didUserEditFacebookText: false,
        previousHeight: 0,
      };
    },
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
      charactersLeftClass() {
        if (this.facebookText.length >= 63206) return 'character-count-exceeded';
        if (this.facebookText.length > 63206 - 100) return 'character-count-warning';
        return 'bg-light';
      },
      charactersLeftCounter() {
        return 63206 - this.facebookText.length;
      },
      canAddMoreVideos() {
        return (
          this.tweetshotContent.length === 0 &&
          (this.tweet.mediaFile.length === 0 ||
            this.tweet.mediaFile.filter((media) => media.type.includes('video')).length === 0)
        );
      },
      canAddMedia() {
        if (this.tweetshotContent.length !== 0) return false;
        if (!this.canAddMoreVideos) return false;
        return true;
      },
      uploadContainerTooltipContent() {
        if (this.tweetshotContent.length > 0) return 'Cannot post media with tweetshots';
        if (!this.canAddMoreVideos) return 'Cannot add more than 1 video';
        return 'Upload media';
      },
    },
    mounted() {
      this.$emit('resetFacebookManualEdit', () => {
        this.didUserEditFacebookText = false;
      });
      this.updateParentValues();
      this.resize();
    },
    updated() {
      this.updateParentValues();
      this.resize();
    },
    methods: {
      addEmoji(emoji) {
        const captionRef = this.$refs.facebookText;
        const position = captionRef.selectionStart;
        this.facebookText = [
          this.facebookText.slice(0, position),
          emoji.native,
          this.facebookText.slice(position),
        ].join('');
        const positionAfterTweet = position + 2;

        this.$nextTick(() => {
          captionRef.focus();
          captionRef.setSelectionRange(positionAfterTweet, positionAfterTweet);
        });
      },
      clearMediaSelectionAtIndex(tweet, index) {
        this.$emit('cleared-media', tweet, index);
      },
      updateParentValues() {
        this.$emit('values-set', {
          facebookText: this.facebookText,
          didUserEditFacebookText: this.didUserEditFacebookText,
        });
      },
      updateValuesFromThread(thread) {
        if (thread.facebook) {
          this.didUserEditFacebookText = lodash.get(thread, 'facebook.didUserEditFacebookText', false);
          this.facebookText =
            thread.source === 'facebook-post'
              ? thread.tweets[0].status
              : lodash.get(thread, 'facebook.text', '');
        }
      },
      resize() {
        const { facebookText } = this.$refs;
        if (facebookText && facebookText.scrollHeight > 100) {
          facebookText.style.height = facebookText.scrollHeight - 4 + 'px';
        }
      },
      onFocus() {
        this.$emit('focused');
        this.resize();
      },
    },
    watch: {
      tweetsCombinedStatuses: {
        immediate: true,
        handler(text) {
          if (!this.didUserEditFacebookText) {
            this.facebookText = text;
          }
        },
      },
      isVisible(visible) {
        if (visible) {
          if (!this.didUserEditFacebookText) {
            this.facebookText = this.tweetsCombinedStatuses;
          }
          this.$nextTick(() => {
            if (this.$refs.facebookText && !this.isTabletOrMobile) {
              this.$refs.facebookText.focus();
            }
            this.resize();
          });
        }
      },
      originalThread(thread) {
        if (thread) {
          this.updateValuesFromThread(thread);
        }
      },
    },
    mixins: [FacebookMixin, UploadContainerMixin, BreakpointsMixin],
  };
</script>
