import axios from 'axios';

export const downloadFile = async (fileURL, fileName) => {
  const response = await axios.get(fileURL, {
    responseType: 'blob',
  });

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};
