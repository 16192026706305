<template>
  <div class="wrapper md:min-h-screen lg:flex">
    <div v-if="currentUser && $route.path !== '/setup'">
      <div class="lg:h-screen lg:overflow-y-auto lg:sticky lg:top-0 lg:w-241 without-scrollbar">
        <hypefury-header :userFollowers="user.followers" />
        <side-bar
          :gumroadRevenue="gumroadRevenue"
          :currencySymbol="gumroadCurrency"
          :userName="user.name"
          :userFollowers="user.followers"
          :userPhoto="user.photoURL"
          :setupProgress="setupProgress"
          @showSetupProgress="showSetupProgressPopup"
        >
          <template slot="links">
            <sidebar-item
              v-if="userProfile.managers.length > 0 && !isUserManaging"
              :link="{
                name: 'Ghostwriting Validation',
                icon: '/img/icons/add.svg',
                path: '/ghostwriting/validation',
              }"
              :classes="{ 'text-red-500': clientGhostwritingThreads && clientGhostwritingThreads.length > 0 }"
            ></sidebar-item>
            <button
              v-if="!cannotSchedulePosts"
              @click="handleSidebarCreateButton"
              data-cy="sidebar-create-button"
              class="custom-menu-button cursor-pointer gap-1 rounded-full px-4 py-1 text-left text-base font-medium"
            >
              Create
            </button>
            <sidebar-item
              v-if="!cannotSchedulePosts"
              :link="{
                name: 'Dashboard',
                counterTooltip: 'Dashboard',
                path: '/dashboard',
              }"
            >
            </sidebar-item>
            <sidebar-item
                    :link="{
                name: 'Queue',
                counterTooltip: 'Retweets are not taken into account.',
                counter: currentlyScheduledPostsCount,
                icon: '/img/icons/queue.svg',
                path: '/queue'
              }">
            </sidebar-item>

            <div class="sidebar-menu-item">
              <new-button
                @click="openNewComposerWithDrafts"
                type="transparent"
                class="app-sidebar-link inline-flex items-center justify-center gap-1 rounded-full px-4 font-medium"
                data-cy="sidebar-drafts-button"
              >
                <span class="text-base font-medium">Drafts</span>
              </new-button>
            </div>

            <div class="sidebar-menu-item">
              <new-button
                @click="openNewComposerWithPrompts"
                type="transparent"
                class="app-sidebar-link inline-flex items-center justify-center gap-1 rounded-full px-4 font-medium"
                data-cy="sidebar-prompts-button"
              >
                <span class="text-base font-medium">Inspiration</span>
              </new-button>
            </div>

            <sidebar-item
              :link="{
                name: 'Recurrent Posts',
                icon: '/img/icons/calendar.svg',
                path: '/recurrent-posts'
              }"
            >
            </sidebar-item>
            <sidebar-item
              :link="{
                name: 'Powerups',
                icon: '/img/icons/queue.svg',
                path: '/powerups'
              }">
            </sidebar-item>
            <sidebar-item
              :link="{
                name: 'Youtube to Tweet',
                icon: '/img/icons/queue.svg',
                path: '/youtube-to-tweet'
              }">
            </sidebar-item>
            <sidebar-item
                    v-if="isWriter(userProfile)"
                    :link="{
                name: 'Growth Program - Write',
                icon: '/img/icons/growth.svg',
                path: '/growth/write'
              }">
            </sidebar-item>
            <sidebar-item
                    v-if="isInfluencer(userProfile)"
                    :link="{
                name: 'Growth Program - Check',
                icon: '/img/icons/growth.svg',
                path: '/growth/check'
              }">
            </sidebar-item>
            <sidebar-item
                    v-if="userProfile.isAdmin"
                    :link="{
                name: 'Growth Program - Admin',
                icon: '/img/icons/growth.svg',
                path: '/growth/admin'
              }">
            </sidebar-item>
          </template>
          <template slot="earn-links">
            <sidebar-item
              :link="{
                name: 'Auto-DMs',
                icon: '/img/icons/queue.svg',
                path: '/auto-dms',
              }"
            >
            </sidebar-item>
            <sidebar-item
              :link="{
                name: 'Gumroad Sales',
                icon: '/img/icons/money.svg',
                path: '/gumroad-sales',
              }">
            </sidebar-item>
            <sidebar-item
                    :link="{
                name: 'Time-based Sales',
                icon: '/img/icons/money.svg',
                path: '/toolbox/time-based-sales'
              }">
            </sidebar-item>
          </template>
          <template slot="engage-links">
            <sidebar-item
              :link="{
                name: 'Engagement Builder',
                icon: '/img/icons/all.svg',
                path: '/feed',
              }"
            />
            <sidebar-item
              :link="{
                name: 'My Mentions',
                icon: '/img/icons/mentions.svg',
                path: '/mentions',
              }"
            />
          </template>
          <template slot="ghostwriting-links">
            <sidebar-item
              :link="{
                name: 'Dashboard',
                icon: '/img/icons/add.svg',
                path: '/ghostwriting/dashboard',
              }"
            ></sidebar-item>
            <sidebar-item
              v-for="managedAccount in userProfile.managedAccounts"
              :key="managedAccount.uid"
              :link="{
                name: `@${managedAccount.profileInfo.screen_name}`,
                counter: managedAccountsAdditionalData[managedAccount.uid]
                  ? managedAccountsAdditionalData[managedAccount.uid].numberOfScheduledPosts
                  : 0,
                icon: '',
                path: `/ghostwriting/client/${managedAccount.profileInfo.screen_name}`,
              }"
            >
            </sidebar-item>
          </template>
          <template slot="track-links">
            <sidebar-item
              :link="{
                name: 'Analytics',
                icon: '/img/icons/analytics.svg',
                path: `/analytics/${userProfile.username}`,
              }">
            </sidebar-item>
            <sidebar-item
                    :link="{
                name: 'History',
                icon: '/img/icons/history.svg',
                path: '/history'
              }">
            </sidebar-item>
            <sidebar-item
              :link="{
                name: 'Evergreen Posts',
                icon: '/img/icons/star-empty.svg',
                path: '/evergreen-posts'
              }">
            </sidebar-item>
            <sidebar-item
              :link="{
                name: 'Autoplugs Metrics',
                icon: '/img/icons/autoplug.svg',
                path: '/autoplugs-metrics',
              }"
            ></sidebar-item>
          </template>
          <template slot="dm-crm-links">
            <sidebar-item
              :link="{
                name: 'Campaigns',
                icon: '/img/icons/autodm.svg',
                path: '/auto-dm/campaigns',
              }"
            ></sidebar-item>
            <sidebar-item
              :link="{
                name: 'Users',
                icon: '/img/icons/followers.svg',
                path: '/auto-dm/users',
              }"
            ></sidebar-item>
          </template>
          <template slot="ghostwriting-clients">
            <sidebar-item
              v-if="userProfile.isGhostwriter"
              :link="{
                name: 'Dashboard',
                icon: '/img/icons/add.svg',
                path: '/ghostwriting/dashboard',
              }"
            ></sidebar-item>
            <sidebar-item
              v-for="managedAccount in userProfile.managedAccounts"
              :key="managedAccount.uid"
              :link="{
                name: `@${managedAccount.profileInfo.screen_name}`,
                counter: managedAccountsAdditionalData[managedAccount.uid]
                  ? managedAccountsAdditionalData[managedAccount.uid].numberOfScheduledPosts
                  : 0,
                icon: '',
                path: `/ghostwriting/client/${managedAccount.profileInfo.screen_name}`,
              }"
            >
            </sidebar-item>
          </template>
          <template slot="toolbox-links">
            <sidebar-item
              :link="{
                name: 'Gumroad Sales',
                icon: '/img/icons/money.svg',
                path: '/gumroad-sales',
              }">
            </sidebar-item>
            <sidebar-item
                    :link="{
                name: 'Time-based Sales',
                icon: '/img/icons/money.svg',
                path: '/toolbox/time-based-sales'
              }">
            </sidebar-item>
            <sidebar-item
                    :link="{
                name: 'Auto-Retweeter',
                icon: '/img/icons/retweet.svg',
                path: '/toolbox/auto-retweeter'
              }">
            </sidebar-item>
            <sidebar-item
              v-if="!isMobile"
              :link="{
                name: 'Archive Uploader',
                icon: '/img/icons/upload.svg',
                path: '/toolbox/twitter-archive-uploader',
              }"
            >
            </sidebar-item>
          </template>
          <template slot="account-links">
            <sidebar-item
                    :link="{
                name: 'Settings',
                icon: '/img/icons/settings.svg',
                path: '/settings'
              }">
            </sidebar-item>
            <sidebar-item
                v-if="userProfile.isAdmin"
                    :link="{
                name: 'Cancellations',
                icon: '/img/icons/settings.svg',
                path: '/cancellations'
              }">
            </sidebar-item>
            <sidebar-item
              v-if="!isUserManaging"
              :link="{
                name: 'Billing',
                icon: '/img/icons/billing.svg',
                path: '/billing'
              }">
            </sidebar-item>
          </template>
        </side-bar>
      </div>
    </div>
    <notifications></notifications>
    <div class="main-content lg:flex-1 border-l border-gray-90">
      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- Only prevent re-rendering for the Twitter Archive uploader component -->
          <keep-alive include="twitter-archive-uploader">
            <!-- your content here -->
            <div>
              <base-alert
                :dismissible="true"
                type="info"
                radius="rounded-lg"
                class="mx-6 my-4 flex max-w-4xl items-center justify-between text-base md:mx-auto"
                v-if="showUpgradeAlert && $route.path !== '/create' && $route.path !== '/billing'"
                @dismiss="hideAnnualUpgradeAlert"
              >
                <div class="flex flex-col items-center gap-x-10 gap-y-6 sm:flex-row">
                  <div>
                    <b class="text-ds-text-primary">Save 20% by switching to yearly billing! </b>
                    <span class="text-ds-text-primary"
                      >- Immunity to price hikes - 2 months for free</span
                    >
                  </div>
                  <router-link
                    to="/billing?upgradeToYearly=true"
                    class="button custom-button small-button"
                    style="text-decoration: none !important;"
                  >
                    Upgrade
                  </router-link>
                </div>
              </base-alert>
              <router-view
                :class="[
                  'mx-auto',
                  {
                    'max-w-610 px-4 pb-8 lg:px-0 lg:py-12':
                      $route.meta.layout === $options.LAYOUTS.DEFAULT,
                    'container px-4 pb-8 lg:px-16 lg:py-12':
                      $route.meta.layout === $options.LAYOUTS.LARGE,
                    'px-4 pb-8 lg:max-w-dashboard lg:px-20 lg:py-8 2xl:px-0':
                      $route.meta.layout === $options.LAYOUTS.DASHBOARD,
                  },
                ]"
              />
              <portal-target name="layout-right-side-bar" />
            </div>
          </keep-alive>
        </fade-transition>
      </div>
    </div>
    <div v-if="showSetupProgress" class="fixed inset-0 z-20 flex items-center justify-center px-4 py-10">
      <div class="bg-white w-466 rounded-3xl py-12 px-8 relative z-30 app-create-modal">
        <div class="flex gap-x-2 items-center mb-6">
          <CircularProgress :value="setupProgress" strokeColor="#28AF87" :radius="24">
            <template v-slot:default>
              <b>{{setupProgress}}</b>
            </template>
          </CircularProgress>
          <span class="font-bold text-2xl">Set up your account</span>
          <button
            class="ml-auto"
            @click="
              showSetupProgress = false;
              $eventStore.general.sidebar('Setup progress: Close');
            "
          >
            <!-- close.svg -->
            <inline-svg src="/img/icons/close.svg" />
          </button>
        </div>
        <p class="text-gray-20 app-modal-text text-lg mb-6">Maximize your social media growth by getting your Hypefury account set up properly.</p>
        <ul class="grid gap-4 list-none m-0 mb-6">
          <li data-cy="setup-first-tweet" class="flex items-center">
            <div class="checkbox">
              <input id="scheduleThread" type="checkbox" class="hidden" :checked="getTask('scheduleThread.isCompleted')" disabled="true" />
              <label
                @click="
                  showComposer = true;
                  showSetupProgress = false;
                  $eventStore.general.sidebar('Setup progress: Schedule first tweet');
                "
                for="scheduleThread"
              >
                Schedule your first tweet
              </label>
            </div>
          </li>
          <li data-cy="setup-publish-tweet" class="flex items-center">
            <div class="checkbox">
              <input
                id="scheduleTenThreads"
                type="checkbox"
                class="hidden"
                :checked="userProfile.totalScheduledPosts >= 10"
                disabled="true"
              />
              <label
                @click="
                  showComposer = true;
                  showSetupProgress = false;
                  $eventStore.general.sidebar('Setup progress: Publish 10 tweets');
                "
                for="scheduleTenThreads"
              >
                Publish 10 tweets
              </label>
            </div>
          </li>
          <li data-cy="setup-use-powerapp" class="flex items-center">
            <div class="checkbox">
              <input id="powerUp" type="checkbox" class="hidden" :checked="getTask('enableTweetBooster.isCompleted') || getTask('setAutoplugTweet.isCompleted')" disabled="true" />
              <label
                @click="
                  $router.push('/powerups');
                  showSetupProgress = false;
                  $eventStore.general.sidebar('Setup progress: Use your first Powerup');
                "
                for="powerUp">
                Use your first Power Up
              </label>
            </div>
          </li>
          <li data-cy="setup-enable-notifications" class="flex items-center justify-between">
            <div class="checkbox" :class="getTask('enableNotifications.isCanceled') ? 'opacity-25' : ''">
              <input id="notification" type="checkbox" class="hidden" :checked="getTask('enableNotifications.isCompleted')" disabled="true" />
              <label
                @click="
                  enableNotifications();
                  $eventStore.general.sidebar('Setup progress: Enable notifications');
                "
                for="notification">
                Enable notifications
              </label>
            </div>
            <button
              class="button small-button text-secondary"
              style="padding: 0;"
              @click="
                setTaskAsCanceled('enableNotifications');
                $eventStore.general.sidebar('Setup progress: Skip enabling notifications');
              "
              v-if="!isTaskCompletedOrCanceled('enableNotifications')"
            >
              Skip
            </button>
          </li>
          <li data-cy="setup-use-evergreen" class="flex items-center justify-between">
            <div class="checkbox" :class="getTask('setBestTweetsAsEvergreen.isCanceled') ? 'opacity-25' : ''">
              <input id="evergreen" type="checkbox" class="hidden" :checked="getTask('setBestTweetsAsEvergreen.isCompleted')" disabled="true" />
              <label
                @click="
                  isSetBestTweetsAsEvergreenModalVisible = true;
                  showSetupProgress = false;
                  $eventStore.general.sidebar('Setup progress: Set evergreen tweets');
                "
                for="evergreen"
              >
                Set best tweets as evergreen
              </label>
            </div>
            <button
              class="button small-button text-secondary"
              style="padding: 0;"
              @click="
                setTaskAsCanceled('setBestTweetsAsEvergreen')
                $eventStore.general.sidebar('Setup progress: Skip setting evergreen tweets');
              "
              v-if="!isTaskCompletedOrCanceled('setBestTweetsAsEvergreen')"
            >
              Skip
            </button>
          </li>
        </ul>
        <div class="grid justify-items-end">
          <button
            data-cy="setup-done-button"
            @click="
              showSetupProgress = false;
              $eventStore.general.sidebar('Setup progress: Done');
            "
            class="button primary-button small-button"
          >
            Done
          </button>
        </div>
      </div>
      <div class="fixed z-20 inset-0 cursor-pointer bg-overlay" @click="showSetupProgress = false" />
    </div>

    <set-best-tweets-as-evergreen-modal
      :show="isSetBestTweetsAsEvergreenModalVisible"
      @close="isSetBestTweetsAsEvergreenModalVisible = false"
    />
    <portal-target name="modal" multiple />

    <new-button
      v-if="!isMobile && userProfile && isUserPlanBasicOrAbove(userProfile.customerStatus)"
      @click="toggleCrispChat"
      type="custom"
      class="chat-button bottom-14 bg-ds-button-secondary-background"
    >
      <inline-svg
        v-if="!showCrispChat"
        src="/img/icons/new/help.svg"
        class="h-6 w-6 stroke-ds-button-primary-background"
      />
      <inline-svg v-else src="/img/icons/close.svg" class="h-6 w-6" />
    </new-button>
    <new-button
      v-if="$route.path !== '/billing'"
      class="!fixed bottom-7 right-7 z-10 gap-x-2.5"
      @click="handleFloatingCreateButton"
      data-cy="create-button"
    >
      <inline-svg class="h-6 w-6 stroke-current" src="/img/icons/new-edit-icon.svg" />
      Create
    </new-button>
    <new-composer
      :initialReelOptions="reelOptions"
      :defaultTab="newComposerDefaultTab"
      :timeFromParent="timeFromParent"
      :threadFromParent="threadFromParent"
      :categoriesFromParent="categoriesFromParent"
      :sourceFromParent="sourceFromParent"
      :postTypeFromParent="postTypeFromParent"
      :isAReplyFromParent="isAReplyFromParent"
      :isAMA="isAMA"
      :tweetsFromParent="tweetsFromParent"
      :showDraftsOnLoad="showDraftsOnLoad"
      :showPromptsOnLoad="showPromptsOnLoad"
      :hasStartedEditingFromParent="hasStartedEditing"
      :youtubeShortRef="youtubeShortRef"
      v-if="showComposer"
      @close="closeNewComposer"
    />
    <empty-schedule-alert-modal
      v-if="areAllScheduleSlotsEmpty"
      @close="areAllScheduleSlotsEmpty = false"
    />
  </div>
</template>
<script>
  /* eslint-disable no-new */
  const getSymbolFromCurrency = require('currency-symbol-map');
  import SetBestTweetsAsEvergreenModal from '@/views/Modals/SetBestTweetsAsEvergreenModal';
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';
  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }
  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  import { countFormatter } from '@/util/countFormatter';
  import { FadeTransition } from 'vue2-transitions';
  import { mapGetters, mapState } from 'vuex';
  import HypefuryHeader from './HypefuryHeader';
  import { isInfluencer, isWriter } from '@/util/getGrowthProgramRole';
  import { setTheme, LAYOUTS } from '@/util/setTheme';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import { get, differenceBy, size, has } from 'lodash';
  import { store } from '@/store';
  import MobileMixin from '../Mixins/MobileMixin.vue';
  import EnableNotificationsMixin from '../Mixins/EnableNotificationsMixin.vue';
  import { EventBus } from '@/event-bus';
  import { getUnfinishedEditedPost, removeUnfinishedEditedPost } from '@/util/unfinishedPosts';
  import { DraftThread } from '../../models/DraftThread';
  import controller from '@/controller';
  import CircularProgress from 'easy-circular-progress';
  import moment from 'moment';
  import NewComposer from '@/components/NewComposer/NewComposer.vue';
  import BreakpointsMixin from '@/views/Mixins/BreakpointsMixin.vue';
  import EmptyScheduleAlertModal from '@/views/Modals/EmptyScheduleAlertModal.vue';
  import CustomerStatusMixin from '../Mixins/CustomerStatusMixin.vue';

  export default {
    name: 'dashboard-layout',
    LAYOUTS,
    beforeCreate() {
      if (this.$route.path === '/drafts') {
        this.$router.push('/queue?showDrafts=true');
      } else if (this.$route.path === '/inspiration') {
        this.$router.push('/queue?showPrompts=true');
      }
    },
    data() {
      return {
        isFocusModeEnabled: false,
        timeForModal: null,
        status: null,
        isSidebarCollapsed: false,
        showSetupProgress: false,
        isSetBestTweetsAsEvergreenModalVisible: false,
        showCrispChat: false,
        showComposer: false,
        newComposerDefaultTab: 'twitter',
        reelOptions: null,
        timeFromParent: null,
        threadFromParent: null,
        categoriesFromParent: null,
        sourceFromParent: null,
        postTypeFromParent: null,
        isAReplyFromParent: false,
        isAnAMATweet: false,
        tweetsFromParent: [],
        showDraftsOnLoad: false,
        showPromptsOnLoad: false,
        hasStartedEditing: false,
        areAllScheduleSlotsEmpty: false,
        isAMA: false,
        youtubeShortRef: null,
      };
    },
    components: {
      FadeTransition,
      HypefuryHeader,
      CircularProgress,
      SetBestTweetsAsEvergreenModal,
      NewComposer,
      EmptyScheduleAlertModal,
    },
    computed: {
      ...mapGetters({
        currentUser: 'getCurrentUser',
        userProfile: 'getUserProfile',
        parentUserProfile: 'getParentUserProfile',
      }),
      ...mapState([
        'isUserManaging',
        'cannotSchedulePosts',
        'schedule',
        'managedAccountsAdditionalData',
        'clientGhostwritingThreads',
        'isWholeScheduleLoaded',
      ]),
      currentlyScheduledPostsCount() {
        return get(this.userProfile, 'currentlyScheduledPostsCount');
      },
      gumroadRevenue() {
        return get(this.userProfile, 'integrations.gumroad.revenue');
      },
      gumroadCurrency() {
        if (this.userProfile.integrations && this.userProfile.integrations.gumroad) {
          return getSymbolFromCurrency(this.userProfile.integrations.gumroad.currency);
        }
        return '$';
      },
      user() {
        const {
          photoURL: normalPhotoUrl,
          name,
          followingCount: following,
          followersCount: followers,
        } = this.userProfile;
        const photoURL = normalPhotoUrl.replace('_normal', '');

        return {
          photoURL,
          name,
          following: countFormatter(following, 1),
          followers: countFormatter(followers, 1),
          earningsThisMonth: 0,
        };
      },
      showGlobalComposer() {
        if (get(this.userProfile, 'source')) {
          return this.isUserSubscribed(this.userProfile.customerStatus);
        } else {
          return Boolean(this.userProfile);
        }
      },
      setupProgress() {
        let count = 0;
        [
          this.getTask('scheduleThread.isCompleted'),
          this.userProfile.totalScheduledPosts >= 10,
          this.getTask('enableTweetBooster.isCompleted') || this.getTask('setAutoplugTweet.isCompleted'),
          this.isTaskCompletedOrCanceled('enableNotifications'),
          this.isTaskCompletedOrCanceled('setBestTweetsAsEvergreen'),
        ].forEach((isTaskCompleted) => {
          if (isTaskCompleted) {
            count++;
          }
        });
        return count * 20;
      },
      showUpgradeAlert() {
        const userParentsCustomerStatus = get(this, 'userProfile.parentsCustomerStatus', {});
        const isChildAccount = size(userParentsCustomerStatus) > 0;
        const customerStatusIsNone = get(this, 'userProfile.customerStatus') === 'none';
        const subscriptionIsMonthly = get(this, 'userProfile.subscriptionInterval') === 'month';
        const annualUpgradeAlertHiddenAt = localStorage.getItem('annual_upgrade_alert_hidden_at');
        return Boolean(
          this.userProfile &&
            (customerStatusIsNone || subscriptionIsMonthly) &&
            !isChildAccount &&
            // Alert should stay hidden for a week if user closed it at some point
            (!annualUpgradeAlertHiddenAt || moment().diff(annualUpgradeAlertHiddenAt, 'week') > 1)
        );
      },
    },
    methods: {
      // TODO: Move onboarding checklist logic to a Mixin
      getTask(taskName) {
        return get(this.userProfile, `onboardingChecklist.tasks.${taskName}`);
      },
      isTaskCompletedOrCanceled(taskName) {
        return this.getTask(`${taskName}.isCompleted`) || this.getTask(`${taskName}.isCanceled`);
      },
      setTaskAsCanceled(taskName) {
        try {
          this.swalModal({
            title: 'Skip',
            html: `Are you sure you want to skip this step?`,
            type: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes, go ahead',
            preConfirm: () => controller.setTaskAsCanceled(this.currentUser, this.userProfile.uid, taskName),
          });
        } catch (error) {
          this.swalModal({
            title: 'Error',
            html: `An error has occurred while trying to skip this task.`,
            type: 'warning',
          });
          throw error;
        }
      },
      showSetupProgressPopup(status) {
        this.showSetupProgress = status;
      },
      isInfluencer,
      isWriter,
      initScrollbar() {
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          initScrollbar('scrollbar-inner');
        }
      },
      setTheme() {
        const theme = get(this, 'userProfile.settings.theme') || 'dark';
        setTheme(theme);
      },
      openModalWithTimeOfNextFreeSlot: function (previousTime, isFocusModeEnabled, status) {
        EventBus.$emit('open-composer', null, false, '');
      },
      reOpenModalWithTimeOfNextFreeSlot: function (previousTime, isFocusModeEnabled) {
        if (this.$route.name !== 'Queue') return null;
        if (!previousTime) throw new Error('No previousTime specified.');
        this.$nextTick(function () {
          this.openModalWithTimeOfNextFreeSlot(previousTime, isFocusModeEnabled);
        });
      },
      async saveUnfinishedEditedPostAsDraft() {
        const unfinishedEditedPost = getUnfinishedEditedPost();
        if (!unfinishedEditedPost) {
          return;
        }

        const thread = new DraftThread({
          tweets: unfinishedEditedPost.tweets,
          user: unfinishedEditedPost.user,
          isFavorite: unfinishedEditedPost.isFavorite,
          conditionalRetweetsConditions: unfinishedEditedPost.conditionalRetweetsConditions,
          autoplug: unfinishedEditedPost.autoplug,
          shareOnInstagram: unfinishedEditedPost.shareOnInstagram,
          linkedIn: unfinishedEditedPost.linkedIn,
          facebook: unfinishedEditedPost.facebook,
          delayBetweenTweets: unfinishedEditedPost.delayBetweenTweets,
          categories: unfinishedEditedPost.categories,
          instagramCaption: unfinishedEditedPost.instagramCaption,
          isRecurrentPost: unfinishedEditedPost.isRecurrentPost,
          autoDM: unfinishedEditedPost.autoDM,
          hasThreadFinisherTweet: unfinishedEditedPost.hasThreadFinisherTweet
        });
        await thread.saveToFirestore(
          this.currentUser,
          this.userProfile.uid,
          this.userProfile.timezone,
          this.$eventStore
        );
        removeUnfinishedEditedPost();
        const isCurrentPageDrafts = this.$route.meta.id == 'drafts';
        this.swalModal({
          title: 'Oops!',
          html: "Looks like you left some work unfinished. We've added it to Drafts.",
          showCancelButton: !isCurrentPageDrafts,
          confirmButtonText: isCurrentPageDrafts ? 'Okay' : 'Take me to Drafts',
          cancelButtonText: 'Nevermind',
          preConfirm: () => this.$router.push('/drafts'),
        });
      },
      async removeAdditionalAccountFromUrlParamAsCSAgent() {
        const urlParams = new URLSearchParams(window.location.search);
        const isCSAgent = urlParams.has('switchToAccount');
        const userId = isCSAgent ? urlParams.get('switchToAccount') : null;
        const additionalAccountToRemove = urlParams.has('additionalAccountToRemove')
          ? urlParams.get('additionalAccountToRemove')
          : null;
        if (!isCSAgent || !additionalAccountToRemove) {
          return null;
        }

        try {
          await controller.removeAdditionalTwitterAccount(
            this.currentUser,
            userId,
            additionalAccountToRemove
          );
          this.$notify({
            type: 'success',
            message: `Additional account ${additionalAccountToRemove} successfully removed! Please close this tab.`,
          });
        } catch (error) {
          console.error(error);
          this.swalModal({
            title: 'Sorry',
            text: `An error has occurred while trying to remove the additional account ${additionalAccountToRemove}. Please check the console.`,
            type: 'warning',
          });
        }
      },
      hideAnnualUpgradeAlert() {
        localStorage.setItem('annual_upgrade_alert_hidden_at', moment().format());
      },
      toggleCrispChat() {
        if (window.$crisp) {
          this.showCrispChat = !this.showCrispChat;
          this.showCrispChat
            ? window.$crisp.push(['do', 'chat:open'])
            : window.$crisp.push(['do', 'chat:close']);
        } else {
          console.error('Crisp is not loaded yet');
        }
      },
      handleSidebarCreateButton() {
        this.$eventStore.general.sidebar(`Create`);
        this.openNewComposer();
      },
      handleFloatingCreateButton() {
        this.$eventStore.general.composerButton();
        this.openNewComposer();
      },
      openNewComposer() {
        const isAllSlotsEmpty = this.userProfile.schedule.getScheduleForDB().every((slot) => !slot);
        if (isAllSlotsEmpty) {
          this.areAllScheduleSlotsEmpty = true;
          return;
        }
        this.timeFromParent = null;
        this.threadFromParent = null;
        this.categoryIdFromParent = null;
        this.sourceFromParent = null;
        this.postTypeFromParent = null;
        this.showComposer = true;
        this.newComposerDefaultTab = 'twitter';
        this.reelOptions = null;
        this.hasStartedEditing = false;
      },
      openNewComposerWithDrafts() {
        this.$eventStore.general.sidebar(`Drafts`);
        this.showDraftsOnLoad = true;
        this.openNewComposer();
      },
      openNewComposerWithPrompts() {
        this.$eventStore.general.sidebar(`Inspiration`);
        this.showPromptsOnLoad = true;
        this.openNewComposer();
      },
      closeNewComposer() {
        this.showComposer = false;
        this.isAReplyFromParent = false;
        this.showDraftsOnLoad = false;
        this.showPromptsOnLoad = false;
        this.isAnAMATweet = false;
      },
    },
    mounted() {
      this.initScrollbar();
      this.setTheme();
      this.saveUnfinishedEditedPostAsDraft();
      this.removeAdditionalAccountFromUrlParamAsCSAgent();

      if (window.$crisp) {
        window.$crisp.push([
          'on',
          'chat:closed',
          () => {
            this.showCrispChat = false;
          },
        ]);
      }
      const that = this;
      EventBus.$on('open-new-composer', async function (composerContext = {}) {
        that.timeFromParent = has(composerContext, 'time') ? composerContext.time : null;
        that.threadFromParent = has(composerContext, 'post') ? composerContext.post : null;
        that.categoriesFromParent = has(composerContext, 'categoryId')
          ? composerContext.categoryId
          : null;
        that.sourceFromParent = has(composerContext, 'source') ? composerContext.source : null;
        that.postTypeFromParent = has(composerContext, 'postType')
          ? composerContext.postType
          : null;
        that.newComposerDefaultTab = has(composerContext, 'selectedTab')
          ? composerContext.selectedTab
          : 'twitter';
        that.reelOptions = has(composerContext, 'reelOptions') ? composerContext.reelOptions : null;
        that.showComposer = true;
        that.isAReplyFromParent = has(composerContext, 'isAReply')
          ? composerContext.isAReply
          : false;
        that.tweetsFromParent = has(composerContext, 'tweetsFromParent')
          ? composerContext.tweetsFromParent
          : [];
        that.isAMA = has(composerContext, 'isAMA') ? composerContext.isAMA : false;
        that.hasStartedEditing = has(composerContext, 'hasStartedEditing')
          ? composerContext.hasStartedEditing
          : false;
        that.youtubeShortRef = has(composerContext, 'youtubeShortRef')
          ? composerContext.youtubeShortRef
          : null;
      });
    },
    watch: {
      userProfile: function (val, old) {
        this.setTheme();
        if (get(val, 'managedAccounts', []).length < get(old, 'managedAccounts', []).length) {
          const removedManagedAccount = differenceBy(
            get(old, 'managedAccounts', []),
            get(val, 'managedAccounts', []),
            'uid'
          )[0];
          store.dispatch('disconnectAdditionalAccount', removedManagedAccount.uid);
        }
      },
      parentUserProfile: function (val, old) {
        if (!val || !old) return;
        if (get(val, 'managedAccounts', []).length < get(old, 'managedAccounts', []).length) {
          const removedManagedAccount = differenceBy(
            get(old, 'managedAccounts', []),
            get(val, 'managedAccounts', []),
            'uid'
          )[0];
          if (removedManagedAccount.uid === localStorage.getItem('currentUserId')) {
            localStorage.removeItem('currentUserId');
            setTimeout(() => window.location.reload(), 200);
          }
        }
      },
      $route: {
        handler() {
          if (has(this.$route.query, 'content')) {
            this.$nextTick(() => {
              this.showComposer = true;
            });
          } else if (has(this.$route.query, 'showDrafts')) {
            this.openNewComposerWithDrafts();
          } else if (has(this.$route.query, 'showPrompts')) {
            this.openNewComposerWithPrompts();
          }
        },
        immediate: true,
      },
    },
    mixins: [
      SwalModalMixin,
      MobileMixin,
      EnableNotificationsMixin,
      BreakpointsMixin,
      CustomerStatusMixin,
    ],
  };
</script>
<style lang="scss" scoped>
  .custom-menu-button {
    outline: none;
  }
</style>
