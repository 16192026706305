import { Retweet } from '../models/Retweet';
import { Thread } from '../models/Thread';
import { DraftThread } from '../models/DraftThread';

export function buildPostFromFirestore(doc, timezone) {
  if (doc.data().type === 'retweet') {
    return Retweet.buildFromFirestore(doc, timezone);
  } else {
    if (doc.data().time) {
      return Thread.buildFromFirestore(doc, timezone);
    } else {
      return DraftThread.buildFromFirestore(doc, timezone);
    }
  }
}
