const moment = require('moment');
const lodash = require('lodash');
const { didUserSubscribeBeforePlansChanges, PLANS } = require('./customerStatus.cjs');
require('moment-timezone');

module.exports.isPostBeyondSchedulingLimit = (time, timezone, schedulingLimit) => {
  return moment.tz(time, timezone).isAfter(
    moment
      .tz(timezone)
      .add(schedulingLimit.value, schedulingLimit.unit)
      // We need to subtract 1 day because the scheduling limit is inclusive of the current day.
      .subtract(1, 'day')
      .endOf('day')
  );
};

module.exports.isUnlimitedSchedulingAllowedForUserPlan = (userProfile) => {
  const schedulingLimit = PLANS[userProfile.customerStatus].limits.scheduling;
  const managers = lodash.get(userProfile, 'managers', []);
  return (
    didUserSubscribeBeforePlansChanges(userProfile) ||
    schedulingLimit === Infinity ||
    managers.length
  );
};
