import LogRocket from 'logrocket';
import { isEnvProd } from '@/config';

export function initLogRocket(store) {
  if (!isEnvProd) {
    return null;
  }

  const userProfile = store.getters.getUserProfile;
  const userProfileId = userProfile && userProfile.uid;

  if (userProfileId) {
    LogRocket.init('06whna/hypefury-prod');
    LogRocket.identify(userProfileId, {
      name: userProfile.name,
      email: userProfile.email,
    });
  }
}
