var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"enter-active-class":"transition duration-100 ease-in","enter-class":"opacity-0","enter-to-class":"opacity-100","leave-active-class":"transition duration-50 ease-out","leave-class":" opacity-100","leave-to-class":" opacity-0"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.open),expression:"open"}],staticClass:"z-200 relative",attrs:{"role":"dialog","aria-modal":"true"}},[_c('div',{staticClass:"fixed inset-0 bg-black opacity-25",class:{
        'slide-over-height-keyboard':
          _vm.enableMobileBottomToTopTranslation &&
          _vm.composerContext.isComposer &&
          _vm.composerContext.isKeyboardVisible,
      },on:{"click":_vm.attemptDismiss}}),_c('div',{class:[
        'pointer-events-none fixed flex max-w-full',
        {
          'inset-y-0 right-0 ': !_vm.enableMobileBottomToTopTranslation,
          'inset-x-0 bottom-0 lg:inset-x-auto lg:inset-y-0 lg:bottom-auto lg:right-0':
            _vm.enableMobileBottomToTopTranslation,
          'slide-over-height':
            _vm.enableMobileBottomToTopTranslation && !_vm.composerContext.isKeyboardVisible,
          'slide-over-height-keyboard bottom-keyboard':
            _vm.enableMobileBottomToTopTranslation &&
            _vm.composerContext.isComposer &&
            _vm.composerContext.isKeyboardVisible,
        },
      ]},[_c('transition',{attrs:{"enter-active-class":"transform transition ease-in-out duration-500","enter-class":`${
          _vm.enableMobileBottomToTopTranslation
            ? 'translate-y-full lg:translate-x-full lg:translate-y-0'
            : 'translate-x-full'
        }`,"enter-to-class":`${
          _vm.enableMobileBottomToTopTranslation ? 'translate-y-0 lg:translate-x-0' : 'translate-x-0'
        }`,"leave-active-class":"transform transition ease-in-out duration-500","leave-class":`${
          _vm.enableMobileBottomToTopTranslation ? 'translate-y-0 lg:translate-x-0' : 'translate-x-0'
        }`,"leave-to-class":`${
          _vm.enableMobileBottomToTopTranslation
            ? 'translate-y-full lg:translate-x-full lg:translate-y-0'
            : 'translate-x-full'
        }`},on:{"after-leave":function($event){return _vm.$emit('close')}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"pointer-events-auto w-screen",class:_vm.containerClasses},[_vm._t("default")],2)])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }