<template>
  <div>
    <div v-if="hideTabsLabel" class="h-16"></div>
    <div v-if="!hideTabsLabel" class="flex mb-6 items-center justify-between">
      <ul
        class="inline-flex list-none items-center gap-x-4"
        :class="{ 'tabs-border': tabStyle === 'bordered' }"
      >
        <li v-for="(tab, i) in tabs" :key="i" v-show="tab.isVisible">
          <a
            :href="tab.hash"
            @click="selectTab(tab.hash, $event)"
            role="tab"
            :class="{
              'is-active': tab.isActive,
              'default-tab': tabStyle === 'default' || tabStyle === 'bordered',
              'underlined-tab': tabStyle === 'underlined',
              'is-disabled': tab.isDisabled,
            }"
            data-cy="new-tab-link"
          >
            <slot name="tab" :label="tab.label">
              <inline-svg v-if="tab.icon" class="h-4 w-4 stroke-current" :src="tab.icon">{{
                tab.icon
              }}</inline-svg>
              {{ tab.label }}
            </slot>
          </a>
        </li>
      </ul>
      <slot name="actions" :activeTab="activeTab" />
    </div>
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'NewTabs',
    props: {
      defaultTabHash: {
        type: String,
        required: true,
      },
      tabStyle: {
        type: String,
        default: 'default',
        validator: (value) => ['default', 'underlined', 'bordered'].includes(value),
      },
      useLocationHash: {
        type: Boolean,
        default: true,
      },
      hideTabsLabel: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      tabs: [],
      activeTabHash: '',
      activeTabIndex: 0,
      lastActiveTabHash: '',
    }),
    created() {
      this.tabs = this.$children;
    },
    mounted() {
      // Selects the default tab if the hash is not present in the URL
      if (this.defaultTabHash !== null && this.findTab('#' + this.defaultTabHash)) {
        this.selectTab('#' + this.defaultTabHash);
        return;
      }
      // Selects the first tab if neither the hash nor the default tab are present
      if (this.tabs.length) {
        this.selectTab(this.tabs[0].hash);
      }
    },
    computed: {
      activeTab() {
        return this.activeTabHash.substring(1);
      },
    },
    methods: {
      findTab(hash) {
        return this.tabs.find((tab) => tab.hash === hash);
      },

      selectTab(selectedTabHash, event) {
        const selectedTab = this.findTab(selectedTabHash);
        if (!selectedTab) {
          return;
        }

        if (selectedTab.isDisabled && event) {
          event.preventDefault();
          return;
        }

        if (this.lastActiveTabHash === selectedTab.hash) {
          this.$emit('clicked', { tab: selectedTab });
          return;
        }

        this.tabs.forEach((tab) => {
          tab.isActive = tab.hash === selectedTab.hash;
        });

        this.$emit('changed', { tab: selectedTab });

        this.activeTabHash = selectedTab.hash;
        this.activeTabIndex = this.getTabIndex(selectedTabHash);

        this.lastActiveTabHash = this.activeTabHash = selectedTab.hash;
      },

      setTabVisible(hash, visible) {
        const tab = this.findTab(hash);

        if (!tab) {
          return;
        }

        tab.isVisible = visible;

        if (tab.isActive) {
          // If tab is active, set a different one as active.
          tab.isActive = visible;

          this.tabs.every((tab) => {
            if (tab.isVisible) {
              tab.isActive = true;
              return false;
            }

            return true;
          });
        }
      },

      getTabIndex(hash) {
        const tab = this.findTab(hash);

        return this.tabs.indexOf(tab);
      },

      getTabHash(index) {
        const tab = this.tabs.find((tab) => this.tabs.indexOf(tab) === index);

        if (!tab) {
          return;
        }

        return tab.hash;
      },

      getActiveTab() {
        return this.findTab(this.activeTabHash);
      },

      getActiveTabIndex() {
        return this.getTabIndex(this.activeTabHash);
      },
    },
    watch: {
      $route: {
        handler(to) {
          this.$nextTick(() => {
            if (this.useLocationHash && this.findTab(to.hash)) {
              this.selectTab(window.location.hash);
            }
          });
        },
        immediate: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  body {
    &.new-ui {
      ul {
        @apply m-0;
      }
      .default-tab {
        @apply inline-flex items-center justify-center gap-x-2 rounded-full px-3 py-1 text-base font-medium;
        @include sm {
          @apply px-4 py-2;
        }
      }
      .underlined-tab {
        @apply inline-flex items-center justify-center gap-x-2  px-1 py-1.5 text-base font-medium;
      }
      .tabs-border {
        @apply border rounded-full p-1.5;
        @include sm {
          @apply p-2;
        }
      }
      &.mode--light {
        .default-tab {
          @apply text-dark-mode-15;
          &.is-active {
            @apply bg-main-color-50 text-main-color-100;
          }
        }
        .underlined-tab {
          @apply text-gray-100;
          &.is-active {
            @apply border-b-2 border-black text-black;
          }
        }
        .tabs-border {
          @apply border-gray-90;
        }
      }
      &.mode--dark {
        .default-tab {
          @apply text-dark-mode-15;
          &.is-active {
            @apply bg-dark-mode-70 text-main-color-100;
          }
        }
        .underlined-tab {
          @apply text-dark-mode-15;
          &.is-active {
            @apply border-b-2 border-white text-white;
          }
        }
        .tabs-border {
          @apply border-dark-mode-70;
        }
      }
    }
  }
</style>
