<template>
  <div>
    <div class="flex items-start gap-4">
      <img
        :src="profilePictureURL || '/img/linkedin_profile_photo_placeholder.png'"
        :alt="userDisplayName"
        class="avatar-img"
      />
      <div class="w-64 flex-1">
        <div class="avatar-name">{{ userDisplayName }}</div>
        <div class="truncate text-sm text-ds-text-secondary dark-mode:text-dark-mode-30">
          {{ userHeadline }}
        </div>
      </div>
    </div>
    <p
      class="word-break ml-13 mt-4 whitespace-pre-wrap break-words text-base"
      v-html="text"
      dir="auto"
    ></p>
    <div v-if="postType === 'text'" class="grid grid-cols-3 gap-2 pt-2">
      <div class="border rounded-lg border-gray-10 p-1" v-for="(mediaFile, i) in media" :key="i">
        <img
          v-if="['image', 'gif'].includes(mediaFile.type)"
          :src="mediaFile.url"
          class="h-full rounded-lg object-cover object-center"
        />
        <video
          v-if="mediaFile.type === 'video'"
          :src="mediaFile.url"
          class="h-full w-full rounded-lg"
        />
      </div>
    </div>
    <div v-else class="ml-13 pt-2">
      <img src="/img/carousel-placeholder.jpeg" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'LinkedInPreview',
    props: {
      profilePictureURL: {
        type: String,
        default: '',
        description: 'LinkedIn profile picture URL',
      },
      userDisplayName: {
        type: String,
        default: '',
        description: 'LinkedIn user display name',
      },
      userHeadline: {
        type: String,
        default: '',
        description: 'LinkedIn user headline',
      },
      text: {
        type: String,
        default: '',
        description: 'LinkedIn text',
      },
      postType: {
        type: String,
        default: 'text',
        description: 'LinkedIn post type',
      },
      media: {
        type: Array,
        default() {
          return [];
        },
        description: 'Media files',
      },
    },
  };
</script>
