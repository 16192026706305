import lodash from 'lodash';
import { getValidRecurrentPosts } from './getValidRecurrentPosts';

export const groupPostsByCategoryId = (posts, userProfile, validCategories) => {
  const categoriesPosts = {};

  // Remove duplicate and invalid posts
  const postsWithoutDuplicates = lodash.uniqBy(posts, 'id');
  const validPosts = getValidRecurrentPosts(postsWithoutDuplicates, userProfile);

  // Group posts by category id
  validPosts.forEach((post) => {
    post.categories.forEach((category) => {
      if (!categoriesPosts[category.id]) {
        categoriesPosts[category.id] = [];
      }
      categoriesPosts[category.id].push(post);
    });
  });

  // Sort posts by order
  Object.entries(categoriesPosts).forEach((category) => {
    const currentCategory = validCategories.find((cat) => cat.id === category[0]);

    const orderedPostsIds = currentCategory
      ? currentCategory.postsOrder.map((post) => post.id)
      : [];
    const sortedPosts = category[1].sort((a, b) => {
      const aIndex = orderedPostsIds.indexOf(a.id);
      const bIndex = orderedPostsIds.indexOf(b.id);
      return aIndex - bIndex;
    });

    categoriesPosts[category[0]] = sortedPosts;
  });

  validCategories.forEach((category) => {
    if (!categoriesPosts[category.id]) {
      categoriesPosts[category.id] = [];
    }
  });

  return categoriesPosts;
};
