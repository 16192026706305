var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mb-6 flex items-center justify-between"},[_c('div',{staticClass:"flex items-center gap-x-3"},[_c('new-button',{attrs:{"icon":"","type":"transparent","data-cy":"prompts-close-button"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('inline-svg',{staticClass:"hidden h-6 rotate-180 transform text-main-color-100 lg:inline",attrs:{"src":"/img/icons/new/modal-collapse.svg"}}),_c('inline-svg',{staticClass:"inline text-gray-80 lg:hidden",attrs:{"src":"/img/icons/new/x.svg"}})],1),_c('h1',[_vm._v("Post inspiration")])],1),(_vm.tweets && _vm.tweets.length)?_c('new-button',{attrs:{"type":"transparent","data-cy":"prompts-shuffle-button"},on:{"click":function($event){return _vm.shuffleTweets()}}},[_vm._v(" Shuffle Tweets "),_c('inline-svg',{staticClass:"ml-2 stroke-current",attrs:{"src":"img/icons/refresh.svg"}})],1):_vm._e()],1),(_vm.show)?[_c('ul',{staticClass:"flex list-none flex-wrap items-center gap-2"},_vm._l((_vm.categories),function(category){return _c('li',{key:category.id},[_c('new-button',{class:{
            'bg-purple-500 px-3 py-1.5 text-base text-white':
              _vm.categoriesState[category.name] === 'both',
            'bg-red-500 px-3 py-1.5 text-base text-white':
              _vm.categoriesState[category.name] === 'user',
          },attrs:{"type":_vm.categoriesState[category.name] === 'hypefury'
              ? 'primary'
              : _vm.categoriesState[category.name] === 'both' ||
                _vm.categoriesState[category.name] === 'user'
              ? 'custom'
              : 'secondary',"size":"sm","data-cy":"prompts-category"},on:{"click":function($event){return _vm.toggleState(category)}}},[_vm._v(" "+_vm._s(category.name)+" "),(_vm.categoriesState[category.name] === 'user')?_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.removeBookmarkCategory(category)}}},[_c('inline-svg',{staticClass:"ml-2 h-4 w-4",attrs:{"src":"/img/icons/close.svg"}})],1):_vm._e()])],1)}),0),_vm._l((_vm.tweets),function(tweet,idx){return _c('div',{key:tweet.id},[(idx <= 80)?_c('div',{staticClass:"mt-6 grid gap-6",attrs:{"data-cy":"prompt-item"}},[_c('div',{staticClass:"border app-preview-border grid gap-4 rounded-3xl border-gray-90 p-6"},[_c('div',{staticClass:"flex items-center justify-between"},[_c('div',{staticClass:"flex items-center gap-4"},[_c('img',{staticClass:"avatar-img-sm",attrs:{"alt":tweet.user ? `${tweet.user.username}'s profile picture` : _vm.userProfile.username,"src":tweet.user ? tweet.user.profilePhoto : _vm.userProfile.photoURL},on:{"error":_vm.displayPlaceHolderImage}}),_c('a',{staticClass:"text-base font-medium text-main-color-100",attrs:{"href":'https://twitter.com/' +
                  (tweet.user ? tweet.user.username : _vm.userProfile.username),"target":"_blank"}},[_vm._v(" @"+_vm._s(tweet.user ? tweet.user.username : _vm.userProfile.username)+" ")])]),_c('inline-svg',{staticClass:"text-main-color-100",attrs:{"src":"/img/icons/twitter.svg"}})],1),_c('div',{staticClass:"whitespace-pre-wrap break-words text-base",attrs:{"dir":"auto","data-cy":"prompts-tweet-text"},domProps:{"innerHTML":_vm._s(_vm.formatTweet(tweet.text))}}),_c('div',{staticClass:"prompts flex items-center justify-between gap-x-2"},[_c('new-button',{staticClass:"flex-shrink-0",attrs:{"size":"sm","data-cy":"prompts-reuse-button"},on:{"click":function($event){return _vm.insertTweetToComposer(tweet)}}},[_vm._v(" Re-use Tweet ")]),(tweet.user)?_c('div',{staticClass:"flex flex-col gap-x-3 md:flex-row md:items-center"},[_c('a',{staticClass:"text-sm text-ds-text-secondary hover:underline dark-mode:text-dark-mode-30",attrs:{"target":"_blank","href":`https://twitter.com/${tweet.user.username}/status/${tweet.id}`}},[_vm._v(" "+_vm._s(_vm.getFormattedTime(tweet.time))+" ")]),_c('a',{staticClass:"text-sm text-main-color-100 hover:underline dark-mode:text-main-color-100",attrs:{"target":"_blank","href":`https://twitter.com/${tweet.user.username}/status/${tweet.id}`}},[_vm._v(" View on Twitter ")])]):_vm._e()],1)])]):_vm._e()])})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }