<template>
  <label class="custom-toggle" :class="computedClasses">
    <div class="form-control-label -mt-1" v-if="labelText">{{ labelText }}</div>
    <input type="checkbox" v-model="model" :disabled="disabled" />
    <span
      class="custom-toggle-slider rounded-circle"
      :data-label-off="offText"
      :data-label-on="onText"
    >
    </span>
  </label>
</template>
<script>
  export default {
    name: 'base-switch',
    props: {
      classes: {
        type: String,
        default: ''
      },
      disabled: Boolean,
      value: [Array, Boolean],
      type: String,
      onText: {
        type: String,
        default: 'Yes',
      },
      offText: {
        type: String,
        default: 'No',
      },
      labelText: {
        type: String,
      },
    },
    computed: {
      computedClasses() {
        return `${this.type ? `custom-toggle-${this.type}` : ''} ${this.classes}`;
      },
      model: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        },
      },
    },
    methods: {
      triggerToggle() {
        this.model = !this.model;
      },
    },
  };
</script>