var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"display-card relative space-y-2",class:{ 'card-selected': _vm.selected },on:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("default"),(_vm.removable)?_c('new-button',{staticClass:"absolute right-2 top-2",attrs:{"type":"transparent","icon":"","absolute":""},on:{"click":function($event){return _vm.$emit('remove')}}},[_c('inline-svg',{staticClass:"text-main-color-100",attrs:{"src":"/img/icons/close.svg"}})],1):_vm._e(),_c(_vm.isRedirectable ? 'a' : 'div',_vm._b({tag:"component",staticClass:"flex items-center space-x-2"},'component',{ ..._vm.redirectionProps },false),[_c('img',{class:{
        'avatar-img-xs': _vm.size === 'xs',
        'avatar-img-sm': _vm.size === 'sm',
        'avatar-img': _vm.size === 'md',
      },attrs:{"src":_vm.profilePictureURL,"alt":`${_vm.username}'s profile picture`},on:{"error":_vm.displayPlaceHolderImage}}),(_vm.userDisplayName)?_c('p',{staticClass:"avatar-name"},[_vm._v(_vm._s(_vm.userDisplayName))]):_vm._e(),(_vm.username)?_c('p',{staticClass:"avatar-username"},[_vm._v("@"+_vm._s(_vm.username))]):_vm._e()]),_c('p',{staticClass:"tweet-text max-w-full whitespace-pre-wrap break-all",class:{
      'text-sm': _vm.size === 'xs',
      'text-base': _vm.size === 'sm',
      'text-lg': _vm.size === 'md',
    },attrs:{"dir":"auto"},domProps:{"innerHTML":_vm._s(_vm.formatTweet(_vm.tweet))}}),(_vm.mediaFiles.length > 0)?_c('div',{staticClass:"grid grid-cols-3 gap-2 pt-2"},_vm._l((_vm.mediaFiles),function(mediaFile,i){return _c('div',{key:i,staticClass:"border rounded-lg border-gray-10 p-1"},[(['image', 'gif'].includes(mediaFile.type))?_c('img',{staticClass:"h-full rounded-lg object-cover object-center",attrs:{"src":mediaFile.url}}):_vm._e(),(mediaFile.type === 'video')?_c('video',{staticClass:"h-full w-full rounded-lg",attrs:{"src":mediaFile.url}}):_vm._e()])}),0):_vm._e(),(_vm.$slots.footer)?_c('div',{staticClass:"card-footer"},[_vm._t("footer")],2):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }