// This function is a duplicate of validateLink from functions/src/util.js
export const validateLink = (s) => {
    if (s.match(/\d{18,19}/)) {
        const tweetId = () => {
            const split = s.split('/');
            return split[split.length - 1];
        };
        return { link: s, tweetId: tweetId(), isValid: true, error: '' };
    } else {
        return { link: null, isValid: false, error: 'Not a valid tweet link or tweet ID.' }
    }
};