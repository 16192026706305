<template>
  <new-drop-down
    data-cy="composer-emoji"
    icon
    buttonType="custom"
    class="hidden md:block"
    buttonSize="sm"
  >
    <template #button>
      <tooltip data-cy="composer-choose-emoji" content="Choose Emoji">
        <emoji-icon />
      </tooltip>
    </template>
    <template>
      <picker set="twitter" :data="emojiIndex" @select="addEmoji" />
    </template>
  </new-drop-down>
</template>

<script>
  import emojiData from 'emoji-mart-vue-fast/data/twitter.json';
  import { Picker, EmojiIndex } from 'emoji-mart-vue-fast';
  import EmojiIcon from '../Icons/emojiIcon.vue';
  const emojiIndex = new EmojiIndex(emojiData);

  export default {
    props: ['textarea', 'classes'],
    components: {
      Picker,
      EmojiIcon,
    },
    data() {
      return {
        show: false,
        emojiIndex: emojiIndex,
      };
    },
    methods: {
      hideEmojiPicker(event) {
        if (!this.shouldHidePicker(event)) {
          return;
        }
        this.show = false;
      },
      shouldHidePicker() {
        if (!this.show) {
          return false;
        }
        return true;
      },
      addEmoji(event) {
        this.$emit('selected-emoji', event);
      },
      toggleEmojiPicker() {
        this.show = !this.show;
      },
    },
  };
</script>

<style lang="scss" scoped>
  body {
    &.new-ui {
      .emoji-mart {
        border: none !important;
      }
    }
  }
</style>
