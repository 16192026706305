export function initBeamer(store, loadScript) {
  const loggedInUser = store.getters.getUserProfile;

  if (!loggedInUser || !loggedInUser.uid) return;

  window.beamer_config = {
    product_id: 'DVgKiqaq23145',
    selector : '.beamer-button',
    user_firstname : loggedInUser.providedName,
    user_email : loggedInUser.email,
    user_id : loggedInUser.uid,
  };

  loadScript('https://app.getbeamer.com/js/beamer-embed.js').then(() => {
    store.commit('setIsBeamerReady', true);
    document.dispatchEvent(new Event('DOMContentLoaded'));
  });
}
