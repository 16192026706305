import moment from 'moment';
import 'moment-timezone';

export const getValidRecurrentPosts = (posts, userProfile) => {
  const weekOldDate = moment
    .tz(userProfile.timezone)
    .subtract(7, 'd')
    .toDate();

  const recurrentPosts = posts.filter((post) => {
    return !post.scheduled && !post.lastClonePostedTime && !post.time && post.isRecurrentPost;
  });

  const postsNotPostedByRecurrentPosts = posts.filter((post) => {
    return (
      post.scheduled && !post.lastClonePostedTime && post.time && post.time.toDate() < weekOldDate
    );
  });

  const postsAlreadyPostedByRecurrentPosts = posts.filter((post) => {
    return (
      post.scheduled && post.lastClonePostedTime && post.lastClonePostedTime.toDate() < weekOldDate
    );
  });

  return [
    ...recurrentPosts,
    ...postsNotPostedByRecurrentPosts,
    ...postsAlreadyPostedByRecurrentPosts,
  ].filter((post) => !post.isRecurrentPostDisabled);
};
