<template>
  <portal to="modal">
    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-class=" opacity-100"
      leave-to-class=" opacity-0"
    >
      <div
        class="z-300 fixed inset-0 flex items-center justify-center overflow-y-auto px-4 md:px-0"
      >
        <div
          @click.self.stop.prevent="dismiss"
          class="fixed inset-0 overflow-y-auto bg-overlay transition-opacity"
        ></div>

        <transition
          enter-active-class="transition duration-200 bg-transparent ease-in"
          enter-class="transform opacity-0 translate-y-4 sm:scale-95 sm:translate-y-0"
          enter-to-class="transform translate-y-0 opacity-100 sm:scale-100"
          leave-active-class="transition ease-out duration-75"
          leave-class="transform opacity-100 translate-y-0 sm:scale-100"
          leave-to-class="transform opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          @after-leave="$emit('close')"
        >
          <div
            v-if="showContent"
            class="modal-bg without-scrollbar fixed z-10 max-h-9/10 overflow-y-auto rounded-3xl"
            :data-cy="dataCy"
          >
            <div
              class="absolute inset-0 flex flex-col items-center justify-center gap-y-2"
              v-if="loading"
            >
              <inline-svg src="/img/icons/loading.svg" class="h-16 w-16 animate-spin text-accent" />
              <div v-if="loadingMessage">{{ loadingMessage }}</div>
            </div>
            <div :class="{ invisible: loading }">
              <div v-if="$slots.header && !title" class="modal-header">
                <slot name="header" />
                <button v-if="hasCloseButton" @click="dismiss">
                  <inline-svg src="/img/icons/close.svg" />
                </button>
              </div>
              <div data-cy="modal-header" v-if="title" class="modal-header">
                {{ title }}
                <button v-if="hasCloseButton" @click="dismiss">
                  <inline-svg src="/img/icons/close.svg" />
                </button>
              </div>
              <slot />
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </portal>
</template>

<script>
  export default {
    name: 'BaseModal',
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      loadingMessage: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '',
      },
      dataCy: {
        type: String,
        default: '',
      },
      hasCloseButton: {
        type: Boolean,
        default: true,
      },
    },
    created() {
      document.body.classList.add('overflow-hidden');
      const listener = document.addEventListener('keydown', (e) => {
        // I did this because the keyCode property because it is deprecated and Key is not support by some old browser.
        if (e.keyCode === 27 || e.key === 'Escape') {
          this.dismiss();
        }
      });

      // temporary fix for the transition after-enter not being called inside the portal (limitation of vue 2 portals)
      setTimeout(() => {
        this.showContent = true;
      }, 100);

      this.$once('hook:destroyed', () => {
        this.showContent = false;
        document.body.classList.remove('overflow-hidden');
        document.removeEventListener('keydown', listener);
      });
    },
    data() {
      return {
        showContent: false,
      };
    },
    methods: {
      dismiss() {
        this.showContent = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .new-ui {
    .modal-header {
      @apply border-b sticky top-0 flex items-center justify-between p-8 text-2xl font-bold;
      z-index: 60;
    }
    &.mode--dark {
      .modal-bg {
        @apply bg-dark-mode-80;
      }
      .modal-header {
        @apply border-dark-mode-20 bg-dark-mode-80;
      }
    }
    &.mode--light {
      .modal-bg {
        @apply bg-white;
      }
      .modal-header {
        @apply border-dark-mode-0 bg-white;
      }
    }
  }
</style>
