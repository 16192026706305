<template>
  <modal :show="show" @close="$emit('close')">
    <div class="space-y-8">
      <div class="flex items-center justify-between">
        <div class="flex items-center gap-4">
          <div>
            <svg
              width="28"
              height="22"
              viewBox="0 0 28 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M27.3334 2.73337C26.3312 3.16816 25.2713 3.45559 24.1867 3.5867C25.331 2.90313 26.1884 1.82775 26.6001 0.560035C25.5248 1.20012 24.3478 1.6511 23.1201 1.89337C22.2995 1.00346 21.2067 0.411097 20.0132 0.209171C18.8196 0.0072441 17.5928 0.20718 16.5251 0.777621C15.4575 1.34806 14.6093 2.25674 14.1137 3.36115C13.6181 4.46556 13.5031 5.70322 13.7867 6.88003C11.6127 6.77007 9.486 6.20397 7.54495 5.21852C5.60391 4.23306 3.89188 2.85029 2.52008 1.16004C2.03893 2.00026 1.78611 2.9518 1.78675 3.92004C1.78504 4.81921 2.0057 5.70486 2.42909 6.49812C2.85247 7.29139 3.46544 7.96765 4.21341 8.4667C3.34405 8.44305 2.49326 8.20977 1.73341 7.7867V7.85337C1.73993 9.11322 2.18141 10.3322 2.98317 11.304C3.78493 12.2758 4.89776 12.9409 6.13341 13.1867C5.65776 13.3315 5.16391 13.4078 4.66675 13.4134C4.3226 13.4093 3.97931 13.3781 3.64008 13.32C3.99196 14.4038 4.67291 15.3509 5.58818 16.0296C6.50345 16.7083 7.60752 17.0848 8.74675 17.1067C6.82302 18.6204 4.44793 19.4465 2.00008 19.4534C1.5544 19.4548 1.10906 19.4281 0.666748 19.3734C3.16599 20.987 6.0785 21.8437 9.05342 21.84C11.1063 21.8614 13.1429 21.4734 15.0442 20.6988C16.9455 19.9242 18.6734 18.7785 20.127 17.3287C21.5806 15.8788 22.7307 14.1539 23.5102 12.2546C24.2896 10.3553 24.6828 8.31967 24.6667 6.2667C24.6667 6.04003 24.6667 5.80004 24.6667 5.56004C25.713 4.77978 26.6154 3.82327 27.3334 2.73337Z"
                fill="#1DA1F2"
              />
            </svg>
          </div>
          <span data-cy="unretweet-header" class="text-2xl font-bold">Autoplug</span>
        </div>
        <button data-cy="unretweet-close" @click="$emit('close')">
          <inline-svg src="/img/icons/close.svg" />
        </button>
      </div>
      <div class="flex items-center justify-between">
        <div class="flex items-center font-medium leading-none">
          <span class="sm-with-130">Autoplug: automatically add a follow up tweet?</span>
          <info-tooltip
            content="We'll tweet this follow up for you when your
          original tweet gets popular!"
          ></info-tooltip>
        </div>
        <div class="flex space-x-2">
          <base-switch data-cy="autoplug-switch" v-model="isAutoplugEnabled"></base-switch>
        </div>
      </div>

      <div
        class="mt-3 mb-6 space-y-2"
        v-if="
          userProfile.autoplugTemplates &&
          userProfile.autoplugTemplates.length > 0 &&
          isAutoplugEnabled
        "
      >
        <base-input label="Load from template" class="mb-0 col-span-5">
          <div class="relative flex">
            <select data-cy="template-select" class="form-control" v-model="selectedTemplate">
              <option :value="null">None</option>
              <option
                v-bind:key="template.name"
                :value="template"
                v-for="template in userProfile.autoplugTemplates"
              >
                {{ template.name }}
              </option>
            </select>

            <base-button
              class="button small-button secondary-button ml-3"
              @click="deleteTemplate"
              v-if="selectedTemplate"
              data-cy="template-delete"
            >
              Delete
            </base-button>
          </div>
        </base-input>
      </div>

      <div class="mt-3 space-y-2" v-if="isAutoplugEnabled">
        <base-input ref="textarea-container" inputGroupClasses="textarea-container" alternative>
          <div class="tweet-container" ref="tweet_container">
            <vue-tribute :options="tributeOptions">
              <textarea
                dir="auto"
                class="form-control simple"
                ref="autoplugStatus"
                rows="3"
                v-model="autoplugStatus"
                placeholder="What do you want to plug? One of your Gumroad products? Your mailing list? Maybe your SoundCloud? 😎"
                id="autoplugStatus"
                data-cy="autoplug-textarea"
              ></textarea>
            </vue-tribute>
          </div>
          <div class="thread-actions flex item-center p-1">
            <div class="ml-auto flex items-center space-x-1">
              <base-button
                size="sm"
                type="secondary"
                :class="charactersLeftClass"
                class="character-count"
              >
                {{ charactersLeftCounter }}
              </base-button>
            </div>
          </div>
          <div
            v-show="wasTemplateEdited && autoplugStatus && autoplugStatus.trim().length"
            class="border-t border-divider rounded-b py-1 px-2"
          >
            <div class="flex items-center justify-between space-x-4 bg-light-darker">
              <info-tooltip
                content="Save this tweet as a template and use it again."
              ></info-tooltip>
              <input
                type="text"
                class="form-control simple"
                placeholder="Template Name..."
                v-model="templateNameInput"
                data-cy="template-name"
              />
              <button
                class="button small-button w-5/12"
                :class="[
                  !templateNameInput.trim().length
                    ? 'cursor-not-allowed text-white'
                    : 'dark-mode-bg cursor-pointer text-main-color-100',
                ]"
                @click="saveTemplate"
                data-cy="template-save"
                :disabled="!templateNameInput.trim().length"
              >
                Save Template
              </button>
            </div>
          </div>
        </base-input>

        <div class="form-group lg:flex lg:items-center lg:justify-between">
          <label for="conditionForRetweet" class="flex items-center form-control-label text-dark-mode-15">
            Delay autoplug until
            <info-tooltip
              content="Choose whether to delay your follow up tweet until this tweet reaches a minimum number of likes or retweets."
            ></info-tooltip>
          </label>
          <div class="relative mt-2 lg:mt-0 lg:w-1/2 flex items-center space-x-2">
            <div class="w-1/2">
              <base-input
                v-if="conditionForAutoplug === 'Retweets'"
                type="number"
                min="0"
                v-model="autoplugMinRetweets"
                name="autoplugMinRetweets"
                data-cy="autoplug-min-retweets"
              ></base-input>
              <base-input
                v-if="conditionForAutoplug === 'Likes'"
                type="number"
                min="0"
                v-model="autoplugMinFavorites"
                name="autoplugMinFavorites"
                data-cy="autoplug-min-likes"
              ></base-input>
            </div>
            <div class="relative w-1/2">
              <select
                class="form-control"
                data-toggle="select"
                id="conditionForRetweet"
                v-model="conditionForAutoplug"
                data-cy="autoplug-condition-select"
              >
                <option v-for="option in conditionForAutoplugOptions" :key="option" :value="option">
                  {{ option }}
                </option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-primary"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  class="fill-current h-4 w-4"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end">
        <button data-cy="save-button" class="custom-button button small-button" :disabled="!canSaveAutoplugSettings" @click="saveThenClose">
          Save
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
  import lodash from 'lodash';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import dao from '@/dao';
  import { countTweetLength } from '@/../functions/src/util/countTweetLength';
  import TaggingMixin from '@/views/Mixins/TaggingMixin';
  import { mapGetters } from 'vuex';

  export default {
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      originalThread: {
        type: Object,
        default: null,
      },
      isThreadToReplace: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isAutoplugEnabled: false,
        autoplugMinFavorites: 0,
        autoplugMinRetweets: 100,
        autoplugStatus: '',
        templateNameInput: '',
        selectedTemplate: null,
        templateBeingEdited: null,
        wasTemplateEdited: false,
        conditionForAutoplug: 'Retweets',
        conditionForAutoplugOptions: ['Retweets', 'Likes'],
      };
    },
    mounted() {
      this.updateValuesFromUserProfile();
    },
    methods: {
      deleteTemplate() {
        this.swalModal({
          title: `Delete autoplug template`,
          text: `Are you sure you want to delete the autoplug template?`,
          type: 'warning',
          focusConfirm: false,
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
          reverseButtons: true,
          preConfirm: async () => {
            const templatesWithoutSelectedTemplate = this.userProfile.autoplugTemplates.filter(
              (template) => template.name !== this.selectedTemplate.name
            );
            await dao.userProfile.updateAutoplugTemplates(
              templatesWithoutSelectedTemplate,
              this.userProfile
            );
            this.selectedTemplate = null;
          },
        });
      },
      updateValuesFromUserProfile() {
        const options = this.userProfile.autoplugConditions;
        if (!options) return;

        const profileTemplates = lodash.get(this.userProfile, 'autoplugTemplates', []);
        const templateFromProfile = options.templateName;
        this.selectedTemplate = options.templateName
          ? profileTemplates.find((template) => {
              return template.name === templateFromProfile;
            })
          : null;

        this.isAutoplugEnabled = true;
        this.autoplugMinRetweets = lodash.get(options, 'minRetweets', null);
        this.autoplugMinFavorites = lodash.get(options, 'minFavorites', null);
        this.autoplugStatus = lodash.get(options, 'status', '');
        this.conditionForAutoplug = this.autoplugMinRetweets ? 'Retweets' : 'Likes';
        this.updateParentValues();
      },
      updateValuesFromThread(thread) {
        if (!lodash.get(thread, 'autoplug.status')) {
          this.updateValuesFromUserProfile();
          this.isAutoplugEnabled = false;
        } else {
          this.isAutoplugEnabled = true;
          const templateFromThread = thread.autoplug.templateName;
          const profileTemplates = lodash.get(this.userProfile, 'autoplugTemplates', []);
          this.selectedTemplate = templateFromThread
            ? profileTemplates.find((template) => template.name === templateFromThread)
            : null;

          this.autoplugStatus = thread.autoplug.status;
          this.autoplugMinRetweets = thread.autoplug.minRetweets;
          this.autoplugMinFavorites = thread.autoplug.minFavorites;
          this.conditionForAutoplug = thread.autoplug.minRetweets ? 'Retweets' : 'Likes';
        }
        this.updateParentValues();
      },
      async saveTemplate() {
        const templateAlreadyExists = this.userProfile.autoplugTemplates.some(
          (t) => t.name === this.templateNameInput.trim()
        );
        if (templateAlreadyExists) {
          this.swalModal({
            title: 'Error',
            text: `Name already exists. Please choose a different name.`,
            type: 'error',
          });
          return;
        }
        const newTemplate = { name: this.templateNameInput.trim(), content: this.autoplugStatus };
        try {
          await dao.userProfile.createAutoplugTemplate(newTemplate, this.userProfile);
          this.selectedTemplate = newTemplate;
        } catch {
          this.swalModal({
            title: 'Error',
            text: `An error has occurred while creating the autoplug template.`,
            type: 'error',
          });
        }
      },
      updateParentValues() {
        this.$emit('values-set', {
          isAutoplugEnabled: this.isAutoplugEnabled,
          autoplugStatus: this.autoplugStatus,
          autoplugMinRetweets: this.autoplugMinRetweets,
          autoplugMinFavorites: this.autoplugMinFavorites,
          autoplugTemplate: this.selectedTemplate,
          wasAutoplugTemplateEdited: this.wasTemplateEdited,
          autoplugType: this.conditionForAutoplug,
        });
      },
      saveThenClose() {
        this.updateParentValues();
        this.$emit('close');
      },
    },
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
      charactersLeftCounter() {
        return 280 - countTweetLength(this.autoplugStatus);
      },
      charactersLeftClass() {
        const statusLength = countTweetLength(this.autoplugStatus);
        if (statusLength >= 280) return 'character-count-exceeded';
        if (statusLength > 260) return 'character-count-warning';
        return 'bg-secondary';
      },
      canSaveAutoplugSettings() {
        const areMinFavoritesConditionsValid =
          this.conditionForAutoplug === 'Likes' &&
          !lodash.isNil(this.autoplugMinFavorites) &&
          Number(this.autoplugMinFavorites) >= 0;
        const areMinRetweetsConditionsValid =
          this.conditionForAutoplug === 'Retweets' &&
          !lodash.isNil(this.autoplugMinRetweets) &&
          Number(this.autoplugMinRetweets) >= 0;

        if (this.isAutoplugEnabled) {
          return (
            (areMinFavoritesConditionsValid || areMinRetweetsConditionsValid) &&
            this.autoplugStatus &&
            this.autoplugStatus.trim().length > 0
          );
        } else {
          return true;
        }
      },
    },
    mixins: [TaggingMixin, SwalModalMixin],
    watch: {
      autoplugMinFavorites(newVal) {
        // Ensure that the value is at least 0 without modifying any preexisting values
        if (newVal <= 0) {
          this.autoplugMinFavorites = 0;
        }
      },
      autoplugMinRetweets(newVal) {
        // Ensure that the value is at least 0 without modifying any preexisting values
        if (newVal <= 0) {
          this.autoplugMinRetweets = 0;
        }
      },
      originalThread(thread) {
        if (thread && !this.isThreadToReplace) {
          this.updateValuesFromThread(thread);
        }
      },
      autoplugStatus(status) {
        const isFromTemplate = this.selectedTemplate && status === this.selectedTemplate.content;
        if (!isFromTemplate) {
          this.wasTemplateEdited = true;
        }
      },
      selectedTemplate(template, oldTemplate) {
        this.wasTemplateEdited = false;
        if (oldTemplate === null) {
          this.templateBeingEdited = this.autoplugStatus;
        }
        if (!template) {
          this.autoplugStatus = this.templateBeingEdited;
          this.wasTemplateEdited = true;
        } else {
          this.autoplugStatus = template.content;
        }
      },
    },
  };
</script>

<style>
  @media only screen and (max-width: 404px) {
    .sm-with-130 {
      width: 130px;
    }
  }
</style>
