class Category {
  name;

  constructor(
    id,
    name,
    userRef,
    color,
    numberOfPosts,
    createdAt,
    updatedAt,
    postsOrder,
    timesToSkip,
    isEnabled,
  ) {
    this.id = id;
    this.name = name;
    this.userRef = userRef;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.numberOfPosts = numberOfPosts;
    this.color = color;
    this.postsOrder = postsOrder;
    this.timesToSkip = timesToSkip;
    this.isEnabled = isEnabled;
  }

  static buildFromFirestore(doc) {
    return new Category(
      doc.id,
      doc.data().name,
      doc.data().userRef,
      doc.data().color,
      doc.data().numberOfPosts,
      doc.data().created_at,
      doc.data().updated_at,
      doc.data().postsOrder,
      doc.data().timesToSkip,
      doc.data().isEnabled,
    );
  }
}

export { Category };
