<script>
  import controller from '@/controller';
  import dao from '@/dao';
  import { getInspirationTweets } from '@/util/inspirationTweets';
  import lodash from 'lodash';
  import { mapGetters, mapState } from 'vuex';
  import moment from 'moment';
  import { store } from '@/store';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  const inspirationCategories = require('../../../public/inspiration-categories.json');

  export default {
    computed: {
      ...mapState(['userCategories', 'userBestTweetsCategory', 'inspirationTweets']),
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
    },
    name: 'inspiration-categories-mixin',
    mixins: [SwalModalMixin],
    data() {
      return {
        areUsersBestTweetsFetched: false,
        bookmarkedCategories: [],
        inspirationCategories: [],
        categoriesState: {},
        categories: [],
        tweets: [],
        showInspirationCategories: false,
        removedTweetIDs: [],
        removedCategoryNames: [],
        usersBestTweets: [],
      };
    },
    created() {
      this.showInspirationCategories = !this.isMobile;
      this.updateSelectedInspirationCategories();
    },
    methods: {
      displayPlaceHolderImage(e) {
        e.target.src = '/img/twittter_profile_photo_placeholder.png';
      },
      getFormattedTime(time) {
        if (!time) return null;
        const browserLocale = require('browser-locale')();
        moment.locale(browserLocale);
        if (new Date(time).toString() !== 'Invalid Date') {
          return moment(new Date(time)).format('L');
        }
        if (time.toDate()) {
          return moment(time.toDate()).format('L');
        }
      },
      async getBestTweetsCategory() {
        this.areUsersBestTweetsFetched = false;
        const usersInspirationTweets = await getInspirationTweets(
          this.currentUser,
          this.userProfile.uid
        );
        this.usersBestTweets = usersInspirationTweets.map((tweet) => {
          return {
            text: tweet.status,
            likeCount: tweet.favoriteCount,
            retweetCount: tweet.retweetCount,
            time: tweet.time,
            id: tweet.tweetId,
            user: {
              name: this.userProfile.name,
              profilePhoto: this.userProfile.photoURL,
              username: this.userProfile.username,
            },
          };
        });
        this.areUsersBestTweetsFetched = true;
        return {
          name: 'My Best Tweets',
          id: 100,
          tweets: lodash.shuffle(this.usersBestTweets),
        };
      },
      async updateBookmarkedCategories() {
        const categoriesStateFromLocalStorage = localStorage.getItem('selected_category_state')
          ? JSON.parse(localStorage.getItem('selected_category_state'))
          : null;

        const bookmarkedCategories = await controller.getBookmarkedCategories(
          this.currentUser,
          this.userProfile.uid
        );

        this.bookmarkedCategories = bookmarkedCategories.map((category) => {
          if (categoriesStateFromLocalStorage) {
            this.$set(this.categoriesState, category.name, categoriesStateFromLocalStorage[category.name]);
          }

          const isUserCreatedCategory = !this.inspirationCategories.some(
            (inspirationCategory) => inspirationCategory.name === category.name
          );
          const tweets = Object.values(category.tweets).map((tweet) => {
            return {
              ...tweet,
              isBookmarkedTweet: true,
              categoryNames: [category.name],
            };
          });
          const categoryWithTweetsArray = {
            isUserCreatedCategory,
            name: category.name,
            id: category.id,
            tweets,
          };
          return categoryWithTweetsArray;
        });

        this.populateTweets();

        const filteredBookmarkCategories = this.bookmarkedCategories.filter(
          (bookmarkedCategory) => bookmarkedCategory.isUserCreatedCategory
        );
        this.categories.push(...filteredBookmarkCategories);
      },
      async removeBookmarkedTweet(tweetToBeRemoved, categoryName) {
        if (tweetToBeRemoved.categoryNames.length > 1 && !categoryName) {
          return;
        }
        const categoryToRemoveFrom = categoryName || tweetToBeRemoved.categoryNames[0];
        try {
          await controller.removeBookmarkedTweet(
            this.currentUser,
            this.userProfile.uid,
            categoryToRemoveFrom,
            tweetToBeRemoved.id
          );
          if (tweetToBeRemoved.categoryNames.length === 1) {
            this.tweets = this.tweets.filter((tweet) => tweet.id !== tweetToBeRemoved.id);
          } else {
            this.tweets = this.tweets.map((tweet) => {
              if (tweet.id === tweetToBeRemoved.id) {
                const newCategoryNames = tweet.categoryNames.filter((name) => categoryName !== name);
                return {
                  ...tweet,
                  categoryNames: newCategoryNames,
                };
              }
              return tweet;
            });
          }
          this.removedTweetIDs.push(tweetToBeRemoved.id);
          this.$notify({
            type: 'success',
            message: `Removed from “${lodash.startCase(categoryToRemoveFrom)}” category!`,
          });
          return;
        } catch (error) {
          console.error(error);
          this.swalModal({
            title: 'Sorry',
            text: `An error has occurred while removing the bookmarked tweet.`,
            type: 'warning',
          });
        }
      },
      removeBookmarkCategory(categoryToRemove) {
        this.swalModal({
          title: 'Are you sure?',
          html: `Deleting the category “${lodash.startCase(categoryToRemove.name)}”
            will delete all your bookmarked tweets in it.
            <br>Are you sure you want to delete it?`,
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
          preConfirm: async () => {
            try {
              await controller.removeBookmarkCategory(
                this.currentUser,
                this.userProfile.uid,
                categoryToRemove.name
              );
              if (categoryToRemove.isUserCreatedCategory) {
                this.categories = this.categories.filter(
                  (category) => category.name !== categoryToRemove.name
                );
              }
              this.removedCategoryNames.push(categoryToRemove.name);
              this.toggleState(categoryToRemove);
              this.$notify({
                type: 'success',
                message: `Category “${lodash.startCase(categoryToRemove.name)}” deleted!`,
              });
              return true;
            } catch (error) {
              console.error(error);
              this.swalModal({
                title: 'Sorry',
                text: `An error has occurred while deleting the category.`,
                type: 'warning',
              });
            }
          },
        });
      },
      toggleCategories() {
        if (this.showInspirationCategories) {
          localStorage.setItem('inspiration_categories_hidden_at', moment().format());
        } else {
          localStorage.removeItem('inspiration_categories_hidden_at');
        }

        this.showInspirationCategories = !this.showInspirationCategories;
      },
      toggleState(category) {
        const isUserOnboarding = this.$route.path === '/setup'
        const hasBookmarkedTweets = this.bookmarkedCategories.some(
          (bookmarkedCategory) => bookmarkedCategory.name === category.name
        );
        const isRemoved = this.removedCategoryNames.includes(category.name);
        const isUserCreatedCategory = category.isUserCreatedCategory;
        const currentState = this.categoriesState[category.name] || 'unselected';
        if (
          currentState === 'unselected' &&
          hasBookmarkedTweets &&
          !isUserCreatedCategory &&
          !isRemoved &&
          !isUserOnboarding
        ) {
          this.$set(this.categoriesState, category.name, 'both');
        } else if (
          (currentState === 'unselected' && isUserCreatedCategory) ||
          currentState === 'both' &&
          !isUserOnboarding
        ) {
          this.$set(this.categoriesState, category.name, 'user');
        } else if (
          (currentState === 'unselected' && (!hasBookmarkedTweets || isRemoved)) ||
          (currentState === 'user' && !isUserCreatedCategory)
        ) {
          this.$set(this.categoriesState, category.name, 'hypefury');
        } else {
          this.$set(this.categoriesState, category.name, 'unselected');
        }
        this.$forceUpdate();
        this.populateTweets();
        localStorage.setItem('selected_category_state', JSON.stringify(this.categoriesState));
      },
      populateTweets() {
        const tweets = [];

        this.inspirationCategories.forEach((category) => {
          if (
            this.categoriesState[category.name] === 'hypefury' ||
            this.categoriesState[category.name] === 'both'
          ) {
            tweets.push(...category.tweets);
          }
        });

        this.bookmarkedCategories.forEach((category) => {
          if (
            this.categoriesState[category.name] === 'user' ||
            this.categoriesState[category.name] === 'both'
          ) {
            category.tweets.filter(
              (tweet) => !this.removedTweetIDs.includes(tweet.id)
            ).forEach((tweet) => {
              const indexOfTweet = lodash.findIndex(tweets, (t) => t.id === tweet.id)
              if (indexOfTweet >= 0) {
                tweets[indexOfTweet] = {
                  ...tweet,
                  categoryNames: [...tweets[indexOfTweet].categoryNames, tweet.categoryNames[0]]
                };
                return;
              }
              tweets.push(tweet);
            });
          }
        });
        this.tweets = lodash.shuffle(lodash.uniqBy(tweets, 'id'));
      },
      async updateSelectedInspirationCategories() {
        if (!this.userBestTweetsCategory) return;
        this.updateBookmarkedCategories();

        const userBestTweetsCategory = await this.getBestTweetsCategory();
        this.inspirationCategories = [userBestTweetsCategory, ...inspirationCategories];

        const categoriesStateFromLocalStorage = localStorage.getItem('selected_category_state')
          ? JSON.parse(localStorage.getItem('selected_category_state'))
          : null;
        if (categoriesStateFromLocalStorage) {
          this.inspirationCategories.forEach((category) => {
            this.$set(this.categoriesState, category.name, categoriesStateFromLocalStorage[category.name]);
          });
        } else {
          this.categoriesState['My best tweets'] = 'hypefury';
        }

        this.categories = [...this.inspirationCategories];
        this.populateTweets();
      },
    },
    watch: {
      userBestTweetsCategory(val, old) {
        if (!old && val) {
          this.updateSelectedInspirationCategories();
        }
      },
      'userProfile.inspirationTweetsLastIndexedAt': function (val, old) {
        if (!old && val) {
          localStorage.removeItem(`inspiration_tweets.${this.userProfile.uid}`);
          this.updateSelectedInspirationCategories();
        }
      },
      async selectedInspirationCategories(val) {
        if (!val) return null;
        const tweets = [];
        await Promise.all(
          this.selectedInspirationCategories.map(async (category) => {
            if (!this.isRecurrentPostsCategory(category)) {
              tweets.push(...category.tweets);
            } else {
              const posts = await dao.posts.getPostsQueriesForHistoryCategory(category.userRef.id, category.id)['all'].get();
              const postsDocs = posts.docs;
              const recurrentPostsTweets = postsDocs.map((postDoc) => {
                return {
                  text: postDoc.data().tweets[0].status,
                  likeCount: postDoc.data().tweets[0].favoriteCount,
                  retweetCount: postDoc.data().tweets[0].retweetCount,
                  time: postDoc.data().time.toDate(),
                  id: postDoc.data().tweets[0].tweetId,
                  user: {
                    name: this.userProfile.name,
                    profilePhoto: this.userProfile.photoURL,
                    username: this.userProfile.username,
                  },
                };
              });
              tweets.push(...recurrentPostsTweets);
            }
            return Promise.resolve();
          })
        );
        this.tweets = lodash.shuffle(lodash.uniqBy(tweets, 'id'));
        store.commit('setInspirationTweets', lodash.uniqBy(tweets, 'id'));
      },
    },
  };
</script>
