<script>
  export default {
    name: 'new-alert',
    props: {
      type: {
        type: String,
        default: 'warning',
        validator: (value) => {
          const acceptedValues = ['warning', 'info', 'danger'];
          return acceptedValues.includes(value);
        },
      },
      hasIcon: {
        type: Boolean,
        default: false,
      },
      icon: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        selectedIcon: '',
      };
    },
    computed: {
      iconPath() {
        if (this.icon) {
          return this.icon;
        }
        return this.selectedIcon;
      },
    },
    created() {
      if (this.icon) {
        return;
      }

      if (this.hasIcon) {
        switch (this.type) {
          case 'warning':
            this.selectedIcon = '/img/icons/warning.svg';
            break;
          case 'info':
            this.selectedIcon = '/img/icons/info.svg';
            break;
          default:
            this.selectedIcon = '';
            break;
        }
      }
    },
  };
</script>
<template>
  <div
    class="n-alert"
    :class="{
      'n-alert-warning': type === 'warning',
      'n-alert-info': type === 'info',
      'n-alert-danger': type === 'danger',
    }"
  >
    <div v-if="hasIcon || icon" class="flex items-center gap-x-4">
      <inline-svg v-if="icon || selectedIcon" :src="iconPath" class="h-5 w-5 flex-shrink-0" />
      <span>
        <slot></slot>
      </span>
    </div>
    <slot v-else />
  </div>
</template>
<style lang="scss" scoped>
  body {
    &.new-ui {
      .n-alert {
        @apply rounded-2xl border-0 p-4 text-sm font-medium;
        @include sm {
          @apply p-6;
        }
      }
      &.mode--light {
        .n-alert {
          &.n-alert-warning {
            @apply border border-yellow-100 border-opacity-25 bg-yellow-50;
          }
          &.n-alert-info {
            @apply bg-success-100 text-white;
          }
          &.n-alert-danger {
            @apply bg-danger-100 text-white;
          }
        }
      }
      &.mode--dark {
        .n-alert {
          @apply bg-dark-mode-70;
          &.n-alert-info {
            @apply bg-success-100;
          }
        }
        .n-alert-danger {
          @apply bg-danger-100 text-white;
        }
      }
    }
  }
</style>
