<script>
  import controller from '@/controller';
  import { v4 as publicIpv4 } from 'public-ip';
  import { mapGetters } from 'vuex';
  import { getName as getCountryName } from 'country-list'

  export default {
    name: 'customer-country-mixin',
    computed: {
      ...mapGetters({
        currentUser: 'getCurrentUser',
        userProfile: 'getUserProfile',
        parentUserProfile: 'getParentUserProfile',
      }),
    },
    data() {
      return {
        countryName: '',
        countryCode: '',
      };
    },
    methods: {
      async getCustomerCountry() {
        if (this.countryCode) return;
        try {
          const customerIp = await publicIpv4();
          const response = await controller.getCustomerCountry(this.currentUser, customerIp);
          this.countryCode = response.countryCode;
          this.countryName = response.country;
          return { countryCode: response.countryCode, countryName: response.countryName };
        } catch (error) {
          console.log(`An error occurred while fetching customer's country: ${error}`);
        }
      },
      getCountryName(countryCode) {
        return getCountryName(countryCode);
      },
    },
  };
</script>
