<template>
  <div
    @click="$emit('click')"
    class="display-card relative space-y-2"
    :class="{ 'card-selected': selected }"
  >
    <slot />
    <new-button
      v-if="removable"
      type="transparent"
      icon
      absolute
      class="absolute right-2 top-2"
      @click="$emit('remove')"
    >
      <inline-svg src="/img/icons/close.svg" class="text-main-color-100" />
    </new-button>
    <component
      :is="isRedirectable ? 'a' : 'div'"
      v-bind="{ ...redirectionProps }"
      class="flex items-center space-x-2"
    >
      <img
        @error="displayPlaceHolderImage"
        :src="profilePictureURL"
        :class="{
          'avatar-img-xs': size === 'xs',
          'avatar-img-sm': size === 'sm',
          'avatar-img': size === 'md',
        }"
        :alt="`${username}'s profile picture`"
      />
      <p v-if="userDisplayName" class="avatar-name">{{ userDisplayName }}</p>
      <p v-if="username" class="avatar-username">@{{ username }}</p>
    </component>
    <p
      class="tweet-text max-w-full whitespace-pre-wrap break-all"
      :class="{
        'text-sm': size === 'xs',
        'text-base': size === 'sm',
        'text-lg': size === 'md',
      }"
      v-html="formatTweet(tweet)"
      dir="auto"
    />
    <div v-if="mediaFiles.length > 0" class="grid grid-cols-3 gap-2 pt-2">
      <div
        class="border rounded-lg border-gray-10 p-1"
        v-for="(mediaFile, i) in mediaFiles"
        :key="i"
      >
        <img
          v-if="['image', 'gif'].includes(mediaFile.type)"
          :src="mediaFile.url"
          class="h-full rounded-lg object-cover object-center"
        />
        <video
          v-if="mediaFile.type === 'video'"
          :src="mediaFile.url"
          class="h-full w-full rounded-lg"
        />
      </div>
    </div>
    <div class="card-footer" v-if="$slots.footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
  import { formatTweet } from '@/util/formatTweet';
  export default {
    name: 'tweet-display',
    props: {
      profilePictureURL: {
        type: String,
        default: '',
        description: 'Tweet profile picture URL',
      },
      userDisplayName: {
        type: String,
        default: '',
        description: 'Tweet user display name',
      },
      username: {
        type: String,
        default: '',
        description: 'Tweet username',
      },
      tweet: {
        type: String,
        default: '',
        description: 'Tweet text',
      },
      size: {
        type: String,
        default: 'sm', // xs, sm, md,
        description: 'Tweet avatar size',
      },
      removable: {
        type: Boolean,
        default: false,
        description: 'Whether tweet is removable',
      },
      selected: {
        type: Boolean,
        default: false,
        description: 'Whether tweet is selected',
      },
      isRedirectable: {
        type: Boolean,
        default: false,
        description: 'Whether tweet contains a link to redirect to Twitter',
      },
      mediaFiles: {
        type: Array,
        default() {
          return [];
        },
        description: 'Tweet media',
      },
    },
    computed: {
      redirectionProps() {
        return this.isRedirectable
          ? {
              href: `https://twitter.com/${this.username}`,
              target: '_blank',
            }
          : {};
      },
    },
    methods: {
      formatTweet,
      displayPlaceHolderImage(e) {
        e.target.src = '/img/twittter_profile_photo_placeholder.png';
      },
    },
  };
</script>

<style lang="scss" scoped>
  body {
    &.new-ui {
      .display-card {
        @apply flex w-full flex-col gap-2 rounded-xl p-4;
      }
      .card-footer {
        @apply border-t pt-2.5;
      }
      .avatar-name,
      .avatar-username {
        @apply text-dark-mode-15;
      }
      &.mode--light {
        .display-card {
          @apply border border-gray-60 bg-gray-10;
          &.card-selected {
            @apply border-main-color-100;
          }
        }
        .card-footer {
          @apply border-gray-100;
        }
        .tweet-text {
          @apply text-black;
        }
      }
      &.mode--dark {
        .display-card {
          @apply bg-dark-mode-60;
          &.card-selected {
            @apply border border-main-color-100;
          }
        }
        .card-footer {
          @apply border-dark-mode-15;
        }
        .tweet-text {
          @apply text-white;
        }
      }
    }
  }
</style>
