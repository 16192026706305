/* eslint no-case-declarations: 0 */

module.exports = (projectId) => {
  switch (projectId) {
    case 'hypefury-896c7':
      return { appURL: 'https://app.hypefury.com', environment: 'prod' };
    case 'hypefury-staging':
      return { appURL: 'https://hypefury-staging.firebaseapp.com', environment: 'staging' };
    case 'hypefury-dev':
      const appURL = process.env.NODE_ENV === 'production'
        ? 'https://hypefury-dev.firebaseapp.com'
        : 'http://localhost:8080';
      return { appURL, environment: 'dev' };
    case 'hypefury-e2e':
      const url =
        process.env.NODE_ENV === 'production'
          ? 'https://hypefury-e2e.firebaseapp.com'
          : 'http://localhost:8080';
      return { appURL: url, environment: 'e2e' };
    case `hypefury-${process.env.VUE_APP_LOCAL_ENV}-dev`:
      return { appURL: 'http://localhost:8080', environment: `${process.env.VUE_APP_LOCAL_ENV}-dev`}
    case 'not-a-project':
      return { appURL: '', environment: '' };
    default:
      throw new Error(`Environment ${projectId} unknown.`);
  }
};
