<template>
  <modal
    data-cy="set_best_as_evergreen"
    :show="show"
    @close="close"
    :class="[{ 'overlay--on': isLoading }]"
  >
    <div data-cy="overlay" class="overlay" v-if="isLoading">
      <div class="loader">
        <span class="loading loading-lg"
          ><inline-svg src="/img/icons/loading.svg"></inline-svg
        ></span>
      </div>
    </div>

    <div
      slot="header"
      class="my-3 flex w-full flex-col items-center justify-between gap-2 lg:flex-row"
    >
      <span data-cy="set_best_title"> Pick some evergreen tweets </span>

      <div class="mr-2 flex items-center space-x-4">
        <base-button
          data-cy="select_all_button"
          @click="toggleTweetsFavoriteState"
          class="button small-button primary-button text-sm"
          >{{ areAllTweetsFavorite ? 'Unselect All' : 'Select All' }}</base-button
        >
        <base-button
          :disabled="
            !bestTweets ||
            (Array.isArray(bestTweets) &&
              (bestTweets.length === 0 || !bestTweets.some((thread) => thread.isFavorite)))
          "
          data-cy="confirm_button"
          @click="setBestTweetsAsEvergreen"
          class="button small-button primary-button text-sm"
          >Confirm</base-button
        >
      </div>
    </div>

    <div v-if="bestTweets && bestTweets.length">
      <base-alert data-cy="queue-subscription-alert" type="evergreen" radius="rounded-lg" class="mb-6 flex space-x-1 evergreen-alert items-start">
        <div class="ml-2">
          <p class="text-base">
            Evergreen tweets are those great tweets that hold value forever.
          </p>
          <p class="text-base">
            Hypefury will suggest them to you as inspiration, and will retweet them to keep your
            timeline active while providing value.
          </p>
          <p class="text-base">
            Below are your recent top tweets, pick some to be set as evergreen.
          </p>
        </div>
      </base-alert>

      <div v-for="thread in bestTweets" :key="thread.id" class="mb-4 focus--hide">
        <div class="space-y-3">
          <div class="card-child-bg mx-auto group relative my-6 flex max-w-lg flex-col items-center gap-y-5 rounded-2xl border-gray-60 p-4">
            <div class="relative w-full">
              <div class="my-2 flex gap-x-2 items-center">
                <img class="avatar-img" :src="userProfile.photoURL" alt="avatar" />
                <span class="avatar-name">
                  {{ userProfile.name }}
                </span>
                <span class="avatar-username">@{{ userProfile.username }}</span>
              </div>
              <p
                class="mt-3 max-w-full break-words px-1 text-base"
                v-html="thread.tweets[0].status"
                dir="auto"
              />
            </div>
            <div class="card-meta flex justify-between w-full">
              <div class="space-x-3">
                <div>
                  <!-- like.svg -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12 21a1 1 0 0 1-.71-.29l-7.77-7.78a5.26 5.26 0 0 1 0-7.4 5.24 5.24 0 0 1 7.4 0L12 6.61l1.08-1.08a5.24 5.24 0 0 1 7.4 0 5.26 5.26 0 0 1 0 7.4l-7.77 7.78A1 1 0 0 1 12 21zM7.22 6a3.2 3.2 0 0 0-2.28.94 3.24 3.24 0 0 0 0 4.57L12 18.58l7.06-7.07a3.24 3.24 0 0 0 0-4.57 3.32 3.32 0 0 0-4.56 0l-1.79 1.8a1 1 0 0 1-1.42 0L9.5 6.94A3.2 3.2 0 0 0 7.22 6z"
                    ></path>
                  </svg>
                  <span>{{ thread.tweets[0].favoriteCount }}</span>
                </div>
                <div>
                  <!-- retweet.svg -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <g
                      fill="none"
                      fill-rule="evenodd"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path d="M15 16l3 3 3-3" />
                      <path d="M18 17.5V7c0-1.1045695-.8954305-2-2-2h-5 0M9 8L6 5 3 8" />
                      <path d="M6 6.5V17c0 1.1045695.8954305 2 2 2h4.5" />
                    </g>
                  </svg>
                  <span>{{ thread.tweets[0].retweetCount }}</span>
                </div>
              </div>
              <div class="ml-auto space-x-1 cursor-pointer">
                <base-button
                  @click="thread.isFavorite = !thread.isFavorite"
                  class="btn icon bg-transparent hover:bg-transparent focus:bg-transparent"
                  :class="thread.isFavorite ? 'evergreen--on' : 'evergreen--off'">
                  <inline-svg :src="!thread.isFavorite ? '/img/icons/star-empty.svg' : '/img/icons/star.svg'" />
                </base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="bestTweets && bestTweets.length === 0">
      No posts found.
    </div>
  </modal>
</template>
<script>
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import { mapGetters } from 'vuex';
  import dao from '@/dao';
  import controller from '@/controller';

  export default {
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      areAllTweetsFavorite() {
        if (!this.bestTweets) {
          return false;
        }

        return this.bestTweets.every((tweet) => tweet.isFavorite);
      },
    },
    data() {
      return {
        isLoading: false,
        bestTweets: null,
      };
    },
    methods: {
      async fetchAndAssignBestTweets() {
        this.isLoading = true;

        const bestTweets = await dao.posts.getBestTweets(this.userProfile);
        const bestTweetsWithIsFavoriteSetToTrue = bestTweets.map((tweet) => ({
          ...tweet,
          isFavorite: true,
        }));

        this.bestTweets = bestTweetsWithIsFavoriteSetToTrue;

        this.isLoading = false;
      },
      toggleTweetsFavoriteState() {
        this.bestTweets = this.bestTweets.map((tweet) => ({
          ...tweet,
          isFavorite: this.areAllTweetsFavorite ? false : true,
        }));
      },
      close() {
        this.$emit('close');
      },
      async setBestTweetsAsEvergreen() {
        this.isLoading = true;

        try {
          const postsToUpdate = this.bestTweets.filter((tweet) => tweet.isFavorite === true);
          await controller.setBestTweetsAsEvergreen(
            this.currentUser,
            this.userProfile,
            postsToUpdate
          );
          this.$notify({ type: 'success', message: 'Tweets successfully set as evergreen!' });
          this.isLoading = false;
          this.$emit('close');
        } catch (error) {
          this.isLoading = false;
          this.swalModal({
            title: 'Sorry',
            text: 'An error has occurred while trying to set your best tweets as evergreen',
            type: 'error',
          });
          this.$emit('close');
          throw error;
        }
      },
    },
    watch: {
      show(val) {
        if (val) {
          this.fetchAndAssignBestTweets();
        }
      },
    },
    name: 'set-best-tweets-as-evergreen-modal',
    props: {
      show: Boolean,
    },
    mixins: [SwalModalMixin],
  };
</script>

<style lang="scss">
  @supports (padding: max(0px)) {
    form {
      padding-bottom: env(safe-area-inset-bottom) !important;
    }
  }
  .evergreen-alert {
    color: white;
    svg {
      margin-top: 0.5em;
      margin-right: 0.5em;
      color: white;
    }
  }
</style>
