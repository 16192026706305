<script>
  import MobileDetect from 'mobile-detect';
  export default {
    name: 'mobile-mixin',
    computed: {
      isMobile() {
        const md = new MobileDetect(navigator.userAgent);
        return Boolean(md.mobile());
      },
    },
    data() {
      return {
        screenType: '',
        isKeyboardVisible: false,
      };
    },
    mounted() {
      const setScreenType = () => {
        this.screenType = window.innerWidth < 1024 ? 'mobile' : '';
      };

      const setIsKeyboardVisible = () => {
        const minKeyboardHeight = 300;
        this.isKeyboardVisible =
          window.screen.height - minKeyboardHeight > window.visualViewport.height;
        if (this.isKeyboardVisible && this.isMobile) {
          const keyboardSize = window.innerHeight - window.visualViewport.height;
          document.body.classList.add('keyboard-visible');
          document.documentElement.style.setProperty('--keyboard-size', `${keyboardSize}px`);
          document.documentElement.style.setProperty(
            '--available-space',
            `${window.visualViewport.height - 50}px`
          );
        } else {
          document.body.classList.remove('keyboard-visible');
        }
      };

      // to calculate the vh for mobile devices with the address bar visible
      const calculateVhThenSetCssVar = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', vh + 'px');
      };

      const onResize = () => {
        setScreenType();
        setIsKeyboardVisible();
        calculateVhThenSetCssVar();
      };

      // to recalculate the vh when the orientation changes on mobile devices
      window.addEventListener('orientationchange', calculateVhThenSetCssVar);

      window.addEventListener('resize', onResize);

      setScreenType();
      setIsKeyboardVisible();
      calculateVhThenSetCssVar();

      this.$once('hook:destroyed', () => {
        window.removeEventListener('resize', onResize);
        window.removeEventListener('orientationchange', calculateVhThenSetCssVar);
      });
    },
  };
</script>
