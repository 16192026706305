import controller from '@/controller';
import moment from 'moment';
import 'moment-timezone';
import { formatTimeWithUserLocale } from '@/util/formatTimeWithUserLocale';
import { getFormattedDayFromTime } from '@/util/getFormattedDayFromTime';

class Post {
  midnight;
  slotType = 'post';

  constructor(id, time, scheduled, user, publishingError, deleted) {
    this.id = id;
    this.time = time;
    this.scheduled = scheduled;
    this.user = user;
    this.publishingError = publishingError;
    this.midnight = moment(time).startOf('day').toISOString();
    this.deleted = deleted || false;
  }

  postingTimeData(timezone) {
    const threadTime = moment.tz(this.time, timezone);

    const time = formatTimeWithUserLocale(threadTime);
    const day = getFormattedDayFromTime(threadTime, timezone);

    return {day, time};
  }

  deleteFromFirestore(firebaseUser, currentUserId) {
    return controller.posts.delete(firebaseUser, currentUserId, this.id)
      .catch(error => {
        console.error(`An error has occurred while deleting the post ${this.id}.`, error);
        throw error;
      });
  }

  updateTime(firebaseUser, currentUserId, time) {
    if (!this.id) {
      throw Error('This thread wasn\'t saved, so it can\'t be updated.');
    }
    return controller.posts.updatePostTime(firebaseUser, currentUserId, this.id, time)
      .catch((error) => {
        console.error(error);
        alert('An error has occurred while updating the time of a post.');
      });
  }

  getTwitterPublishingError() {
    if (
      this.publishingError &&
      this.publishingError[0] &&
      (this.publishingError[0].message || this.publishingError[0].detail)
    ) {
      return this.publishingError[0].message || this.publishingError[0].detail;
    }
    return null;
  }

  wasNotPublishedBecauseOfNotACustomer() {
    return this.publishingError && this.publishingError.includes('trial period');
  }

  minutesSincePosting(timezone) {
    const now = moment.tz(timezone);
    return now.diff(this.time, 'minutes');
  }
}

export { Post };