<script>
  import { mapGetters } from 'vuex';
  import { canRetweetBeMade } from '@/util/canRetweetBeMade';

  export default {
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
    },
    methods: {
      // Remove query params added by Twitter's app (e.g ?s=20)
      formatTweetLink(link) {
        return link.split('?')[0];
      },
      canRetweetOthers(originalTweetInfo) {
        return canRetweetBeMade(originalTweetInfo, this.userProfile);
      },
    },
    name: 'retweet-mixin',
  };
</script>
