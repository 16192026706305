import { flatten } from 'lodash';
import { countTweetLength } from '@/../functions/src/util/countTweetLength';
const splitSentences = require('sentence-splitter');

const TWEET_SIZE = 280;

export function splitTextIntoTweets(text, isNewUI) {
  let tweetCount = 0;
  const result = [''];

  const split = splitSentences.split(text.trim()).map((s) => {
    const sWithLineBreakMarkers = s.raw.replace(/\n/g, '$LINEBREAK$\n');
    const sentences = sWithLineBreakMarkers
      .split('\n')
      .map((s) => s.replace(/\$LINEBREAK\$/, '\n'));
    if (sentences[sentences.length - 1] && sentences[sentences.length - 1].endsWith('\n')) {
      sentences[sentences.length - 1] = sentences[sentences.length - 1].trim();
    }
    return sentences;
  });

  const sentences = flatten(split);

  sentences.forEach((sentence) => {
    const value = sentence;
    if (countTweetLength(value) > TWEET_SIZE) {
      const words = value.split(' ');
      words.forEach((word) => {
        if (countTweetLength(result[tweetCount]) + countTweetLength(word) + 1 > TWEET_SIZE) {
          tweetCount++;
          result[tweetCount] = '';
        }
        result[tweetCount] += word + ' ';
      });
    } else {
      if (countTweetLength(result[tweetCount]) + countTweetLength(value) > TWEET_SIZE) {
        tweetCount++;
        result[tweetCount] = '';
      }

      result[tweetCount] += value;
    }
  });

  const resultForClassicUI = result.map((t) => {
    return t.trim().replace(/ \n/g, '\n');
  });

  if (isNewUI) {
    return flatten(resultForClassicUI.map((t) => t.split('\n\n\n')));
  }

  return resultForClassicUI;
}
