<script>
  import controller from '@/controller';
  import { decodeTweetText } from '@/util/decodeTweetText';
  import * as linkify from 'linkifyjs';
  import { validateLink } from '@/util/validateLink';
  import RetweetMixin from '@/views/Mixins/RetweetMixin.vue';
  import lodash from 'lodash';

  export default {
    data() {
      return {
        quoteURL: null,
        quoteTweetPreview: null,
        initialLoad: false,
        isFetchingQuoteTweetData: false,
      };
    },
    mounted() {
      const quoteTweetData = lodash.get(this, 'tweet.quoteTweetData');

      if (quoteTweetData) {
        this.initialLoad = true;
        this.quoteTweetPreview = lodash.omit(quoteTweetData, 'url');
        this.quoteURL = quoteTweetData.url;
      }
    },
    methods: {
      removeQuoteTweet() {
        this.quoteURL = null;
        this.quoteTweetPreview = null;
        this.$emit('quote-tweet-data-updated', null);
      },
      setQuoteURLIfValid(text) {
        const link = text
          .split(/\s+/)
          .find(
            (l) =>
              (l.includes('twitter.com') || l.includes('x.com')) &&
              validateLink(l).isValid &&
              !l.includes('/video/') &&
              l.includes('/status/')
          );

        if (link) {
          setTimeout(() => {
            this.status = this.status.replace(link, '');
            this.$emit('status-updated', this.status);
          }, 100);
          this.quoteURL = link;
        }
      },
      async getQuoteTweetData(link) {
        try {
          const tweetId = link.split('/').pop();
          const result = await controller.getTweet(tweetId, this.currentUser, this.userProfile.uid);
          const tweet = result.data;
          const userFromTwitter = lodash.get(result, 'includes.users', [{}])[0];

          const quoteTweetData = {
            text: decodeTweetText(tweet.text),
            userProfilePictureURL: userFromTwitter.profile_image_url,
            userDisplayName: userFromTwitter.name,
            username: userFromTwitter.username,
            tweetId: tweet.id,
          };

          const linkInfo = linkify.find(link);

          const formattedQuoteURL = linkInfo.length
            ? this.formatTweetLink(linkInfo[0].href)
            : this.formatTweetLink(link);

          return {
            ...quoteTweetData,
            url: formattedQuoteURL,
          };
        } catch (error) {
          console.error(error);
          this.swalModal({
            title: 'Sorry',
            text: `An error has occured while trying to fetch the quoted tweet's data.`,
            type: 'warning',
          });
        }
      },
    },
    watch: {
      async quoteURL(val) {
        // When editing the post we already have the quoteTweetData so we don't need to fetch it.
        if (this.initialLoad) {
          this.initialLoad = false;
          return;
        }

        if (!val) return;

        this.isFetchingQuoteTweetData = true;

        const quoteTweetData = await this.getQuoteTweetData(val);

        this.quoteTweetPreview = lodash.omit(quoteTweetData, 'url');

        this.$emit('quote-tweet-data-updated', quoteTweetData);

        this.isFetchingQuoteTweetData = false;
      },
      tweet: {
        deep: true,
        handler(val) {
          if (
            val &&
            val.quoteTweetData &&
            val.quoteTweetData.url !== this.quoteURL &&
            !this.isFetchingQuoteTweetData
          ) {
            this.quoteTweetPreview = lodash.omit(val.quoteTweetData, 'url');
            this.quoteURL = val.quoteTweetData.url;
          }
        },
      },
    },
    mixins: [RetweetMixin],
  };
</script>