<script>
  import { mapGetters } from 'vuex';
  import lodash from 'lodash';
  import controller from '@/controller';
  import SwalModalMixin from './SwalModalMixin.vue';
  const fb = require('@/firebase');
  const config = require('@/config');

  export default {
    data() {
      return {
        isEnablingNotifications: false,
      };
    },
    computed: {
      doesUserHaveNotificationsEnabled() {
        return lodash.get(this.userProfile, 'notifications.enabled', false);
      },
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
    },
    methods: {
      async enableNotifications() {
        try {
          if (fb.messaging) {
            this.isEnablingNotifications = true;
            const deviceToken = await fb.messaging.getToken({ vapidKey: config.messagingVapidKey });

            await controller.addDeviceNotificationsToken(
              this.currentUser,
              this.userProfile.uid,
              deviceToken,
              true
            );

            this.$notify({ type: 'success', message: 'Notifications were enabled successfully!' });
          } else {
            this.swalModal({
              title: 'Error',
              html: `Your browser/device does not support notifications or they are blocked by it. Please check if you haven't blocked them.`,
              type: 'warning',
            });
          }
        } catch (error) {
          this.swalModal({
            title: 'Error',
            html: `An error has occurred while trying to enable notifications. Please check if you haven't blocked them.`,
            type: 'warning',
          });
        } finally {
          this.isEnablingNotifications = false;
        }
      },
    },
    mixins: [SwalModalMixin]
  };
</script>
