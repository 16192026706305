<script>
  import CustomerStatusMixin from './CustomerStatusMixin.vue';
  import { mapGetters, mapState } from 'vuex';

  export default {
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
      ...mapState(['userCategories']),
      didUserReachCategoriesLimit() {
        return this.enabledUserCategories.length >= this.userCategoriesLimit;
      },
      userCategoriesLimit() {
        return this.PLANS[this.userProfile.customerStatus].limits.categories;
      },
      enabledUserCategories() {
        return this.userCategories.filter((category) => category.isEnabled);
      },
    },
    mixins: [CustomerStatusMixin],
  };
</script>
