<template>
  <div data-cy="composer-threads" class="overscroll-behavior-contain mb-13 lg:mb-0">
    <new-alert class="mb-6" v-if="!isUserConnectedToThreads">
      You can schedule a Threads post but it won't be published unless you
      <router-link class="text-main-color-100 underline" to="/settings#threads"
        >connect your Threads account to Hypefury</router-link
      >
    </new-alert>

    <div class="group relative flex w-full items-start gap-x-2 sm:gap-4" ref="root">
      <div class="flex h-full flex-shrink-0 flex-col justify-between pt-12" ref="tweetSide">
        <div class="flex flex-col justify-between">
          <img
            v-if="tweet.count === 0"
            :src="userProfile.photoURL"
            :alt="userProfile.name"
            class="avatar-img -mt-12 mb-3"
          />
          <div v-if="threadLength > 1" class="relative">
            <div class="absolute -top-11 h-9 w-9"></div>
            <new-button
              v-show="tweet.count !== 0"
              type="secondary"
              icon
              absolute
              class="absolute -top-11 transition-opacity duration-700 ease-in-out"
              :class="{
                'opacity-0 group-hover:opacity-100': isDesktop,
                'opacity-100': !isDesktop,
              }"
              @click="$emit('add-tweet', {}, tweet.count)"
            >
              <inline-svg src="img/icons/add.svg" />
            </new-button>
            <div>
              <new-button
                type="danger"
                icon
                @click="deleteTweet(tweet)"
                data-cy="composer-remove-tweet"
              >
                <inline-svg src="img/icons/minus-icon.svg" class="stroke-current" />
              </new-button>
            </div>
          </div>
        </div>
        <div
          class="absolute"
          :class="{
            '-bottom-0.75 xs:-bottom-1.5 ': threadLength > 1,
            '-bottom-0.75': threadLength === 1,
          }"
        >
          <portal-target v-if="tweet.count === threadLength - 1" name="addTweet"></portal-target>
        </div>
      </div>
      <div
        class="flex-grow min-w-0 flex-1"
        :class="{
          'mb-13': isLastTweet && !isDesktop,
        }"
      >
        <div class="flex items-center justify-between">
          <div class="flex-grow flex min-w-0 flex-wrap items-center gap-2 py-1.5">
            <span class="avatar-name">{{ userProfile.name }} </span>
            <span class="avatar-username">@{{ userProfile.username }}</span>
          </div>
          <div
            class="flex items-center justify-end gap-2 py-1.5"
            :class="{ invisible: !isCurrentTextAreaFocused }"
          >
            <character-counter :textLength="tweetLength" :maximumCharacters="500" />
          </div>
        </div>
        <div
          ref="tweet_container"
          class="grow min-h-18"
          @drop="uploadSectionDrop(tweet, $event)"
          @dragover="uploadSectionDragOver(tweet, $event)"
          @dragleave="uploadSectionDragLeave(tweet, $event)"
        >
          <textarea
            class="form-control simple my-1 min-h-16"
            ref="tweet"
            :id="`${tweet.count}`"
            v-model="status"
            :placeholder="statusPlaceholder"
            @paste="$emit('pasted', $event, tweet.count, 500)"
            @input="$emit('input-changed')"
            @keyup.ctrl.enter="$emit('pressed-ctrl-enter')"
            @keydown.meta.enter="$emit('pressed-ctrl-enter')"
            @keydown.exact.enter="handleEnterKeyPress"
            @focus="onFocus"
            data-cy="composer-input"
            @blur="onBlur"
            dir="auto"
            autofocus
          />
        </div>

        <portal-target
          :name="tweet.guid"
          multiple
          class="thread-preview focus--hide space-y-2 p-2 pr-0"
        />

        <portal :to="`tweet-actions-${tweet.guid}`">
          <div
            :class="{ hidden: !isCurrentTextAreaFocused }"
            class="flex-grow ml-auto flex items-center justify-end gap-3 sm:flex-1"
          >
            <tooltip data-cy="compose-upload-media" :content="getUploadContainerTooltip(false)">
              <upload-container
                :pastedFiles="pastedFiles"
                :tweet="tweet"
                :portalTargetName="tweet.guid"
                :canAddMedia="canAddMedia"
                :canAddMoreVideos="canAddMoreVideos"
                :clearMediaSelectionAtIndexParent="clearMediaSelectionAtIndex"
                :imageCountInThread="imageCountInThread"
                postType="threads"
                :uploadingMedia="uploadingMedia"
              />
            </tooltip>
            <new-drop-down
              icon
              ref="gifDropdown"
              buttonType="custom"
              :disabled="isGifDisabled"
              buttonSize="sm"
              placement="left"
              data-cy="composer-choose-gif"
            >
              <template #button>
                <tooltip :content="getUploadContainerTooltip(true)">
                  <div class="relative">
                    <inline-svg
                      src="/img/icons/gif-icon.svg"
                      class="fill-main-color-100 stroke-main-color-100"
                    />
                  </div>
                </tooltip>
              </template>
              <template>
                <new-gif-selector :columns="3" :index="tweet.count" @selected-gif="selectedGif" />
              </template>
            </new-drop-down>
            <emoji-picker @selected-emoji="addEmoji($event)" />
          </div>
        </portal>
        <div
          class="flex w-full min-w-0 items-center justify-start"
          :class="{ invisible: !isCurrentTextAreaFocused }"
        >
          <div class="flex-grow ml-auto flex items-center justify-end gap-3 sm:flex-1">
            <portal-target
              v-if="isDesktop"
              :name="`tweet-actions-${tweet.guid}`"
              multiple
              class="flex items-center gap-x-4"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import lodash from 'lodash';
  import { mapGetters } from 'vuex';
  import EmojiPicker from '@/components/Threads/EmojiPicker.vue';
  import UploadContainer from '@/components/UploadContainer.vue';
  import ThreadsMixin from '@/views/Mixins/ThreadsMixin.vue';
  import UploadContainerMixin from '@/views/Mixins/UploadContainerMixin.vue';
  import BreakpointsMixin from '@/views/Mixins/BreakpointsMixin.vue';
  import { adjustTextAreaHeight } from '@/util/adjustTextAreaHeight';
  import NewGifSelector from '@/components/NewGifSelector.vue';
  import CharacterCounter from '../CharacterCounter.vue';
  import { formatTweet } from '@/util/formatTweet';

  export default {
    name: 'ThreadsComposer',
    components: {
      UploadContainer,
      EmojiPicker,
      NewGifSelector,
      CharacterCounter,
    },
    mixins: [ThreadsMixin, UploadContainerMixin, BreakpointsMixin],
    props: {
      isMobileKeyboardVisible: {
        type: Boolean,
        default: false,
      },
      tweet: {
        type: Object,
        required: true,
      },
      threadLength: {
        type: Number,
        default: 1,
      },
      canAddMoreVideos: {
        type: Boolean,
        required: true,
      },
      imageCountInThread: {
        type: Number,
        default: 0,
      },
      tweetIndexToFocus: {
        type: Number,
        default: null,
      },
      originalThread: {
        type: Object,
        default: null,
      },
      wasSubmitted: {
        type: Boolean,
        default: false,
      },
      currentlyFocusedTweetIndex: {
        type: Number,
        default: 0,
      },
      uploadingMedia: Array,
    },
    data() {
      return {
        previousHeight: 79,
        ...this.initialState(),
      };
    },
    mounted() {
      this.$refs.tweet.focus();
      this.$el.addEventListener('paste', (e) => {
        this.pastedFiles = [];
        if (!this.canAddMedia || e.clipboardData.files.length === 0) {
          return;
        }
        this.pastedFiles = [e.clipboardData.files[0]];
      });

      this.adjustTextAreaHeight();
    },
    updated() {
      this.adjustTextAreaHeight();
    },
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
      isCurrentTextAreaFocused() {
        return this.tweet.count === this.currentlyFocusedTweetIndex;
      },
      isLastTweet() {
        return this.tweet.count === this.threadLength - 1;
      },
      canAddMedia() {
        if (!this.tweet.mediaFile) return;

        if (this.tweet.count > 0) {
          return this.tweet.mediaFile.length < 1;
        }

        return this.tweet.mediaFile.length < 10;
      },
      statusPlaceholder() {
        if (this.placeholder) {
          return this.placeholder;
        }

        if (this.tweet.count === 0) {
          return Math.random() >= 0.5
            ? 'Tip: paste a long text and it will be turned into a thread!'
            : 'What would you like to share?';
        } else {
          return 'Reply ' + (this.tweet.count + 1);
        }
      },
      isGifDisabled() {
        return !this.canAddMedia;
      },
      tweetLength() {
        return this.tweet.status.length;
      },
    },
    methods: {
      handleEnterKeyPress() {
        const { createTweetOnEnterPressEnabled } = this.userProfile.settings;
        if (createTweetOnEnterPressEnabled) {
          clearTimeout(this.enterTimeout);
          this.enterCount++;

          const lastIndexOfNewLine = this.status.lastIndexOf('\n\n');

          if (lastIndexOfNewLine !== -1 && this.enterCount === 3) {
            const firstPart = this.status.slice(0, lastIndexOfNewLine);
            const secondPart = this.status.slice(lastIndexOfNewLine + 3);

            this.$emit('status-updated', firstPart, this.tweet.count);
            this.$emit('add-tweet', { status: secondPart }, this.tweet.count + 1);
          }

          this.enterTimeout = setTimeout(() => {
            this.enterCount = 0;
          }, 600);

          setTimeout(() => {
            if (this.$refs.root) {
              this.$refs.root.scrollIntoView({ block: 'nearest' });
            }
          }, 0);
        }
      },
      initialState() {
        return {
          enterCount: 0,
          enterTimeout: null,
          pastedFiles: [],
          status: '',
        };
      },
      formatTweet(tweetStatus) {
        return formatTweet(tweetStatus);
      },
      deleteTweet(tweet) {
        this.$emit('deleted', tweet.count, this.isThreadFinisherTweet);
      },
      getUploadContainerTooltip(isGIFButton) {
        if (Array.isArray(this.tweet.mediaFile) && this.tweet.mediaFile.length === 10) {
          return 'You can only add up to 10 media files per thread. Remove some to add more.';
        }

        if (isGIFButton) {
          return 'Choose GIF';
        }

        return 'Upload Media';
      },
      clearMediaSelectionAtIndex(tweet, index) {
        this.$emit('cleared-media', tweet, index);
      },
      addEmoji(emoji) {
        const status = this.status;
        const tweetRef = this.$refs.tweet;
        const position = tweetRef.selectionStart;
        this.status = [status.slice(0, position), emoji.native, status.slice(position)].join('');
        const positionAfterTweet = position + 2;

        this.$nextTick(() => {
          tweetRef.focus();
          tweetRef.setSelectionRange(positionAfterTweet, positionAfterTweet);
        });
      },
      adjustTextAreaHeight() {
        this.previousHeight = adjustTextAreaHeight(
          this.$refs.tweet,
          this.$refs.tweet_container,
          this.previousHeight,
        );
      },
      resetData() {
        Object.assign(this.$data, { ...this.initialState(), quoteTweetPreview: null });
      },
      onFocus(event) {
        this.$emit('set-currently-focused-tweet-index', event);
        this.$emit('focused');
      },
      onBlur() {
        this.$emit('blurred');
      },
      selectedGif(gif) {
        this.$emit('selected-gif', gif, this.tweet.count);
        const gifDropdown = this.$refs.gifDropdown;
        if (gifDropdown) {
          gifDropdown.close();
        }
      },
    },
    watch: {
      tweetIndexToFocus: {
        immediate: true,
        handler: function (tweetIndex) {
          if (tweetIndex === this.tweet.count) {
            setTimeout(() => {
              this.$refs.tweet.focus();
              // Sometimes when focusing the cursor is set to the beginning, this sets it to the end
              if (!this.isTabletOrMobile) {
                this.$refs.tweet.setSelectionRange(this.status.length, this.status.length);
              }
            }, 0);
          }
        },
      },
      wasSubmitted: {
        immediate: true,
        handler: function (wasSubmitted) {
          if (wasSubmitted) {
            this.resetData();
          }
        },
      },
      originalThread: {
        immediate: true,
        handler: function (thread) {
          if (thread && this.tweet.poll) {
            this.hasPoll = true;
            this.pollOptions = this.tweet.poll.options;
            this.pollDuration = this.tweet.poll.duration;
          }
        },
      },
      tweet: {
        deep: true,
        immediate: true,
        handler: function (newVal) {
          this.$nextTick(() => {
            this.status = newVal.status;
          });
        },
      },
      status: function (val) {
        this.$emit('status-updated', val, this.tweet.count);
      },
    },
    beforeDestroy() {
      // this for mobile devices to solve the issue of the keyboard closing to focus on the prev tweet before destroying the current one
      if (this.isTabletOrMobile) {
        const index = this.$parent.$children.findIndex((item) => item._uid === this._uid);
        if (index > 0) {
          const tweetRef = lodash.get(this.$parent.$children, `[${index - 1}].$refs.tweet`);
          if (tweetRef) {
            tweetRef.focus();
          }
        }
      }
    },
  };
</script>

<style lang="scss">
  .circle-bg {
    fill: none;
    stroke-width: 1.5;
  }

  .mode--dark {
    .circle-bg {
      stroke: #4c5160;
    }
  }

  .mode--light {
    .circle-bg {
      stroke: #f2f2f2;
    }
  }

  .circle {
    fill: none;
    stroke-width: 1.5;
    stroke-linecap: round;
  }

  .strokeColor {
    stroke: #0078fb;
  }
</style>
