<template>
  <base-modal @close="$emit('close')">
    <div class="calendar-container relative z-10 rounded-3xl bg-ds-foreground p-10">
      <div class="absolute right-5 top-5">
        <new-button @click="$emit('close')" icon type="transparent" data-cy="autoplug-close">
          <inline-svg src="/img/icons/new/x.svg" class="stroke-ds-text-secondary-tertiary" />
        </new-button>
      </div>

      <div class="grid gap-6">
        <div class="flex items-center justify-between gap-4">
          <span class="text-ds-primary text-2xl font-bold">Choose time</span>
        </div>
        <div class="grid grid-cols-1 grid-rows-2 gap-y-4 md:grid-cols-3 md:grid-rows-1 md:gap-x-6">
          <div class="flex-grow col-span-2">
            <DatePicker
              class="!border-none"
              v-model="date"
              is-expanded
              :model-config="{
                type: 'string',
                mask: 'YYYY-MM-DD',
              }"
              :min-date="new Date()"
              title-position="left"
              is-required
            />
          </div>
          <div>
            <div class="mb-4">
              <label
                for="time"
                class="mb-2 block text-sm font-semi-medium text-ds-text-secondary-tertiary"
                >Custom Time</label
              >
              <div
                class="border cursor-pointer gap-3 rounded-lg border-ds-button-primary-background bg-ds-button-primary-background px-4 py-2"
              >
                <input
                  data-cy="pick-time-time-input"
                  type="time"
                  class="cursor-pointer text-center text-lg text-ds-button-primary-label"
                  v-model="time"
                />
              </div>
              <div
                v-if="getTimeError(fullTime, threadToEdit)"
                class="pb-4 text-ds-system-error-text"
                v-html="getTimeError(fullTime, threadToEdit)"
                data-cy="schedule-time-error"
              ></div>
            </div>

            <div>
              <span class="mb-2 block text-sm font-semi-medium text-ds-text-secondary-tertiary"
                >Time Slots</span
              >

              <div class="flex overflow-y-auto">
                <span v-if="timeSlots.length === 0">
                  No time slots available for the selected date.
                </span>
                <div class="grid max-h-32 w-full gap-2 overflow-y-auto">
                  <new-base-radio
                    v-for="(slot, index) in timeSlots"
                    :key="index"
                    :value="getSlotTime(slot.time, 24)"
                    v-model="time"
                    size="md"
                    type="dark"
                    class="text-center"
                    data-cy="pick-time-slot-button"
                  >
                    {{ getSlotTime(slot.time) }}
                  </new-base-radio>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="h-px border-none bg-ds-outline-secondary" />
        <div class="flex items-center justify-between">
          <div class="pr-1.5 text-sm">
            <p class="font-bold">You've selected</p>
            <p>{{ formattedDate }} at {{ time }}</p>
          </div>
          <div>
            <new-button
              v-if="selectedTab !== 'reel-editor'"
              :disabled="!canPostBeScheduled"
              @click="$emit('schedule-post')"
              :data-cy="threadToEdit ? 'pick-time-update-button' : 'pick-time-schedule-button'"
            >
              {{ threadToEdit ? 'Update' : 'Schedule' }}
            </new-button>
            <new-button v-else :disabled="!canPostBeScheduled" @click="$emit('schedule-reel')">
              Schedule
            </new-button>
          </div>
        </div>
      </div>
    </div>
  </base-modal>
</template>
<script>
  import NewThreadMixin from '@/views/Mixins/NewThreadMixin.vue';
  import DatePicker from 'v-calendar/lib/components/date-picker.umd';

  import moment from 'moment';
  export default {
    name: 'NewCalendarModal',
    components: { DatePicker },
    props: {
      fullTime: {
        type: String,
        default: null,
      },
      threadToEdit: {
        type: Object,
        default: null,
      },
      timeSlots: {
        type: Array,
        default: null,
      },
      canPostBeScheduled: {
        type: Boolean,
        default: false,
      },
      selectedTab: {
        type: String,
        default: 'twitter',
      },
      timeFromParent: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        date: null,
        time: null,
      };
    },
    methods: {
      getSlotTime(slotTime, hourType) {
        const timeFormat = hourType === 24 ? 'HH:mm' : 'hh:mm A';
        return slotTime.format(timeFormat);
      },
      updateParentTime() {
        this.$emit('values-set', {
          time: this.time,
          date: this.date,
        });
      },
    },
    computed: {
      formattedDate() {
        return moment(this.date).format('dddd D MMMM YYYY');
      },
    },
    mounted() {
      if (this.timeFromParent) {
        this.time = this.timeFromParent.format('HH:mm');
        this.date = this.timeFromParent.format('YYYY-MM-DD');
      } else {
        const firstFreeSlot = this.timeSlots[0].time;
        this.time = firstFreeSlot.format('HH:mm');
        this.date = firstFreeSlot.format('YYYY-MM-DD');
      }
    },
    watch: {
      time() {
        this.updateParentTime();
      },
      date() {
        this.updateParentTime();
      },
    },
    mixins: [NewThreadMixin],
  };
</script>
<style lang="scss" scoped>
  .calendar-container {
    max-width: 608px;

    @include lg {
      width: 608px;
    }
  }
</style>

<style>
  .vc-day.is-today {
    color: var(--button-icon) !important;
  }
  .vc-highlights.vc-day-layer {
    border-radius: 8px !important;
    background-color: var(--button-icon) !important;
    color: #fff !important;
    width: 38px;
    margin: 0 auto;
  }
  .vc-highlights.vc-day-layer span.vc-day-content.vc-focusable {
    color: #fff !important;
    display: none !important;
  }
  .vc-arrow {
    color: var(--button-icon) !important;
    border-radius: 50% !important;
    background-color: #eff7ff !important;
  }

  .vc-arrow.is-right {
    margin-left: 8px !important;
  }

  .vc-header {
    padding: 10px 18px 0 0px !important;
  }

  .vc-day-content:hover {
    background-color: var(--button-icon) !important;
    width: 38px;
    height: 32px;
    border-radius: 8px !important;
    color: var(--button-secondary-background) !important;
  }
  .vc-day-content:focus {
    background-color: var(--button-icon) !important;
  }

  .vc-highlight {
    background-color: var(--button-icon) !important;
  }

  .vc-highlight:hover {
    background-color: var(--button-icon) !important;
  }

  .vc-day-content.vc-focusable.is-disabled {
    color: var(--text-secondary-tertiary) !important;
  }

  .vc-day {
    color: var(--text-secondary) !important;
  }

  .vc-day-content.vc-focusable:hover {
    color: #fff !important;
  }

  .vc-weeks,
  .vc-header {
    background-color: var(--foreground) !important;
  }

  .vc-title {
    color: var(--text-primary) !important;
  }
</style>
