import moment from 'moment';
import 'moment-timezone';
import lodash from 'lodash';
import { EmptySlot } from './EmptySlot';
import EvergreenSlot from './EvergreenSlot';
import InstagramSlot from './InstagramSlot';
import { RecurrentPostSlot } from './RecurrentPostSlot';
import LinkedInSlot from './LinkedInSlot';
import FacebookSlot from './FacebookSlot';
import ThreadsSlot from './ThreadsSlot';
import { store } from '@/store';
import { PLANS } from '@/../functions/src/util/customerStatus.cjs';
import {
  isUnlimitedSchedulingAllowedForUserPlan,
  isPostBeyondSchedulingLimit,
} from '@/../functions/src/util/schedulingDateLimit';

class Schedule {
  #timeSlotsForEachDay;
  #size;
  #timezone;
  #threads;
  #state = {};
  #categoriesPosts;

  constructor(size, timezone, threads, timeSlotsForEachDay, recurrentPosts = [], categoriesPosts) {
    this.#threads = threads;
    this.#size = size;
    this.#timezone = timezone;
    this.#categoriesPosts = categoriesPosts;
    this.#timeSlotsForEachDay = timeSlotsForEachDay.getScheduleForDB().map((s) => {
      if (!s) return [];
      return s.split(',').map((s) => {
        const split = s.split(':');
        const [hour, minute, type] = split;
        return {
          hour: parseInt(hour),
          minute: parseInt(minute),
          type,
        };
      });
    });

    recurrentPosts.forEach((post) => {
      // Required Explanation: In here, Sunday == 0, in our DB we use 1-7 ISO Weekdays where Sunday is 7.
      // The following line transforms Sunday from 7 to 0, while keeping everything else the same.
      const day = post.day === 7 ? 0 : post.day;
      this.#timeSlotsForEachDay[day].push({
        hour: parseInt(post.time.split(':')[0]),
        minute: parseInt(post.time.split(':')[1]),
        type: 'recurrent',
        recurrentPost: post,
      });
    });

    this.#computeState();
  }

  /*
   * Returns the time slots organized by date
   */
  getThreadsByDate(date) {
    if (!date) {
      const userProfile = lodash.get(store, 'getters.getUserProfile');
      const schedulingLimit =
        PLANS[lodash.get(userProfile, 'customerStatus', 'none')].limits.scheduling;

      if (!isUnlimitedSchedulingAllowedForUserPlan(userProfile)) {
        const limitedSchedule = {};

        Object.entries(this.#state).forEach(([date, slots]) => {
          if (!isPostBeyondSchedulingLimit(date, this.#timezone, schedulingLimit)) {
            limitedSchedule[date] = slots;
          }
        });

        return limitedSchedule;
      } else {
        return this.#state;
      }
    }

    const midnight = date.startOf('day').toISOString();
    return this.#state[midnight];
  }

  getPostCountInNextNDays(n) {
    return lodash
      .flatten(Object.values(this.#state).slice(0, n))
      .filter((slot) => slot.slotType === 'post').length;
  }

  getTimeSlotsByNonEmptyDate() {
    const daysWithEmptySlots = {};
    lodash.mapValues(this.getThreadsByDate(), (slotsOfDay, day) => {
      if (slotsOfDay.filter((slot) => slot.isEmpty()).length > 0) {
        daysWithEmptySlots[day] = slotsOfDay;
      }
    });
    return daysWithEmptySlots;
  }

  getAllSlots() {
    return lodash.sortBy(lodash.flatten(Object.values(this.#state)), (t) => t.time.unix());
  }

  #computeState = () => {
    const daySlots = this.#fillDaySlots(this.#size);
    const sortedDays = daySlots.sort((d1, d2) => {
      return moment.tz(d1, this.#timezone).diff(moment.tz(d2, this.#timezone));
    });

    const now = moment.tz(this.#timezone);
    sortedDays.forEach((daySlot) => {
      const dayDate = moment.tz(daySlot, this.#timezone);
      const day = dayDate.day();
      const timeSlotsForDay = this.#timeSlotsForEachDay[day];

      const daySlotString = daySlot;
      this.#state[daySlotString] = [];

      if (!timeSlotsForDay) return;

      timeSlotsForDay.forEach((timeSlot) => {
        const time = dayDate.clone().set({ hour: timeSlot.hour, minute: timeSlot.minute });

        if (time.diff(now, 'seconds') <= 0) return;

        if (timeSlot.type === 'evergreen') {
          this.#state[daySlotString].push(new EvergreenSlot(time));
        } else if (timeSlot.type === 'recurrent') {
          this.#state[daySlotString].push(new RecurrentPostSlot(time, timeSlot.recurrentPost));
        } else if (timeSlot.type === 'instagram') {
          this.#state[daySlotString].push(new InstagramSlot(time));
        } else if (timeSlot.type === 'linkedin') {
          this.#state[daySlotString].push(new LinkedInSlot(time));
        } else if (timeSlot.type === 'facebook') {
          this.#state[daySlotString].push(new FacebookSlot(time));
        } else if (timeSlot.type === 'threads') {
          this.#state[daySlotString].push(new ThreadsSlot(time));
        } else {
          this.#state[daySlotString].push(new EmptySlot(time));
        }
      });
    });

    const latestDayOfSchedule = moment
      .tz(this.#timezone)
      .add(this.#size - 1, 'days')
      .startOf('day');

    this.#threads.forEach((thread) => {
      if (latestDayOfSchedule.diff(moment(thread.midnight)) < 0) return;
      if (!this.#state[thread.midnight]) this.#state[thread.midnight] = [];
      this.#state[thread.midnight] = this.#state[thread.midnight].filter((slot) => {
        const isInstagramPost = thread.source === 'instagram-post' && slot instanceof InstagramSlot;
        const isLinkedInPost = thread.source === 'linkedin-post' && slot instanceof LinkedInSlot;
        const isFacebookPost = thread.source === 'facebook-post' && slot instanceof FacebookSlot;
        const isThreadsPost = thread.source === 'threads-post' && slot instanceof ThreadsSlot;
        const isAPost = !(slot instanceof EmptySlot);
        if (isAPost && !isInstagramPost && !isLinkedInPost && !isFacebookPost && !isThreadsPost)
          return true; // Posts with the same time should not be filtered
        return slot.time && slot.time.diff(thread.time) !== 0;
      });
      this.#state[thread.midnight].push(thread);
    });

    Object.keys(this.#state).forEach((key) => {
      this.#state[key] = this.#state[key].sort((t1, t2) => t1.time.diff(t2.time));
    });

    if (this.#categoriesPosts) {
      const recurrentPostsCounter = {};

      Object.entries(this.#state).forEach((day) => {
        day[1].forEach((slot, slotIndex) => {
          if (!(slot instanceof RecurrentPostSlot)) {
            return;
          }

          const timesToSkip = lodash.get(slot, 'recurrentPost.category.timesToSkip', []);
          const shouldSkipTime = timesToSkip.some((timeToSkip) =>
            slot.time.isSame(timeToSkip.toDate(), 'minute')
          );
          if (shouldSkipTime) {
            this.#state[day[0]][slotIndex].isSkipped = true;
            return;
          }

          const categoryId = slot.recurrentPost.category.id;

          const postIndex = recurrentPostsCounter[categoryId] || 0;

          if (lodash.get(this.#categoriesPosts, categoryId, []).length > postIndex) {
            this.#state[day[0]][slotIndex].thread = this.#categoriesPosts[categoryId][postIndex];
            recurrentPostsCounter[categoryId] = postIndex + 1;
          }
        });
      });
    }
  };

  #fillDaySlots = (size) => {
    const daySlots = [];
    [...Array(size).keys()].forEach((x) => {
      daySlots.push(moment.tz(this.#timezone).startOf('day').add(x, 'days').toISOString());
    });
    return daySlots;
  };

  isEmpty() {
    return (
      Object.keys(this.#state).length === 0 ||
      Object.values(this.#state).every((daySlots) => daySlots.length === 0)
    );
  }

  changeTimeOfThread(thread, time) {
    this.#threads.filter((t) => t.id === thread.id).forEach((t) => (t.time = time));

    this.#computeState();
  }

  getNextTimeSlot(time) {
    const now = moment.tz(this.#timezone);
    const slots = this.getThreadsByDate();
    const sortedTimes = lodash
      .flatten(Object.values(slots))
      .filter((slot) => {
        const timeFilter = !time ? true : slot.time.diff(time) > 1;
        return slot.isEmpty() && timeFilter;
      })
      .map((s) => s.time)
      .sort((a, b) => moment(a).valueOf() - moment(b).valueOf())
      .filter((time) => time.diff(now) > 0);
    return sortedTimes[0];
  }

  hasUserReachedSchedulingLimits() {
    const now = moment.tz(this.#timezone);
    const slots = this.getThreadsByDate();
    const sortedTimes = lodash
      .flatten(Object.values(slots))
      .filter((slot) => slot.isEmpty() && slot.time.diff(now) > 0);
    return sortedTimes.length <= 0;
  }
}

export { Schedule };
