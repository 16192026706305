<template>
  <component
    :is="link ? 'a' : 'button'"
    class="btn"
    v-bind="$attrs"
    v-on="$listeners"
    :class="{
      'px-4 py-2.5 text-base md:px-6 md:py-4 md:text-lg':
        size === 'lg' && !icon && type !== 'custom',
      'px-4 py-2 text-base': size === 'md' && !icon && type !== 'custom',
      'px-3 py-1.5 text-sm': size === 'sm' && !icon && type !== 'custom',
      'px-3 py-1 text-xs': size === 'xs' && !icon && type !== 'custom',
      'flex h-10 w-10 items-center justify-center': size === 'lg' && icon,
      'flex h-8 w-8 items-center justify-center': size === 'md' && icon,
      'flex h-5 w-5 items-center justify-center': size === 'sm' && icon,
      'flex h-4 w-4 items-center justify-center': size === 'xs' && icon,
      'btn-primary': type === 'primary',
      'btn-primary rounded-left': type === 'rounded-left',
      'btn-primary rounded-right': type === 'rounded-right',
      'btn-secondary': type === 'secondary',
      'btn-success': type === 'success',
      'btn-light': type === 'light',
      'btn-transparent': type === 'transparent',
      'btn-danger': type === 'danger',
      'btn-transparent-gray': type === 'transparent-gray',
      'btn-dropdown': type === 'dropdown',
      'btn-dropdown-full': type === 'dropdown-full',
      'btn-dropdown-full primary': type === 'dropdown-full-primary',
      'btn-dropdown-full secondary': type === 'dropdown-full-secondary',
      'btn-custom': type === 'custom',
      'btn-bordered': type === 'bordered',
      'btn-underline': type === 'underline',
      inactive: inactive,
      'btn-absolute': absolute,
      'btn-highlight': highlighted,
    }"
  >
    <slot></slot>
  </component>
</template>

<script>
  export default {
    name: 'NewButton',
    inheritAttrs: false,
    props: {
      type: {
        type: String,
        default: 'primary',
        validator(value) {
          return [
            'primary',
            'secondary',
            'success',
            'light',
            'transparent',
            'danger',
            'transparent-gray',
            'dropdown',
            'dropdown-full',
            'dropdown-full-primary',
            'dropdown-full-secondary',
            'bordered',
            'custom',
            'underline',
            'rounded-left',
            'rounded-right',
          ].includes(value);
        },
      },
      highlighted: {
        type: Boolean,
        default: false,
      },
      size: {
        type: String,
        default: 'md',
        validator(value) {
          return ['md', 'lg', 'sm', 'xs'].includes(value);
        },
      },
      link: {
        type: Boolean,
        default: false,
      },
      icon: {
        type: Boolean,
        default: false,
      },
      inactive: {
        type: Boolean,
        default: false,
      },
      // temp : to override the old css buttons position
      absolute: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  body {
    &.new-ui {
      a {
        display: inline-block;
      }
      button:disabled {
        cursor: not-allowed;
        pointer-events: all !important;
        * {
          pointer-events: all !important;
        }
        &.btn-custom {
          cursor: default;
        }
      }
      .btn {
        @apply flex justify-center rounded-full font-medium leading-5;
      }
      .btn-primary {
        @apply bg-ds-button-primary-background text-white;
      }
      .btn-primary.rounded-left {
        @apply border-r rounded-l-full rounded-r-none border-white;
      }
      .btn-primary.rounded-right {
        @apply rounded-l-none rounded-r-full;
      }
      .btn-transparent {
        @apply bg-transparent;
      }
      .btn-success {
        @apply bg-success-100 text-white;
      }
      .btn-light {
        @apply text-main-color-100;
      }
      .btn-bordered {
        @apply text-main-color-100;
      }
      .btn-dropdown {
        @apply flex w-full items-center justify-start gap-x-1.5 rounded-xl bg-transparent text-sm font-normal;
        &:hover {
          @apply rounded-none;
        }
      }
      .btn-underline {
        @apply text-dark-mode-30;
        &:hover {
          @apply underline;
        }
      }
      &.mode--light {
        .btn-secondary {
          @apply bg-ds-button-secondary-background text-main-color-100;
        }
        .btn-dropdown-full {
          @apply border w-full items-center border-ds-outline-secondary bg-ds-foreground text-ds-text-secondary;
          &:disabled {
            @apply text-gray-80;
            svg {
              @apply text-gray-80;
            }
          }
        }
        .btn-dropdown-full.primary {
          @apply border-none bg-ds-button-primary-background text-ds-button-primary-label;
        }
        .btn-dropdown-full.secondary {
          @apply border-none bg-ds-button-secondary-background text-ds-button-secondary-label;
        }
        .btn:disabled {
          @apply bg-gray-60 text-white;
          &.btn-custom {
            @apply bg-transparent;
          }
        }
        .btn-transparent {
          @apply text-black;
        }
        .btn-danger {
          @apply bg-red-100 text-red-500;
        }
        .btn-transparent-gray {
          @apply text-gray-100;
        }
        .btn-light {
          @apply border border-gray-90 bg-white;
        }
        .btn-bordered {
          @apply border-1.5 border-ds-button-icon bg-transparent;
        }
        .btn-dropdown {
          @apply text-main-color-100;
          &:hover {
            @apply bg-main-color-50;
          }
          &.inactive {
            @apply bg-transparent text-gray-80;
            svg {
              @apply text-gray-80;
            }
          }
          &:disabled {
            @apply bg-transparent text-gray-80;
            svg {
              @apply text-gray-80;
            }
          }
        }
        .btn-highlight {
          @apply rounded-none bg-main-color-50;
        }
      }
      &.mode--dark {
        .btn:disabled {
          @apply bg-dark-mode-70 text-dark-mode-30;
          &.btn-custom {
            @apply bg-transparent;
          }
        }
        .btn-secondary {
          @apply bg-ds-button-secondary-background text-button-secondary-title;
        }
        .btn-dropdown-full {
          @apply border w-full items-center border-ds-outline-secondary bg-ds-button-secondary-background text-button-secondary-title;
          &:disabled {
            @apply text-dark-mode-30;
            svg {
              @apply text-dark-mode-30;
            }
          }
        }
        .btn-dropdown-full.primary {
          @apply border-none bg-ds-button-primary-background text-ds-button-primary-label;
        }
        .btn-dropdown-full.secondary {
          @apply border-none bg-ds-button-secondary-background text-ds-button-secondary-label;
        }
        .btn-transparent {
          @apply text-white;
        }
        .btn-danger {
          @apply bg-dark-mode-60 text-red-500;
        }
        .btn-transparent-gray {
          @apply text-dark-mode-15;
        }
        .btn-light {
          @apply bg-transparent;
        }
        .btn-bordered {
        @apply border-1.5 border-ds-button-icon bg-ds-background;
        }
        .btn-dropdown {
          @apply text-white;
          &:hover {
            @apply bg-dark-mode-80;
          }
          &.inactive {
            @apply bg-transparent text-dark-mode-30;
            svg {
              @apply text-dark-mode-30;
            }
          }
          &:disabled {
            @apply bg-transparent;
            svg {
              @apply text-dark-mode-30;
            }
          }
        }
        .btn-highlight {
          @apply rounded-none bg-dark-mode-80;
        }
      }
      // todo : remove this class after removing the old css
      //temporarily added  to override the old css buttons position
      .btn-absolute {
        @apply absolute;
      }
    }
  }
</style>
