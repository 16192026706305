<script>
  export default {
    name: 'minus-icon',
    props: {
      width: {
        type: String,
        default: '20px',
      },
      height: {
        type: String,
        default: '20px',
      },
    },
  };
</script>

<template>
  <svg :width="width" :height="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.50002 18.3334H12.5C16.6667 18.3334 18.3334 16.6667 18.3334 12.5V7.50002C18.3334 3.33335 16.6667 1.66669 12.5 1.66669H7.50002C3.33335 1.66669 1.66669 3.33335 1.66669 7.50002V12.5C1.66669 16.6667 3.33335 18.3334 7.50002 18.3334Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.49998 8.33333C8.42045 8.33333 9.16665 7.58714 9.16665 6.66667C9.16665 5.74619 8.42045 5 7.49998 5C6.57951 5 5.83331 5.74619 5.83331 6.66667C5.83331 7.58714 6.57951 8.33333 7.49998 8.33333Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.22498 15.7917L6.33331 13.0333C6.99164 12.5917 7.94164 12.6417 8.53331 13.15L8.80831 13.3917C9.45831 13.95 10.5083 13.95 11.1583 13.3917L14.625 10.4167C15.275 9.85834 16.325 9.85834 16.975 10.4167L18.3333 11.5833"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<style lang="scss" scoped>
  svg {
    stroke: var(--primary-blue);
  }
</style>
