import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import moment from 'moment';
import { Thread } from "./Thread";
const fb = require('../firebase');

class DraftThread extends Thread {
  constructor({
    id,
    tweets,
    user,
    isFavorite,
    conditionalRetweetsConditions,
    autoplug,
    source,
    writer,
    growthProgram,
    shareOnInstagram,
    linkedIn,
    facebook,
    delayBetweenTweets,
    categories,
    recurrentPostRef,
    replyToTweetId,
    replyToTweetInfo,
    instagramCaption,
    isRecurrentPost,
    publishingError,
    ghostwritingRefusal,
    ghostwritingStatus,
    autoDM,
    hasThreadFinisherTweet,
    created_at,
    isRecurrentPostDisabled,
    instagramThreadFinisherText,
    postIdToReplace,
    isLongTweetshot,
    isLargeFontTweetshot,
    threads,
  }) {
    super({
      id,
      tweets,
      user,
      publishingError,
      isFavorite,
      conditionalRetweetsConditions,
      autoplug,
      source,
      writer,
      growthProgram,
      shareOnInstagram,
      linkedIn,
      facebook,
      delayBetweenTweets,
      categories,
      recurrentPostRef,
      replyToTweetId,
      replyToTweetInfo,
      instagramCaption,
      isRecurrentPost,
      ghostwritingRefusal,
      ghostwritingStatus,
      autoDM,
      hasThreadFinisherTweet,
      created_at,
      isRecurrentPostDisabled,
      instagramThreadFinisherText,
      isLongTweetshot,
      isLargeFontTweetshot,
      threads,
    });
    this.postIdToReplace = postIdToReplace;
  }

  _delayThreadPostingTimeIfItIsTooClose() {
    return this;
  }

  _timeForFirestore() {
    return null;
  }

  toScheduledThread(time) {
    return new Thread({
      id: this.id,
      time,
      tweets: this.tweets,
      user: this.user.id,
      isFavorite: this.isFavorite,
      conditionalRetweetsConditions: this.conditionalRetweetsConditions,
      autoplug: this.autoplug,
      source: this.source,
      writer: this.writer ? this.writer.id : null,
      growthProgram: this.growthProgram,
      tweetshot: this.tweetshot,
      shareOnInstagram: this.shareOnInstagram,
      linkedIn: this.linkedIn,
      facebook: this.facebook,
      delayBetweenTweets: this.delayBetweenTweets,
      categories: Array.isArray(this.categories)
        ? this.categories.map((category) => category.id)
        : null,
      replyToTweetId: this.replyToTweetId,
      replyToTweetInfo: this.replyToTweetInfo,
      instagramCaption: this.instagramCaption,
      ghostwritingRefusal: this.ghostwritingRefusal,
      ghostwritingStatus: this.ghostwritingStatus,
      autoDM: this.autoDM,
      hasThreadFinisherTweet: this.hasThreadFinisherTweet,
      created_at: this.created_at,
      isRecurrentPostDisabled: this.isRecurrentPostDisabled,
      instagramThreadFinisherText: this.instagramThreadFinisherText,
      isLongTweetshot: this.isLongTweetshot,
      isLargeFontTweetshot: this.isLargeFontTweetshot,
      threads: this.threads,
    });
  }

  update() {
    if (!this.id) {
      throw Error(`This draft wasn't saved, so it can't be updated.`);
    }

    const updateMap = {
      tweets: this.tweets,
      updated_at: firebase.firestore.FieldValue.serverTimestamp(),
      isFavorite: this.isFavorite,
      shareOnInstagram: this.shareOnInstagram,
      linkedIn: this.linkedIn,
      facebook: this.facebook,
      delayBetweenTweets: this.delayBetweenTweets,
      categories: Array.isArray(this.categories)
        ? this.categories.map((categoryId) => fb.categoriesCollection.doc(categoryId))
        : null,
      recurrentPostRef: this.recurrentPostRef,
      replyToTweetId: this.replyToTweetId,
      replyToTweetInfo: this.replyToTweetInfo,
      instagramCaption: this.instagramCaption,
      autoplug: this.autoplug,
      ghostwritingRefusal: this.ghostwritingRefusal,
      ghostwritingStatus: this.ghostwritingStatus,
      conditionalRetweetsConditions: this.conditionalRetweetsConditions,
      autoDM: this.autoDM,
      hasThreadFinisherTweet: this.hasThreadFinisherTweet,
      isRecurrentPost: this.isRecurrentPost,
      isRecurrentPostDisabled: this.isRecurrentPostDisabled,
      instagramThreadFinisherText: this.instagramThreadFinisherText,
      time: this.time,
      isLongTweetshot: this.isLongTweetshot,
      isLargeFontTweetshot: this.isLargeFontTweetshot,
      threads: this.threads,
    };

    return fb.threadsCollection.doc(this.id).update(updateMap)
      .catch(error => {
        console.error('An error has occurred while updating the draft thread.', error);
        throw error;
      })
  }

  static buildFromFirestore(doc) {
    const tweets = doc.data().tweets.map((tweet) => {
      if (!tweet.media) tweet.media = [];
      return tweet;
    });

    return new DraftThread({
      id: doc.id,
      tweets,
      user: doc.data().user,
      isFavorite: doc.data().isFavorite,
      conditionalRetweetsConditions: doc.data().conditionalRetweetsConditions,
      autoplug: doc.data().autoplug,
      source: doc.data().source,
      writer: doc.data().writer,
      growthProgram: doc.data().growthProgram,
      shareOnInstagram: doc.data().shareOnInstagram,
      linkedIn: doc.data().linkedIn,
      facebook: doc.data().facebook,
      delayBetweenTweets: doc.data().delayBetweenTweets,
      categories: doc.data().categories,
      recurrentPostRef: doc.data().recurrentPostRef,
      replyToTweetId: doc.data().replyToTweetId,
      replyToTweetInfo: doc.data().replyToTweetInfo,
      instagramCaption: doc.data().instagramCaption,
      isRecurrentPost: doc.data().isRecurrentPost,
      publishingError: doc.data().publishingError,
      ghostwritingRefusal: doc.data().ghostwritingRefusal,
      ghostwritingStatus: doc.data().ghostwritingStatus,
      autoDM: doc.data().autoDM,
      hasThreadFinisherTweet: doc.data().hasThreadFinisherTweet,
      created_at: moment(doc.data().created_at.toDate()),
      isRecurrentPostDisabled: doc.data().isRecurrentPostDisabled,
      instagramThreadFinisherText: doc.data().instagramThreadFinisherText,
      postIdToReplace: doc.data().postIdToReplace,
      isLongTweetshot: doc.data().isLongTweetshot,
      isLargeFontTweetshot: doc.data().isLargeFontTweetshot,
      threads: doc.data().threads,
    });
  }
}

export { DraftThread };
