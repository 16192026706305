<script>
import dao from '@/dao';
import * as linkify from 'linkifyjs';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({ userProfile: 'getUserProfile' }),
  },
  data() {
    return {
      tweets: null,
      ...this.tweetshotMixinInitialState(),
    };
  },
  async mounted() {
    const mostRecentThreads = await dao.posts.getMostRecentThreads(this.userProfile);
    const tweets = mostRecentThreads.filter(t => Array.isArray(t.tweets) && t.tweets.length > 0).flatMap((thread) => thread.tweets);
    const filteredTweets = tweets.filter((tweet) => {
      const tweetLinks = linkify
        .find(tweet.status)
        .filter((link) => link.type === 'url');
      const doesTweetHaveLinks = tweetLinks.length > 0;
      return !doesTweetHaveLinks && tweet.tweetId;
    });
    this.tweets = filteredTweets;
  },
  methods: {
    tweetshotMixinInitialState() {
      return {
        isTweetshotMode: false,
        tweetshotContent: [],
      };
    },
    setTweetshotSettings(tweet) {
      const index = this.tweetshotContent.findIndex(
        (content) => content.tweetId === tweet.tweetId
      );
      if (index >= 0) {
        this.tweetshotContent.splice(index, 1);
      } else if (this.tweetshotContent.length < 9) {
        this.tweetshotContent.push({
          tweetId: tweet.tweetId,
          tweetText: tweet.status,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.tweetshot {
  position: relative;

  ::v-deep a {
    color: inherit;
  }

  .arrow {
    width: 0;
    height: 0;
    border-top: 16px solid var(--tweetshot);
    border-bottom: 16px solid transparent;
    border-left: 16px solid var(--tweetshot);
    position: absolute;
    bottom: -16px;
    clip-path: polygon(100% 50%, 0 50%, 0 100%);
  }
}

.selected-tweet {
  @apply border-2 border-accent rounded-lg;
}
</style>
