import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import moment from 'moment';
import 'moment-timezone';
import { Post } from './Post';
import controller from '@/controller';

const fb = require('../firebase');

// This file is a duplicate of '/functions/src/models/Retweet.js'
class Retweet extends Post {
  constructor(id, tweetId, time, user, scheduled, createdAt, publishingError, retweetId,
              originalTweetInfo, deleted, conditionalRetweetsConditions) {
    super(id, time, scheduled, user, publishingError, deleted, conditionalRetweetsConditions);
    this.tweetId = tweetId;
    this.createdAt = createdAt;
    this.type = 'retweet';
    this.retweetId = retweetId;
    this.originalTweetInfo = originalTweetInfo;
    this.conditionalRetweetsConditions = conditionalRetweetsConditions;
  }

  static newRetweet(tweetId, time, user, originalTweetInfo) {
    return new Retweet(null, tweetId, time, user, null, null, null,
      null, originalTweetInfo, false, null);
  }

  saveToFirestore(eventStore) {
    return fb.threadsCollection
      .add({
        tweetId: this.tweetId,
        time: this._timeForFirestore(),
        created_at: firebase.firestore.FieldValue.serverTimestamp(),
        scheduled: false,
        user: fb.usersCollection.doc(this.user.uid),
        type: 'retweet',
        originalTweetInfo: this.originalTweetInfo,
        deleted: false,
        publishingError: null,
        postNow: false,
        conditionalRetweetsConditions: null,
      }).then((result) => {
        eventStore.scheduleRetweet();
        return result;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  _timeForFirestore() {
    return moment(this.time).toDate();
  }

  update() {
    if (!this.id) throw Error('This retweet wasn\'t saved, so it can\'t be updated.');

    const updateMap = {
      tweetId: this.tweetId,
      time: this._timeForFirestore(),
      scheduled: this.scheduled,
      updated_at: firebase.firestore.FieldValue.serverTimestamp(),
      deleted: this.deleted,
      originalTweetInfo: this.originalTweetInfo,
      growthProgramIsValidated: this.growthProgramIsValidated ? this.growthProgramIsValidated : null,
    };

    return fb.threadsCollection.doc(this.id).update(updateMap)
      .catch(error => {
        console.error(error);
        alert('An error has occurred while updating the retweet.')
      });
  }

  hasErrors() {
    if (this.publishingError) return true;

    return false;
  }

  isEmpty() {
    return false;
  }

  couldNotBePostedBecauseNotPremium() {
    return this.hasErrors() && this.publishingError[0].message &&
      this.publishingError[0].message.includes('Only premium');
  }

  doPostNow(firebaseUser, currentUserId) {
    if (!this.id) throw Error("This retweet wasn't saved, so it can't be updated.");
    return controller.posts.postNow(firebaseUser, currentUserId, this.id).catch((error) => {
      console.error(error);
      alert('An error has occurred while instantly posting the retweet.');
    });
  }

  static buildFromFirestore(doc, timezone) {
    return new Retweet(doc.id,
      doc.data().tweetId,
      moment.tz(doc.data().time.seconds * 1000, timezone),
      doc.data().user,
      doc.data().scheduled,
      doc.data().createdAt,
      doc.data().publishingError,
      doc.data().retweetId,
      doc.data().originalTweetInfo,
      doc.data().deleted,
      doc.data().conditionalRetweetsConditions)
  }

}

export { Retweet };
