const moment = require('moment');

export default class LinkedInSlot {
  slotType = 'linkedin';

  constructor(time) {
    this.time = time;
    this.midnight = moment(time).startOf('day').toISOString();
  }

  isEmpty() {
    return false;
  }
}
