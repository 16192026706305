<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    :class="[charactersLeft <= warningCharacters ? charactersLeftClass : 'strokeColor']"
    xmlns="http://www.w3.org/2000/svg"
    data-cy="chars-counter"
  >
    <path
      d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
      class="circle-bg"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      v-if="percent < 100"
    />
    <path
      d="M12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 9.21805 2.03272 6.6773 3.7357 4.74021C4.69795 3.64569 5.87418 2.7439 7.19902 2.10021"
      stroke-miterlimit="10"
      stroke-linejoin="round"
      v-if="percent >= 0 && percent < 100"
      class="circle"
      :stroke-dasharray="`${percent}, 100`"
    />
    <text
      v-if="charactersLeft <= warningCharacters"
      x="50%"
      y="50%"
      text-anchor="middle"
      alignment-baseline="middle"
      font-size="12px"
    >
      {{ charactersLeft }}
    </text>
  </svg>
</template>

<script>
  export default {
    name: 'CharcterCounter',
    props: {
      warningCharacters: {
        type: Number,
        default: 20,
      },
      maximumCharacters: {
        type: Number,
        default: 280,
      },
      textLength: {
        type: Number,
        default: 0,
      },
    },
    computed: {
      percent() {
        let percent = (this.textLength / this.maximumCharacters) * 100;
        return percent.toFixed(1);
      },
      charactersLeftClass() {
        const statusLength = this.textLength;
        if (statusLength >= this.maximumCharacters) return 'character-count-exceeded';
        if (statusLength > this.maximumCharacters - this.warningCharacters)
          return 'character-count-warning';
        return 'strokeColor';
      },
      charactersLeft() {
        return this.maximumCharacters - this.textLength;
      },
    },
  };
</script>

<style lang="scss">
  .circle-bg {
    fill: none;
    stroke-width: 1.5;
  }

  .mode--dark {
    .circle-bg {
      stroke: #4c5160;
    }
  }

  .mode--light {
    .circle-bg {
      stroke: #f2f2f2;
    }
  }

  .circle {
    fill: none;
    stroke-width: 1.5;
    stroke-linecap: round;
  }

  .strokeColor {
    stroke: #0078fb;
  }
</style>
