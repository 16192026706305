<template>
  <div
    @click="onClick($event)"
    class="input-container"
    :class="{
      radio: type === 'radio',
      button: type === 'button',
      tag: type === 'tag',
      dark: type === 'dark',
      active: checked,
      inactive: !checked,
      disabled: disabled,
      sm: size === 'sm',
      md: size === 'md',
    }"
  >
    <input
      ref="input"
      type="radio"
      :name="name"
      :disabled="disabled"
      :checked="checked"
      :value="value"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
    />
    <label v-if="$slots.default">
      <slot />
    </label>
  </div>
</template>

<script>
  import { isEqual } from 'lodash';
  export default {
    name: 'NewBaseRadio',
    model: {
      prop: 'modelValue',
      event: 'change',
    },
    props: {
      value: {
        type: [String, Number, Boolean, Object],
        default: null,
      },
      modelValue: {
        type: [String, Number, Boolean, Object],
        default: null,
      },
      name: {
        type: String,
        default: null,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: 'radio',
        validator: (value) => ['radio', 'button', 'tag', 'dark'].includes(value),
      },
      size: {
        type: String,
        default: 'md',
        validator: (value) => ['md', 'sm'].includes(value),
      },
    },
    methods: {
      onClick(event) {
        if (!this.disabled) {
          this.$emit('click', event);
          this.$refs.input.focus();
          if (!this.checked) {
            this.$emit('change', this.value);
          }
        }
      },
    },
    computed: {
      checked() {
        return this.value !== null && isEqual(this.modelValue, this.value);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .new-ui {
    .input-container {
      @apply relative z-50 cursor-pointer;
      &.button {
        @apply border relative flex cursor-pointer justify-center rounded-full bg-transparent leading-5;
        label {
          @apply cursor-pointer;
        }
        &.active {
          @apply border-main-color-100 bg-main-color-100 text-white;
          label {
            @apply text-white;
          }
        }
        &.sm {
          @apply px-3 py-1.5 text-sm;
        }
        &.md {
          @apply px-4 py-2 text-base;
        }
        input {
          @apply absolute inset-0 h-px w-px  opacity-0;
        }
      }
      &.tag {
        @apply inline-flex cursor-pointer items-center rounded-full text-xs;
        label {
          @apply cursor-pointer;
        }
        &.active {
          @apply bg-main-color-100 text-white;
        }
        &.sm {
          @apply px-3 py-1.5 text-sm;
        }
        &.md {
          @apply px-4 py-2 text-base;
        }
        input {
          @apply absolute inset-0 h-px w-px  opacity-0;
        }
      }
      &.dark {
        @apply inline-flex cursor-pointer items-center rounded-lg text-xs;
        label {
          @apply w-full cursor-pointer text-center ;
        }
        &.active {
          @apply bg-ds-button-primary-background text-ds-button-primary-label;
        }
        &.inactive {
          @apply bg-ds-button-secondary-background;

          label{
            @apply text-ds-text-secondary;
          }
        }
        &.sm {
          @apply px-2 py-1 text-sm;
        }
        &.md {
          @apply px-3 py-2 text-base;
        }
        input {
          @apply absolute inset-0 h-px  w-px opacity-0;
        }
      }
      &.radio {
        @apply inline-flex cursor-pointer items-center;
        label {
          @apply cursor-pointer;
        }
        input {
          @apply grid h-6 w-6 appearance-none place-content-center rounded-full border-2 border-gray-100 text-current;
          &::before {
            @apply h-4 w-4 transform rounded-full transition-transform duration-150 ease-in-out;
            content: '';
            transform: scale(0);
          }
          &:checked::before {
            transform: scale(1);
          }
        }
      }
    }
    &.mode--light {
      .input-container {
        &.button {
          @apply border-0 bg-gray-100;
          &.active {
            @apply border-main-color-100 bg-main-color-100;
          }
        }
        &.tag {
          @apply bg-gray-100;
          &.active {
            @apply bg-main-color-100;
            label {
              @apply text-white;
            }
          }
        }
        &.dark {
          @apply border border-gray-90 bg-ds-button-secondary-background;
          &.active {
            @apply bg-ds-button-primary-background;
            label {
              @apply text-white;
            }
          }
        }
        &.radio {
          input {
            @apply border-gray-100 bg-white;
            &::before {
              @apply bg-black;
              box-shadow: inset 1rem 1rem black;
            }
            &:checked {
              @apply border-black;
            }
          }
        }
      }
    }
    &.mode--dark {
      .input-container {
        &.button {
          @apply border-dark-mode-30;
          &.active {
            @apply border-main-color-100;
            label {
              @apply text-white;
            }
          }
        }
        &.tag {
          @apply bg-dark-mode-70;
          &.active {
            @apply bg-main-color-100;
            label {
              @apply text-white;
            }
          }
        }
        &.radio {
          input {
            @apply border-gray-20 bg-transparent;
            &::before {
              @apply bg-black;
              box-shadow: inset 1rem 1rem white;
            }
            &:checked {
              @apply border-white;
            }
          }
        }
      }
    }
  }
</style>
