var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.tweet.mediaFile),function(media,index){return _c('portal',{key:index,attrs:{"to":_vm.portalTargetName}},[_c('div',{staticClass:"app-create-image-desc relative flex w-full items-center justify-between rounded-lg bg-gray-10",class:{
        'px-2 py-0.5': _vm.containerSize === 'sm',
        'p-2': _vm.containerSize === 'md',
      }},[_c('div',{staticClass:"upload-container flex-shrink-0 mr-4",on:{"click":function($event){_vm.triggerFileUpload(_vm.tweet.mediaFile.indexOf(media))}}},[(media.type === 'image' || media.type === 'gif')?_c('img',{attrs:{"src":media.url}}):_vm._e(),(media.type === 'video' && media._type !== 'youtube-short')?_c('video',{attrs:{"disabledRemotePlayback":""}},[_c('source',{attrs:{"src":media.url}})]):_vm._e(),(
            media.type === 'video' && media._type === 'youtube-short' && media.status === 'done'
          )?_c('video',{attrs:{"disabledRemotePlayback":""}},[_c('source',{attrs:{"src":media.url}})]):(media._type === 'youtube-short' && media.status === 'rendering')?_c('img',{attrs:{"src":media.thumbnail}}):_vm._e()]),(!['instagram'].includes(_vm.postType))?_c('base-input',{key:index,ref:"textarea-container",refInFor:true,attrs:{"alternative":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.altTextArray[index]),expression:"altTextArray[index]"}],staticClass:"form-control simple",class:{
            'container-input-sm': _vm.containerSize === 'sm',
            'container-input-md': _vm.containerSize === 'md',
          },attrs:{"data-cy":"image-description-input","rows":"3","placeholder":`Add ${media.type} ${_vm.postType === 'linkedin' || media.type === 'video' ? 'title' : 'description'}`,"type":"text"},domProps:{"value":(_vm.altTextArray[index])},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.altTextArray, index, $event.target.value)},function($event){return _vm.updateAltText(_vm.tweet, index)}]}})]):(_vm.showLinkedInBlock && _vm.imageCountInThread > 0)?_c('div',{staticClass:"flex text-muted text-xs space-x-2 items-center w-full"},[_c('inline-svg',{attrs:{"src":"/img/icons/info.svg"}}),_c('span',[_c('em',[_vm._v("Video will not be shared to LinkedIn when the thread has images.")])])],1):_vm._e(),(_vm.uploadingMedia.includes(_vm.tweet.mediaFile.indexOf(media)))?_c('span',[_c('inline-svg',{staticClass:"animate-spin",attrs:{"src":"/img/icons/loading.svg"}})],1):(!_vm.hideInput)?_c('div',{staticClass:"cursor-pointer opacity-25",attrs:{"data-cy":"delete-image","id":"remove-media"},on:{"click":function($event){_vm.clearMediaSelectionAtIndex(_vm.tweet, _vm.tweet.mediaFile.indexOf(media))}}},[_c('i',{staticClass:"fas fa-times-circle"})]):_vm._e()],1)])}),(!_vm.hideInput)?_c('div',{staticClass:"upload-icon col-3 px-0"},[_c('div',{staticClass:"btn",class:{
        disabled: !_vm.canAddMedia || _vm.disabled,
      },on:{"click":function($event){return _vm.triggerFileUpload(_vm.tweet.mediaFile.length)}}},[_c('div',{staticClass:"w-5 h-5 cursor-pointer"},[_c('gallery-icon')],1)]),_c('div',{staticClass:"image-preview text-center add-image-icon d-flex align-items-center justify-content-center flex-wrap"})]):_vm._e(),_c('input',{ref:"uploadBtn",staticClass:"hidden-file-input",attrs:{"data-cy":"upload-input","type":"file","multiple":""},on:{"change":function($event){return _vm.filesChange(_vm.tweet, $event.target.files, $event)}}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }