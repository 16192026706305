const moment = require('moment');

export default class FacebookSlot {
  slotType = 'facebook';

  constructor(time) {
    this.time = time;
    this.midnight = moment(time).startOf('day').toISOString();
  }

  isEmpty() {
    return false;
  }
}
