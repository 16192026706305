<template>
  <portal to="isLoading">
    <div class="overlay" v-if="isLoading">
      <div class="loader fixed">
        <span class="loading loading-lg"
          ><inline-svg src="/img/icons/loading.svg"></inline-svg
        ></span>
      </div>
    </div>
  </portal>
</template>

<script>
  import controller from '@/controller';
  import { getPricesForUser, getStripePricesForUser } from '@/util/getPricesForUser';
  import lodash from 'lodash';
  import { mapGetters } from 'vuex';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import CustomerCountryMixin from '@/views/Mixins/CustomerCountryMixin.vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import { v4 as publicIpv4 } from 'public-ip';
  import CustomerStatusMixin from '../views/Mixins/CustomerStatusMixin.vue';

  const fb = require('@/firebase');
  export default {
    data() {
      return {
        isLoading: false,
        unsubscribe: null,
      };
    },
    name: 'upgrade-to-premium-pop-up',
    computed: {
      ...mapGetters({
        currentUser: 'getCurrentUser',
        userProfile: 'getUserProfile',
        parentUserProfile: 'getParentUserProfile',
      }),
    },
    props: {
      content: {
        type: String,
      },
      contentForNonSubscribers: {
        type: String,
      },
      show: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      close() {
        this.$emit('close');
      },
      async upgradeUserToPremium(newPriceInfo = null) {
        try {
          this.$emit('upgrading');
          this.parentUserProfile.isStripeCustomer
            ? await controller.upgradeStripeUserToPremium(this.currentUser)
            : await controller.upgradeUserToPremium(this.currentUser, newPriceInfo);
          this.isLoading = true;
          this.unsubscribe = await fb.usersCollection
            .doc(this.userProfile.uid)
            .onSnapshot((doc) => {
              const customerStatus = lodash.get(doc.data(), 'customerStatus');
              if (customerStatus === 'premium') {
                const message = `Upgraded to ${this.getPlanLabel('premium')} successfully!`;
                this.$notify({ type: 'success', message });
                this.unsubscribe();
                this.isLoading = false;
                this.$emit('continue-operation');
                return;
              }
            });
          this.$eventStore.updateUserSubscription.updateToPremium();
        } catch (error) {
          this.isLoading = false;
          this.swalModal({
            title: 'Sorry',
            text: 'An error occurred while upgrading your account.',
            type: 'error',
          });
          console.error(error);
          return true;
        } finally {
          this.isLoading = false;
        }
      },
    },
    watch: {
      async show(val, old) {
        if (old === null) return;
        if (val) {
          if (this.isUserNotSubscribed(this.parentUserProfile.customerStatus)) {
            const message =
              this.contentForNonSubscribers ||
              `Grab a subscription to the ${this.getPlanLabel(
                'premium'
              )} plan and unlock this and a ton of other powerful features!`;

            return this.swalModal({
              title: `Unlock this feature`,
              html: message,
              type: 'info',
              confirmButtonText: 'Go to Billing',
              cancelButtonText: 'No, thanks',
              showCancelButton: true,
              onClose: this.close,
              preConfirm: () => {
                this.$router.push('/billing');
                return true;
              },
            });
          }

          try {
            this.isLoading = true;
            await this.getCustomerCountry();
            const customerIp = await publicIpv4();
            const prices = this.parentUserProfile.isStripeCustomer
              ? (await controller.getStripePlansPrices(this.currentUser)).prices
              : await controller.getPlansPrices(this.currentUser, customerIp);

            this.isLoading = false;

            const { premium, standard, basic } = this.parentUserProfile
              .isStripeCustomer
              ? getStripePricesForUser(this.parentUserProfile, prices)
              : getPricesForUser(this.parentUserProfile, prices);

            let discountMessage = '';
            let newPriceInfo = null;

            let priceComparisonToShow = standard;
            if (this.parentUserProfile.customerStatus === 'basic') {
              priceComparisonToShow = basic;
            }

            const message =
              this.content ||
              `Upgrade to the ${this.getPlanLabel(
                'premium'
              )} plan to unlock a ton of powerful features!`;

            this.swalModal({
              title: `Unlock this feature`,
              html:
                discountMessage +
                message +
                `<br>You will be billed <b>${premium}</b> instead of ${priceComparisonToShow}.`,
              type: 'info',
              confirmButtonText: 'Yes, I want the best plan!',
              cancelButtonText: "No, thanks, I'm good",
              showCancelButton: true,
              allowOutsideClick: false,
              onClose: this.close,
              preConfirm: async () => {
                if (this.userProfile.customerStatus === 'none') {
                  this.$router.push('/billing');
                  return true;
                }
                Swal.getConfirmButton().textContent = 'Upgrading...';
                Swal.getCloseButton().disabled = true;
                await this.upgradeUserToPremium(newPriceInfo);
                return true;
              },
            });
          } catch (error) {
            console.error(`An error occurred while getting plan prices: ${error}`);
          } finally {
            this.isLoading = false;
          }
        }
      },
    },
    mixins: [SwalModalMixin, CustomerCountryMixin, CustomerStatusMixin],
  };
</script>
