<script>
  /* eslint-disable no-useless-escape */
  import { clone } from 'lodash';
  import moment from 'moment';
  import 'moment-timezone';
  import 'moment/min/locales';
  import controller from '@/controller';
  import dao from '@/dao';
  import lodash from 'lodash';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import { getSelectionText } from '@/util/getSelectionText';
  import { countTweetLength } from '@/../functions/src/util/countTweetLength';
  import HighlightWithinTextarea from '@/util/highlightWithinTextarea';
  import { splitTextIntoTweets } from '@/util/splitTextIntoTweets';
  import { v1 as uuidv1 } from 'uuid';
  import { formatTweet } from '@/util/formatTweet';
  import browserLocale from 'browser-locale';
  import TimeFormatterMixin from './TimeFormatterMixin.vue';
  import { setUnfinishedPost } from '../../util/unfinishedPosts';
  import { defaultThreadFinisherText } from "@/views/Composer/config";
  import CustomerStatusMixin from './CustomerStatusMixin.vue';

  const fb = require('../../firebase');

  export default {
    data() {
      return {
        autoplugStatus: '',
        autoplugTemplate: null,
        autoplugMinFavorites: 0,
        autoplugMinRetweets: 100,
        delayBetweenTweets: 0,
        delayBetweenTweetsEnabled: false,
        focusModeStatus: '',
        isFocusModeEnabled: false,
        isPostDropdownOpen: false,
        isGifUploading: false,
        showTimeBox: false,
        focusOnTweetIndex: 0,
        slotLength: 0,
        shareOnInstagram: false,
        instagramCaption: '',
        instagramThreadFinisherText: null,
        autoplugEnabled: false,
        conditionalRetweetEnabled: false,
        conditionalRetweetType: 'Retweets',
        showCategoryBlock: false,
        autoplugType: 'Likes',
        previousStatusesCount: 1,
        shouldUpdateTime: false,
        wasTimeUpdatedManually: false,
        isAutoDMEnabled: false,
        autoDMMessages: [{ reply: '', content: '' }],
        autoDMTriggerOptions: ['reply', 'retweet'],
        autoDMTags: [],
        autoDMrequiresOnlyOneOption: false,
        linkedInPostType: 'text',
        linkedInThreadFinisherText: '',
        isLinkedInThreadFinisherEnabled: false,
      };
    },
    computed: {
      oneDayFromNow() {
        return moment.tz(this.timezone).add(1, 'day').toDate();
      },
      canAddMoreVideos: function () {
        return (
          Array.isArray(this.tweets) &&
          this.tweets.filter((t) => t.mediaFile[0] && t.mediaFile[0].type.includes('video'))
            .length < 10
        );
      },
      imageCountInThread() {
        let count = 0;
        Array.isArray(this.tweets) && this.tweets.forEach((tweet) => {
          count += tweet.mediaFile.filter((media) => media.type.toLowerCase().includes('image')).length;
        });
        return count;
      },
      canDeleteTweet() {
        return Array.isArray(this.tweets) && this.tweets.length > 1;
      },
      canShareOnInstagram: function () {
        const canShare =
          Array.isArray(this.tweets) &&
          (this.hasThreadFinisherTweet ? this.tweets.length <= 11 : this.tweets.length <= 10) &&
          this.tweets.every((tweet) => tweet.mediaFile.length === 0);
        return canShare;
      },
      canShareOnLinkedIn: function () {
        const videoCounter = this.tweets.reduce(
          (acc, val) =>
            acc +
            val.mediaFile.filter((media) => media.type.toLowerCase().includes('video')).length,
          0
        );
        return (
          (videoCounter === 1 && this.imageCountInThread === 0) ||
          (videoCounter === 0 && this.imageCountInThread <= 9)
        );
      },
      canAddMediaToLinkedIn() {
        if (Array.isArray(this.tweets)) {
          const videoCounter = this.tweets.reduce(
            (acc, val) =>
              acc +
              val.mediaFile.filter((media) => media.type.toLowerCase().includes('video')).length,
            0
          );
          return videoCounter === 0 && this.imageCountInThread < 9;
        }
        return false;
      },
      canAddMediaToFacebook() {
        if (Array.isArray(this.tweets)) {
          /*
           * We are counting videos and GIFs attached to the thread to see if we can add another Media
           * since Facebook allows only one GIF, one video, or multiple images per post
           * This computed property is used when Facebook cross posting feature is enabled
           */
          const videoCounter = this.tweets.reduce(
            (acc, val) =>
              acc +
              val.mediaFile.filter((media) => media.type.toLowerCase().includes('video')).length,
            0
          );

          const gifCounter = this.tweets.reduce(
            (acc, val) =>
              acc +
              val.mediaFile.filter((media) => media.type.toLowerCase().includes('gif')).length,
            0
          );

          if (gifCounter >= 1 || videoCounter >= 1) return false;

          return true;
        }
        return false;
      },
      canShareOnFacebook() {
        if (Array.isArray(this.tweets)) {
          /*
           * We are counting videos and GIFs attached to the thread to see if we can add another Media
           * since Facebook allows only one GIF, one video, or multiple images per post
           * This computed property determines if Facebook cross posting can be enabled when it is disabled
           */

          const videoCounter = this.tweets.reduce(
            (acc, val) =>
              acc +
              val.mediaFile.filter((media) => media.type.toLowerCase().includes('video')).length,
            0
          );

          const gifCounter = this.tweets.reduce(
            (acc, val) =>
              acc +
              val.mediaFile.filter((media) => media.type.toLowerCase().includes('gif')).length,
            0
          );

          if (gifCounter > 1 || videoCounter > 1 || (gifCounter >= 1 && videoCounter >= 1)) {
            return false;
          }

          if ((gifCounter >= 1 || videoCounter >= 1) && this.imageCountInThread > 0) return false;

          return true;
        }
        return false;
      },
      noEmptySlots() {
        return !this.timeFromParent && !this.threadToEdit;
      },
      linkedInInfo() {
        if (this.linkedInPostType === 'carousel') {
          return {
            postType: this.linkedInPostType,
            ...(this.linkedInThreadFinisherText ? { threadFinisherText: this.linkedInThreadFinisherText } : {}),
          };
        } else {
          return {
            text: this.showLinkedInBlock ? this.linkedInText : null,
            isPostPublic: this.linkedInIsPostPublic,
            idsToPostAs: this.selectedLinkedInIds || [],
            postType: this.linkedInPostType,
          };
        }
      },
      categories() {
        if (!this.selectedCategories) {
          return [];
        }
        if (!this.showCategoryBlock) {
          return [];
        }
        return this.selectedCategories.map((categoryId) => {
          return fb.categoriesCollection.doc(categoryId);
        });
      },
      facebookInfo() {
        return {
          text: this.showFacebookBlock ? this.facebookText : null,
        };
      },
      isThreadFinisherDisabled() {
        return this.hasThreadFinisherTweet || this.tweets.length < 2;
      },
      scheduledDateTimeText() {
        return (
          'Scheduled for ' +
          moment(this.time).locale(browserLocale()).format('ddd Do MMM, YYYY') +
          ' at ' +
          this.formatTimeWithUserLocale(moment(this.time))
        );
      },
      scheduledDateTimeTextSimplyFormed() {
        return moment(this.time).locale(browserLocale()).format('ddd L, LT');
      },
      timeForFromPage() {
        return moment(this.time).toDate();
      },
      timezone() {
        return lodash.get(this, 'userProfile.timezone', moment.tz.guess());
      },
      hasMedia() {
        return Boolean(Array.isArray(this.tweets) && this.tweets.find((t) => t.mediaFile[0]));
      },
      isLinkedInEnabled() {
        return (
          lodash.get(this.linkedInInfo, 'text') ||
          (lodash.get(this.linkedInInfo, 'postType') === 'carousel' && this.showLinkedInBlock)
        );
      },
      areImagesFormatsSupported() {
        const mediaFiles = this.tweets.map((tweet) => tweet.mediaFile || []).flat();

        const areImagesFormatsSupported = mediaFiles.filter(f => f.type.includes('image')).every((file) =>
          ['image/jpeg', 'image/png', 'image/gif'].includes(file.mimeType)
        );

        return !this.showLinkedInBlock || areImagesFormatsSupported;
      },
    },
    methods: {
      addThreadFinisherTweet(focusOnTweetIndex, isThreadFinisher = false) {
        const lastTweet = lodash.last(this.tweets);
        this.addTweetBox(lastTweet, focusOnTweetIndex, isThreadFinisher);
        this.hasThreadFinisherTweet = true;
        this.isThreadFinisherPromptHidden = true;
      },
      postNow(post) {
        const that = this;

        const postType = (() => {
          if (post.source === 'instagram-post') {
            return 'Instagram';
          } else if (post.source === 'linkedin-post') {
            return 'LinkedIn';
          } else if (post.source === 'facebook-post') {
            return 'Facebook';
          } else if (post.source === 'threads-post') {
            return 'Threads';
          } else {
            return 'Twitter';
          }
        })();

        this.swalModal({
          title: `Post Now`,
          text: `Are you sure you want to post to ${postType} now?`,
          type: 'question',
          showCancelButton: true,
          confirmButtonText: 'Yes, post now',
          preConfirm: () => {
            post
              .doPostNow(that.currentUser, that.userProfile.uid)
              .then(() => {
                let tweetType = 'Tweet';
                if (post.tweets === undefined) {
                  tweetType = 'Retweet';
                } else if (post.tweets.length > 1) {
                  tweetType = 'Thread';
                }

                const message = `${
                  post.source === 'instagram-post' ? 'Instagram post' : tweetType
                } on its way!`;
                that.$notify({ type: 'success', message: message });
              })
              .catch((error) => {
                this.swalModal({
                  title: 'Sorry',
                  text: `An error has occurred while deleting the post.`,
                  type: 'warning',
                });
                console.error(error);
              });
          },
        });
      },
      addTweetBox: function (tweet, focusOnTweetIndex, isThreadFinisher) {
        const tweets = clone(this.tweets);
        const position = tweet.count + 1;

        for (let i = tweets.length - 1; i >= position; i--) {
          tweets[i + 1] = tweets[i];
          tweets[i + 1].count++;
        }

        const emptyTweet = this.emptyTweet(position);

        if (isThreadFinisher) {
          const savedThreadFinisherText = lodash.get(this.userProfile, 'settings.threadFinisherText');
          emptyTweet.status = savedThreadFinisherText || defaultThreadFinisherText(this.userProfile.username);
        }

        tweets[position] = emptyTweet;
        tweets.sort((a, b) => a.count - b.count);
        this.tweets = tweets;

        const oldFocusOnTweetIndex = this.focusOnTweetIndex;
        const newFocusOnTweetIndex = !lodash.isNil(focusOnTweetIndex)
          ? focusOnTweetIndex
          : position;

        if (oldFocusOnTweetIndex === newFocusOnTweetIndex) {
          // When deleting two tweets with the same index in a row the watcher won't trigger
          this.focusOnTweetIndex = null;
        }

        setTimeout(() => {
          this.focusOnTweetIndex = newFocusOnTweetIndex;
        }, 0);
      },
      autoSelectNewCategory(categoryId) {
        this.selectedCategories.push(categoryId);
      },
      processNewAutoplugValues(
        show,
        status,
        minRetweets,
        minFavorites,
        template,
        wasTemplateEdited,
        autoplugType
      ) {
        this.autoplugEnabled = show;
        this.autoplugStatus = status;
        this.autoplugMinRetweets = minRetweets;
        this.autoplugMinFavorites = minFavorites;
        this.autoplugTemplate = template;
        this.autoplugWasTemplateEdited = wasTemplateEdited;
        this.autoplugType = autoplugType;
        this.isAdvancedOptionsModalVisible = false;
      },
      processNewAutoDMValues(
        show,
        triggerOptions,
        messages,
        tags,
        requiresOnlyOneOption,
      ) {
        this.isAutoDMEnabled = show;
        this.autoDMTriggerOptions = lodash.clone(triggerOptions);
        this.autoDMMessages = lodash.cloneDeep(messages);
        this.autoDMTags = lodash.clone(tags);
        this.isAdvancedOptionsModalVisible = false;
        this.autoDMrequiresOnlyOneOption = requiresOnlyOneOption;
      },
      areAutoplugConditionsValid() {
        const areMinFavoritesConditionsValid =
          this.autoplugType === 'Likes' &&
          !lodash.isNil(this.autoplugMinFavorites) &&
          Number(this.autoplugMinFavorites) >= 0;
        const areMinRetweetsConditionsValid =
          this.autoplugType === 'Retweets' &&
          !lodash.isNil(this.autoplugMinRetweets) &&
          Number(this.autoplugMinRetweets) >= 0;

        if (this.autoplugEnabled) {
          return (
            (areMinFavoritesConditionsValid || areMinRetweetsConditionsValid) &&
            this.autoplugStatus &&
            this.autoplugStatus.trim().length > 0
          );
        }
        return true;
      },
      areConditionalRetweetConditionsValid() {
        let areConditionalRetweetConditionsValid = true;
        if (this.conditionalRetweetEnabled) {
          return (
            (!lodash.isNil(this.minFavoritesThreshold) &&
              Number(this.minFavoritesThreshold) >= 1) ||
            (!lodash.isNil(this.minRetweetsThreshold) && Number(this.minRetweetsThreshold) >= 1)
          );
        }
        return areConditionalRetweetConditionsValid;
      },
      areDelayBetweenTweetsConditionsValid() {
        if (!this.delayBetweenTweetsEnabled) {
          return true;
        }
        return !isNaN(this.delayBetweenTweets) && this.delayBetweenTweets > 0;
      },
      isPollsDataValid() {
        return this.tweets.every((tweet) => {
          if (tweet.poll) {
            return !lodash.isNil(tweet.poll.options[0]) && tweet.poll.options[0].trim().length > 0
              && !lodash.isNil(tweet.poll.options[1]) && tweet.poll.options[1].trim().length > 0;
          }
          else return true;
        });
      },
      isLinkedInInfoValid() {
      if (this.showLinkedInBlock) {
        return (
          (this.linkedInPostType === 'carousel' &&
            (!this.isLinkedInThreadFinisherEnabled ||
              (countTweetLength(this.linkedInThreadFinisherText) > 0 &&
                countTweetLength(this.linkedInThreadFinisherText) <= 280))) ||
          (this.linkedInText &&
            this.linkedInText.trim().length > 0 &&
            this.linkedInText.trim().length <= 3000)
        );
      }
        return true;
      },
      isFacebookInfoValid() {
        if (this.showFacebookBlock) {
          return this.facebookText && this.facebookText.trim().length > 0 && this.facebookText.trim().length <= 63206;
        }
        return true;
      },
      areShareOnInstagramConditionsValid() {
        if (this.shareOnInstagram) {
          const caption = lodash.get(this, 'instagramCaption', '');
          if (!caption) {
            return true;
          } else {
            return (caption.match(/#/g)||[]).length <= 30 && (caption.match(/@/g)||[]).length <= 20 && caption.length <= 2000;
          }
        }

        return true;
      },
      areAutoDMConditionsValid() {
        if (this.isAutoDMEnabled) {
          return (
            this.autoDMTriggerOptions.length > 0 &&
            this.autoDMMessages &&
            this.autoDMMessages.every(message => message.content.trim().length > 0)
          );
        }
        return true;
      },
      canFormBeSubmittedAsADraft() {
        return (
          this.filterOutEmptyTweets(this.tweets).length > 0 &&
          this.areConditionalRetweetConditionsValid() &&
          this.areAutoplugConditionsValid() &&
          this.areAutoDMConditionsValid() &&
          this.areShareOnInstagramConditionsValid() &&
          !this.uploadingGif
        );
      },
      close() {
        this.$emit('close');
      },
      closeDropDown() {
        this.isPostDropdownOpen = false;
      },
      emptyTweet(count) {
        return {
          status: '',
          count: count,
          fileList: [],
          media: [],
          guid: uuidv1(),
          mediaFile: [],
          published: false,
          quoteTweetData: null,
        };
      },
      filterOutEmptyTweets(tweets) {
        return tweets
          .filter((tweet) => {
            return countTweetLength(tweet.status.trim()) > 0 || tweet.mediaFile.length > 0 || tweet.quoteTweetData;
          })
          .map((tweet, i) => {
            const clonedTweet = clone(tweet);
            clonedTweet.count = i;
            clonedTweet.status = clonedTweet.status.trim();
            return clonedTweet;
          });
      },
      formatTimeForDisplay(time) {
        if (!time) return;
        return moment(time).format('MMM DD YYYY, HH:mm A');
      },
      formatTweet,
      showAddBtn(tweet) {
        return this.tweets[tweet.count].media || this.tweets[tweet.count].status !== '';
      },
      getAutoplugConditions() {
        let autoplugConditions = null;
        if (this.autoplugEnabled) {
          const conditionIsFavorite = this.autoplugType === 'Likes';
          autoplugConditions = {
            processed: false,
            minRetweets: conditionIsFavorite ? null : Number(this.autoplugMinRetweets),
            minFavorites: conditionIsFavorite ? Number(this.autoplugMinFavorites) : null,
          };
          if (this.autoplugTemplate && !this.autoplugWasTemplateEdited) {
            autoplugConditions.templateName = this.autoplugTemplate.name;
            autoplugConditions.status = this.autoplugTemplate.content;
          } else {
            autoplugConditions.templateName = null;
            autoplugConditions.status = this.autoplugStatus;
          }
        }

        return autoplugConditions;
      },
      getConditionalRetweetsConditions() {
        let conditionalRetweetsConditions = null;

        if (this.conditionalRetweetEnabled) {
          const conditionIsFavorite = this.conditionalRetweetType === 'Likes';
          conditionalRetweetsConditions = {
            delayForRetweet: this.delayForRetweet ? this.delayForRetweet : '1 hour',
            minRetweetsThreshold: conditionIsFavorite ? null : Number(this.minRetweetsThreshold),
            minFavoritesThreshold: conditionIsFavorite ? Number(this.minFavoritesThreshold) : null,
          };
        }

        return conditionalRetweetsConditions;
      },
      saveAndGetDelayBetweenTweets() {
        const areTweetsConditionsMet =
          this.tweets.filter((tweet) => tweet.status || tweet.mediaFile.length).length > 1;

        const delayBetweenTweetsConditions = {
          delayBetweenTweetsEnabled: this.delayBetweenTweetsEnabled,
          delayBetweenTweets:
            !isNaN(this.delayBetweenTweets) && this.delayBetweenTweets >= 0
              ? this.delayBetweenTweets
              : 0,
        };

        let delayBetweenTweets = delayBetweenTweetsConditions.delayBetweenTweets;

        if (!delayBetweenTweetsConditions.delayBetweenTweetsEnabled || !areTweetsConditionsMet) {
          delayBetweenTweets = null;
        }

        return delayBetweenTweets;
      },
      getAutoDMConditions() {
        let autoDMConditions = null;

        if (this.isAutoDMEnabled) {
          autoDMConditions = {
            enabled: true,
            messages: this.autoDMMessages,
            triggerOptions: this.autoDMTriggerOptions,
            tags: this.autoDMTags,
            isFree: this.isUserPlanPremiumOrAbove(this.userProfile.customerStatus),
            requiresOnlyOneOption: this.autoDMrequiresOnlyOneOption,
          };
        }

        return autoDMConditions;
      },
      deleteTweet(tweet, isThreadFinisherTweet, isFocused, tweetCount) {
        let counter = 0;
        if (this.tweets.length === 1) {
          return;
        }

        const tweetIndex = tweet.count;

        this.tweets = this.tweets
          .filter((t) => t.guid !== tweet.guid)
          .map((t) => {
            t.count = counter++;
            return t;
          });

        const oldFocusOnTweetIndex = this.focusOnTweetIndex;
        const lastTweetIndex = lodash.maxBy(this.tweets, 'count').count;
        const newFocusOnTweetIndex = (() => {
          if (isFocused) {
            return this.tweets.length - 1 >= tweetIndex ? tweetIndex : lastTweetIndex;
          } else {
            const value =
              tweetCount < oldFocusOnTweetIndex ? oldFocusOnTweetIndex - 1 : oldFocusOnTweetIndex;
            return this.tweets.length - 1 >= value ? value : lastTweetIndex;
          }
        })();

        if (oldFocusOnTweetIndex === newFocusOnTweetIndex) {
          // When deleting two tweets with the same index in a row the watcher won't trigger which causes the textarea to not be focused
          this.focusOnTweetIndex = null;
        }

        this.$nextTick(() => {
          this.focusOnTweetIndex = newFocusOnTweetIndex;
        });

        if (isThreadFinisherTweet && this.hasThreadFinisherTweet) {
          this.hasThreadFinisherTweet = false;
        }

        if (!this.threadToEdit) {
          setUnfinishedPost(
            this.tweets,
            this.isFavorite,
            this.shareOnInstagram,
            lodash.get(this, 'hasThreadFinisherTweet', false),
            lodash.get(this, 'wasThreadFinisherAdded', false),
          );
        }
      },
      formatTimeForInput(time) {
        return time.format('YYYY-MM-DDTHH:mm:ss');
      },
      getDiffBetweenLocalTZAndOtherTZ: function (tz1) {
        const now = moment();
        const localOffset = now.utcOffset();
        now.tz(tz1);
        const otherOffset = now.utcOffset();
        return localOffset - otherOffset;
      },
      getTextForLinkedInAndFacebook() {
        return this.tweets
          .filter((tweet) => !(this.hasThreadFinisherTweet && tweet.count === this.tweets.length - 1))
          .map((tweet) => tweet.status)
          .join('\n\n');
      },
      getTimeError(noEmptySlots, time, minTimeInMinutes = 1) {
        switch (this.getTimeStatus(time, minTimeInMinutes)) {
          case 'invalid':
            return 'Invalid date.';
          case 'past':
            this.shouldUpdateTime = true;
            return 'The selected date is in the past.';
          case 'too early':
            return `Must be at least ${minTimeInMinutes} minutes from now.`;
          case 'more than one year':
            return 'Scheduling is limited to 1 year from now.';
          case 'slot full':
            return 'You have already scheduled 2 tweets for the selected date.';
          case 'more than two days':
            return 'As a free plan user you are only allowed to schedule up to 2 days.'
          default:
            return noEmptySlots
              ? 'You have no empty slots available!<br>' +
                  'The time was set 10 minutes from now.'
              : '';
        }
      },
      getTimeStatus(time, minTimeInMinutes = 1) {
        const chosenTime = moment(time);
        if (!chosenTime._isValid) {
          return 'invalid';
        }

        const minutesDifference =
          this.getDiffBetweenLocalTZAndOtherTZ(this.timezone) - minTimeInMinutes + 1;
        chosenTime.add(minutesDifference, 'minute');

        const now = moment().add(minutesDifference, 'minutes');
        now.set({ second: 0, millisecond: 0 });
        const diff = chosenTime.diff(now, 'minutes');
        if (
          diff + minutesDifference <= 0 ||
          (diff + minutesDifference === 0 && chosenTime.minutes() === now.minutes())
        ) {
          return minTimeInMinutes > 1 ? 'too early' : 'past';
        }

        if (this.userProfile.customerStatus === 'none' && chosenTime.isAfter(this.oneDayFromNow, 'day')) {
          return 'more than two days'
        }

        if (chosenTime > moment().add(1, 'year')) {
          return 'more than one year';
        }

        if (this.slotLength === 2) return 'slot full';
        return 'valid';
      },
      initOverflowHighlighting(element) {
        this.$nextTick(function () {
          new HighlightWithinTextarea(element, {
            highlight: (s) => {
              let overflowingText = '';
              const diff = countTweetLength(s) - 280;
              for (let i = s.length - diff; i < s.length; i++) {
                overflowingText += s[i];
              }
              const overflowingTextEscapedRegExp = overflowingText.replace(
                /[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g,
                '\\$&'
              );
              return new RegExp(`${overflowingTextEscapedRegExp}$`);
            },
          });
        });
      },
      isTimeValid(time, minTimeInMinutes = 1) {
        switch (this.getTimeStatus(time, minTimeInMinutes)) {
          case 'valid':
            return true;
          default:
            return false;
        }
      },
      now: function () {
        return this.formatTimeForInput(
          moment().add(10, 'minute').startOf('minute').tz(this.timezone)
        );
      },
      onPaste(event) {
        if (this.userProfile && !this.userProfile.settings.shouldSplitLongText) {
          return;
        }

        const clipboardData = event.clipboardData.getData('text');
        const targetTweetIndex = parseInt(event.target.dataset.tweetCount);
        const targetTweet = this.tweets[targetTweetIndex].status;
        const selectedText = getSelectionText();
        let text;
        if (!selectedText) {
          text = targetTweet + clipboardData;
        } else {
          const splitTargetTweet = targetTweet.split(selectedText);
          const canSplit = splitTargetTweet.length === 2;
          if (canSplit) {
            text = splitTargetTweet[0] + clipboardData + splitTargetTweet[1];
          } else {
            this.$notify({
              type: 'warning',
              message:
                'Sorry, I am not smart enough to split this long text. :(\n' +
                'Please paste it again in a different tweet box.',
            });
            return;
          }
        }

        if (countTweetLength(text) < 280) {
          return;
        }

        event.preventDefault();

        const tweetsAfterCurrent = [];
        if (targetTweetIndex < this.tweets.length - 1) {
          tweetsAfterCurrent.push(...this.tweets.splice(targetTweetIndex + 1, this.tweets.length));
        }

        const statuses = splitTextIntoTweets(text);

        // let positionOfTheLastPastedTweet = 0;

        this.tweets[targetTweetIndex].status = statuses[0];
        for (let i = 1; i < statuses.length; i++) {
          const newTweet = this.emptyTweet(this.tweets.length);
          newTweet.status = statuses[i];
          this.tweets.push(newTweet);
          // positionOfTheLastPastedTweet = this.tweets.length - 1;
        }

        tweetsAfterCurrent.forEach((t) => {
          t.count = this.tweets.length;
          this.tweets.push(t);
        });
      },
      resetModal() {
        Object.assign(this.$data, this.initialState());
      },
      setFavorite(value, thread) {
        if (thread) {
          thread.isFavorite = value;
        } else {
          this.isFavorite = value;
        }
      },
      statusUpdated(value, tweet) {
        tweet.status = value;
      },
      quoteTweetDataUpdated(quoteTweetData, tweet) {
        tweet.quoteTweetData = quoteTweetData;
      },
      updatePoll(value, tweet) {
        tweet.poll = value;
        this.$forceUpdate();
      },
      deletePoll(tweet) {
        delete tweet.poll;
        this.$forceUpdate();
      },
      toggleTimeBox: function () {
        this.showTimeBox = true;
      },
      updateShareOnInstagramValues(shareOnInstagram, instagramCaption, instagramThreadFinisherText) {
        this.shareOnInstagram = shareOnInstagram;
        this.instagramCaption = instagramCaption;
        this.instagramThreadFinisherText = instagramThreadFinisherText;
        this.isAdvancedOptionsModalVisible = false;
      },
      updateInstagramInfo(tweets) {
        const canAddInstagramThreadFinisher =
          tweets.length > 1 && (this.hasThreadFinisherTweet ? tweets.length - 1 < 10 : tweets.length < 10);
        if (!canAddInstagramThreadFinisher) {
          this.instagramThreadFinisherText = null;
        }
        this.updateInstagramInfoFromParentToChildFunction(
          this.instagramCaption,
          this.instagramThreadFinisherText,
          canAddInstagramThreadFinisher
        );
      },
      updateConditionalRetweetValues(delay, minRetweets, minFavorites, conditionalRetweetType) {
        this.delayForRetweet = delay;
        this.minRetweetsThreshold = minRetweets;
        this.minFavoritesThreshold = minFavorites;
        this.conditionalRetweetType = conditionalRetweetType;
        this.isAdvancedOptionsModalVisible = false;
      },
      updateDelayBetweenTweets(delay, delayBetweenTweetsEnabled) {
        this.delayBetweenTweets = delay;
        this.delayBetweenTweetsEnabled = delayBetweenTweetsEnabled;
      },
      async addGifToTweet(gifURL, tweetIndex) {
        if (!this.canAddGif(this.tweets[tweetIndex])) {
          return;
        }
        this.tweets[tweetIndex].mediaFile.push({ url: gifURL, type: 'gif' });

        this.isGifUploading = true;
        const image = await controller.proxyFile(this.currentUser, gifURL);
        const file = new File([image], 'tenor.gif', { type: 'image/gif' });
        this.tweets[tweetIndex].fileList = [];
        this.tweets[tweetIndex].fileList[0] = file;
        this.isGifUploading = false;
      },
      canAddGif(tweet) {
        return tweet.mediaFile && tweet.mediaFile.length == 0;
      },
      updateLinkedInInfoInParent(text, isPostPublic, selectedLinkedInIds, postType, isThreadFinisherEnabled, threadFinisherText) {
        this.linkedInText = text;
        this.linkedInIsPostPublic = isPostPublic;
        this.selectedLinkedInIds = selectedLinkedInIds;
        this.isAdvancedOptionsModalVisible = false;
        this.linkedInPostType = postType;
        this.linkedInThreadFinisherText = isThreadFinisherEnabled ? threadFinisherText : null;
        this.isLinkedInThreadFinisherEnabled = isThreadFinisherEnabled;
      },
      currentSlotLength(time) {
        if (this.schedule) {
          const selectedDate = Object.entries(this.schedule.getThreadsByDate()).find((elem) => {
            return moment(elem[0]).isSame(moment(time), 'day');
          });
          if (selectedDate) {
            const selectedTimeSlot = selectedDate[1].filter((elem) => {
              return (
                elem.slotType !== 'empty' &&
                elem.slotType !== 'evergreen' &&
                elem.slotType !== 'recurrent' &&
                (!this.threadToEdit || elem.id !== this.threadToEdit.id) &&
                moment(elem.time).isSame(moment(time), 'hour') &&
                moment(elem.time).isSame(moment(time), 'minute')
              );
            });
            this.slotLength = selectedTimeSlot.length;
          }
        }
      },
      updateFacebookInfoInParent(text) {
        this.facebookText = text;
        this.isAdvancedOptionsModalVisible = false;
      },
      canFormBeSubmittedOptions() {
        return (
          this.areConditionalRetweetConditionsValid() &&
          this.areAutoplugConditionsValid() &&
          this.areAutoDMConditionsValid() &&
          this.isLinkedInInfoValid() &&
          this.isFacebookInfoValid() &&
          this.areDelayBetweenTweetsConditionsValid() &&
          this.areShareOnInstagramConditionsValid()
        );
      },
      toggleConditionalRetweet() {
        this.conditionalRetweetEnabled = !this.conditionalRetweetEnabled;
      },
      toggleAutoPlug() {
        this.autoplugEnabled = !this.autoplugEnabled;
      },
      toggleShareOnInstagram() {
        this.shareOnInstagram = !this.shareOnInstagram;
      },
      toggleLinkedIn() {
        this.showLinkedInBlock = !this.showLinkedInBlock;
      },
      toggleDelayBetweenTweets() {
        this.delayBetweenTweetsEnabled = !this.delayBetweenTweetsEnabled;
      },
      toggleFacebook() {
        this.showFacebookBlock = !this.showFacebookBlock;
      },
      toggleAutoDM() {
        this.isAutoDMEnabled = !this.isAutoDMEnabled;
      },
      formatThreadForLocalStorage(thread) {
        const propertiesToRemove = [
          'id',
          'isCancelled',
          'lastAutoRTTime',
          'slotType',
          'tweetMetricsUpdatedAt',
          'tweetshot',
        ];
        return lodash.pick(
          {...thread, tweetIds: [] },
          Object.keys(thread).filter((property) => !propertiesToRemove.includes(property))
        );
      },
      saveThreadToLocalStorage(post) {
        const storedThreads = JSON.parse(localStorage.getItem('threads_from_login_page'));
        const thread = this.formatThreadForLocalStorage(post);

        if (Array.isArray(storedThreads)) {
          storedThreads.push(thread);
          return localStorage.setItem('threads_from_login_page', JSON.stringify(storedThreads));
        } else {
          return localStorage.setItem('threads_from_login_page', JSON.stringify([thread]))
        }
      },
      updateThreadOnLocalStorage(thread, toUpdate) {
        const threadsFromLocalStorage = localStorage.getItem('threads_from_login_page');
        const threads = JSON.parse(threadsFromLocalStorage);

        // We use the first tweet's guid to find the thread since we don't assign IDs to Thread objects (Firestore does this automatically)
        const firstTweetGuid = thread.tweets[0].guid;

        const updatedThreads = threads.map((threadObject) => ({
          ...threadObject,
          ...(firstTweetGuid === threadObject.tweets[0].guid ? { ...toUpdate } : {}),
        }));

        localStorage.setItem('threads_from_login_page', JSON.stringify(updatedThreads));
      },
      onFocusModePaste() {
        this.tweets = this.focusModeStatus.split('\n\n\n').map((status, i) => {
          const tweet = this.tweets[i] || this.emptyTweet(i);
          return {
            ...tweet,
            status: status.trim(),
          };
        });
      },
      updateTweets(event) {
        const cursorPosition = this.$refs['focus-mode-textarea'].selectionStart;
        const statuses = this.focusModeStatus.split('\n\n\n');
        const editingTweetIndex = this.focusModeStatus.slice(0, cursorPosition).split('\n\n\n').length - 1;
        if (event.inputType === 'insertFromPaste' || this.previousStatusesCount !== statuses.length) {
          this.onFocusModePaste();
          this.previousStatusesCount = statuses.length;
          return;
        }

        const quoteTweetData = lodash.get(this.tweets[editingTweetIndex], 'quoteTweetData', null);

        this.$set(this.tweets, editingTweetIndex, {
          ...this.tweets[editingTweetIndex],
          status: statuses[editingTweetIndex].trim(),
          quoteTweetData,
        });
        this.previousStatusesCount = statuses.length;
        this.onInputChange();
      },
      removeThreadsFromLocalStorage() {
        localStorage.removeItem('threads_from_login_page');
      },
      deleteThreadFromLocalStorage(thread) {
        const threadsFromLocalStorage = localStorage.getItem('threads_from_login_page');
        const threads = JSON.parse(threadsFromLocalStorage);
        const firstTweetId = thread.tweets[0].guid;

        const updatedThreads = threads.filter((thread) => {
          const firstTweetGuid = thread.tweets[0].guid;
          return firstTweetGuid !== firstTweetId;
        });

        localStorage.setItem('threads_from_login_page', JSON.stringify(updatedThreads));
      },
      toggleFocusMode: function () {
        setTimeout(() => {
          this.isFocusModeEnabled = !this.isFocusModeEnabled;
        }, 5);
      },
      tweetHasPoll(index) {
        return Boolean(Array.isArray(this.tweets) && this.tweets[index] && this.tweets[index].poll);
      },
      tweetHasMedia(index) {
        return Boolean(
          Array.isArray(this.tweets) &&
            Array.isArray(this.tweets[index].mediaFile) &&
            this.tweets[index].mediaFile.length > 0
        );
      },
    },
    watch: {
      isFocusModeEnabled: function (val) {
        if (!val) return;

        if (!this.originalTweetInfo) {
          this.focusModeStatus = this.tweets.map((tweet) => tweet.status).join('\n\n\n');
        }
        this.$nextTick(function() {
          return this.$refs['focus-mode-textarea'] ? this.$refs['focus-mode-textarea'].focus() : null;
        });
        return dao.userProfile.updateFocusModeDate(this.userProfile.uid);
      },
      canShareOnInstagram: function (val) {
        if (!val) {
          this.shareOnInstagram = false;
        }
      },
      wasThreadFinisherAdded(val, old) {
        if (!old && val && this.isFocusModeEnabled) {
          const cursorPosition = this.focusModeStatus.length;
          this.focusModeStatus = this.tweets.map((tweet) => tweet.status).join('\n\n\n');
          setTimeout(() => {
            this.$refs['focus-mode-textarea'].setSelectionRange(cursorPosition, cursorPosition);
          }, 0);
        }
      },
    },
    mixins: [SwalModalMixin, TimeFormatterMixin, CustomerStatusMixin],
    name: 'thread-mixin',
  };
</script>
