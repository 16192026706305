class RecurrentPost {
  name;

  constructor(id, day, time, category, userRef, createdAt, updatedAt) {
    this.id = id;
    this.day = day;
    this.category = category;
    this.time = time;
    this.userRef = userRef;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  static newRecurrentPost(day, time, category, userRef) {
    return new RecurrentPost(null, day, time, category, userRef);
  }

  static buildFromFirestore(doc) {
    return new RecurrentPost(
      doc.id,
      doc.data().day,
      doc.data().time,
      doc.data().category,
      doc.data().userRef,
      doc.data().created_at,
      doc.data().updated_at
    );
  }
}

export { RecurrentPost };
