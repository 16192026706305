export function setTheme(theme) {
  const classListOfBody = document.getElementsByTagName('body')[0].classList;
  classListOfBody.remove(`mode--dark`);
  classListOfBody.remove(`mode--light`);
  classListOfBody.remove(`mode--pink`);
  if (theme !== 'sync-with-system') {
    classListOfBody.add(`mode--${theme}`);
    localStorage.setItem('theme', theme);
  }
  if (theme === 'sync-with-system') {
    const isDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
    checkSystemMode(isDark);
    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", function (e) {
      checkSystemMode(e.matches ? true : false);
    });
  }
}

function checkSystemMode (isDark) {
  const classListOfBody = document.getElementsByTagName('body')[0].classList;
  if (isDark) {
    localStorage.setItem('theme', 'dark');
    classListOfBody.remove(`mode--light`);
    classListOfBody.add('mode--dark');
  } else {
    localStorage.setItem('theme', 'light');
    classListOfBody.remove('mode--dark');
    classListOfBody.add('mode--light');
  }
}

export const LAYOUTS = {
  DEFAULT: 'default',
  LARGE: 'large',
  EMPTY: 'empty',
  DASHBOARD: 'dashboard',
};
