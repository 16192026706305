<template>
  <transition
    enter-active-class="transition duration-100 ease-in"
    enter-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition duration-50 ease-out"
    leave-class=" opacity-100"
    leave-to-class=" opacity-0"
  >
    <div v-show="open" class="z-200 relative" role="dialog" aria-modal="true">
      <div
        @click="attemptDismiss"
        class="fixed inset-0 bg-black opacity-25"
        :class="{
          'slide-over-height-keyboard':
            enableMobileBottomToTopTranslation &&
            composerContext.isComposer &&
            composerContext.isKeyboardVisible,
        }"
      ></div>
      <div
        :class="[
          'pointer-events-none fixed flex max-w-full',
          {
            'inset-y-0 right-0 ': !enableMobileBottomToTopTranslation,
            'inset-x-0 bottom-0 lg:inset-x-auto lg:inset-y-0 lg:bottom-auto lg:right-0':
              enableMobileBottomToTopTranslation,
            'slide-over-height':
              enableMobileBottomToTopTranslation && !composerContext.isKeyboardVisible,
            'slide-over-height-keyboard bottom-keyboard':
              enableMobileBottomToTopTranslation &&
              composerContext.isComposer &&
              composerContext.isKeyboardVisible,
          },
        ]"
      >
        <transition
          enter-active-class="transform transition ease-in-out duration-500"
          :enter-class="`${
            enableMobileBottomToTopTranslation
              ? 'translate-y-full lg:translate-x-full lg:translate-y-0'
              : 'translate-x-full'
          }`"
          :enter-to-class="`${
            enableMobileBottomToTopTranslation ? 'translate-y-0 lg:translate-x-0' : 'translate-x-0'
          }`"
          leave-active-class="transform transition ease-in-out duration-500"
          :leave-class="`${
            enableMobileBottomToTopTranslation ? 'translate-y-0 lg:translate-x-0' : 'translate-x-0'
          }`"
          :leave-to-class="`${
            enableMobileBottomToTopTranslation
              ? 'translate-y-full lg:translate-x-full lg:translate-y-0'
              : 'translate-x-full'
          }`"
          @after-leave="$emit('close')"
        >
          <div v-show="show" class="pointer-events-auto w-screen" :class="containerClasses">
            <slot />
          </div>
        </transition>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'SlideOver',
    props: {
      open: {
        type: Boolean,
        default: false,
      },
      containerClasses: {
        type: String,
        default: 'max-w-3xl',
      },
      enableMobileBottomToTopTranslation: {
        type: Boolean,
        default: false,
      },
      isNestedSlideOver: {
        type: Boolean,
        default: false,
      },
      composerContext: {
        type: Object,
        default: () => ({
          isComposer: false,
          isKeyboardVisible: false,
        }),
      },
      confirmOnDismiss: {
        type: Boolean,
        default: false,
      },
    },
    created() {
      const listener = document.addEventListener('keydown', (e) => {
        // I did this because the keyCode property because it is deprecated and Key is not support by some old browser.
        if (e.keyCode === 27 || e.key === 'Escape') {
          this.attemptDismiss();
        }
      });

      this.$once('hook:destroyed', () => {
        document.removeEventListener('keydown', listener);
        document.body.classList.remove('slide-over-open');
      });
    },
    data() {
      return {
        show: false,
      };
    },
    methods: {
      dismiss() {
        this.show = false;
      },
      attemptDismiss() {
        if (this.confirmOnDismiss) {
          this.$emit('attempt-dismiss');
        } else {
          this.dismiss();
        }
      },
    },
    watch: {
      open: {
        immediate: true,
        handler(val) {
          this.show = val;
          if (val) {
            !this.isNestedSlideOver && document.body.classList.add('slide-over-open');
          } else {
            !this.isNestedSlideOver && document.body.classList.remove('slide-over-open');
          }
        },
      },
    },
  };
</script>

<style>
  .slide-over-open {
    overflow: hidden;
  }
</style>

<style lang="scss" scoped>
  .slide-over-height {
    height: calc(100% - 50px);
    @include lg {
      height: 100%;
    }
  }
  .slide-over-height-keyboard {
    height: var(--available-space, auto);
  }
  .bottom-keyboard {
    bottom: var(--keyboard-size);
  }
</style>
