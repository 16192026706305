<script>
  import lodash from 'lodash';
  import VueTribute from 'vue-tribute';
  import { mapGetters, mapState } from 'vuex';
  import controller from '@/controller';
  import isTwitterHandleValid from '@/../functions/src/util/isTwitterHandleValid';

  export default {
    components: {
      VueTribute,
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser' }),
      ...mapState(['followedUsers']),
    },
    props: {
      debounce: {
        type: Number,
        default: 200,
      },
      maxWait: {
        type: Number,
        default: 1000,
      },
    },
    data() {
      return {
        tributeOptions: this.buildTributeOptions(),
      };
    },
    methods: {
      buildTributeOptions: function () {
        const that = this;
        return {
          trigger: '@',
          searchOpts: {
            pre: '<span class="twitter-handle">',
            post: '</span>',
            skip: true,
          },
          menuItemTemplate: function (item) {
            if (item.original.errorMessage) {
              return `<p class="full-name mb-0 text-ds-text-primary">${item.original.errorMessage}</p>`;
            }
            return `
              <div class="hpf-option">
                <div class="img-section mr-2">
                  <img src="${item.original.image}">
                </div>
                <div class="names-section max-w-xxs">
                  <p class="full-name mb-0 truncate block">${item.original.fullName}</p>
                  <p class="twitter-handle mt--1 mb-0 truncate">@${item.original.key}</p>
                </div>
            `;
          },
          noMatchTemplate: ``,
          values: lodash.debounce(
            function (keyword, callback) {
              that.setLoading(true);
              that.getRemoteData(keyword, callback);
            },
            that.debounce,
            { maxWait: that.maxWait },
          ),
        };
      },
      setLoading(shouldBeLoading) {
        const tributeContainer = document.querySelector('.tribute-container');
        if (!tributeContainer) return;
        if (shouldBeLoading) {
          tributeContainer.classList.add('loading');
          return;
        }
        tributeContainer.classList.remove('loading');
      },
      async getRemoteData(keyword, callback) {
        if (!isTwitterHandleValid(keyword)) {
          callback([]);
          return;
        }

        try {
          const fetchedMatchingUsers = await controller.twitter.usersSearch(
            this.currentUser,
            keyword,
          );
          const followedUsersWithKeyword = this.followedUsers.filter((user) =>
            user.key.toLowerCase().includes(keyword.toLowerCase()),
          );

          const usersFound = lodash.uniqBy(
            [...followedUsersWithKeyword, ...fetchedMatchingUsers],
            'key',
          );

          callback(usersFound);
        } catch (error) {
          callback([{ errorMessage: error.response.data }]);
        }
        this.setLoading(false);
      },
    },
  };
</script>
