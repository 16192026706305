<template>
  <div class="composer-container overscroll-behavior-contain relative">
    <fade-transition
      :duration="{
        leave: 0,
      }"
      class="h-full w-full"
    >
      <div class="loader flex items-center justify-center">
        <loading-scheduler size="lg" v-show="isLoading" />
      </div>
    </fade-transition>
    <fade-transition
      :duration="{
        enter: 800,
      }"
    >
      <div v-show="!isLoading" class="flex flex-col justify-between lg:px-16">
        <div class="flex flex-col-reverse items-center gap-x-3 gap-y-2 lg:flex-row lg:items-start">
          <div style="width: 225px; height: 400px">
            <iframe
              allowfullscreen
              :src="tweetReelPreviewURL"
              class="h-full w-full rounded-lg"
              ref="remotion-player"
            />
          </div>
          <div class="mx-6 mr-auto">
            <div class="flex-grow flex min-w-0 flex-col justify-between gap-y-4">
              <div class="flex items-center gap-x-3">
                <new-button
                  icon
                  :type="selectedTab === 'background' ? 'primary' : 'secondary'"
                  @click="selectedTab = 'background'"
                >
                  <inline-svg
                    src="/img/icons/color-palette.svg"
                    class="fill-main-color inline h-5 w-5 stroke-current"
                  />
                </new-button>
                <new-button
                  icon
                  :type="selectedTab === 'audio' ? 'primary' : 'secondary'"
                  @click="selectedTab = 'audio'"
                >
                  <inline-svg
                    src="/img/icons/sound.svg"
                    class="fill-main-color inline h-5 w-5 stroke-current"
                  />
                </new-button>
              </div>
              <div
                class="flex flex-wrap gap-1 lg:grid lg:grid-cols-4 lg:gap-3"
                v-show="selectedTab === 'background'"
              >
                <new-button
                  icon
                  class="overflow-hidden"
                  type="secondary"
                  v-for="(video, index) in reelsVideo"
                  :key="`reels-video-${index}`"
                  @click="changeBackground(video)"
                >
                  <img
                    :src="video.thumbnail"
                    class="h-8 w-8 lg:h-12 lg:w-12"
                    v-if="video.url !== selectedVideoUrl"
                  />
                  <div v-else class="border h-8 w-8 rounded-full border-ds-button-icon">
                    <inline-svg
                      src="/img/icons/new/success.svg"
                      class="fill-main-color inline h-3 w-3 stroke-current"
                    />
                  </div>
                </new-button>
              </div>
              <div
                class="flex flex-wrap gap-x-2 gap-y-3 lg:flex-col"
                v-show="selectedTab === 'audio'"
              >
                <div
                  class="flex cursor-pointer gap-x-2"
                  v-for="(audio, index) in reelsAudio"
                  :key="`reels-audio-${index}`"
                  @click="changeAudio(audio)"
                >
                  <new-button icon class="h-8 w-8 overflow-hidden lg:h-10 lg:w-10" type="secondary">
                    <inline-svg
                      src="/img/icons/sound.svg"
                      class="fill-main-color inline h-5 w-5 stroke-current"
                      v-if="audio.url !== selectedAudioUrl"
                    />
                    <div
                      v-else
                      class="border h-8 w-8 rounded-full border-ds-button-icon lg:h-10 lg:w-10"
                    >
                      <inline-svg
                        src="/img/icons/new/success.svg"
                        class="fill-main-color inline h-3 w-3 stroke-current"
                      />
                    </div>
                  </new-button>
                  <div class="flex flex-col">
                    <div>Song {{ index + 1 }}</div>
                    <div class="text-xs text-secondary">
                      Artist name · {{ formatAudioDuration(audio.duration) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <base-input alternative>
            <textarea
              dir="auto"
              class="form-control simple min-h-16"
              ref="instagramCaption"
              rows="3"
              v-model="instagramCaption"
              placeholder="Caption goes here..."
              id="instagramCaption"
              maxlength="2200"
              @focus="resize"
              @keyup="resize"
            ></textarea>
          </base-input>
          <div class="flex items-center justify-end gap-6">
            <tooltip data-cy="compose-choose-emoji" content="Choose Emoji" class="hidden md:block">
              <emoji-picker @selected-emoji="addEmoji($event)" />
            </tooltip>
          </div>
        </div>
      </div>
    </fade-transition>
  </div>
</template>

<script>
  import dao from '@/dao';
  import { mapGetters, mapState } from 'vuex';
  import lodash from 'lodash';
  import EmojiPicker from '@/components/Threads/EmojiPicker.vue';
  import { FadeTransition } from 'vue2-transitions';
  import LoadingScheduler from '@/components/Loading/LoadingScheduler.vue';

  const config = require('@/config');

  export default {
    components: { EmojiPicker, FadeTransition, LoadingScheduler },
    data() {
      return {
        instagramCaption: '',
        selectedTab: 'background',
        reelOptions: null,
        reelsAudio: [],
        reelsVideo: [],
        isLoading: true,
      };
    },
    props: {
      thread: Object,
      initialReelOptions: {
        type: Object,
        default: () => null,
      },
    },
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile', currentUser: 'getCurrentUser' }),
      ...mapState(['schedule']),
      selectedAudioUrl() {
        return lodash.get(this.reelOptions, 'options.audio.url', '');
      },
      selectedVideoUrl() {
        return lodash.get(this.reelOptions, 'options.videoUrl', '');
      },
      tweetReelPreviewURL() {
        return config.tweetReelPreviewURL;
      },
    },
    async created() {
      if (this.thread) {
        this.reelsAudio = (await dao.appSettings.fetchReelsAudio()).map((m) => ({
          url: config.buildStorageMediaURL(m.path),
          duration: m.duration,
        }));
        this.reelsVideo = (await dao.appSettings.fetchReelsVideo()).map((m) => ({
          url: config.buildStorageMediaURL(m.path),
          thumbnail: config.buildStorageMediaURL(m.thumbnail),
        }));

        this.reelOptions = {
          ...this.initialReelOptions,
        };

        const tweet = this.thread.tweets[0];
        this.instagramCaption = tweet.status;

        this.$nextTick(() => {
          this.sendTweetToRemotionPlayer();
          this.updateParentValues();
        });
      }
    },
    mounted() {
      const iframe = this.$refs[`remotion-player`];
      if (iframe) {
        const self = this;
        window.onmessage = function (e) {
          if (e.data == 'loaded') {
            self.isLoading = false;
          }
        };
      }
    },
    watch: {
      instagramCaption() {
        this.updateParentValues();
      },
    },
    methods: {
      updateParentValues() {
        this.$emit('values-set', {
          instagramCaption: this.instagramCaption,
          reelOptions: this.reelOptions,
        });
      },
      formatAudioDuration(duration) {
        if (duration < 10) return '00:0' + duration;
        else if (duration < 60) return '00:' + duration;
        else {
          const minutes = Math.floor(duration / 60);
          const seconds = duration % 60;
          return `${minutes}:${seconds}`;
        }
      },
      resize() {
        const { instagramCaption } = this.$refs;
        if (instagramCaption && instagramCaption.scrollHeight > 100) {
          instagramCaption.style.height = instagramCaption.scrollHeight - 4 + 'px';
        }
      },
      addEmoji(emoji) {
        const captionRef = this.$refs.instagramCaption;
        const position = captionRef.selectionStart;
        this.instagramCaption = [
          this.instagramCaption.slice(0, position),
          emoji.native,
          this.instagramCaption.slice(position),
        ].join('');
        const positionAfterTweet = position + 2;

        this.$nextTick(() => {
          captionRef.focus();
          captionRef.setSelectionRange(positionAfterTweet, positionAfterTweet);
        });
      },
      changeBackground(video) {
        this.$eventStore.tweetToVideo('Edit: Select Background');
        this.reelOptions = {
          ...this.reelOptions,
          options: {
            ...this.reelOptions.options,
            videoUrl: video.url,
          },
        };
        this.sendTweetToRemotionPlayer();
        this.updateParentValues();
      },
      changeAudio(audio) {
        this.$eventStore.tweetToVideo('Edit: Select Audio');
        this.reelOptions = {
          ...this.reelOptions,
          options: {
            ...this.reelOptions.options,
            duration: audio.duration,
            audio: {
              url: audio.url,
              duration: audio.duration,
              startFrom: 0,
              endAt: audio.duration,
            },
          },
        };
        this.sendTweetToRemotionPlayer();
        this.updateParentValues();
      },
      formatTweetData() {
        const tweet = this.thread.tweets[0];
        return {
          user: {
            name: this.userProfile.name,
            username: this.userProfile.username,
            profile_image_url: this.userProfile.photoURL,
            verified: true,
            verified_type: 'blue',
          },
          public_metrics: {
            retweet_count: this.thread.retweetCountOfTheFirstTweet,
            reply_count: this.thread.replyCountOfTheFirstTweet,
            like_count: this.thread.favoriteCountOfTheFirstTweet,
          },
          text: tweet.status,
          createdAt: this.thread.time.toDate(),
          lang: 'en',
        };
      },
      sendTweetToRemotionPlayer() {
        const { tweet, options } = this.reelOptions;
        const player = this.$refs[`remotion-player`];
        if (!player) return;
        player.contentWindow.postMessage(
          {
            tweet,
            ...options,
          },
          this.tweetReelPreviewURL
        );
      },
    },
  };
</script>
