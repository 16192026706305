import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import controller from '@/controller';
import moment from 'moment';
import 'moment-timezone';
import { Post } from './Post';
import { formatTimeWithUserLocale } from '@/util/formatTimeWithUserLocale';
import { getFormattedDayFromTime } from '@/util/getFormattedDayFromTime';
const fb = require('../firebase');

class Thread extends Post {
  constructor({
    id,
    time = null,
    tweets,
    scheduled = false,
    user,
    publishingError = null,
    lastAutoRTTime,
    isFavorite,
    deleted = false,
    tweetIds,
    postNow,
    conditionalRetweetsConditions,
    autoplug,
    source,
    writer,
    growthProgram,
    tweetshot,
    shareOnInstagram,
    linkedIn,
    facebook,
    delayBetweenTweets,
    tweetMetricsUpdatedAt,
    categories,
    recurrentPostRef,
    replyToTweetId,
    replyToTweetInfo,
    isCancelled,
    instagramCaption,
    isCloned,
    isRecurrentPost,
    timerData,
    isPinned,
    ghostwritingRefusal,
    ghostwritingStatus,
    impressionsCountOfTheFirstTweet,
    tweetshotContent,
    instagramPublishingError,
    publishedToInstagram,
    autoDM,
    hasThreadFinisherTweet,
    created_at,
    linkedInPublishingError,
    isRecurrentPostDisabled,
    instagramThreadFinisherText,
    lastClonePostedTime,
    facebookPublishingError,
    isDeletedFromTwitter,
    isLongTweetshot,
    isLargeFontTweetshot,
    tweetReel,
    ama,
    youtubeShortRef,
    threads,
  }) {
    super(id, time, scheduled, user, publishingError, deleted);
    this.tweets = tweets;
    this.user = user;
    this.lastAutoRTTime = lastAutoRTTime || null;
    this.isFavorite = isFavorite || false;
    this.type = 'post';
    this.tweetIds = tweetIds || null;
    this.conditionalRetweetsConditions = conditionalRetweetsConditions ? conditionalRetweetsConditions : null;
    this.autoplug = autoplug ? autoplug : null;
    this.postNow = postNow ? postNow : false;
    this.source = source ? source : null;
    this.writer = writer ? writer : null;
    this.growthProgram = growthProgram ? growthProgram : null;
    this.tweetshot = tweetshot ? tweetshot : null;
    this.shareOnInstagram = shareOnInstagram || false;
    this.linkedIn = linkedIn || null;
    this.facebook = facebook || null;
    this.delayBetweenTweets = delayBetweenTweets || null;
    this.tweetMetricsUpdatedAt = tweetMetricsUpdatedAt || null;
    this.categories = categories || null;
    this.recurrentPostRef = recurrentPostRef || null;
    this.replyToTweetId = replyToTweetId || null;
    this.replyToTweetInfo = replyToTweetInfo || null;
    this.isCancelled = isCancelled || false;
    this.tweetsCount = Array.isArray(tweets) ? tweets.length : 0;
    this.instagramCaption = instagramCaption || null;
    this.isCloned = isCloned || false;
    this.isRecurrentPost = isRecurrentPost || false;
    this.timerData = timerData || null;
    this.isPinned = isPinned || false;
    this.ghostwritingRefusal = ghostwritingRefusal || null;
    this.ghostwritingStatus = ghostwritingStatus || null;
    this.impressionsCountOfTheFirstTweet = impressionsCountOfTheFirstTweet || null;
    this.tweetshotContent = tweetshotContent || null;
    this.instagramPublishingError = instagramPublishingError || null;
    this.facebookPublishingError = facebookPublishingError || null;
    this.publishedToInstagram = publishedToInstagram || false;
    this.autoDM = autoDM || null;
    this.hasThreadFinisherTweet = hasThreadFinisherTweet || false;
    this.created_at = created_at || null;
    this.linkedInPublishingError = linkedInPublishingError || null;
    this.isRecurrentPostDisabled = isRecurrentPostDisabled || false;
    this.instagramThreadFinisherText = instagramThreadFinisherText || null;
    this.lastClonePostedTime = lastClonePostedTime || null;
    this.isDeletedFromTwitter = isDeletedFromTwitter || false;
    this.isLongTweetshot = isLongTweetshot || false;
    this.isLargeFontTweetshot = isLargeFontTweetshot || false;
    this.tweetReel = tweetReel || null;
    this.ama = ama || null;
    this.youtubeShortRef = youtubeShortRef || null;
    this.threads = threads || null;
  }

  static newThread({
    time,
    tweets,
    user,
    isFavorite,
    postNow,
    conditionalRetweetsConditions,
    autoplug,
    shareOnInstagram,
    linkedIn,
    facebook,
    delayBetweenTweets,
    categories,
    replyToTweetId,
    replyToTweetInfo,
    instagramCaption,
    isRecurrentPost,
    timerData,
    source,
    tweetshotContent,
    autoDM,
    hasThreadFinisherTweet,
    instagramThreadFinisherText,
    writer,
    ghostwritingStatus,
    isLongTweetshot,
    isLargeFontTweetshot,
    tweetReel,
    ama,
    youtubeShortRef,
    threads,
  }) {
    return new Thread({
      time,
      tweets,
      user,
      isFavorite,
      postNow,
      conditionalRetweetsConditions,
      autoplug,
      source,
      writer,
      shareOnInstagram,
      linkedIn,
      facebook,
      delayBetweenTweets,
      categories,
      replyToTweetId,
      replyToTweetInfo,
      instagramCaption,
      isRecurrentPost,
      timerData,
      ghostwritingStatus,
      tweetshotContent,
      autoDM,
      hasThreadFinisherTweet,
      instagramThreadFinisherText,
      isLongTweetshot,
      isLargeFontTweetshot,
      tweetReel,
      ama,
      youtubeShortRef,
      threads,
    });
  }

  deleteFromFirestore(firebaseUser, currentUserId) {
    return super.deleteFromFirestore(firebaseUser, currentUserId);
  }

  saveToFirestore(firebaseUser, currentUserId, timezone, eventStore, postOnNextEmptySlot) {
    // TODO: maybeUpdatedThread is useless, we can use this instead
    const maybeUpdatedThread = this._delayThreadPostingTimeIfItIsTooClose(timezone);
    return controller.posts.save(firebaseUser, currentUserId, maybeUpdatedThread, postOnNextEmptySlot).then(result => {
      if (this.source === 'growth-program') return result;
      if (this.source === 'instagram-post') {
        eventStore.instagramPost.createPost();
      } else if (this.source === 'linkedin-post') {
        eventStore.linkedInPost.createPost();
      } else if (this.source === 'facebook-post') {
        eventStore.facebookPost.createPost();
        } else if (this.source === 'threads-post') {
          eventStore.threadsPost.createPost();
        } else if (this.source === 'ghostwriting') {
        eventStore.ghostwriting.queueTweet();
      } else if (this.source === 'time-based-sale') {
        eventStore.timeBasedSales.scheduleSale();
      } else if (!this.time) {
        eventStore.scheduleDraft();
      } else if (this.tweets.length > 1) {
        eventStore.scheduleThread();
      } else {
        eventStore.scheduleTweet();
      }
      if (this.shareOnInstagram) {
        eventStore.scheduleInstashot();
      }
      return result;
    });
  }

  _delayThreadPostingTimeIfItIsTooClose(timezone) {
    const now = moment.tz();
    const threadTime = moment.tz(this.time, timezone);
    if (threadTime.diff(now, 'seconds') < 10) {
      now.add(60 + 10, 'seconds').startOf('minute');
      this.time = now.toDate();
    }
    return this;
  }

  _timeForFirestore(timezone) {
    if (this.postNow) return new Date();
    return moment.tz(this.time, timezone).toDate();
  }

  postingTimeData(timezone) {
    const {day, time} = super.postingTimeData(timezone);
    const threadOrTweet = this.tweets.length > 1 ? 'thread' : 'tweet';
    return {threadOrTweet, day, time};
  }

  lastClonePostedTimeData(timezone) {
    const time = formatTimeWithUserLocale(moment(this.lastClonePostedTime));
    const day = getFormattedDayFromTime(moment(this.lastClonePostedTime), timezone);

    return { day, time };
  }

  lastAutoRTTimeDifference(timezone) {
    if (!this.lastAutoRTTime) return null;

    const threadTime = moment.tz(this.lastAutoRTTime.toDate(), timezone);
    const midnightOfToday = moment.tz(timezone).startOf('day');
    const midnightOfTime = threadTime.startOf('day');
    if (midnightOfToday.diff(midnightOfTime, 'day') === 0) {
      return 'today';
    } else if (midnightOfToday.diff(midnightOfTime, 'day') === 1) {
      return 'yesterday';
    } else {
      return `${midnightOfToday.diff(midnightOfTime, 'day')} days ago`;
    }
  }

  update(firebaseUser, userProfile) {
    if (!this.id) throw Error('This thread wasn\'t saved, so it can\'t be updated.');
    const threadWithMaybeUpdatedTime = this._delayThreadPostingTimeIfItIsTooClose(userProfile.timezone);
    return controller.posts.update(firebaseUser, userProfile.uid, this.id, threadWithMaybeUpdatedTime)
      .catch(error => {
        console.error(error);
        alert('An error has occurred while updating the post.')
      });
  }

  isEmpty() {
    return false;
  }

  delayByMinutes(minutes) {
    this.time.add(minutes, 'minutes');
  }

  hasErrors() {
    if (!this.scheduled) {
      return false;
    }

    if (this.publishingError) {
      return true;
    }

    const unpublishedTweets = this.tweets.filter((tweet) => !tweet.published);

    if (unpublishedTweets.length > 0 && this.publishingError) {
      return true;
    }

    return false;
  }

  toggleFavorite() {
    const isFavorite = this.isFavorite;
    this.isFavorite = !isFavorite;
    return fb.threadsCollection.doc(this.id).update({
      isFavorite: !isFavorite,
      updated_at: firebase.firestore.FieldValue.serverTimestamp(),
    })
      .catch(error => {
        console.error(`An error has occurred while toggling the favorite status of post ${this.id}.`, error);
        throw error;
      });
  }

  toggleDisabled() {
    return fb.threadsCollection
      .doc(this.id)
      .update({
        isRecurrentPostDisabled: !this.isRecurrentPostDisabled,
        updated_at: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .catch((error) => {
        console.error(
          `An error has occurred while toggling the isRecurrentPostDisabled status of post ${this.id}.`,
          error
        );
        throw error;
      });
  }

  doPostNow(firebaseUser, currentUserId) {
    if (!this.id) throw Error('This thread wasn\'t saved, so it can\'t be updated.');
    return controller.posts.postNow(firebaseUser, currentUserId, this.id)
      .catch(error => {
        console.error(error);
        alert('An error has occurred while instantly posting the thread.')
      });
  }

  static buildFromFirestore(doc, timezone) {
    const tweets = doc.data().tweets.map((tweet) => {
      if (!tweet.media) tweet.media = [];
      return tweet;
    });
    return new Thread({
      id: doc.id,
      time: doc.data().time ? moment.tz(doc.data().time.seconds * 1000, timezone) : null,
      tweets,
      scheduled: doc.data().scheduled,
      user: doc.data().user,
      publishingError: doc.data().publishingError,
      lastAutoRTTime: doc.data().lastAutoRTTime,
      isFavorite: doc.data().isFavorite,
      deleted: doc.data().deleted,
      tweetIds: doc.data().tweetIds,
      postNow: doc.data().postNow,
      conditionalRetweetsConditions: doc.data().conditionalRetweetsConditions,
      autoplug: doc.data().autoplug,
      source: doc.data().source,
      writer: doc.data().writer,
      growthProgram: doc.data().growthProgram,
      tweetshot: doc.data().tweetshot,
      shareOnInstagram: doc.data().shareOnInstagram,
      linkedIn: doc.data().linkedIn,
      facebook: doc.data().facebook,
      delayBetweenTweets: doc.data().delayBetweenTweets,
      tweetMetricsUpdatedAt: doc.data().tweetMetricsUpdatedAt,
      categories: doc.data().categories,
      recurrentPostRef: doc.data().recurrentPostRef,
      replyToTweetId: doc.data().replyToTweetId,
      replyToTweetInfo: doc.data().replyToTweetInfo,
      isCancelled: doc.data().isCancelled,
      instagramCaption: doc.data().instagramCaption,
      isCloned: doc.data().isCloned,
      isRecurrentPost: doc.data().isRecurrentPost,
      timerData: doc.data().timerData,
      isPinned: doc.data().isPinned,
      ghostwritingRefusal: doc.data().ghostwritingRefusal,
      ghostwritingStatus: doc.data().ghostwritingStatus,
      impressionsCountOfTheFirstTweet: doc.data().impressionsCountOfTheFirstTweet,
      tweetshotContent: doc.data().tweetshotContent,
      instagramPublishingError: doc.data().instagramPublishingError,
      publishedToInstagram: doc.data().publishedToInstagram,
      autoDM: doc.data().autoDM,
      hasThreadFinisherTweet: doc.data().hasThreadFinisherTweet,
      created_at: moment(doc.data().created_at.toDate()),
      linkedInPublishingError: doc.data().linkedInPublishingError,
      isRecurrentPostDisabled: doc.data().isRecurrentPostDisabled,
      instagramThreadFinisherText: doc.data().instagramThreadFinisherText,
      lastClonePostedTime: doc.data().lastClonePostedTime,
      facebookPublishingError: doc.data().facebookPublishingError,
      isDeletedFromTwitter: doc.data().isDeletedFromTwitter,
      isLongTweetshot: doc.data().isLongTweetshot,
      isLargeFontTweetshot: doc.data().isLargeFontTweetshot,
      tweetReel: doc.data().tweetReel,
      ama: doc.data().ama,
      youtubeShortRef: doc.data().youtubeShortRef,
      threads: doc.data().threads,
    });
  }
}

export { Thread };
