<template>
  <section v-show="isActive" :id="hasAnchor ? computedId : ''">
    <slot />
  </section>
</template>

<script>
  export default {
    name: 'NewTab',
    props: {
      id: {
        type: [String, Number],
        default: null,
      },
      label: {
        type: String,
        required: true,
      },
      icon: {
        type: String,
        default: null,
      },
      hasAnchor: {
        type: Boolean,
        default: false,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      isHidden: {
        type: Boolean,
        default: true,
      },
    },
    data: () => ({
      isActive: false,
      isVisible: true,
    }),
    watch: {
      isHidden: {
        immediate: true,
        handler(value) {
          this.isVisible = value;
        },
      },
    },
    computed: {
      computedId() {
        return this.id ? this.id : this.label.toLowerCase().replace(/ /g, '-');
      },

      hash() {
        if (this.isDisabled) {
          return '#';
        }
        return '#' + this.computedId;
      },
    },
  };
</script>
