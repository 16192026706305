import App from './App.vue';
import DashboardPlugin from './plugins/dashboard-plugin';
import { initAcute } from './acute';
import amplitude from '@/amplitude';
import dao from '@/dao';
import { initBeamer } from './beamer';
import { initCrisp } from './crisp';
import { initPaddle } from './paddle';
import { initProfitWell } from './profitwell';
import { initMatomo } from './matomo';
import { initLogRocket } from './logrocket';
import initServerWorker from './service-worker-settings';
import { router as routerBuilder } from './routes/router';
import { store } from './store';
import { sync } from 'vuex-router-sync';
import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';
import LoadScript from 'vue-plugin-load-script';
import InlineSvg from 'vue-inline-svg';
import PortalVue from 'portal-vue';
import TouchEvents from 'vue2-touch-events';
import VueSelect from 'vue-select';
import VueForceNextTick from 'vue-force-next-tick';
import VueMasonry from 'vue-masonry-css';
import lodash from 'lodash';
import fetchFirebaseConfig from './firebase';
import controller from '@/controller';
import { downloadFile } from '@/util/downloadFile';
import { buildStorageMediaURL } from '@/config';
import { createPinia, PiniaVuePlugin } from 'pinia';
import { isUserPlanBasicOrAbove } from '@/../functions/src/util/customerStatus.cjs';

fetchFirebaseConfig.then((firebaseConfig) => {
  const config = require('./config').build(firebaseConfig);
  const pinia = createPinia();
  Vue.use(PortalVue);
  Vue.use(DashboardPlugin);
  Vue.use(LoadScript);
  Vue.use(TouchEvents);
  Vue.use(VueForceNextTick);
  Vue.use(VueMasonry);
  Vue.use(PiniaVuePlugin);

  VueClipboard.config.autoSetContainer = true;
  Vue.use(VueClipboard);

  Vue.component('inline-svg', InlineSvg);
  Vue.component('v-select', VueSelect);

  if (!window.location.href.includes(config.appURL)) {
    window.location = config.appURL;
  }

  const router = routerBuilder(store);

  sync(store, router);

  let app;
  const initApp = () => {
    if (app) {
      if (Vue.prototype.$eventStore) {
        Vue.prototype.$eventStore.visitApp();
      }
      return;
    }

    app = new Vue({
      el: '#app',
      render: (h) => h(App),
      store,
      router,
      pinia,
      created() {
        if (Vue.prototype.$eventStore) {
          Vue.prototype.$eventStore.visitApp();
        }
        initServerWorker(config.isAppDeployed, this);
        const urlParams = new URLSearchParams(window.location.search);
        if (
          !urlParams.has('switchToAccount') &&
          isUserPlanBasicOrAbove(lodash.get(store, 'getters.getUserProfile.customerStatus'))
        ) {
          initCrisp(config.crispWebsiteID, store);
        }

        if (window.location.pathname === '/create' && urlParams.get('design') === 'new') {
          router.replace('/queue');
          const userId = lodash.get(store, 'getters.getUserProfile.uid');
          dao.userProfile.toggleNewUI(userId, true);
        }

        if (config.isAppDeployed) {
          initAcute(store, Vue.loadScript);
        }
        initBeamer(store, Vue.loadScript);
        initPaddle(store, Vue.loadScript);
        initProfitWell(store);
        initMatomo(store);
        initLogRocket(store);
      },
    });
  };

  firebaseConfig.auth.onAuthStateChanged(async (user) => {
    if (user) {
      store.commit('setCurrentUser', user);
      const userProfile = await store.dispatch('fetchUserProfile');

      if (userProfile.isBeingDeleted === true) {
        console.warn(`User ${userProfile.uid} has been marked to be deleted. Stopping app load.`);
        return;
      }

      Vue.prototype.$eventStore = amplitude._initAndReturn();
      initApp();

      if (lodash.get(userProfile, 'notifications.enabled') === true && firebaseConfig.messaging) {
        try {
          firebaseConfig.messaging.onMessage((payload) => {
            if (lodash.get(payload, 'data.action', '') === 'PUBLISH_LINKED_IN_CAROUSEL') {
              const triggerDownload = () => {
                downloadFile(
                  buildStorageMediaURL(`carousel/${payload.data.fileName}.pdf`),
                  `${payload.data.fileName}.pdf`
                );
              };

              Vue.prototype.$notify({
                type: 'success',
                message: `Your LinkedIn carousel is ready. Click <span class="underline cursor-pointer">here</span> to download it.`,
                timeout: 60000,
                onClick: triggerDownload,
              });
            }
          });

          const deviceToken = await firebaseConfig.messaging.getToken({
            vapidKey: config.messagingVapidKey,
          });

          if (!lodash.get(userProfile, 'notifications.devicesTokens', []).includes(deviceToken)) {
            await controller.addDeviceNotificationsToken(user, userProfile.uid, deviceToken, true);
          }
        } catch (error) {
          console.error(
            `An error occurred while trying to add your device token. Notifications might be blocked.`,
            error
          );
        }
      }

      if (lodash.get(userProfile, 'managers', []).length > 0) {
        store.dispatch('fetchClientGhostwritingThreads');
      }

      _cio.identify({
        id: lodash.get(userProfile, 'uid'),
        created_at: lodash.get(userProfile, 'created_at.seconds'),
        email: lodash.get(userProfile, 'email'),
        name: lodash.get(userProfile, 'name'),
        customerStatus: lodash.get(userProfile, 'customerStatus'),
      });

      store.dispatch('updateIsUserFollowingHypefury');
      store.dispatch('fetchFollowedUsers');
      store.dispatch('fetchUserCategories');
      store.dispatch('fetchUserRecurrentPosts');
      store.dispatch('fetchUserAffiliateDetails');
      store.dispatch('fetchManagedAccountsAdditionalData');
      store.dispatch('fetchInspirationTweets');
      const twitterUserInfo = await store.dispatch('fetchTwitterUserInfo');
      dao.userProfile.updateUserProfileIfNecessary(userProfile, twitterUserInfo);
      dao.userProfile.updateAppLastUsedAt(userProfile.uid);
    } else {
      initApp();
    }
  });
});
