<template>
  <div class="relative inline-block h-full" v-click-outside="close">
    <div class="h-full">
      <new-button
        v-if="!extended"
        :type="buttonType"
        :size="buttonSize"
        :icon="icon"
        :disabled="disabled"
        :class="[buttonClasses, disabled && 'pointer-events-none']"
        @click.prevent="isOpen = !isOpen"
      >
        <slot name="button" :isOpen="isOpen">
          <span class="sr-only">{{ !isOpen ? 'Open' : 'Closed' }}</span>
          <inline-svg
            src="/img/icons/options-icon.svg"
            :class="['h-10 w-10', isOpen ? 'fill-main-color-100' : 'fill-current']"
          />
        </slot>
      </new-button>
      <div v-else class="flex">
        <slot name="extended-button"></slot>
        <new-button
          :icon="icon"
          :size="buttonSize"
          :type="extendedButtonType"
          @click.prevent="isOpen = !isOpen"
          data-cy="compose-schedule-open"
        >
          <inline-svg
            :src="
              isDesktop ? '/img/icons/new/modal-collapse.svg' : '/img/icons/arrow-right-icon.svg'
            "
            class="h-4 w-4 transform stroke-ds-button-primary-label"
            :class="{ 'h-4 w-4 -rotate-90': isDesktop, 'h-6 w-6': !isDesktop }"
          />
        </new-button>
      </div>
    </div>
    <portal to="modal">
      <transition
        enter-active-class="transition duration-100 ease-out"
        enter-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
        @enter="onEnter"
        @leave="onLeave"
      >
        <div
          ref="panel"
          v-if="isOpen"
          class="focus:outline-none z-300 mt-2 w-full rounded-lg bg-ds-foreground shadow-tooltip sm:w-max sm:max-w-md"
          :class="{
            'origin-top-right': placement === 'right',
            'origin-top-left': placement === 'left',
            'origin-top': placement === 'center',
            'p-4': padded,
            absolute: !fixed,
            'rounded-b-none rounded-t-2xl sm:rounded-lg ': isStickyOnMobile,
          }"
          role="menu"
          aria-labelledby="menu"
          tabindex="-1"
        >
          <slot :isOpen="isOpen"></slot>
        </div>
      </transition>
    </portal>
  </div>
</template>

<script>
  import { setAbsolutePosition } from '@/util/DomHandler.js';
  import BreakpointsMixin from '@/views/Mixins/BreakpointsMixin.vue';
  import lodash from 'lodash';

  export default {
    name: 'NewDropDown',
    mixins: [BreakpointsMixin],
    props: {
      fixed: {
        type: Boolean,
        default: false,
      },
      padded: {
        type: Boolean,
        default: true,
      },
      placement: {
        type: String,
        default: 'right',
        validator(value) {
          return ['right', 'left', 'center'].includes(value);
        },
      },
      buttonType: {
        type: String,
        default: 'transparent',
        validator(value) {
          return [
            'primary',
            'secondary',
            'success',
            'light',
            'transparent',
            'danger',
            'transparent-gray',
            'bordered',
            'custom',
          ].includes(value);
        },
      },
      buttonClasses: {
        type: [String, Object, Array],
      },
      buttonSize: {
        type: String,
        default: 'md',
        validator(value) {
          return ['md', 'lg', 'sm', 'xs'].includes(value);
        },
      },
      icon: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      isStickyOnMobile: {
        type: Boolean,
        default: true,
      },
      extended: {
        type: Boolean,
        default: false,
      },
      extendedButtonType: {
        type: String,
        default: 'rounded-right',
        validator(value) {
          return [
            'primary',
            'secondary',
            'rounded-right',
            'rounded-left',
            'icon',
            'custom',
          ].includes(value);
        },
      },
    },
    data() {
      return {
        isOpen: false,
      };
    },
    methods: {
      close(event) {
        const target = lodash.get(event, 'target', null);
        if (this.$refs.panel && this.$refs.panel.contains(target)) {
          return;
        }
        this.isOpen = false;
      },
      onEnter(el) {
        if (this.isSmallScreen && this.isStickyOnMobile) {
          document.body.classList.add('overflow-hidden', 'dropdown-open');
          setAbsolutePosition(el, this.$el, { bottom: 0, left: 0, right: 0 });
          return;
        }
        setAbsolutePosition(el, this.$el, null, this.fixed);
      },
      onLeave() {
        if (this.isSmallScreen && this.isStickyOnMobile) {
          document.body.classList.remove('overflow-hidden', 'dropdown-open');
        }
      },
    },
    beforeDestroy() {
      if (this.isSmallScreen && this.isStickyOnMobile) {
        this.onLeave();
      }
    },
  };
</script>
