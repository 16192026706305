<template>
  <div class="timeline-block" :class="{ 'timeline-inverted': inverted }">
    <slot name="badge">
      <span class="timeline-step timeline-step-new-ui" :class="[`badge-${badgeType}`]">
        <span>{{ title }}</span>
      </span>
    </slot>
    <div class="timeline-content overflow-hidden w-full" :class="additionalClasses">
      <slot></slot>
    </div>
  </div>
</template>
<script>

export default {
  name: 'time-line-item',
  props: {
    inverted: Boolean,
    title: String,
    badgeType: {
      type: String,
      default: 'success'
    },
    badgeIcon: {
      type: String,
      default: ''
    },
    additionalClasses: {
      type: String,
      default: ''
    }
  },
};
</script>
<style lang="scss">
  .timeline-step-new-ui {
    padding-bottom: 1.5rem;
    position: relative;
    padding-left: 1.5rem;
    margin-left: -0.5rem;
    border-left: 1px solid #a6adc1;

    &:before {
      content: '';
      width: 1.2rem;
      height: 1.2rem;
      background: white;
      border: solid 1.5px #a6adc1;
      outline: solid 5px white;
      border-radius: 100%;
      position: absolute;
      left: -10px;
      top: 2px;
    }
  }

  .mode--dark {
    .timeline-step-new-ui {
      border-left: 1px solid #8e96ae;
      &:before {
        background: #272a33;
        border: solid 1.5px #8e96ae;
        outline: solid 5px #272a33;
      }
    }
  }

  .timeline-step-new-ui > span {
    visibility: hidden;
  }
</style>
