import moment from 'moment';
import 'moment-timezone';
import { formatTimeWithUserLocale } from '@/util/formatTimeWithUserLocale';

class DMCampaign {
  constructor(id, time, user, autoDM, message) {
    this.id = id;
    this.time = time;
    this.user = user;
    this.autoDM = autoDM;
    this.message = message;
  }

  // Todo: this is duplicate from Post.js
  postingTimeData(timezone) {
    const threadTime = moment.tz(this.time, timezone);

    const time = formatTimeWithUserLocale(threadTime);
    let day = 'today';

    const midnightOfToday = moment.tz(timezone).startOf('day');
    const midnightOfTime = threadTime.startOf('day');
    if (midnightOfToday.diff(midnightOfTime, 'day') === 0) {
      day = 'today';
    } else if (midnightOfToday.diff(midnightOfTime, 'day') === -1) {
      day = 'tomorrow';
    } else if (midnightOfToday.diff(midnightOfTime, 'day') === 1) {
      day = 'yesterday';
    } else {
      day = threadTime.format('dddd');
      day += ' ' + threadTime.format('MMMM Do');
    }

    return { day, time };
  }

  static buildFromFirestore(doc, timezone) {
    return new DMCampaign(
      doc.id,
      moment.tz(doc.data().time.seconds * 1000, timezone),
      doc.data().user,
      doc.data().autoDM,
      doc.data().message
    );
  }
}

export { DMCampaign };
