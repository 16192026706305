<template>
  <component
    :is="tag"
    :class="[
      { disabled: disabled },
      { show: isOpen }, `drop${direction}`,
    ]"
    @click.stop="toggleDropDown"
    v-click-outside="closeDropDown"
  >
    <slot name="title-container" :is-open="isOpen">
      <component
        :is="titleTag"
        class="btn-rotate"
        :class="[{'dropdown-toggle': hasToggle}, titleClasses]"
        :aria-expanded="isOpen"
        data-toggle="dropdown"
      >
        <slot name="title" :is-open="isOpen">
          <i :class="icon"></i> {{ title }}
        </slot>
      </component>
    </slot>
    <div
      class="dropdown-menu dark-mode-bg bg-light"
      :class="[
        { show: isOpen },
        `dropdown-menu-${direction}`,
        { 'dropdown-menu-right': menuOnRight },
        { 'dropdown-menu-fixed': menuFixed },
        { 'dropdown-menu-account-switcher w-full': menuAccounts },
        menuClasses,
      ]"
    >
      <slot :is-open="isOpen"></slot>
    </div>
  </component>
</template>
<script>
export default {
  name: 'base-dropdown',
  props: {
    tag: {
      type: String,
      default: 'div',
      description: 'Dropdown html tag (e.g div, ul etc)'
    },
    titleTag: {
      type: String,
      default: 'button',
      description: 'Dropdown title (toggle) html tag'
    },
    title: {
      type: String,
      description: 'Dropdown title'
    },
    direction: {
      type: String,
      default: 'down', // up | down
      description: 'Dropdown menu direction (up|down)'
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      description: 'Dropdown icon'
    },
    titleClasses: {
      type: [String, Object, Array],
      description: 'Title css classes'
    },
    menuClasses: {
      type: [String, Object],
      description: 'Menu css classes'
    },
    menuOnRight: {
      type: Boolean,
      description: 'Whether menu should appear on the right'
    },
    menuFixed: {
      type: Boolean,
      description: 'Whether menu should appear fixed'
    },
    menuAccounts: {
      type: Boolean,
      description: 'Whether menu is the account switcher'
    },
    hasToggle: {
      type: Boolean,
      description: 'Whether dropdown has arrow icon shown',
      default: true
    }
  },
  data() {
    return {
      isOpen: false
    };
  },
  methods: {

    toggleDropDown(e) {
        this.menuFixed && this.toggleClasses();
      var element = e.target.classList;
      if (element.contains('vc-svg-icon') || element.contains('vc-arrow') || element.contains('vc-header') || element.contains('vc-header') ) {
        // don't close dropdown, this is needed for v-calendar in the queue-calendar
        return;
      } else {
        if (this.disabled) return;
        this.isOpen = !this.isOpen;
        this.$emit('change', this.isOpen);
      }
    },
    closeDropDown(e) {
      this.menuFixed && document.body.classList.remove('dropdown-open', 'overflow-hidden');
      this.isOpen = false;
      this.$emit('change', false);
    },
      toggleClasses() {
        document.body.classList.toggle('dropdown-open');
        document.body.classList.toggle('overflow-hidden');
      },
    },
    beforeDestroy() {
      this.closeDropDown();
    },
  };
</script>
<style lang="scss" scoped>
.dropdown {
  cursor: pointer;
  user-select: none;
}
.disabled {
  @apply pointer-events-none;
}
</style>
