<template>
  <a class="hover:text-primary" v-if="!isLoading" href="javascript:;" @click="confirmUserForUpgrade">
    <slot></slot>
  </a>
  <span v-else>
    <slot></slot>
    <span class="overlay" v-if="isLoading">
      <span class="loader fixed" style="z-index: 1000">
        <span class="loading loading-lg">
          <inline-svg src="/img/icons/loading.svg"></inline-svg>
        </span>
      </span>
    </span>
  </span>
</template>

<script>
  import controller from '@/controller';
  import { getPricesForUser, getStripePricesForUser } from '@/util/getPricesForUser';
  import { mapGetters } from 'vuex';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import CustomerCountryMixin from '@/views/Mixins/CustomerCountryMixin.vue';
  import { v4 as publicIpv4 } from 'public-ip';
  import CustomerStatusMixin from '../views/Mixins/CustomerStatusMixin.vue';

  export default {
    data() {
      return {
        isLoading: false,
        unsubscribe: null,
      };
    },
    name: 'upgrade-to-premium-link',
    computed: {
      ...mapGetters({
        currentUser: 'getCurrentUser',
        userProfile: 'getUserProfile',
        parentUserProfile: 'getParentUserProfile',
      }),
      customerStatus() {
        if (!this.parentUserProfile) {
          return null;
        }
        return this.parentUserProfile.customerStatus;
      },
    },
    props: {
      contentForNonSubscribers: {
        type: String,
      },
      show: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      async confirmUserForUpgrade() {
        if (this.isUserNotSubscribed(this.parentUserProfile.customerStatus)) {
          return this.swalModal({
            title: `Unlock this feature`,
            html:
              this.contentForNonSubscribers ||
              `Grab a subscription to the ${this.getPlanLabel(
                'premium'
              )} plan and unlock this and a ton of other powerful features!`,
            type: 'info',
            confirmButtonText: 'Go to Billing',
            cancelButtonText: 'No, thanks',
            showCancelButton: true,
            onClose: this.close,
            preConfirm: () => {
              this.$router.push('/billing');
              return true;
            },
          });
        }

        try {
          this.isLoading = true;
          await this.getCustomerCountry();

          const customerIp = await publicIpv4();

          const prices = this.parentUserProfile.isStripeCustomer
            ? (await controller.getStripePlansPrices(this.currentUser)).prices
            : await controller.getPlansPrices(this.currentUser, customerIp);

          this.isLoading = false;

          const { premium, standard, basic } = this.parentUserProfile
            .isStripeCustomer
            ? getStripePricesForUser(this.parentUserProfile, prices)
            : getPricesForUser(this.parentUserProfile, prices);

          let discountMessage = '';
          let newPriceInfo = null;

          let priceComparisonToShow = standard;
          if (this.parentUserProfile.customerStatus === 'basic') {
            priceComparisonToShow = basic;
          }

          this.swalModal({
            title: `Unlock this feature`,
            html: `${discountMessage}Unlock the full potential by upgrading to Hypefury ${this.getPlanLabel(
              'premium'
            )}!<br>
            You will be billed <b>${premium}</b> instead of ${priceComparisonToShow}.`,
            type: 'info',
            showCancelButton: true,
            confirmButtonText: 'Yes, I want the best plan!',
            cancelButtonText: "No, thanks, I'm good",
            preConfirm: () => this.upgradeUserToPremium(newPriceInfo),
          });
        } catch (error) {
          console.error(`An error occurred while getting plan prices: ${error}`);
        } finally {
          this.isLoading = false;
        }
      },
      upgradeUserToPremium(newPriceInfo = null) {
        if (this.customerStatus === 'none') {
          this.$router.push('/billing');
          return;
        }
        this.isLoading = true;
        const upgradeUserToPremium = this.parentUserProfile.isStripeCustomer
          ? controller.upgradeStripeUserToPremium
          : controller.upgradeUserToPremium;
        return upgradeUserToPremium(this.currentUser, newPriceInfo).catch((error) => {
          this.isLoading = false;
          this.swalModal({
            title: 'Sorry',
            text: 'An error occurred while upgrading your account.',
            type: 'error',
          });
          console.error(error);
        });
      },
    },
    watch: {
      customerStatus(val, old) {
        if (val === 'premium' && old !== 'premium' && this.isLoading) {
          this.isLoading = false;
          const message = `Upgraded to ${this.getPlanLabel('premium')} successfully!`;
          this.$notify({ type: 'success', message });
        }
      },
    },
    mixins: [SwalModalMixin, CustomerCountryMixin, CustomerStatusMixin],
  };
</script>
