<template>
  <div class="w-full">
    <div class="relative w-full">
      <input
        :value="value"
        @input="updateValue"
        type="number"
        v-bind="$attrs"
        v-on="listeners"
        :placeholder="placeholder"
        :class="{
          sm: size === 'sm',
          md: size === 'md',
          'error-input': error,
        }"
      />
      <span
        v-if="$slots.icon && !error"
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
      >
        <slot name="icon" />
      </span>
      <span
        v-if="error"
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
      >
        <svg class="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
      </span>
    </div>
    <slot name="error">
      <p v-if="error" class="mt-1 text-xs text-red-500">{{ error }}</p>
    </slot>
  </div>
</template>

<script>
  export default {
    name: 'BaseNumberInput',
    inheritAttrs: false,
    props: {
      value: {
        type: Number,
        required: true,
      },
      placeholder: {
        type: String,
        default: '',
      },
      size: {
        type: String,
        default: 'md',
        validator: (value) => ['sm', 'md'].includes(value),
      },
      error: {
        type: String,
        default: null,
      },
    },
    computed: {
      listeners() {
        return {
          ...this.$listeners,
          input: this.updateValue,
        };
      },
    },
    methods: {
      updateValue(event) {
        this.$emit('input', parseFloat(event.target.value));
      },
    },
  };
</script>

<style lang="scss" scoped>
  .new-ui {
    input {
      @apply transition outline-none block w-full rounded-lg p-4 duration-150 ease-in-out;
    }
    input.sm {
      @apply h-10;
    }
    input.md {
      @apply h-12;
    }
    input.error-input {
      @apply border border-red-500 text-red-600;
    }
    &.mode--dark {
      input {
        @apply border-0 bg-dark-mode-70;
      }
      input::placeholder {
        @apply text-dark-mode-30;
      }
      input.error-input {
        @apply border;
      }
    }

    &.mode--light {
      input {
        @apply border border-gray-90 bg-white;
      }
      input::placeholder {
        @apply text-gray-80;
      }
    }
  }
</style>
