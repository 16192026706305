<template>
  <div class="overscroll-behavior-contain mb-13 lg:mb-0">
    <div data-cy="composer-instagram">
      <div ref="container" class="mb-5 flex flex-col space-y-2">
        <div class="space-y-4">
          <div class="flex items-center gap-4">
            <img :src="userProfile.photoURL" :alt="userProfile.name" class="avatar-img" />
            <span class="avatar-name">{{ userProfile.name }}</span>
          </div>
          <VueSlickCarousel
            :key="isThreadFinisherEnabled ? '0' : '1'"
            ref="carousel"
            :infinite="false"
            :arrows="false"
            :dots="true"
          >
            <div ref="firstSlide" class="someclass">
              <div
                class="border relative mx-auto mt-2 flex w-full items-center justify-center rounded-lg border-gray-90 px-8 py-13 lg:max-w-md"
                :style="`min-height: ${isLongTweetshot ? '30rem' : '24rem'}`"
              >
                <div
                  v-if="isLoadingTweetshot || !shouldDisplayTweetshotPreview"
                  class="dark-mode-bg absolute flex h-full w-full items-center justify-center rounded-lg bg-gray-60"
                >
                  <div
                    v-if="!shouldDisplayTweetshotPreview"
                    class="flex h-full w-full items-center justify-center"
                  >
                    <span v-if="isPostOfTypeInstagram">Select</span>
                    <span v-else>Input</span>
                    &nbsp;tweets to see a preview here
                  </div>
                  <div v-else class="loader" style="width: 24rem; height: 24rem">
                    <span class="loading loading-lg">
                      <inline-svg src="/img/icons/loading.svg" />
                    </span>
                  </div>
                </div>
                <div
                  v-else
                  class="dark-mode-bg absolute top-0 flex h-full w-full items-center justify-center rounded-lg bg-gray-60 px-8 text-center text-lg opacity-0 duration-200 ease-in hover:opacity-75"
                >
                  This is just a preview.<br />
                  If you have a thread, all of its tweets will be posted as part of the carousel.
                </div>
                <img
                  :src="tweetshotUrl"
                  class="tweet-shot-preview rounded-lg"
                  alt="tweetShot preview"
                />
              </div>
            </div>
            <div v-if="isThreadFinisherEnabled">
              <div
                class="border relative mx-auto mt-2 flex w-full flex-col items-start justify-center gap-y-4 rounded-lg border-gray-90 px-8 py-13 lg:max-w-md"
                :style="`height: ${slideHeight}px`"
              >
                <div class="flex items-center gap-4">
                  <img :src="userProfile.photoURL" :alt="userProfile.name" class="avatar-img" />
                  <span class="avatar-name">{{ userProfile.name }}</span>
                </div>
                <textarea
                  dir="auto"
                  class="form-control simple"
                  :style="`min-height: 10rem`"
                  rows="3"
                  ref="threadFinisherText"
                  v-model="threadFinisherText"
                  placeholder="Add text for your custom last carousel slide here..."
                  id="instagramCaption"
                  maxlength="280"
                  @focus="onFocus"
                  @blur="$emit('blurred')"
                ></textarea>
              </div>
            </div>
          </VueSlickCarousel>
          <div class="ml-0 sm:ml-13">
            <base-input alternative>
              <textarea
                dir="auto"
                class="form-control simple min-h-16"
                ref="instagramCaption"
                rows="3"
                v-model="instagramCaption"
                placeholder="Caption goes here..."
                id="instagramCaption"
                maxlength="2200"
                @focus="onFocus"
                @blur="$emit('blurred')"
                @keyup="resize"
                @keydown.ctrl.enter="$emit('pressed-ctrl-enter')"
                @keydown.meta.enter="$emit('pressed-ctrl-enter')"
                data-cy="composer-ig-caption-input"
              ></textarea>
              <portal-target
                name="instagram_post_media"
                multiple
                class="thread-preview focus--hide mb-20 space-y-2 p-2"
              />
              <portal-target name="tweetshot-preview" />
            </base-input>
            <portal-target
              :name="`instagram-actions-${tweet.guid}`"
              v-if="isDesktop"
              class="px-2.5"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      data-cy="composer-instagram-error"
      v-if="validationMessage"
      class="pt-2 text-right text-badge-error"
    >
      {{ validationMessage }}
    </div>
    <portal :to="`instagram-actions-${tweet.guid}`">
      <div class="flex items-center justify-end gap-6">
        <tooltip
          data-cy="compose-upload-media"
          :content="uploadContainerTooltipContent"
          v-if="isPostOfTypeInstagram"
        >
          <upload-container
            :tweet="tweet"
            portalTargetName="instagram_post_media"
            :canAddMedia="tweet.mediaFile.length < 10 && tweetshotContent.length === 0"
            :canAddMoreVideos="tweet.mediaFile.length < 10 && tweetshotContent.length === 0"
            postType="instagram"
            :clearMediaSelectionAtIndexParent="clearMediaSelectionAtIndex"
            :uploadingMedia="uploadingMedia"
          />
        </tooltip>
        <tooltip
          data-cy="compose-tweetshots"
          v-if="isPostOfTypeInstagram"
          :content="
            tweet.mediaFile.length > 0 ? 'Cannot post tweetshots with images' : 'Tweetshots'
          "
        >
          <div>
            <new-button
              type="custom"
              @click="$emit('show-tweetshot-modal')"
              :disabled="tweet.mediaFile.length > 0"
            >
              <inline-svg class="h-5 w-5 text-main-color-100" src="/img/icons/tweetshot.svg" />
            </new-button>
          </div>
        </tooltip>
        <emoji-picker @selected-emoji="addEmoji($event)" />
        <new-drop-down
          icon
          data-cy="instagram-long-format-dropdown"
          buttonType="bordered"
          buttonSize="sm"
        >
          <template v-slot:button="slotProps">
            <inline-svg
              src="/img/icons/arrow-down-icon.svg"
              class="h-2 w-2 stroke-current"
              :class="['transform', slotProps.isOpen ? 'rotate-180' : 'rotate-0']"
            />
          </template>
          <template>
            <div class="flex flex-col gap-y-3">
              <div
                v-if="!isPostOfTypeInstagram"
                class="flex items-center justify-between gap-x-3 px-3 text-sm"
                :class="{
                  'text-gray-80': !isThreadFinisherEnabled,
                  'dropdown-main-text': isThreadFinisherEnabled,
                }"
              >
                <div class="flex items-center gap-x-3">
                  <inline-svg class="h-5 w-5" src="/img/icons/new/custom-slide.svg" />
                  Add custom last carousel slide
                </div>
                <base-toggle
                  data-cy="instagram-large-font-toggle"
                  v-model="isThreadFinisherEnabled"
                />
              </div>
              <div
                class="flex items-center justify-between gap-x-3 px-3 text-sm"
                :class="{
                  'text-gray-80': !isLongTweetshot,
                  'dropdown-main-text': isLongTweetshot,
                }"
              >
                <div class="flex items-center gap-x-3">
                  <inline-svg class="h-5 w-5" src="/img/icons/long-tweetshots.svg" />
                  Use long format
                </div>
                <base-toggle data-cy="instagram-long-format-toggle" v-model="isLongTweetshot" />
              </div>
              <div
                class="flex items-center justify-between gap-x-3 px-3 text-sm"
                :class="{
                  'text-gray-80': !isLargeFontTweetshot,
                  'dropdown-main-text': isLargeFontTweetshot,
                }"
              >
                <div class="flex items-center gap-x-3">
                  <inline-svg class="h-5 w-5" src="/img/icons/font_size.svg" />
                  Use large font
                </div>
                <base-toggle data-cy="instagram-large-font-toggle" v-model="isLargeFontTweetshot" />
              </div>
            </div>
          </template>
        </new-drop-down>
      </div>
    </portal>
  </div>
</template>

<script>
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import { mapGetters } from 'vuex';
  import lodash from 'lodash';
  import InstagramMixin from '@/views/Mixins/InstagramMixin.vue';
  import UploadContainerMixin from '@/views/Mixins/UploadContainerMixin.vue';
  import EmojiPicker from '@/components/Threads/EmojiPicker.vue';
  import UploadContainer from '@/components/UploadContainer.vue';
  import { countTweetLength } from '@/../functions/src/util/countTweetLength';
  import NewThreadMixin from '@/views/Mixins/NewThreadMixin.vue';
  import controller from '@/controller';
  import BreakpointsMixin from '@/views/Mixins/BreakpointsMixin.vue';
  import VueSlickCarousel from 'vue-slick-carousel';
  const config = require('@/config');

  export default {
    components: {
      UploadContainer,
      EmojiPicker,
      VueSlickCarousel,
    },
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
      charactersLeftClass() {
        if (this.instagramCaption.length >= 2200) return 'character-count-exceeded';
        if (this.instagramCaption.length > 2200 - 100) return 'character-count-warning';
        return 'bg-light';
      },
      charactersLeftCounter() {
        return 2200 - this.instagramCaption.length;
      },
      shouldDisplayTweetshotPreview() {
        return (
          (!window.Cypress && !this.isPostOfTypeInstagram && this.tweet.status.trim().length > 0) ||
          this.tweetshotContent.length > 0
        );
      },
      threadFinisherCharactersLeftClass() {
        if (countTweetLength(this.threadFinisherText) >= 280) return 'character-count-exceeded';
        if (countTweetLength(this.threadFinisherText) > 280 - 20) return 'character-count-warning';
        return 'bg-light';
      },
      threadFinisherCharactersLeftCounter() {
        return 280 - countTweetLength(this.threadFinisherText);
      },
      uploadContainerTooltipContent() {
        return this.tweet.mediaFile.length === 10
          ? 'Cannot add more than 10 media'
          : this.tweetshotContent.length > 0
          ? 'Cannot post images with tweetshots'
          : 'Upload media';
      },
    },
    mounted() {
      this.updateValuesFromUserProfile();
      this.resize();
    },
    props: {
      isMobileKeyboardVisible: {
        type: Boolean,
        default: false,
      },
      tweetshotContent: {
        type: Array,
        default: () => [],
      },
      tweet: {
        type: Object,
        default: null,
      },
      isPostOfTypeInstagram: {
        type: Boolean,
        default: false,
      },
      hasMultipleTweets: {
        type: Boolean,
        default: false,
      },
      isVisible: {
        type: Boolean,
        default: false,
      },
      canShareOnInstagram: {
        type: Boolean,
        default: false,
      },
      originalThread: {
        type: Object,
        default: null,
      },
      isShareOnInstagramEnabled: {
        type: Boolean,
        default: false,
      },
      uploadingMedia: Array,
    },
    data() {
      return {
        instagramCaption: '',
        threadFinisherText: '',
        isThreadFinisherEnabled: false,
        previousHeight: 0,
        tweetshotUrl: '/img/tweetshot_placeholder_blank.png',
        isLoadingTweetshot: false,
        currentTweetshotText: '',
        currentTweetshotMedia: null,
        isLongTweetshot: false,
        isLargeFontTweetshot: false,
        slideHeight: 0,
      };
    },
    updated() {
      this.updateParentValues();
      this.resize();
    },
    methods: {
      addEmoji(emoji) {
        const captionRef = this.$refs.instagramCaption;
        const position = captionRef.selectionStart;
        this.instagramCaption = [
          this.instagramCaption.slice(0, position),
          emoji.native,
          this.instagramCaption.slice(position),
        ].join('');
        const positionAfterTweet = position + 2;

        this.$nextTick(() => {
          captionRef.focus();
          captionRef.setSelectionRange(positionAfterTweet, positionAfterTweet);
        });
      },
      clearMediaSelectionAtIndex(tweet, index) {
        this.$emit('cleared-media', tweet, index);
      },
      updateParentValues() {
        this.$emit('values-set', {
          instagramCaption: this.instagramCaption,
          instagramThreadFinisherText: this.isThreadFinisherEnabled
            ? this.threadFinisherText
            : null,
          isLongTweetshot: this.isLongTweetshot,
          isLargeFontTweetshot: this.isLargeFontTweetshot,
        });
      },
      updateValuesFromUserProfile() {
        this.instagramCaption =
          lodash.get(this, 'userProfile.integrations.instagram.instagramCaption', '') || '';
        if (!this.instagramCaption) return;
        this.updateParentValues();
      },
      updateValuesFromThread(thread) {
        const instagramCaption =
          thread.source === 'instagram-post' ? thread.tweets[0].status : thread.instagramCaption;
        this.instagramCaption = instagramCaption || '';
        this.isLongTweetshot = thread.isLongTweetshot;
        this.isLargeFontTweetshot = thread.isLargeFontTweetshot;
        this.isThreadFinisherEnabled = !!thread.instagramThreadFinisherText;
        this.threadFinisherText = thread.instagramThreadFinisherText || '';
      },
      resize() {
        const { instagramCaption } = this.$refs;
        if (instagramCaption && instagramCaption.scrollHeight > 100) {
          instagramCaption.style.height = instagramCaption.scrollHeight - 4 + 'px';
        }
      },
      async generateTweetshotPreview(tweetStatus, mediaURL) {
        this.isLoadingTweetshot = true;
        try {
          this.tweetshotUrl = await controller.generateTweetshotFromText(
            this.currentUser,
            this.userProfile.uid,
            tweetStatus,
            mediaURL,
            this.isLongTweetshot,
            this.isLargeFontTweetshot
          );
        } catch (error) {
          this.swalModal({
            title: 'Error',
            text: `An error occurred while creating the preview of your tweet.`,
            type: 'error',
          });
        }
        this.currentTweetshotText = tweetStatus;
        this.currentTweetshotMedia = mediaURL;
        this.isLoadingTweetshot = false;
        setTimeout(() => {
          this.resizeCarouselSlides();
        }, 200);
      },
      onFocus() {
        this.$emit('focused');
        this.resize();
      },
      resizeCarouselSlides() {
        const firstSlideRef = this.$refs.firstSlide;
        if (firstSlideRef) {
          this.slideHeight = firstSlideRef.offsetHeight;
        }
      },
    },
    watch: {
      isVisible(visible) {
        if (visible) {
          const isMediaUpdated =
            (
              this.tweet.media[0] &&
              config.buildStorageMediaURL(this.tweet.media[0].name) !== this.currentTweetshotMedia
            ) ||
            (this.currentTweetshotMedia && !this.tweet.media[0]);
          if (
            this.shouldDisplayTweetshotPreview &&
            (this.currentTweetshotText !== this.tweet.status || isMediaUpdated)
          ) {
            const mediaURL = this.tweet.media[0]
              ? config.buildStorageMediaURL(this.tweet.media[0].name)
              : null;
            this.generateTweetshotPreview(this.tweet.status, mediaURL);
          }
          this.$nextTick(() => {
            if (this.$refs.instagramCaption && !this.isTabletOrMobile) {
              this.$refs.instagramCaption.focus();
            }
            this.resize();
          });
        }
      },
      tweetshotContent() {
        if (
          this.tweetshotContent.length &&
          this.currentTweetshotText !== this.tweetshotContent[0].tweetText
        ) {
          this.generateTweetshotPreview(this.tweetshotContent[0].tweetText);
        }
      },
      originalThread(thread) {
        if (thread) {
          this.updateValuesFromThread(thread);
        }
      },
      isLongTweetshot() {
        if (this.tweetshotContent.length) {
          this.generateTweetshotPreview(this.tweetshotContent[0].tweetText);
        } else if (this.shouldDisplayTweetshotPreview) {
          this.generateTweetshotPreview(this.tweet.status);
        }
      },
      isLargeFontTweetshot() {
        if (this.tweetshotContent.length) {
          this.generateTweetshotPreview(this.tweetshotContent[0].tweetText);
        } else if (this.shouldDisplayTweetshotPreview) {
          this.generateTweetshotPreview(this.tweet.status);
        }
      },
      isThreadFinisherEnabled() {
        this.resizeCarouselSlides;
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.carousel.next();
          }, 100);
        });
      },
    },
    mixins: [
      InstagramMixin,
      UploadContainerMixin,
      NewThreadMixin,
      SwalModalMixin,
      BreakpointsMixin,
    ],
  };
</script>

<style src="vue-slick-carousel/dist/vue-slick-carousel.css"></style>

<style lang="scss">
  .slick-dots {
    background-color: transparent;
    list-style: none;
    display: flex !important;
    align-items: center;
    justify-content: center;

    button {
      color: transparent;
      outline: none;
    }

    button:before {
      color: var(--outline-secondary);
      background-color: transparent;
      content: '•';
      font-size: 16px;
    }

    .slick-active button:before {
      color: var(--text-secondary-tertiary);
    }
  }
</style>

<style lang="scss" scoped>
  .tweet-shot-preview {
    width: 100%;
    @include sm {
      width: 24rem;
    }
  }
</style>
