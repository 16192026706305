<script>
  import { mapGetters } from 'vuex';
  import lodash from 'lodash';

  export default {
    name: 'facebook-mixin',
    methods: {},
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
      isUserConnectedToFacebook() {
        return lodash.get(this.userProfile, 'integrations.facebook.tokenInfo.accessToken');
      },
      isUserConnectedToFacebookWithPage() {
        return (
          lodash.get(this.userProfile, 'integrations.facebook.tokenInfo') &&
          lodash.get(this.userProfile, 'integrations.facebook.page.accessToken')
        );
      },
      isFacebookPageConnected() {
        return lodash.get(this.userProfile, 'integrations.facebook.page.connected') === true;
      },
      isFacebookAccountValid() {
        return lodash.get(this.userProfile, 'integrations.facebook.page.id');
      },
      facebookPages() {
        return lodash.get(this.userProfile, 'integrations.facebook.pages', []);
      },
      selectedFacebookPageId() {
        return lodash.get(this.userProfile, 'integrations.facebook.page.id', null);
      },
      facebookGroups() {
        return lodash.get(this.userProfile, 'integrations.facebook.groups', []);
      },
      isAnyFacebookGroupSelected() {
        return this.facebookGroups.some((group) => group.isSelected);
      },
    },
  };
</script>
