<script>
  import MobileMixin from '@/views/Mixins/MobileMixin.vue';
  // this mixin is used to detect the screen size following the breakpoints on _breakpoints.scss file
  const breakpoints = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
  };

  export default {
    name: 'breakpointsMixin',
    mixins: [MobileMixin],
    data() {
      return {
        match: false,
        mediaQuery: null,
      };
    },
    methods: {
      init(query) {
        this.mediaQuery = window.matchMedia(query);
        if (!this.mediaQuery) {
          return;
        }
        // just for Compatibility because addEventListener is not fully supported
        if ('addEventListener' in this.mediaQuery)
          this.mediaQuery.addEventListener('change', this.handler);
        else {
          this.mediaQuery.addListener(this.handler);
        }
        this.match = this.mediaQuery.matches;
      },
      handler(event) {
        this.match = event.matches;
      },
      getValue(breakPoint, delta) {
        let value = breakpoints[breakPoint];
        if (delta) {
          value += delta;
        }

        if (typeof value === 'number') {
          return `${value}px`;
        }

        return value;
      },
      greaterThan(breakPoint) {
        const query = `(min-width: ${this.getValue(breakPoint, 0.1)})`;
        this.init(query);
        return this.match;
      },
      greaterOrEqual(breakPoint) {
        const query = `(min-width: ${this.getValue(breakPoint)})`;
        this.init(query);
        return this.match;
      },
      smallerThan(breakPoint) {
        const query = `(max-width: ${this.getValue(breakPoint, -0.1)})`;
        this.init(query);
        return this.match;
      },
      smallerOrEqual(breakPoint) {
        const query = `(max-width: ${this.getValue(breakPoint)})`;
        this.init(query);
        return this.match;
      },
    },
    computed: {
      isSmallScreen() {
        return this.smallerThan('sm');
      },
      isTabletOrMobile() {
        return this.smallerOrEqual('md');
      },
      isDesktop() {
        return this.greaterOrEqual('lg');
      },
    },
    destroyed() {
      if (!this.mediaQuery) {
        return;
      }
      if ('removeEventListener' in this.mediaQuery)
        this.mediaQuery.removeEventListener('change', this.handler);
      else {
        this.mediaQuery.removeListener(this.handler);
      }
    },
  };
</script>
