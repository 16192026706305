<template>
  <div>
    <div class="flex items-center gap-4">
      <img
        :src="profilePictureURL"
        :alt="`${userDisplayName} profile picture`"
        class="avatar-img"
      />
      <span class="avatar-name">{{ userDisplayName }}</span>
    </div>
    <p
      class="word-break ml-13 mt-4 whitespace-pre-wrap break-words text-base"
      v-html="text"
      dir="auto"
    ></p>
    <div class="grid grid-cols-3 gap-2 pt-2">
      <div class="border rounded-lg border-gray-10 p-1" v-for="(mediaFile, i) in media" :key="i">
        <img
          v-if="['image', 'gif'].includes(mediaFile.type)"
          :src="mediaFile.url"
          class="h-full rounded-lg object-cover object-center"
        />
        <video
          v-if="mediaFile.type === 'video'"
          :src="mediaFile.url"
          class="h-full w-full rounded-lg"
        />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'FacebookPreview',
    props: {
      profilePictureURL: {
        type: String,
        default: '',
        description: "Tweet's profile picture URL",
      },
      userDisplayName: {
        type: String,
        default: '',
        description: "Tweet's user display name",
      },
      text: {
        type: String,
        default: '',
        description: 'Facebook text content',
      },
      media: {
        type: Array,
        default() {
          return [];
        },
        description: 'Tweet media',
      },
    },
  };
</script>
