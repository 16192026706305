export function adjustTextAreaHeight(tweetRef, tweetContainerRef, previousHeight) {
  if (!tweetRef || !tweetRef.scrollHeight || !tweetContainerRef) {
    return;
  }
  if (previousHeight) tweetContainerRef.style.height = previousHeight + 'px';
  tweetRef.style.height = 'auto';
  tweetRef.style.height = tweetRef.scrollHeight + 'px';
  tweetContainerRef.style.height = tweetRef.scrollHeight + 'px';
  return tweetRef.scrollHeight;
}
