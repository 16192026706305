<script>
  import CustomerStatusMixin from './CustomerStatusMixin.vue';
  import SwalModalMixin from './SwalModalMixin.vue';
  import { mapGetters } from 'vuex';
  import lodash from 'lodash';

  export default {
    mixins: [SwalModalMixin, CustomerStatusMixin],
    data() {
      return {
        nextPlan: null,
        nextPlanPopUpContent: null,
        showNextPlanPopUp: false,
      };
    },
    computed: {
      ...mapGetters({
        parentUserProfile: 'getParentUserProfile',
        userProfile: 'getUserProfile',
      }),
      customerStatus() {
        if (this.parentUserProfile) {
          return this.parentUserProfile.customerStatus;
        }
        return this.userProfile.customerStatus;
      },
      plansAboveCurrentPlan() {
        return this.getPlansAboveCurrentPlan(this.customerStatus);
      },
      nextAutoDMLimitPlan() {
        const nextPlan = this.getNextPlan('dailyAutoDMs');

        if (!nextPlan) {
          return null;
        }

        return nextPlan;
      },
    },
    methods: {
      getNextPlan(fieldName) {
        return this.plansAboveCurrentPlan.find(
          (plan) =>
            lodash.get(this.PLANS[plan.name].limits, fieldName) >
            lodash.get(this.PLANS[this.customerStatus].limits, fieldName),
        );
      },
      openCategoriesLimitNextPlanPopUp() {
        const currentPlanCategoriesLimit =
          this.PLANS[this.customerStatus].limits.categories;
        const nextPlan = this.getNextPlan('categories');
        const nextPlanLimits = this.PLANS[nextPlan.name].limits.categories;
        const nextPlanLimitsMessage =
          nextPlanLimits === Infinity ? 'an unlimited number of' : `up to ${nextPlanLimits}`;

        this.nextPlan = nextPlan;
        this.nextPlanPopUpContent = `As a ${this.userPlan} plan user you are allowed to enable up to ${currentPlanCategoriesLimit} categories, by upgrading to ${nextPlan.label} plan you can enable ${nextPlanLimitsMessage} categories. <br />Would you like to upgrade?`;
        this.showNextPlanPopUp = true;
      },
      openSchedulingLimitNextPlanPopUp() {
        const nextPlan = this.plansAboveCurrentPlan.find((plan) => {
          const planLimits = this.PLANS[plan.name].limits.scheduling;
          return (
            planLimits.unit !== this.userSchedulingLimit.unit ||
            planLimits.value > this.userSchedulingLimit.value
          );
        });
        const nextPlanLimits = this.PLANS[nextPlan.name].limits.scheduling;
        const nextPlanLimitsMessage =
          nextPlanLimits.value === Infinity
            ? '1 year'
            : `${nextPlanLimits.value} ${nextPlanLimits.unit}`;

        this.nextPlan = nextPlan;
        this.nextPlanPopUpContent = `Upgrade to the ${nextPlan.label} plan to be able to schedule up to ${nextPlanLimitsMessage} ahead.`;
        this.showNextPlanPopUp = true;
      },
      openSalesLimitNextPlanPopUp() {
        const currentPlanSalesLimit =
          this.PLANS[this.customerStatus].limits.weeklySales;
        const nextPlan = this.getNextPlan('weeklySales');
        const nextPlanLimits = this.PLANS[nextPlan.name].limits.weeklySales;
        const nextPlanLimitsMessage =
          nextPlanLimits === Infinity ? 'unlimited sales' : `${nextPlanLimits} per week`;

        this.nextPlan = nextPlan;
        this.nextPlanPopUpContent = `As a ${this.userPlan} plan user you are allowed to create ${currentPlanSalesLimit} sales per week. Upgrade to the ${nextPlan.label} plan to be able to create ${nextPlanLimitsMessage}.`;
        this.showNextPlanPopUp = true;
      },
      openFeedLimitNextPlanPopUp(fieldName) {
        const currentPlanFeedLimit =
          this.PLANS[this.customerStatus].limits.feed[fieldName];
        const nextPlan = this.getNextPlan(`feed.${fieldName}`);
        const nextPlanLimits = this.PLANS[nextPlan.name].limits.feed[fieldName];
        const nextPlanLimitsMessage =
          nextPlanLimits === Infinity ? 'an unlimited number of' : `up to ${nextPlanLimits}`;

        const featureTerm = fieldName === 'watchedUsers' ? 'watch' : 'add';
        const featureName = fieldName === 'watchedUsers' ? 'users' : 'search terms';
        this.nextPlan = nextPlan;
        this.nextPlanPopUpContent = `As a ${
          this.userPlan
        } plan user you are allowed to ${featureTerm} ${currentPlanFeedLimit} ${
          currentPlanFeedLimit === 1 ? featureName.slice(0, -1) : featureName
        }. Upgrade to the ${
          nextPlan.label
        } plan to be able to be able to ${featureTerm} ${nextPlanLimitsMessage} ${
          nextPlanLimitsMessage === 1 ? featureName.slice(0, -1) : featureName
        }.`;
        this.showNextPlanPopUp = true;
      },
      openAutoDMLimitNextPlanPopUp() {
        const currentPlanAutoDMLimit =
          this.PLANS[this.customerStatus].limits.dailyAutoDMs;
        const nextPlanLimits = this.PLANS[this.nextAutoDMLimitPlan.name].limits.dailyAutoDMs;

        this.nextPlan = this.nextAutoDMLimitPlan;
        this.nextPlanPopUpContent = `As a ${this.userPlan} plan user you are allowed to send ${currentPlanAutoDMLimit} daily AutoDMs. Upgrade to the ${this.nextAutoDMLimitPlan.label} plan to be able to send ${nextPlanLimits} daily Auto-DMs.`;
        this.showNextPlanPopUp = true;
      },
      openRetargetingListsLimitNextPlanPopUp() {
        const currentPlanRetargetingListsLimit =
          this.PLANS[this.customerStatus].limits.autoDMRetargetingList
            .numberOfLists;
        const nextPlan = this.getNextPlan('autoDMRetargetingList.numberOfLists');
        const nextPlanLimits = this.PLANS[nextPlan.name].limits.autoDMRetargetingList.numberOfLists;
        const nextPlanLimitsMessage = nextPlanLimits === Infinity ? 'unlimited' : nextPlanLimits;

        this.nextPlan = nextPlan;
        this.nextPlanPopUpContent = `As a ${this.userPlan} plan user you are allowed to create ${currentPlanRetargetingListsLimit} lists. Upgrade to the ${nextPlan.label} plan to be able to create ${nextPlanLimitsMessage} lists.`;
        this.showNextPlanPopUp = true;
      },
      openDraftsLimitNextPlanPopUp() {
        const currentPlanDraftsLimit =
          this.PLANS[this.customerStatus].limits.drafts;
        const nextPlan = this.getNextPlan('drafts');

        if (nextPlan) {
          const nextPlanLimits = this.PLANS[nextPlan.name].limits.drafts;
          const nextPlanLimitsMessage =
            nextPlanLimits === Infinity ? 'an unlimited number of' : `up to ${nextPlanLimits}`;

          this.nextPlan = nextPlan;
          this.nextPlanPopUpContent = `As a ${this.userPlan} plan user you are allowed to save ${currentPlanDraftsLimit} drafts. Upgrade to the ${nextPlan.label} plan to be able to save ${nextPlanLimitsMessage} drafts.`;
          this.showNextPlanPopUp = true;
        } else {
          this.swalModal({
            title: 'Drafts limit reached',
            html: `As a ${this.userPlan} plan user you are only allowed to save ${currentPlanDraftsLimit} drafts.`,
            type: 'error',
          });
        }
      },
      openAdditionalAccountsLimitNextPlanPopUp(additionalAccountsLimit, limitsToUse) {
        const numberOfUsersMessage =
          additionalAccountsLimit === 0
            ? "you can't add any additional accounts"
            : `you can only add ${additionalAccountsLimit} additional accounts`;

        const nextPlan = this.getNextPlan(`additionalAccounts.${limitsToUse}`);
        const nextPlanLimits = lodash.get(nextPlan, `limits.additionalAccounts.${limitsToUse}`);
        const nextPlanLimitsMessage = nextPlanLimits === Infinity ? 'unlimited' : nextPlanLimits;

        if (nextPlan) {
          this.nextPlanPopUpContent = `As a ${this.userPlan} user ${numberOfUsersMessage}. <br /><br /> Would you like to upgrade to ${nextPlan.label} and be able to add ${nextPlanLimitsMessage} additional accounts?`;
          this.nextPlan = nextPlan;
          this.showNextPlanPopUp = true;
        } else {
          this.swalModal({
            title: 'Additional accounts limit reached',
            html: `You can have only ${additionalAccountsLimit} additional accounts.`,
            type: 'error',
          });
        }
      },
    },
  };
</script>
