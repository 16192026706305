var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeDropDown),expression:"closeDropDown"}],tag:"component",class:[
    { disabled: _vm.disabled },
    { show: _vm.isOpen }, `drop${_vm.direction}`,
  ],on:{"click":function($event){$event.stopPropagation();return _vm.toggleDropDown.apply(null, arguments)}}},[_vm._t("title-container",function(){return [_c(_vm.titleTag,{tag:"component",staticClass:"btn-rotate",class:[{'dropdown-toggle': _vm.hasToggle}, _vm.titleClasses],attrs:{"aria-expanded":_vm.isOpen,"data-toggle":"dropdown"}},[_vm._t("title",function(){return [_c('i',{class:_vm.icon}),_vm._v(" "+_vm._s(_vm.title)+" ")]},{"isOpen":_vm.isOpen})],2)]},{"isOpen":_vm.isOpen}),_c('div',{staticClass:"dropdown-menu dark-mode-bg bg-light",class:[
      { show: _vm.isOpen },
      `dropdown-menu-${_vm.direction}`,
      { 'dropdown-menu-right': _vm.menuOnRight },
      { 'dropdown-menu-fixed': _vm.menuFixed },
      { 'dropdown-menu-account-switcher w-full': _vm.menuAccounts },
      _vm.menuClasses,
    ]},[_vm._t("default",null,{"isOpen":_vm.isOpen})],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }