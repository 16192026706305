import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import AuthLayout from '@/views/Pages/AuthLayout.vue';
import NotFound from '@/views/GeneralViews/NotFoundPage.vue';

const Analytics = () => import('@/views/Analytics.vue');
const Subscription = () => import('@/views/Subscription.vue');
const EvergreenQueue = () => import('@/views/EvergreenQueue.vue');
const ExtendTrial = () => import('@/views/ExtendTrial.vue');
const History = () => import('@/views/History.vue');
const InstagramShare = () => import('@/views/InstagramShare.vue');
const NewFeedPage = () => import('@/views/NewFeedPage.vue');
const GrowthProgramAdminPage = () => import('@/views/GrowthProgram/AdminPage.vue');
const GrowthProgramInfluencerPage = () => import('@/views/GrowthProgram/InfluencerPage.vue');
const GrowthProgramWriterPage = () => import('@/views/GrowthProgram/WriterPage.vue');
const GumroadSales = () => import('@/views/GumroadSales.vue');
const Mentions = () => import('@/views/Mentions.vue');
const Login = () => import('@/views/Pages/Login.vue');
const Queue = () => import('@/views/Queue.vue');
const Dashboard = () => import('@/views/Dashboard.vue');
const AutoDMs = () => import('@/views/AutoDMs.vue');
const Powerups = () => import('@/views/Powerups.vue');
const RecurrentPostsCalendar = () => import('@/views/RecurrentPostsCalendar.vue');
const ResetTrial = () => import('@/views/ResetTrial.vue');
const Settings = () => import('@/views/Settings.vue');
const Marketplace = () => import('@/views/Marketplace.vue');
const Setup = () => import('@/views/Setup.vue');
const ToolboxAutoRetweeter = () => import('@/views/Toolbox/AutoRetweet.vue');
const TimeBasedSales = () => import('@/views/Toolbox/TimeBasedSales.vue');
const TwitterArchiveUploader = () =>
  import('@/views/Toolbox/TwitterArchiveUploader/TwitterArchiveUploader.vue');
const SchedulingGame = () => import('@/views/SchedulingGame.vue');
const GhostwritingDashboard = () => import('@/views/GhostwritingDashboard.vue');
const GhostwritingClient = () => import('@/views/GhostwritingClient.vue');
const GhostwritingValidation = () => import('@/views/GhostwritingValidation.vue');
const Cancellations = () => import('@/views/Cancellations.vue');
const AutoplugsMetrics = () => import('@/views/AutoplugsMetrics.vue');
const ShareAnalytics = () => import('@/views/ShareAnalytics.vue');
const PaddleCoupons = () => import('@/views/PaddleCoupons.vue');
const Feedhive = () => import('@/views/Feedhive.vue');
const AutoDMMetrics = () => import('@/views/AutoDMMetrics.vue');
const YoutubeToTweet = () => import('@/views/YoutubeToTweet.vue');

import { LAYOUTS } from '@/util/setTheme';

function routes() {
  return [
    {
      path: '/login',
      component: AuthLayout,
      children: [
        {
          path: '/',
          name: 'Login',
          component: Login,
          meta: {
            id: 'login',
            menu: null,
          },
        },
        {
          path: '/setup',
          component: Setup,
          name: 'Welcome to Hypefury!',
          meta: {
            id: 'setup',
            requiresAuth: true,
            menu: null,
          },
        },
        { path: '*', component: NotFound },
      ],
    },
    {
      path: '/free',
      component: AuthLayout,
      children: [
        {
          path: '/',
          name: 'Login',
          component: Login,
          meta: {
            id: 'login',
            menu: null,
          },
        },
        { path: '*', component: NotFound },
      ],
    },
    {
      path: '/',
      component: DashboardLayout,
      redirect: '/queue',
      name: 'Dashboard layout',
      children: [
        {
          path: '/',
          redirect: '/create',
        },
        {
          path: '/queue',
          name: 'Queue',
          component: Queue,
          alias: ['/drafts', '/inspiration'],
          meta: {
            id: 'queue',
            requiresAuth: true,
            menu: 'actions',
            layout: LAYOUTS.DEFAULT,
          },
        },
        {
          path: '/evergreen-posts',
          name: 'Evergreen Posts',
          component: EvergreenQueue,
          meta: {
            id: 'evergreen_posts',
            requiresAuth: true,
            menu: 'actions',
            layout: LAYOUTS.DEFAULT,
          },
        },
        {
          path: '/analytics/:username?',
          name: 'Analytics',
          component: Analytics,
          meta: {
            id: 'analytics',
            menu: 'actions',
            layout: LAYOUTS.LARGE,
          },
        },
        {
          path: '/auto-dms',
          name: 'Auto DMs',
          component: AutoDMs,
          meta: {
            id: 'auto-dms',
            requiresAuth: true,
            layout: LAYOUTS.DEFAULT,
          },
        },
        {
          path: '/powerups',
          name: 'Powerups',
          component: Powerups,
          meta: {
            id: 'powerups',
            requiresAuth: true,
            layout: LAYOUTS.EMPTY,
          }
        },
        {
          path: '/youtube-to-tweet',
          name: 'YouTube to Tweet',
          component: YoutubeToTweet,
          meta: {
            id: 'youtube-to-tweet',
            requiresAuth: true,
            layout: LAYOUTS.DASHBOARD,
          },
        },
        {
          path: '/share-analytics',
          name: 'Share Analytics',
          component: ShareAnalytics,
          meta: {
            id: 'share-analytics',
            requiresAuth: true,
            requiresOwnerShip: true,
            menu: 'actions',
          },
        },
        {
          path: '/billing',
          name: 'Subscription',
          component: Subscription,
          meta: {
            id: 'subscription',
            requiresAuth: true,
            requiresOwnerShip: true,
            menu: 'account',
            layout: LAYOUTS.LARGE,
          },
        },
        {
          path: '/history/:threadId?',
          name: 'History',
          component: History,
          meta: {
            id: 'history',
            requiresAuth: true,
            menu: 'actions',
            layout: LAYOUTS.DEFAULT,
          },
        },
        {
          path: '/recurrent-posts/categories/:categoryId',
          name: 'Category Posts',
          props: true,
          component: History,
          meta: {
            id: 'history_categories',
            requiresAuth: true,
            menu: 'actions',
            layout: LAYOUTS.DEFAULT,
          },
        },
        {
          path: '/new',
          redirect: '/queue',
        },
        {
          path: '/scheduled',
          redirect: '/queue',
        },
        {
          path: '/settings',
          name: 'Settings',
          component: Settings,
          meta: {
            id: 'settings',
            requiresAuth: true,
            menu: 'account',
            layout: LAYOUTS.DEFAULT,
          },
        },
        {
          path: '/marketplace',
          name: 'Grow faster',
          component: Marketplace,
          meta: {
            id: 'marketplace',
            requiresAuth: true,
          },
        },
        {
          path: '/extend-trial/:id',
          name: 'Extend Trial',
          component: ExtendTrial,
          meta: {
            id: 'extend_trial',
            requiresAuth: true,
            menu: null,
          },
        },
        {
          path: '/reset-trial',
          name: 'Reset Trial',
          component: ResetTrial,
          meta: {
            id: 'reset_trial',
            requiresAuth: true,
            menu: null,
          },
        },
        {
          path: '/growth/write',
          name: 'Growth Program',
          component: GrowthProgramWriterPage,
          meta: {
            id: 'growthprogram-growth',
            requiresAuth: true,
            requiresWriter: true,
            menu: 'actions',
          },
        },
        {
          path: '/growth/check',
          name: 'Growth Program',
          component: GrowthProgramInfluencerPage,
          meta: {
            id: 'growthprogram-influencers',
            requiresAuth: true,
            requiresInfluencer: true,
            menu: 'actions',
          },
        },
        {
          path: '/growth/admin',
          name: 'Admin Page',
          component: GrowthProgramAdminPage,
          meta: {
            id: 'growthprogram-admin',
            requiresAuth: true,
            requiresAdmin: true,
            menu: 'actions',
          },
        },
        {
          path: '/instagram/share/:userId/:threadId',
          name: 'Share on Instagram',
          component: InstagramShare,
          meta: {
            id: 'instagram_share',
            requiresAuth: true,
            menu: null,
          },
        },
        {
          path: '/gumroad-sales',
          name: 'Gumroad Sales',
          component: GumroadSales,
          meta: {
            id: 'gumroad_sales',
            requiresAuth: true,
            menu: 'toolbox',
            layout: LAYOUTS.DEFAULT,
          },
        },
        {
          path: '/recurrent-posts/:subPage?',
          name: 'Recurrent Posts Calendar',
          props: true,
          component: RecurrentPostsCalendar,
          meta: {
            id: 'recurrent_posts_calendar',
            requiresAuth: true,
            menu: 'actions',
            layout: LAYOUTS.LARGE,
          },
        },
        {
          path: '/toolbox/auto-retweeter',
          name: 'Auto-Retweeter',
          component: ToolboxAutoRetweeter,
          meta: {
            id: 'toolbox-auto-retweeter',
            requiresAuth: true,
            menu: 'toolbox',
          },
        },
        {
          path: '/toolbox/time-based-sales',
          name: 'Time-based sales',
          component: TimeBasedSales,
          meta: {
            id: 'time_based_sales',
            requiresAuth: true,
            menu: 'toolbox',
            layout: LAYOUTS.DEFAULT,
          },
        },
        {
          path: '/mentions',
          name: 'Mentions',
          component: Mentions,
          meta: {
            id: 'mentions',
            requiresAuth: true,
            menu: 'actions',
            layout: LAYOUTS.DEFAULT,
          },
        },
        {
          path: '/toolbox/twitter-archive-uploader',
          name: 'Archive Uploader',
          component: TwitterArchiveUploader,
          meta: {
            id: 'twitter-archive-uploader',
            requiresAuth: true,
            menu: 'toolbox',
          },
        },
        {
          path: '/schedule',
          name: 'Schedule',
          component: SchedulingGame,
          meta: {
            id: 'schedule',
            requiresAuth: true,
            menu: null,
          },
        },
        {
          path: '/feed',
          name: 'Engagement Builder',
          component: NewFeedPage,
          meta: {
            id: 'feed',
            requiresAuth: true,
            menu: 'actions',
            layout: LAYOUTS.DEFAULT,
          },
        },
        {
          path: '/feed/:subPage?',
          name: 'Engagement Builder',
          props: true,
          component: NewFeedPage,
          meta: {
            id: 'feed',
            requiresAuth: true,
            menu: 'actions',
            layout: LAYOUTS.DEFAULT,
          },
        },
        {
          path: '/create',
          redirect: '/queue',
        },
        {
          path: '/dashboard',
          name: 'Dashboard',
          component: Dashboard,
          meta: {
            id: 'dashboard',
            requiresAuth: true,
            menu: 'actions',
            layout: LAYOUTS.DASHBOARD,
          },
        },
        {
          path: '/ghostwriting/dashboard',
          name: 'Ghostwriting Dashboard',
          props: true,
          component: GhostwritingDashboard,
          meta: {
            id: 'ghostwriting_dashboard',
            requiresAuth: true,
            requiresGhostwriter: true,
            menu: 'ghostwriting-clients',
            layout: LAYOUTS.DEFAULT,
          },
        },
        {
          path: '/ghostwriting/client/:username',
          name: 'Ghostwriting Client',
          props: true,
          component: GhostwritingClient,
          meta: {
            id: 'ghostwriting_client',
            requiresAuth: true,
            requiresGhostwriter: true,
            menu: 'ghostwriting-clients',
            layout: LAYOUTS.DEFAULT,
          },
        },
        {
          path: '/ghostwriting/validation',
          name: 'Ghostwriting Validation',
          props: true,
          component: GhostwritingValidation,
          meta: {
            id: 'ghostwriting_validation',
            requiresAuth: true,
            menu: 'actions',
            layout: LAYOUTS.DEFAULT,
          },
        },
        {
          path: '/external-apps',
          redirect: {
            path: '/settings',
            hash: '#external-apps',
          },
        },
        {
          path: '/cancellations',
          name: 'Cancellations',
          component: Cancellations,
          meta: {
            id: 'cancellations',
            requiresAdmin: true,
            menu: null,
          },
        },
        {
          path: '/autoplugs-metrics',
          name: 'Autoplugs Metrics',
          props: true,
          component: AutoplugsMetrics,
          meta: {
            id: 'autoplugs_metrics',
            requiresAuth: true,
            menu: 'actions',
            layout: LAYOUTS.DEFAULT,
          },
        },
        {
          path: '/coupons',
          name: 'Paddle Coupons',
          props: true,
          component: PaddleCoupons,
          meta: {
            id: 'coupons',
            requiresCS: true,
            menu: null,
          },
        },
        {
          path: '/feedhive',
          name: 'Feedhive',
          props: true,
          component: Feedhive,
          meta: {
            id: 'feedhive',
            menu: null,
          },
        },
        {
          path: '/auto-dm/metrics',
          name: 'AutoDM Metrics',
          props: true,
          component: AutoDMMetrics,
          meta: {
            id: 'auto_dm_metrics',
            menu: null,
            requiresAuth: true,
            requiresAdmin: true,
          },
        },
        { path: '*', component: NotFound },
      ],
    },
  ];
}

export default routes;
