import axios from 'axios';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/messaging';
import 'firebaseui/dist/firebaseui.css'

/**
 * Many things are happening here:
 * 1) Because we load Firebase's configuration from /__/firebase/init.json,
 * We must wait for the fetch to finish because we load the app,
 * hence why we export the promise to main.js (that will load the app after
 * the promise resolves).
 * 2) Because we need the firebase "components", such as storage and auth, in
 * the app, we export them separately.
 */

let firestore;
let auth;
let storageRoot;
let threadsCollection;
let publicUsersCollection;
let usersCollection;
let growthProgramsCollection;
let gumroadSalesCollection;
let csvUploadsCollection;
let categoriesCollection;
let recurrentPostsCollection;
let publicPostsCollection;
let dmCampaignsCollection;
let amaRepliesCollection;
let youtubeVideosCollection;
let youtubeShortsCollection;
let messaging;

let defaultExport;

const isNotTestEnv = process.env.JEST_WORKER_ID === undefined;

if (isNotTestEnv) {
  let json;

  defaultExport = new Promise((resolve, reject) => {
    axios.get('/__/firebase/init.json', {}, {})
      .then(result => json = result.data)
      .catch(error => {
        if (error.response.status) {
          json = {
            "apiKey": "AIzaSyCqWn8tpLDjGfcqF4gbDgjrq9AV8iJQfCM",
            "appId": "1:6976844171:web:29b857b9174fbc0e6c3f8e",
            "authDomain": "hypefury-dev.firebaseapp.com",
            "databaseURL": "https://hypefury-dev.firebaseio.com",
            "messagingSenderId": "6976844171",
            "projectId": "hypefury-dev",
            "storageBucket": "hypefury-dev.appspot.com"
          }
        } else {
          reject(error);
        }
      })
      .finally(() => {
        const app = firebase.initializeApp(json);

        firestore = firebase.firestore();
        let storage = firebase.storage();
        if (window.Cypress && window.Cypress.env('FIRESTORE_EMULATOR_HOST')) {
          firestore.settings({
            host: 'localhost:8082',
            ssl: false,
            experimentalForceLongPolling: true
          })
          storage.useEmulator("localhost", 9199)
        }
        auth = firebase.auth();
        storageRoot = storage.ref();

        const arePushNoticationsAllowed =
          'Notification' in window && Notification.permission !== 'denied';

        if (firebase.messaging.isSupported() && arePushNoticationsAllowed) {
          messaging = firebase.messaging();
        }

        threadsCollection = firestore.collection('threads');
        publicUsersCollection = firestore.collection('publicUsers');
        usersCollection = firestore.collection('users');
        growthProgramsCollection = firestore.collection('growthPrograms');
        gumroadSalesCollection = firestore.collection('gumroadSales');
        csvUploadsCollection = firestore.collection('csvUploads');
        categoriesCollection = firestore.collection('categories');
        recurrentPostsCollection = firestore.collection('recurrentPosts');
        publicPostsCollection = firestore.collection('publicPosts');
        dmCampaignsCollection = firestore.collection('dmCampaigns');
        amaRepliesCollection = firestore.collection('amaReplies');
        youtubeVideosCollection = firestore.collection('youtubeVideos');
        youtubeShortsCollection = firestore.collection('youtubeShorts');

        resolve({
          auth,
          firebase,
          firestore,
          projectId: app.options.projectId,
          storageRoot,
          threadsCollection,
          publicUsersCollection,
          usersCollection,
          growthProgramsCollection,
          gumroadSalesCollection,
          csvUploadsCollection,
          categoriesCollection,
          recurrentPostsCollection,
          publicPostsCollection,
          dmCampaignsCollection,
          amaRepliesCollection,
          youtubeVideosCollection,
          youtubeShortsCollection,
          messaging,
        });
      });
  });
}

export default defaultExport;

export {
  auth,
  firebase,
  firestore,
  storageRoot,
  threadsCollection,
  publicUsersCollection,
  usersCollection,
  growthProgramsCollection,
  gumroadSalesCollection,
  csvUploadsCollection,
  categoriesCollection,
  recurrentPostsCollection,
  publicPostsCollection,
  dmCampaignsCollection,
  amaRepliesCollection,
  youtubeVideosCollection,
  youtubeShortsCollection,
  messaging,
};
