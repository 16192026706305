<template>
  <router-view></router-view>
</template>


<script>
  export default {
    name: "App",
    mounted() {
      this.$nextTick(function() {
        try {
          document.getElementById('app-preloader').style.display = 'none';
        } catch (error) {
          console.error('Could not hide preloader');
        }
      });
    }
  };
</script>
