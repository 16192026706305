<template>
  <div class="group relative flex w-full items-start gap-x-2 sm:gap-4" ref="root">
    <div class="flex h-full flex-shrink-0 flex-col justify-between pt-12" ref="tweetSide">
      <div class="flex flex-col justify-between">
        <img
          v-if="tweet.count === 0"
          :src="userProfile.photoURL"
          :alt="userProfile.name"
          class="avatar-img -mt-12 mb-3"
        />
        <div v-if="threadLength > 1" class="relative">
          <div class="absolute -top-11 h-9 w-9"></div>
          <new-button
            v-show="tweet.count !== 0"
            type="secondary"
            icon
            absolute
            class="absolute -top-11 transition-opacity duration-700 ease-in-out"
            :class="{
              'opacity-0 group-hover:opacity-100': isDesktop,
              'opacity-100': !isDesktop,
            }"
            @click="$emit('add-tweet', {}, tweet.count)"
          >
            <inline-svg src="img/icons/add.svg" />
          </new-button>
          <div>
            <new-button
              type="danger"
              icon
              @click="deleteTweet(tweet)"
              data-cy="composer-remove-tweet"
            >
              <inline-svg src="img/icons/minus-icon.svg" class="stroke-current" />
            </new-button>
          </div>
        </div>
      </div>
      <div
        class="absolute"
        :class="{
          '-bottom-0.75 xs:-bottom-1.5 ': threadLength > 1,
          '-bottom-0.75': threadLength === 1,
        }"
      >
        <portal-target v-if="tweet.count === threadLength - 1" name="addTweet"></portal-target>
      </div>
    </div>
    <div
      class="flex-grow min-w-0 flex-1"
      :class="{
        'mb-13': isLastTweet && !isDesktop,
      }"
    >
      <div class="flex items-center justify-between">
        <div class="flex-grow flex min-w-0 flex-wrap items-center gap-2 py-1.5">
          <span class="avatar-name">{{ userProfile.name }} </span>
          <span class="avatar-username">@{{ userProfile.username }}</span>
        </div>
        <div
          class="flex items-center justify-end gap-2 py-1.5"
          :class="{ invisible: !isCurrentTextAreaFocused }"
        >
          <div
            v-if="
              !isUsedOnAutoDMPages &&
              !hasStartedEditing &&
              !originalThread &&
              shouldAutoFillEmptyComposer() &&
              userBestTweetsCount > 0
            "
            class="flex items-center gap-2 md:min-w-34"
          >
            <new-button type="custom" @click="$emit('change-best-tweet', 'previous')">
              <inline-svg
                class="h-5 w-5 rotate-90 transform rounded-full fill-ds-button-icon"
                :class="{
                  'border pointer-events-none cursor-not-allowed border-ds-button-secondary-background bg-ds-background fill-ds-button-secondary-background':
                    bestTweetIndex === 0,
                  'bg-ds-button-secondary-background': bestTweetIndex !== 0,
                }"
                src="/img/icons/arrow-down.svg"
              />
            </new-button>
            <p class="hidden text-sm font-semi-medium text-ds-button-icon md:block">Auto-fill</p>
            <div class="md:hidden">
              <new-button type="custom" icon @click="$emit('clear-composer')">
                <inline-svg class="h-5 w-5 stroke-ds-button-icon" src="/img/icons/new/eraser.svg" />
              </new-button>
            </div>
            <new-button type="custom" icon @click="$emit('change-best-tweet', 'next')">
              <inline-svg
                class="h-5 w-5 -rotate-90 transform rounded-full fill-ds-button-icon"
                :class="{
                  'border pointer-events-none cursor-not-allowed border-ds-button-secondary-background bg-ds-background fill-ds-button-secondary-background':
                    bestTweetIndex === userBestTweetsCount,
                  'bg-ds-button-secondary-background': bestTweetIndex !== userBestTweetsCount,
                }"
                src="/img/icons/arrow-down.svg"
              />
            </new-button>
          </div>
          <template v-else-if="isLongFormTweetMode">
            <character-counter v-if="tweetLength <= 280" :textLength="tweetLength" />
            <span class="select-none" v-else-if="tweetLength <= tweetCharactersLimit - 100"
              >This is a longform tweet</span
            >
            <character-counter
              v-else
              :maximumCharacters="tweetCharactersLimit"
              :textLength="tweetLength"
              :warningCharacters="100"
            />
          </template>
          <character-counter v-else :textLength="tweetLength" />
        </div>
      </div>
      <div
        ref="tweet_container"
        class="grow min-h-18"
        @drop="uploadSectionDrop(tweet, $event)"
        @dragover="uploadSectionDragOver(tweet, $event)"
        @dragleave="uploadSectionDragLeave(tweet, $event)"
      >
        <vue-tribute :options="tributeOptions">
          <textarea
            class="form-control simple my-1 min-h-16"
            ref="tweet"
            :id="`${tweet.count}`"
            v-model="status"
            :placeholder="statusPlaceholder"
            @paste="onPaste"
            @input="$emit('input-changed')"
            @keyup.ctrl.enter="$emit('pressed-ctrl-enter')"
            @keydown.meta.enter="$emit('pressed-ctrl-enter')"
            @keydown.exact.enter="handleEnterKeyPress"
            @focus="onFocus"
            data-cy="composer-input"
            @blur="onBlur"
            dir="auto"
            autofocus
          />
        </vue-tribute>
      </div>
      <tweet-display
        v-if="quoteTweetPreview"
        :profilePictureURL="quoteTweetPreview.userProfilePictureURL"
        :tweet="quoteTweetPreview.text"
        :username="quoteTweetPreview.username"
        removable
        @remove="removeQuoteTweet"
        class="mb-4"
      />

      <portal-target
        :name="tweet.guid"
        multiple
        class="thread-preview focus--hide space-y-2 p-2 pr-0"
      />
      <div v-if="hasPoll && !isReply" class="tweet-container poll-container-new">
        <div v-for="(pollOption, i) in pollOptions" :key="i" class="poll-question mb-2 border-none">
          <div class="poll-option flex w-full items-center justify-between p-1">
            <input
              :ref="`poll-${i}`"
              :placeholder="`Option ${i + 1}${i > 1 ? ' (optional)' : ''}`"
              v-model="pollOptions[i]"
              maxlength="25"
              class="form-control simple"
              data-cy="compose-poll-input"
            />
            <div class="flex items-center space-x-1">
              <div v-if="pollOption" class="character-count">
                {{ pollOption.length }}
              </div>
              <div v-else class="character-count transparent-bg">0</div>
              <new-button v-if="i > 1" type="danger" icon size="sm" @click="removePollOption(i)">
                <inline-svg src="/img/icons/remove.svg" />
              </new-button>
              <new-button
                v-if="i === pollOptions.length - 1 && pollOptions.length < 4"
                type="secondary"
                icon
                size="sm"
                @click="addPollOption"
              >
                <inline-svg src="/img/icons/add.svg" />
              </new-button>
            </div>
          </div>
        </div>
        <div class="poll-date flex items-center justify-between">
          <div class="flex items-center">
            <span class="poll-heading">Poll Duration</span>
            <div class="poll-select-container flex justify-between">
              <div class="poll-select relative flex items-center gap-x-1">
                <select data-cy="compose-poll-day" v-model="pollDuration.days" class="form-control">
                  <option v-for="i in Array.from(Array(8).keys())" :value="i" :key="i">
                    {{ i }}d
                  </option>
                </select>
                <div
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center text-primary"
                >
                  <inline-svg class="h-4 w-4 fill-current" src="/img/icons/arrow-down.svg" />
                </div>
              </div>
            </div>

            <div class="poll-select-container flex justify-between">
              <div class="poll-select relative flex items-center gap-x-1">
                <select
                  v-model="pollDuration.hours"
                  class="form-control"
                  :disabled="pollDuration.days === 7"
                  data-cy="compose-poll-hour"
                >
                  <option v-for="i in Array.from(Array(24).keys())" :value="i" :key="i">
                    {{ i }}h
                  </option>
                </select>
                <div
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center text-primary"
                >
                  <inline-svg class="h-4 w-4 fill-current" src="/img/icons/arrow-down.svg" />
                </div>
              </div>
            </div>

            <div class="poll-select-container flex justify-between">
              <div class="poll-select relative flex items-center gap-x-1">
                <select
                  v-model="pollDuration.minutes"
                  class="form-control"
                  :disabled="pollDuration.days === 7"
                  data-cy="compose-poll-minute"
                >
                  <option v-for="i in pollDurationMinutesOptions" :value="i" :key="i">
                    {{ i }}m
                  </option>
                </select>
                <div
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center text-primary"
                >
                  <inline-svg class="h-4 w-4 fill-current" src="/img/icons/arrow-down.svg" />
                </div>
              </div>
            </div>
          </div>
          <button
            @click="hasPoll ? removePoll() : addPoll()"
            class="outline-none focus:outline-none flex gap-2 text-red-600 hover:font-medium"
          >
            <span class="hidden sm:inline">Remove Poll</span>
            <inline-svg class="h-4 w-4 fill-current sm:hidden" src="/img/icons/trash-icon.svg" />
          </button>
        </div>
      </div>
      <tweet-display
        v-if="isThreadFinisherTweet"
        :username="userProfile.username"
        :tweet="firstTweet.status"
        :profilePictureURL="userProfile.photoURL"
        :mediaFiles="firstTweet.mediaFile"
        size="md"
        class="mb-4"
      />
      <portal :to="`tweet-actions-${tweet.guid}`">
        <div
          :class="{ hidden: !isCurrentTextAreaFocused }"
          class="flex-grow ml-auto flex items-center justify-end gap-3 sm:flex-1"
        >
          <div class="mr-1 flex gap-4" v-if="threadLength === 1 && isAIOn">
            <tooltip data-cy="compose-ai-icon" content="AI options">
              <new-drop-down buttonType="custom" buttonClasses="h-full" ref="AIDropdown">
                <template #button>
                  <inline-svg src="/img/icons/ai-request.svg" class="stroke-main-color-100" />
                </template>
                <template>
                  <new-button
                    v-for="action in AIActions"
                    :key="action.name"
                    @click="callGptAction(action.name)"
                    type="dropdown"
                    size="sm"
                    :disabled="isStatusEmpty"
                  >
                    <inline-svg
                      :src="action.icon"
                      :class="['stroke-current', !isStatusEmpty && 'text-main-color-30']"
                    />
                    {{ action.text }}
                  </new-button>
                  <hr class="my-1.5 h-0 w-full bg-main-color-10" />
                  <div class="flex items-center gap-x-3 px-3 text-sm">
                    <inline-svg
                      src="/img/icons/ai-request.svg"
                      :class="{
                        'stroke-main-color-100': !isStatusEmpty,
                        'stroke-gray': isStatusEmpty,
                      }"
                    />
                    <input
                      type="text"
                      :disabled="isStatusEmpty"
                      class="h-6 border-none p-0"
                      placeholder="Type a request..."
                      v-model="customRequest"
                      @keyup.enter="customGptRequest"
                    />
                  </div>
                </template>
              </new-drop-down>
            </tooltip>
            <hr class="h-5 w-px self-center bg-main-color-10" />
          </div>
          <portal-target v-if="tweet.count === threadLength - 1" name="addThreadFinisher" />
          <tooltip v-if="!isThreadFinisherTweet" :content="sendUsDMButtonTooltipContent">
            <div>
              <new-button type="custom" :disabled="hasSendUsDMButton" @click="addSendUsDMButton">
                <inline-svg src="/img/icons/new/dm-me.svg" class="stroke-ds-button-icon" />
              </new-button>
            </div>
          </tooltip>
          <tooltip
            data-cy="compose-upload-media"
            :content="getUploadContainerTooltip(false)"
            v-if="!isThreadFinisherTweet"
          >
            <upload-container
              :pastedFiles="pastedFiles"
              :tweet="tweet"
              :portalTargetName="tweet.guid"
              :canAddMedia="canAddMedia"
              :disabled="Boolean(tweet.poll)"
              :canAddMoreVideos="canAddMoreVideos"
              :clearMediaSelectionAtIndexParent="clearMediaSelectionAtIndex"
              :imageCountInThread="imageCountInThread"
              :showLinkedInBlock="isShareOnLinkedInEnabled"
              :showFacebookBlock="isShareOnFacebookEnabled"
              :isShareOnThreadsEnabled="isShareOnThreadsEnabled"
              :uploadingMedia="uploadingMedia"
            />
          </tooltip>
          <new-drop-down
            icon
            ref="gifDropdown"
            buttonType="custom"
            :disabled="isGifDisabled"
            buttonSize="sm"
            placement="left"
            v-if="!isThreadFinisherTweet"
            data-cy="composer-choose-gif"
          >
            <template #button>
              <tooltip :content="getUploadContainerTooltip(true)">
                <div class="relative" @click="chooseGIF">
                  <inline-svg
                    src="/img/icons/gif-icon.svg"
                    class="fill-main-color-100 stroke-main-color-100"
                  />
                </div>
              </tooltip>
            </template>
            <template>
              <new-gif-selector :columns="3" :index="tweet.count" @selected-gif="selectedGif" />
            </template>
          </new-drop-down>
          <tooltip
            v-if="!isReply"
            data-cy="composer-add-poll"
            :content="hasPoll ? 'Remove Poll' : 'Add a Poll'"
          >
            <div>
              <new-button
                type="custom"
                :disabled="tweetHasMedia"
                @click="hasPoll ? removePoll() : addPoll()"
              >
                <inline-svg src="/img/icons/chart-icon.svg" class="stroke-main-color-100" />
              </new-button>
            </div>
          </tooltip>
          <emoji-picker @selected-emoji="addEmoji($event)" />
          <new-drop-down
            data-cy="composer-categories"
            v-if="isCategoriesVisible && isLastTweet"
            icon
            buttonType="custom"
            buttonSize="sm"
            @click="chooseEmoji"
          >
            <template v-slot:button="slotProps">
              <tooltip data-cy="composer-categories-icon" content="Add a Category">
                <div class="relative" @click="openCategoryDropdown">
                  <span
                    v-if="selectedCategories.length > 0 && !slotProps.isOpen"
                    class="absolute -right-0.75 -top-0.75 box-content flex h-3.5 w-3.5 items-center justify-center rounded-full border-1.5 border-white bg-main-color-100 text-xxs text-white"
                  >
                    {{ selectedCategories.length }}
                  </span>
                  <inline-svg
                    src="/img/icons/categories-icon.svg"
                    class="h-5 w-5 stroke-main-color-100"
                    :class="[slotProps.isOpen ? 'fill-main-color-100' : '']"
                  />
                </div>
              </tooltip>
            </template>
            <template>
              <div class="flex max-h-300 flex-col gap-y-2 overflow-auto">
                <div
                  class="flex items-center gap-x-3"
                  v-for="category in categories"
                  :key="category.id"
                  data-cy="composer-category"
                >
                  <span
                    class="h-4 w-4 rounded-full"
                    :style="`background-color:${category.color}`"
                  />
                  <span>{{ category.name }}</span>
                  <base-toggle
                    class="ml-auto"
                    :value="category.enabled"
                    @input="handleCategoryToggle($event, category)"
                  />
                </div>
                <hr class="dropdown-separator" />
                <new-category-form @new-category-added="addCategory" />
              </div>
            </template>
          </new-drop-down>
          <new-drop-down
            icon
            data-cy="composer-dropdown"
            v-if="isTweetBoosterDropdownEnabled && isLastTweet && isComposerDropdownVisible"
            buttonType="bordered"
            buttonSize="sm"
          >
            <template v-slot:button="slotProps">
              <inline-svg
                src="/img/icons/arrow-down-icon.svg"
                class="h-2 w-2 stroke-current"
                :class="['transform', slotProps.isOpen ? 'rotate-180' : 'rotate-0']"
              />
            </template>
            <template>
              <portal-target name="options-dropdown"></portal-target>
            </template>
          </new-drop-down>
        </div>
      </portal>
      <div
        class="flex w-full min-w-0 items-center justify-start"
        :class="{ invisible: !isCurrentTextAreaFocused }"
      >
        <portal-target
          v-if="tweet.count === threadLength - 1"
          class="sm:flex-1"
          name="newDelayTweet"
        />
        <div class="flex-grow ml-auto flex items-center justify-end gap-3 sm:flex-1">
          <portal-target
            v-if="isDesktop || isUsedOnAutoDMPages"
            :name="`tweet-actions-${tweet.guid}`"
            multiple
            class="flex items-center gap-x-4"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import lodash from 'lodash';
  import UploadContainer from '@/components/UploadContainer.vue';
  import EmojiPicker from '@/components/Threads/EmojiPicker.vue';
  import { formatTweet } from '@/util/formatTweet';
  import controller from '@/controller';
  import TaggingMixin from '@/views/Mixins/TaggingMixin.vue';
  import QuoteTweetMixin from '@/views/Mixins/QuoteTweetMixin.vue';
  import UploadContainerMixin from '@/views/Mixins/UploadContainerMixin.vue';
  import { adjustTextAreaHeight } from '@/util/adjustTextAreaHeight';
  import { countTweetLength } from '@/../functions/src/util/countTweetLength';
  import TweetDisplay from '@/components/TweetDisplay.vue';
  import { AxiosError } from 'axios';
  import NewCategoryForm from '@/components/NewCategoryForm';
  import BreakpointsMixin from '@/views/Mixins/BreakpointsMixin.vue';
  import NewGifSelector from '@/components/NewGifSelector.vue';
  import CharacterCounter from '../CharacterCounter.vue';
  import TweetCharactersLimitMixin from '../../views/Mixins/TweetCharactersLimitMixin.vue';

  export default {
    name: 'NewComposerTextArea',
    components: {
      UploadContainer,
      EmojiPicker,
      TweetDisplay,
      NewCategoryForm,
      NewGifSelector,
      CharacterCounter,
    },
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
      ...mapState(['userCategories', 'userBestTweetsCount']),
      shouldShowLongFormTweetMessage() {
        return this.isLongFormTweetMode && this.tweetLength <= this.tweetCharactersLimit - 100;
      },
      hasSendUsDMButton() {
        const sendUsDMButtonRegex = /https:\/\/twitter\.com\/messages\/compose\?recipient_id=/;
        return sendUsDMButtonRegex.test(this.tweet.status);
      },
      sendUsDMButtonTooltipContent() {
        return 'Add a "DM me" Button\n(Make sure "Allow DMs from everyone" is enabled in your 𝕏 settings)';
      },
      isCurrentTextAreaFocused() {
        return this.tweet.count === this.currentlyFocusedTweetIndex;
      },
      isLastTweet() {
        return this.tweet.count === this.threadLength - 1;
      },
      isThreadFinisherTweet() {
        const isLastTweet = this.tweet.count + 1 === this.threadLength;
        const areThereMultipleTweets = this.threadLength > 1;
        return this.hasThreadFinisherTweet && isLastTweet && areThereMultipleTweets;
      },
      canAddMedia() {
        if (!this.tweet.mediaFile) return;

        if (
          (this.isShareOnFacebookEnabled && !this.canAddMediaToFacebook) ||
          (this.isShareOnLinkedInEnabled && !this.canAddMediaToLinkedIn) ||
          this.hasPoll
        ) {
          return false;
        }

        return (
          this.tweet.mediaFile.length === 0 ||
          (this.tweet.mediaFile.length < 4 &&
            !this.tweet.mediaFile[0].type.includes('gif') &&
            !this.tweet.mediaFile[0].type.includes('video'))
        );
      },
      statusPlaceholder() {
        if (this.placeholder) {
          return this.placeholder;
        }

        if (this.tweet.count === 0) {
          return Math.random() >= 0.5
            ? 'Tip: paste a long text and it will be turned into a thread!'
            : 'What would you like to share?';
        } else {
          return 'Tweet ' + (this.tweet.count + 1);
        }
      },
      isGifDisabled() {
        return Boolean(
          this.doesTweetHavePoll ||
            !this.canAddMedia ||
            this.tweet.mediaFile.length > 0 ||
            (this.isShareOnFacebookEnabled && this.imageCountInThread > 0)
        );
      },
      tweetHasMedia() {
        return this.tweet.mediaFile && this.tweet.mediaFile.length > 0;
      },
      tweetLength() {
        return countTweetLength(this.tweet.status);
      },
      isStatusEmpty() {
        return typeof this.status === 'string' && this.status.trim().length === 0;
      },
      categories() {
        return this.userCategories.map((category) => {
          return {
            ...category,
            enabled: this.selectedCategories.some(
              (selectedCategory) => selectedCategory.id === category.id
            ),
          };
        });
      },
    },
    mounted() {
      this.$refs.tweet.focus();
      this.$el.addEventListener('paste', (e) => {
        this.pastedFiles = [];
        if (!this.canAddMedia || e.clipboardData.files.length === 0) {
          return;
        }
        this.pastedFiles = [e.clipboardData.files[0]];
      });

      this.adjustTextAreaHeight();
    },
    updated() {
      this.adjustTextAreaHeight();
    },
    methods: {
      chooseGIF() {
        this.$eventStore.composer('Action bar: Choose GIF');
      },
      openCategoryDropdown() {
        this.$eventStore.composer('Action bar: Add category');
      },
      handleEnterKeyPress() {
        const { createTweetOnEnterPressEnabled } = this.userProfile.settings;
        if (createTweetOnEnterPressEnabled) {
          clearTimeout(this.enterTimeout);
          this.enterCount++;

          const lastIndexOfNewLine = this.status.lastIndexOf('\n\n');

          if (lastIndexOfNewLine !== -1 && this.enterCount === 3) {
            const firstPart = this.status.slice(0, lastIndexOfNewLine);
            const secondPart = this.status.slice(lastIndexOfNewLine + 2);

            this.$emit('status-updated', firstPart, this.tweet.count);
            this.$emit('add-tweet', { status: secondPart }, this.tweet.count + 1);
          }

          this.enterTimeout = setTimeout(() => {
            this.enterCount = 0;
          }, 600);

          setTimeout(() => {
            if (this.$refs.root) {
              this.$refs.root.scrollIntoView({ block: 'nearest' });
            }
          }, 0);
        }
      },
      addSendUsDMButton() {
        this.$eventStore.composer('Action bar: Send DM');
        this.$emit('append-send-us-dm-button');
      },
      async callGptAction(action) {
        if (this.threadLength === 1) {
          this.$emit('set-generating-ai-result', true);

          this.$refs.AIDropdown.close();

          try {
            const body = {
              input: this.tweet.status,
              action,
            };
            const { data } = await controller.twitter.generateGptTweets(body, this.currentUser);
            if (action === 'makeThread') {
              const statuses = data.result.output.map((val) => val.content);
              this.$emit('set-ai-thread', statuses);
            } else {
              const status = data.result.output[0].content;
              this.$emit('status-updated', status);
            }
          } catch (err) {
            if (err instanceof AxiosError && err.response.status === 401) {
              this.$notify({
                type: 'warning',
                message:
                  'Invalid OpenAI key provided, you will be using from your plan limit instead',
              });
            } else {
              this.$notify({
                type: 'warning',
                message: 'Error occured while trying to user OpenAI feature, try again later',
              });
            }
          } finally {
            this.$emit('set-generating-ai-result', false);
          }
        }
        return null;
      },
      async customGptRequest() {
        if (this.threadLength === 1 && this.customRequest.length > 4) {
          this.$emit('set-generating-ai-result', true);

          this.$refs.AIDropdown.close();

          try {
            const body = {
              input: this.tweet.status,
              action: 'makeCustom',
              customCommand: this.customRequest,
            };
            const { data } = await controller.twitter.generateGptTweets(body, this.currentUser);
            const status = data.result.output[0].content;
            this.$emit('status-updated', status);
          } catch (err) {
            if (err.response && err.response.status === 401) {
              this.$notify({
                type: 'warning',
                message:
                  'Invalid OpenAI key provided, you will be using from your plan limit instead',
              });
            } else {
              this.$notify({
                type: 'warning',
                message: 'Error occured while trying to user OpenAI feature, try again later',
              });
            }
          } finally {
            this.$emit('set-generating-ai-result', false);
          }
        }
        return null;
      },
      initialState() {
        return {
          enterCount: 0,
          enterTimeout: null,
          pastedFiles: [],
          status: '',
          customRequest: '',
          hasPoll: false,
          pollOptions: ['', ''],
          pollDuration: { days: 1, hours: 0, minutes: 0 },
        };
      },
      formatTweet(tweetStatus) {
        return formatTweet(tweetStatus);
      },
      updateStatus: lodash.debounce(
        function () {
          this.$emit('status-updated', this.status);
        },
        50,
        { maxWait: 100 }
      ),
      deleteTweet(tweet) {
        this.$emit('deleted', tweet.count, this.isThreadFinisherTweet);
      },
      onPaste(event) {
        this.$emit('pasted', event, this.tweet.count);

        // A tweet shouldn't have both thread finisher and quote tweet
        if (this.isThreadFinisherTweet) return;

        const link = event.clipboardData.getData('text');
        this.setQuoteURLIfValid(link);
      },
      // TODO for Mahdi: Move to computed if possible
      getUploadContainerTooltip(isGIFButton) {
        if (this.isShareOnFacebookEnabled && !this.canAddMediaToFacebook) {
          return 'Facebook only supports posting either 1 GIF, 1 video or multiple images';
        }

        if (this.isShareOnFacebookEnabled && this.imageCountInThread > 0 && isGIFButton) {
          return 'Facebook only supports posting either 1 GIF, 1 video or multiple images';
        }

        if (this.isShareOnLinkedInEnabled && !this.canAddMediaToLinkedIn) {
          return 'LinkedIn only supports posting either 1 video or up to 9 images';
        }

        if (
          (Array.isArray(this.tweet.mediaFile) &&
            this.tweet.mediaFile.length === 1 &&
            (this.tweet.mediaFile[0].type.includes('gif') ||
              this.tweet.mediaFile[0].type.includes('video'))) ||
          (Array.isArray(this.tweet.mediaFile) &&
            ((this.tweet.mediaFile.length === 4 &&
              this.tweet.mediaFile[0].type.includes('image')) ||
              (isGIFButton && this.tweet.mediaFile.length > 0)))
        ) {
          return 'You can choose either 1 GIF, 1 video or up to 4 images';
        }

        if (this.tweet.poll) {
          return 'Media upload is disabled because the tweet has a poll';
        }

        if (isGIFButton) {
          return 'Choose GIF';
        }

        return 'Upload Media';
      },
      clearMediaSelectionAtIndex(tweet, index) {
        this.$emit('cleared-media', tweet, index);
      },
      chooseEmoji() {
        this.$eventStore.composer('Action bar: Choose Emoji');
      },
      addEmoji(emoji) {
        this.$eventStore.composer('Action bar: Add emoji');
        const status = this.status;
        const tweetRef = this.$refs.tweet;
        const position = tweetRef.selectionStart;
        this.status = [status.slice(0, position), emoji.native, status.slice(position)].join('');
        const positionAfterTweet = position + 2;

        this.$nextTick(() => {
          tweetRef.focus();
          tweetRef.setSelectionRange(positionAfterTweet, positionAfterTweet);
        });
      },
      addPoll() {
        this.$eventStore.composer('Action bar: Add poll');
        this.hasPoll = true;
        const poll = {
          duration: this.pollDuration,
          options: this.pollOptions,
        };
        this.$emit('updated-poll', poll);
        this.focusPollOption(0);
      },
      removePoll() {
        this.$eventStore.composer('Action bar: Remove poll');
        this.hasPoll = false;
        this.$emit('updated-poll', null);
      },
      addPollOption() {
        this.pollOptions = [...this.pollOptions, ''];
        this.focusPollOption(this.pollOptions.length - 1);
      },
      removePollOption(index) {
        this.pollOptions = this.pollOptions.filter((_, i) => i !== index);
        this.focusPollOption(index - 1);
      },
      focusPollOption(index) {
        this.$nextTick(() => {
          const pollRef = this.$refs[`poll-${index}`][0];
          pollRef.focus();
        });
      },
      adjustTextAreaHeight() {
        this.previousHeight = adjustTextAreaHeight(
          this.$refs.tweet,
          this.$refs.tweet_container,
          this.previousHeight
        );
      },
      resetData() {
        Object.assign(this.$data, { ...this.initialState(), quoteTweetPreview: null });
      },
      selectTextIfInspiration() {
        if (this.hasInspirationTweet && this.tweet.count === 0) {
          this.$refs.tweet.select();
        }
      },
      handleCategoryToggle(enable, category) {
        if (enable) {
          this.addCategory(category);
        } else {
          this.$emit('remove-category', category);
        }
      },
      addCategory(category) {
        this.$emit('add-category', category);
      },
      onFocus(event) {
        this.$emit('set-currently-focused-tweet-index', event);
        this.$emit('focused');
      },
      onBlur() {
        this.$emit('blurred');
      },
      selectedGif(gif) {
        this.$emit('selected-gif', gif, this.tweet.count);
        const gifDropdown = this.$refs.gifDropdown;
        if (gifDropdown) {
          gifDropdown.close();
        }
      },
      shouldAutoFillEmptyComposer() {
        return lodash.get(this.userProfile, 'settings.shouldAutoFillEmptyComposer', false);
      },
    },
    data() {
      return {
        pollDurationMinutesOptions: Array.from(Array(60).keys()),
        previousHeight: 79,
        AIActions: [
          {
            name: 'makeShorter',
            icon: '/img/icons/ai-minimize.svg',
            text: 'Make it shorter',
          },
          {
            name: 'makeLonger',
            icon: '/img/icons/ai-maximize.svg',
            text: 'Make it longer',
          },
          {
            name: 'makeEasier',
            icon: '/img/icons/eye.svg',
            text: 'Make it easier to read',
          },
          {
            name: 'makeRewrite',
            icon: '/img/icons/refresh.svg',
            text: 'Rewrite',
          },
          {
            name: 'makeThread',
            icon: '/img/icons/messages.svg',
            text: 'Create a thread',
          },
        ],
        ...this.initialState(),
      };
    },
    watch: {
      tweet: {
        deep: true,
        immediate: true,
        handler: function (newVal) {
          this.$nextTick(() => {
            this.status = newVal.status;
          });
        },
      },
      status: function (val) {
        this.$emit('status-updated', val, this.tweet.count);
      },
      pollDuration: {
        deep: true,
        immediate: true,
        handler: function (value) {
          if (!this.tweet.poll || !value) return;
          // Minimum poll duration allowed is 5 minutes
          if (value.days === 0 && value.hours === 0) {
            this.pollDurationMinutesOptions = Array.from(Array(60).keys()).slice(5);
            if (value.minutes < 5) {
              this.pollDuration.minutes = 5;
            }
          } else {
            this.pollDurationMinutesOptions = Array.from(Array(60).keys());
          }

          // Maximum poll duration allowed is 7 days
          if (value.days === 7) {
            this.pollDuration.hours = 0;
            this.pollDuration.minutes = 0;
          }

          const poll = {
            duration: this.pollDuration,
            options: this.pollOptions,
          };
          this.$emit('updated-poll', poll);
        },
      },
      pollOptions(value) {
        if (!this.tweet.poll) return;
        const pollOptions = value.filter((option, i) => {
          if (i === 0 || i === 1) {
            return true;
          }
          return !lodash.isNil(option) && option.trim().length > 0;
        });

        const poll = {
          duration: this.pollDuration,
          options: pollOptions,
        };
        this.$emit('updated-poll', poll);
      },
      tweetIndexToFocus: {
        immediate: true,
        handler: function (tweetIndex) {
          if (tweetIndex === this.tweet.count) {
            setTimeout(() => {
              this.$refs.tweet.focus();
              // Sometimes when focusing the cursor is set to the beginning, this sets it to the end
              if (!this.isTabletOrMobile) {
                this.$refs.tweet.setSelectionRange(this.status.length, this.status.length);
                this.selectTextIfInspiration();
              }
            }, 0);
          }
        },
      },
      wasSubmitted: {
        immediate: true,
        handler: function (wasSubmitted) {
          if (wasSubmitted) {
            this.resetData();
          }
        },
      },
      originalThread: {
        immediate: true,
        handler: function (thread) {
          if (thread && this.tweet.poll) {
            this.hasPoll = true;
            this.pollOptions = this.tweet.poll.options;
            this.pollDuration = this.tweet.poll.duration;
          }
        },
      },
    },
    props: {
      isShareOnThreadsEnabled: {
        type: Boolean,
        default: false,
      },
      isAIOn: {
        type: Boolean,
      },
      tweet: {
        type: Object,
        required: true,
      },
      numberOfTweets: {
        type: Number,
        default: 1,
      },
      threadLength: {
        type: Number,
        default: 1,
      },
      hasThreadFinisherTweet: {
        type: Boolean,
        default: false,
      },
      canAddMoreVideos: {
        type: Boolean,
        required: true,
      },
      imageCountInThread: {
        type: Number,
        default: 0,
      },
      isShareOnLinkedInEnabled: {
        type: Boolean,
        default: false,
      },
      firstTweet: {
        type: Object,
        default: () => ({}),
      },
      canDeleteTweet: {
        type: Boolean,
        default: false,
      },
      isShareOnFacebookEnabled: {
        type: Boolean,
        default: false,
      },
      canAddMediaToFacebook: {
        type: Boolean,
        default: false,
      },
      tweetIndexToFocus: {
        type: Number,
        default: null,
      },
      originalThread: {
        type: Object,
        default: null,
      },
      wasSubmitted: {
        type: Boolean,
        default: false,
      },
      mentionedUserLength: {
        type: Number,
        default: 0,
      },
      isTweetBoosterDropdownEnabled: {
        type: Boolean,
        default: true,
      },
      currentlyFocusedTweetIndex: {
        type: Number,
        default: 0,
      },
      placeholder: {
        type: String,
      },
      canAddMediaToLinkedIn: {
        type: Boolean,
        default: false,
      },
      hasInspirationTweet: Boolean,
      selectedCategories: {
        type: Array,
        default: () => [],
      },
      isCategoriesVisible: {
        type: Boolean,
        default: true,
      },
      isComposerDropdownVisible: {
        type: Boolean,
        default: true,
      },
      isUsedOnAutoDMPages: {
        type: Boolean,
        default: false,
      },
      hasStartedEditing: {
        type: Boolean,
        default: false,
      },
      bestTweetIndex: {
        type: Number,
        default: 0,
      },
      isReply: {
        type: Boolean,
        default: false,
      },
      uploadingMedia: Array,
    },
    mixins: [
      TaggingMixin,
      QuoteTweetMixin,
      UploadContainerMixin,
      BreakpointsMixin,
      TweetCharactersLimitMixin,
    ],
    beforeDestroy() {
      // this for mobile devices to solve the issue of the keyboard closing to focus on the prev tweet before destroying the current one
      if (this.isTabletOrMobile) {
        const index = this.$parent.$children.findIndex((item) => item._uid === this._uid);
        if (index > 0) {
          const tweetRef = lodash.get(this.$parent.$children, `[${index - 1}].$refs.tweet`);
          if (tweetRef) {
            tweetRef.focus();
          }
        }
      }
    },
  };
</script>

<style lang="scss">
  .circle-bg {
    fill: none;
    stroke-width: 1.5;
  }

  .mode--dark {
    .circle-bg {
      stroke: #4c5160;
    }
  }

  .mode--light {
    .circle-bg {
      stroke: #f2f2f2;
    }
  }

  .circle {
    fill: none;
    stroke-width: 1.5;
    stroke-linecap: round;
  }

  .strokeColor {
    stroke: #0078fb;
  }

  .tribute-container {
    @apply bg-ds-foreground;

    ul {
      @apply m-0 p-0;
    }
  }
</style>
