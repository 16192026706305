<script>
  import { mapGetters, mapState } from 'vuex';
  const firebase = require('@/firebase');
  import dao from '@/dao';
  import CustomerStatusMixin from './CustomerStatusMixin.vue';
  import CategoriesMixin from './CategoriesMixin.vue';

  export default {
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
      ...mapState(['userCategories']),
      canFormBeSubmitted() {
        return (
          this.category.name.trim().length !== 0 &&
          this.category.color.length !== 0 &&
          !this.isSaving &&
          !this.isCategoryNameTooLong
        );
      },
      isCategoryNameTooLong() {
        return this.category.name.length >= 120;
      },
    },
    data() {
      return {
        isNameNotUnique: false,
      };
    },
    methods: {
      resetIsNameNotUnique() {
        this.isNameNotUnique = false;
      },
      async categoryWithSameNameExists(name, id = null) {
        const user = firebase.usersCollection.doc(this.userProfile.uid);
        const matchingCategories = await dao.categories.findByName(name, user, id);
        let matchingCategoriesIds = matchingCategories.docs.map(doc => doc.id);

        // Category is being updated: ignore matching id from results
        if (id) {
          matchingCategoriesIds = matchingCategoriesIds.filter(categoryId => categoryId !== id)
        }

        this.isNameNotUnique = matchingCategoriesIds.length > 0;
        return this.isNameNotUnique;
      },
    },
    mixins: [CustomerStatusMixin, CategoriesMixin],
  };
</script>
