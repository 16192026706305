const getAppInfo = require('@/../functions/src/util/getAppInfo');

let amplitudeAPIKey;
let appURL;
let buildStorageMediaURL;
let chromeExtensionId;
let crispWebsiteID;
let gumroadApplicationId;
let linkedInApplicationId;
let facebookApplicationId;
let threadsApplicationId;
let youtubeApplicationId;
let youtubeAPIKey;
let httpRoutesURL;
let instagramApplicationId;
let isAppDeployed;
let isEnvProd;
let redirectURI;
let retweeterURL;
let servicebotBillingPageID;
let tweetGetterURL;
let hypefuryAccountTwitterId;
let twitterLoginRedirectURI;
let messagingVapidKey;
let paddleInfo;
let hypefuryUrlShortenerURL;
let tweetReelPreviewURL;
let mediaEditorURL;

export function build(firebase) {
  const appInfo = getAppInfo(firebase.projectId);
  const environment = appInfo.environment;
  isEnvProd = environment === 'prod';

  hypefuryUrlShortenerURL = isEnvProd
    ? 'https://hfl.ink'
    : 'https://link-shortener-server-development.up.railway.app';

  tweetReelPreviewURL = (() => {
    if (!isEnvProd && process.env.VUE_APP_REEL === 'local') {
      return 'http://localhost:5173';
    }
    return 'https://tweet-to-reel.vercel.app';
  })();

  mediaEditorURL = (() => {
    if (!isEnvProd && process.env.VUE_APP_EDITOR === 'local') {
      return 'http://localhost:3000';
    } else if (!isEnvProd) {
      return 'https://hypefury-videos-dev.vercel.app';
    } else {
      return 'https://hypefury-videos.vercel.app';
    }
  })();

  amplitudeAPIKey = isEnvProd
    ? '2c8edf521679207bef79d932b65d13bd'
    : 'c1670c24cf9df2094bc0a1c553905af8';

  appURL = appInfo.appURL;

  isAppDeployed = process.env.NODE_ENV === 'production';

  chromeExtensionId = isEnvProd ? 'eoobmkmdlgpnpegpccclfjbedcpkmdmi' : 'febkjdegjijhmidbhhdhokfaihiphpli';

  crispWebsiteID = isEnvProd
    ? 'e6bae4c0-595e-4dc5-b6a7-bba6202f9c6f'
    : '6ba74aab-cb6e-4db9-bd03-007d0edfd5b4';

  gumroadApplicationId = (() => {
    switch (environment) {
      case 'prod':
        return '7cd27f1e825921ebec53fa2e283820b3bcddc152b080392e7a2e451e2e8572c7';
      case 'staging':
        return '4f510df7677c781f150d60114f960293e0a4e23ef60cef71b8acf75868367c8d';
      case 'dev':
        if (process.env.NODE_ENV === 'production') {
          return 'b0b9124b6ed5b195b5d0fb05f5190085cb00414758c00728a4ce1f676022d5f6';
        } else {
          return '88cdb2e1085c32634dc78b8f6a19f6540ebeffc39a1085e126f44f6d9bbd6448';
        }
      case `${process.env.VUE_APP_LOCAL_ENV}-dev`:
        return '88cdb2e1085c32634dc78b8f6a19f6540ebeffc39a1085e126f44f6d9bbd6448';
      case 'e2e':
        return '88cdb2e1085c32634dc78b8f6a19f6540ebeffc39a1085e126f44f6d9bbd6448';
      default:
        throw new Error(`Environment ${environment} unknown.`);
    }
  })();

  redirectURI = appURL + '/settings';

  linkedInApplicationId = isEnvProd ? '77aihk6l9qshme' : '77h1kvqh39cuye';

  instagramApplicationId = '626193984926772';

  facebookApplicationId = '626193984926772';

  threadsApplicationId = '25746248308355573';

  youtubeApplicationId = isEnvProd
    ? '560600773755-mvhtsdvdg4lsb5hg1vg8qg6336eb2hih.apps.googleusercontent.com'
    : '6976844171-9imoe2h394vl10niaqk65qfrali5qgk7.apps.googleusercontent.com';

  httpRoutesURL = isAppDeployed
    ? `${appURL}/api`
    : `http://localhost:5001/hypefury-${environment}/us-central1/httpRoutes/api`;

  buildStorageMediaURL = (mediaName) => {
    return (
      `https://firebasestorage.googleapis.com/v0/b/` +
      `${firebase.projectId}.appspot.com/o/${encodeURIComponent(mediaName)}?alt=media`
    );
  };

  servicebotBillingPageID = isEnvProd ? 'Y1qQSOBGdpjwcVNIfY0H' : 'WZ6BxpiF1ETZfYUZrX7Y';

  hypefuryAccountTwitterId = '1160721354649460736';
  twitterLoginRedirectURI = appURL;

  messagingVapidKey = isEnvProd
    ? 'BJvRnmpTnt-GVkdesvUd3-eH8-6RgdHSlN3I6AOYalqoSAW2omad8PsHaCfEuFnK6CZwVmpPDUSXpwdAiN4fsTk'
    : 'BEUCUvR3CKO7yQ2k2HwWESXHnVPcDcO058qkw0LrCLCdonyKfpYxWTYevQHwp4LqVggfaVGMm_uKqGlywExRH0I';

  paddleInfo = isEnvProd
    ? {
        // TODO: add missing plans for prod env
        vendorId: 150796,
        plans: {
          premiumMonthly: '786131',
          premiumYearly: '786132',
          standardMonthly: '786129',
          standardYearly: '786130',
          basicMonthly: '879228',
          basicYearly: '879227',
          agencyMonthly: '878285',
          agencyYearly: '878284',
          businessMonthly: '878282',
          businessYearly: '878283',
        },
      }
    : {
        vendorId: 6198,
        plans: {
          premiumMonthly: '28966',
          premiumYearly: '31626',
          standardMonthly: '28965',
          standardYearly: '31627',
          basicMonthly: '48121',
          basicYearly: '48122',
          agencyMonthly: '51172',
          agencyYearly: '51171',
          businessMonthly: '51170',
          businessYearly: '51169',
        },
      };

  return {
    amplitudeAPIKey,
    appURL,
    buildStorageMediaURL,
    chromeExtensionId,
    crispWebsiteID,
    facebookApplicationId,
    threadsApplicationId,
    gumroadApplicationId,
    linkedInApplicationId,
    httpRoutesURL,
    instagramApplicationId,
    isAppDeployed,
    isEnvProd,
    redirectURI,
    retweeterURL,
    servicebotBillingPageID,
    tweetGetterURL,
    hypefuryAccountTwitterId,
    twitterLoginRedirectURI,
    messagingVapidKey,
    paddleInfo
  };
}

export {
  amplitudeAPIKey,
  appURL,
  buildStorageMediaURL,
  chromeExtensionId,
  crispWebsiteID,
  facebookApplicationId,
  threadsApplicationId,
  gumroadApplicationId,
  linkedInApplicationId,
  httpRoutesURL,
  instagramApplicationId,
  youtubeAPIKey,
  youtubeApplicationId,
  isAppDeployed,
  isEnvProd,
  redirectURI,
  retweeterURL,
  servicebotBillingPageID,
  tweetGetterURL,
  hypefuryAccountTwitterId,
  twitterLoginRedirectURI,
  paddleInfo,
  hypefuryUrlShortenerURL,
  tweetReelPreviewURL,
  mediaEditorURL,
};
