<template>
  <div>
    <div class="mb-6 flex items-center justify-between">
      <div class="flex items-center gap-x-3">
        <new-button icon @click="$emit('close')" type="transparent" data-cy="prompts-close-button">
          <inline-svg
            src="/img/icons/new/modal-collapse.svg"
            class="hidden h-6 rotate-180 transform text-main-color-100 lg:inline"
          />
          <inline-svg src="/img/icons/new/x.svg" class="inline text-gray-80 lg:hidden" />
        </new-button>

        <h1>Post inspiration</h1>
      </div>
      <new-button v-if="tweets && tweets.length" @click="shuffleTweets()" type="transparent" data-cy="prompts-shuffle-button">
        Shuffle Tweets
        <inline-svg src="img/icons/refresh.svg" class="ml-2 stroke-current" />
      </new-button>
    </div>
    <template v-if="show">
      <ul class="flex list-none flex-wrap items-center gap-2">
        <li v-for="category in categories" :key="category.id">
          <new-button
            :type="
              categoriesState[category.name] === 'hypefury'
                ? 'primary'
                : categoriesState[category.name] === 'both' ||
                  categoriesState[category.name] === 'user'
                ? 'custom'
                : 'secondary'
            "
            :class="{
              'bg-purple-500 px-3 py-1.5 text-base text-white':
                categoriesState[category.name] === 'both',
              'bg-red-500 px-3 py-1.5 text-base text-white':
                categoriesState[category.name] === 'user',
            }"
            @click="toggleState(category)"
            size="sm"
            data-cy="prompts-category"
          >
            {{ category.name }}
            <div
              v-if="categoriesState[category.name] === 'user'"
              @click.stop="removeBookmarkCategory(category)"
            >
              <inline-svg class="ml-2 h-4 w-4" src="/img/icons/close.svg" />
            </div>
          </new-button>
        </li>
      </ul>
      <div v-for="(tweet, idx) in tweets" :key="tweet.id">
        <div v-if="idx <= 80" class="mt-6 grid gap-6" data-cy="prompt-item">
          <div class="border app-preview-border grid gap-4 rounded-3xl border-gray-90 p-6">
            <div class="flex items-center justify-between">
              <div class="flex items-center gap-4">
                <img
                  @error="displayPlaceHolderImage"
                  :alt="
                    tweet.user ? `${tweet.user.username}'s profile picture` : userProfile.username
                  "
                  :src="tweet.user ? tweet.user.profilePhoto : userProfile.photoURL"
                  class="avatar-img-sm"
                />
                <a
                  :href="
                    'https://twitter.com/' +
                    (tweet.user ? tweet.user.username : userProfile.username)
                  "
                  target="_blank"
                  class="text-base font-medium text-main-color-100"
                >
                  @{{ tweet.user ? tweet.user.username : userProfile.username }}
                </a>
              </div>
              <inline-svg src="/img/icons/twitter.svg" class="text-main-color-100" />
            </div>
            <div
              class="whitespace-pre-wrap break-words text-base"
              v-html="formatTweet(tweet.text)"
              dir="auto"
              data-cy="prompts-tweet-text"
            ></div>
            <div class="prompts flex items-center justify-between gap-x-2">
              <new-button @click="insertTweetToComposer(tweet)" size="sm" class="flex-shrink-0" data-cy="prompts-reuse-button">
                Re-use Tweet
              </new-button>
              <div v-if="tweet.user" class="flex flex-col gap-x-3 md:flex-row md:items-center">
                <a
                  target="_blank"
                  :href="`https://twitter.com/${tweet.user.username}/status/${tweet.id}`"
                  class="text-sm text-ds-text-secondary hover:underline dark-mode:text-dark-mode-30"
                >
                  {{ getFormattedTime(tweet.time) }}
                </a>
                <a
                  target="_blank"
                  :href="`https://twitter.com/${tweet.user.username}/status/${tweet.id}`"
                  class="text-sm text-main-color-100 hover:underline dark-mode:text-main-color-100"
                >
                  View on Twitter
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import { formatTweet } from '@/util/formatTweet';
  import InspirationCategoriesMixin from '@/views/Mixins/InspirationCategoriesMixin.vue';
  import { mapGetters } from 'vuex';
  import lodash from 'lodash';

  export default {
    name: 'new-composer-prompts',
    props: {
      show: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
    },
    methods: {
      formatTweet,
      insertTweetToComposer(tweet) {
        this.$eventStore.composer('Inspiration: Re-use tweets');

        const isTweetFromCurrentUser =
          !tweet.user || tweet.user.username === this.userProfile.username;
        const tweetWithUsername = `“${tweet.text}”\n\n– @${lodash.get(tweet.user, 'username', '')}`;
        const isTweetWithUsernameOverLimit = tweetWithUsername.length > 280;
        const tweetText =
          isTweetFromCurrentUser || isTweetWithUsernameOverLimit ? tweet.text : tweetWithUsername;

        this.$emit('insert-inspiration-tweet', tweetText);
        this.$refs.slideOver.dismiss();
      },
      shuffleTweets() {
        this.$eventStore.composer('Inspiration: Shuffle tweets');
        this.tweets = lodash.shuffle(this.tweets);
      },
    },
    mixins: [InspirationCategoriesMixin],
  };
</script>
