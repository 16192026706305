// Get the current scroll position of the window
export function getWindowScrollTop() {
  let doc = document.documentElement;

  return (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
}

// Get the current horizontal scroll position of the window
export function getWindowScrollLeft() {
  let doc = document.documentElement;

  return (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
}

// Get the dimensions of an element that is currently hidden
export function getHiddenElementDimensions(element) {
  if (element) {
    let dimensions = {};

    // Temporarily hide the element to get its dimensions
    element.style.visibility = 'hidden';
    element.style.display = 'block';
    dimensions.width = element.offsetWidth;
    dimensions.height = element.offsetHeight;

    // Restore the element's original visibility and display properties
    element.style.display = 'none';
    element.style.visibility = 'visible';

    return dimensions;
  }

  return 0;
}

// Get the dimensions of the viewport (i.e. the visible area of the page)
export function getViewport() {
  let win = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    w = win.innerWidth || e.clientWidth || g.clientWidth,
    h = win.innerHeight || e.clientHeight || g.clientHeight;

  return { width: w, height: h };
}

// Position an element absolutely on the page, next to a target element
export function setAbsolutePosition(element, target, coordinates = null, isFixed = false) {
  if (element) {
    // Get the dimensions of the element and the target element
    const elementDimensions = element.offsetParent
      ? { width: element.offsetWidth, height: element.offsetHeight }
      : getHiddenElementDimensions(element);
    const elementOuterHeight = elementDimensions.height;
    const elementOuterWidth = elementDimensions.width;
    const targetOuterHeight = target.offsetHeight;
    const targetOuterWidth = target.offsetWidth;
    const targetOffset = target.getBoundingClientRect();
    const windowScrollTop = getWindowScrollTop();
    const windowScrollLeft = getWindowScrollLeft();
    const viewport = getViewport();
    let top, left;

    // translateElement is used to adjust the position of the element in case we gonna show it on the top of target
    let translateElement = 0;

    // If the element would be positioned off the bottom of the viewport, position it above the target element instead
    if (targetOffset.top + targetOuterHeight + elementOuterHeight > viewport.height) {
      top = targetOffset.top + windowScrollTop - elementOuterHeight;
      element.style.transformOrigin = 'bottom';
      translateElement = -targetOuterHeight / 2;

      // If the element would be positioned off the top of the viewport, position it at the top of the viewport instead
      if (top < 0) {
        top = windowScrollTop;
      }
    } else {
      // Otherwise, position the element below the target element
      top = targetOuterHeight + targetOffset.top + windowScrollTop;
      element.style.transformOrigin = 'top';
    }

    // If the element would be positioned off the right side of the viewport, move it to the left as necessary to keep it on the screen
    if (targetOffset.left + elementOuterWidth > viewport.width)
      left = Math.max(
        0,
        targetOffset.left + windowScrollLeft + targetOuterWidth - elementOuterWidth
      );
    else left = targetOffset.left + windowScrollLeft;

    if (isFixed) {
      element.style.position = 'fixed';
      top -= windowScrollTop;
    }

    if (!coordinates) {
      // Set the top and left properties of the element's style to position it correctly on the page
      element.style.top = top + translateElement + 'px';
      element.style.left = left + 'px';
    } else {
      element.style.position = 'fixed';
      Object.keys(coordinates).forEach((key) => {
        element.style[key] = coordinates[key] + 'px';
      });
    }
  }
}
