import lodash from 'lodash';

const checkForRoles = (userProfile, role) =>
  userProfile.growthProgramsRoles ?
    lodash.flatten(Object.values(userProfile.growthProgramsRoles)).includes(role) :
    false;

export const isInfluencer  = userProfile => checkForRoles(userProfile, 'influencer');

export const isWriter  = userProfile => checkForRoles(userProfile, 'writer');
