<script>
  import {formatTimeWithUserLocale} from "@/util/formatTimeWithUserLocale";

  export default {
    methods: {
      formatTimeWithUserLocale(momentTime) {
        return formatTimeWithUserLocale(momentTime);
      },
    },
    name: 'time-formatter-mixin',
  };
</script>
