var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"focus:outline-none relative inline-flex flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out",class:{
    'bg-main-color-100': _vm.enabled,
    'bg-ds-outline-secondary': !_vm.enabled,
    'h-5 w-9': _vm.size === 'sm',
    'h-28 w-42': _vm.size === 'lg',
  },attrs:{"role":"switch"},on:{"click":function($event){$event.stopPropagation();return _vm.handleClick.apply(null, arguments)}}},[_c('span',{staticClass:"shadow transition pointer-events-none inline-block h-4 w-4 transform rounded-full duration-200 ease-in-out",class:{
      'translate-x-4': _vm.enabled && _vm.size === 'sm',
      'translate-x-3.5': _vm.enabled && _vm.size === 'lg',
      'translate-x-0': !_vm.enabled,
      'h-4 w-4': _vm.size === 'sm',
      'h-6 w-6': _vm.size === 'lg',
      'bg-white': _vm.enabled,
      'bg-ds-button-controls-background': !_vm.enabled,
    },attrs:{"aria-hidden":"true"}})])
}
var staticRenderFns = []

export { render, staticRenderFns }