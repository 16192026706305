const moment = require('moment');
require('moment-timezone');

module.exports.trialPeriodCalculator = (userProfile) => {
  const userCreatedAt = userProfile.created_at || moment();

  if (userProfile.trialEndsAt) {
    const endDate = moment.tz(userProfile.trialEndsAt.toDate(), userProfile.timezone);
    const trialDurationInDays = moment(endDate).diff(
      moment.tz(userCreatedAt.toDate(), userProfile.timezone),
      'days'
    );
    const diff = moment(endDate).diff(moment(), 'second');
    const days = secondsToDays(diff);
    const daysLeft = days < 0 ? 0 : days;

    const percentage =
      trialDurationInDays === 0 ? 100 : Math.floor((1 - daysLeft / trialDurationInDays) * 100);
    return { daysLeft, percentage };
  }

  const startDate = userProfile.trialStartsAt
    ? moment(userProfile.trialStartsAt.toDate())
    : moment.tz(userCreatedAt.toDate(), userProfile.timezone);

  const diff = moment().diff(startDate, 'second');
  const days = 7 - secondsToDays(diff);
  const daysLeft = days < 0 ? 0 : days;
  const percentage = Math.floor(((7 - daysLeft) / 7) * 100);
  return { daysLeft, percentage };
};

function secondsToDays(seconds) {
  return Math.floor(seconds / 3600 / 24);
}
