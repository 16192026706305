const twitterText = require('twitter-text');

module.exports.countTweetLength = (status) => {
  /* When using require for twiterText in VueJS we get an object
  which contains the default property which contains the twitterText methods
  while in NodeJS we get the twitterText methods directly
  Possible explanation : https://stackoverflow.com/questions/42475691/importing-vs-requiring-with-babel-in-node */
  const twitterTextLib = twitterText.default ? twitterText.default : twitterText;

  return twitterTextLib.parseTweet(status).weightedLength;
};
