import { isEnvProd } from '@/config';

export function initProfitWell(store) {
  if (!isEnvProd) return;

  loadProfitWell();
  const loggedInUser = store.getters.getUserProfile;

  if (!loggedInUser || !loggedInUser.uid) return;

  window.profitwell && window.profitwell('start', { user_email: loggedInUser.email });
}

function loadProfitWell() {
  (function () {
    const documentObject = document;
    const scriptElement = documentObject.createElement('script');
    scriptElement.id = 'profitwell-js';
    scriptElement.setAttribute('data-pw-auth', '3b77e0df84f695cf07981b40d4c43154');
    documentObject.getElementsByTagName('head')[0].appendChild(scriptElement);
    (function (i, s, o, g, r, a, m) {
      i[o] =
        i[o] ||
        function () {
          (i[o].q = i[o].q || []).push(arguments);
        };
      a = s.createElement(g);
      m = s.getElementsByTagName(g)[0];
      a.async = 1;
      a.src = r + '?auth=' + s.getElementById(o + '-js').getAttribute('data-pw-auth');
      m.parentNode.insertBefore(a, m);
    })(window, document, 'profitwell', 'script', 'https://public.profitwell.com/js/profitwell.js');
  })();
}
