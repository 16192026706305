<template>
  <div
    class="relative flex items-center justify-center"
    :class="{
      'h-32 w-32': size === 'sm',
      'h-64 w-64': size === 'lg',
    }"
  >
    <img src="/logo.png" alt="logo" class="h-1/5 relative z-50 w-1/5" />
    <div class="loader-rotate absolute left-0 top-0 z-40 h-full w-full">
      <svg
        class="text-ds-button-secondary-background"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="42.5 42.5 255 255"
      >
        <circle
          cx="170"
          cy="85"
          r="42.5"
          fill="currentColor"
          style="--darkreader-inline-fill: #1d1f20"
          data-darkreader-inline-fill=""
        />
        <circle
          cx="170"
          cy="255"
          r="42.5"
          fill="currentColor"
          style="--darkreader-inline-fill: #1d1f20"
          data-darkreader-inline-fill=""
        />
        <circle
          cx="85"
          cy="170"
          r="42.5"
          fill="currentColor"
          style="--darkreader-inline-fill: #1d1f20"
          data-darkreader-inline-fill=""
        />
        <circle
          cx="255"
          cy="170"
          r="42.5"
          fill="currentColor"
          style="--darkreader-inline-fill: #1d1f20"
          data-darkreader-inline-fill=""
        />
      </svg>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'loading-scheduler',
    props: {
      size: {
        type: String,
        default: 'lg',
        validator(value) {
          return ['lg', 'sm'].includes(value);
        },
      },
    },
  };
</script>

<style>
  @keyframes loader-rotate {
    0% {
      transform: rotate(0deg);
    }
    45% {
      transform: rotate(-185deg);
    }
    60% {
      transform: rotate(-180deg);
    }
    100% {
      transform: rotate(-180deg);
    }
  }

  .rotate {
    animation: loader-rotate 1s ease-in-out infinite;
  }
</style>
