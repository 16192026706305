import { isUserPlanPremiumOrAbove } from '@/../functions/src/util/customerStatus.cjs';

export function canRetweetBeMade(retweetInfo, userProfile) {
  const customerStatus = userProfile.customerStatus;
  const areUsersTheSame = () => {
    if (retweetInfo.userTwitterId) {
      return retweetInfo.userTwitterId === userProfile.twitterId;
    } else {
      return retweetInfo.username === userProfile.username;
    }
  };
  return isUserPlanPremiumOrAbove(customerStatus) || areUsersTheSame();
}
