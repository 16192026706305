<template>
  <component
    :is="tag"
    :type="tag === 'button' ? nativeType : ''"
    :disabled="disabled || loading"
    @click="handleClick"
    :class="[
      { disabled: disabled && tag !== 'button' }
    ]"
  >
    <slot name="loading" v-if="loading">
      <span class="loading">
        <!-- loading.svg -->
        <inline-svg src="/img/icons/loading.svg" />
      </span>
    </slot>
    <div><slot></slot></div>
    <new-feature-badge v-if="newFeature" class="absolute bottom-0 right-0" style="width: auto !important;"/>
  </component>
</template>
<script>
import NewFeatureBadge from '@/components/NewFeatureBadge';

export default {
  name: 'base-button',
  components: {
    NewFeatureBadge
  },
  props: {
    tag: {
      type: String,
      default: 'button',
      description: 'Button html tag'
    },
    round: Boolean,
    icon: Boolean,
    block: Boolean,
    loading: Boolean,
    wide: Boolean,
    disabled: Boolean,
    type: {
      type: String,
      default: 'default',
      description: 'Button type (primary|secondary|danger etc)'
    },
    nativeType: {
      type: String,
      default: 'button',
      description: 'Button native type (e.g button, input etc)'
    },
    size: {
      type: String,
      default: '',
      description: 'Button size (sm|lg)'
    },
    outline: {
      type: Boolean,
      description: 'Whether button is outlined (only border has color)'
    },
    newFeature: {
      type: Boolean,
      default: false,
      description: "Whether this is a new feature or not"
    },
    link: {
      type: Boolean,
      description: 'Whether button is a link (no borders or background)'
    }
  },
  methods: {
    handleClick(evt) {
      this.$emit('click', evt);
    }
  },
};
</script>