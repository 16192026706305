<template>
  <div class="flex-shrink relative inline-block w-full">
    <select
      :disabled="disabled"
      :value="value"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
      @change="$emit('change', $event)"
      v-model="selected"
      :class="{
        sm: size === 'sm',
        md: size === 'md',
      }"
    >
      <option disabled :value="null" selected>{{ placeholder }}</option>

      <option v-for="(option, index) in options" :key="index" :value="getOptionValue(option)">
        {{ getOptionLabel(option) }}
      </option>
    </select>
    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
      <inline-svg src="/img/icons/arrow-down.svg" class="h-4 w-4 fill-current" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BaseSelect',
    props: {
      options: {
        type: Array,
        required: true,
      },
      placeholder: {
        type: String,
        default: '',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      value: {
        type: [String, Number, Object],
        default: null,
      },
      labelAttribute: {
        type: String,
        default: '',
      },
      valueAttribute: {
        type: String,
        default: '',
      },
      size: {
        type: String,
        default: 'md',
        validator: (value) => ['sm', 'md'].includes(value),
      },
    },
    data() {
      return {
        selected: null,
      };
    },
    watch: {
      selected: {
        handler(value) {
          this.$emit('input', value);
        },
      },
      value: {
        handler(value) {
          this.selected = value;
        },
        immediate: true,
      },
    },
    methods: {
      getOptionLabel(option) {
        if (typeof option === 'string' || typeof option === 'number') return option;
        if (this.labelAttribute) return option[this.labelAttribute];
        return option.label;
      },
      getOptionValue(option) {
        if (typeof option === 'string' || typeof option === 'number') return option;
        if (this.valueAttribute) return option[this.valueAttribute];
        return option;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .new-ui {
    select {
      @apply transition outline-none block w-full appearance-none rounded-lg py-2 pr-8 text-left  duration-150 ease-in-out;
    }
    select.sm {
      @apply h-10;
    }
    select.md {
      @apply h-12;
    }
    &.mode--dark {
      select {
        @apply border-0 bg-dark-mode-70;
        option {
          @apply text-white;
        }
      }
    }
    &.mode--light {
      input {
        @apply border border-gray-90 bg-white;
      }
    }
  }
</style>
