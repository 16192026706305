<script>
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import controller from '@/controller';
  import { store } from '@/store';

  export default {
    data() {
      return {
        isCreatingAffiliateAccount: false,
      };
    },
    methods: {
      async createAffiliateAccount() {
      this.$eventStore.general.sidebar('Create Affiliate account');
        const displayAffiliateAccountCreatedAlert = (generatedPassword) => {
          const copyPassword = async () => {
            const that = this;
            await this.$copyText(generatedPassword);
            that.$notify({ type: 'success', message: 'Password copied successfully!' });
          };
          this.swalModal({
            title: 'Affiliate account successfully created',
            html: `<div>You will shortly receive an email with information about your
              affiliate account.<br>
              We also generated a password for you to <a target="_blank" href="https://hypefury.firstpromoter.com/login">access it</a>.
              Please save it securely.</div>
              <div class="mt-6 bg-card-secondary p-2 rounded-lg leading-none flex items-center justify-between">
                <div class="text-secondary pl-2">Generated Password</div>
                <div class="flex items-center space-x-4">
                  <div class="text-secondary">${generatedPassword}</div>
                  <div id="copyButton" class="text-muted border border-muted p-2 text-xs rounded cursor-pointer hover:text-secondary hover:border-secondary">Copy</div>
                </div>
              </div>`,
            onOpen: () =>
              document.getElementById('copyButton').addEventListener('click', copyPassword),
          });
        };
        try {
          this.isCreatingAffiliateAccount = true;
          const response = await controller.createAffiliateAccount(this.currentUser, this.userProfile);

          displayAffiliateAccountCreatedAlert(response.data.generatedPassword);
          this.$notify({ type: 'success', message: 'Affiliate account created successfully' });

          store.commit('setUserAffiliateDetails', { totalSales: 0, totalCustomers: 0, referralLink: response.data.referralLink });
          store.commit('setUserAffiliateDetailsLoaded', true);
        } catch (error) {
          const alertText =
            error.response && error.response.status === 422
              ? `There already exists an affiliate account with email address ${this.userProfile.email}`
              : `An error has occurred while trying to create an affiliate account.`;
          this.swalModal({
            title: 'Sorry',
            text: alertText,
            type: 'warning',
          });
        }
        this.isCreatingAffiliateAccount = false;
      },
    },
    mixins:[SwalModalMixin]
  };
</script>
