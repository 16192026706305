<template>
  <modal :show="show" @close="$emit('close')">
    <div class="space-y-8">
      <div class="flex items-center justify-between">
        <div class="flex items-center gap-4">
          <div>
            <svg
              width="28"
              height="22"
              viewBox="0 0 28 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M27.3334 2.73337C26.3312 3.16816 25.2713 3.45559 24.1867 3.5867C25.331 2.90313 26.1884 1.82775 26.6001 0.560035C25.5248 1.20012 24.3478 1.6511 23.1201 1.89337C22.2995 1.00346 21.2067 0.411097 20.0132 0.209171C18.8196 0.0072441 17.5928 0.20718 16.5251 0.777621C15.4575 1.34806 14.6093 2.25674 14.1137 3.36115C13.6181 4.46556 13.5031 5.70322 13.7867 6.88003C11.6127 6.77007 9.486 6.20397 7.54495 5.21852C5.60391 4.23306 3.89188 2.85029 2.52008 1.16004C2.03893 2.00026 1.78611 2.9518 1.78675 3.92004C1.78504 4.81921 2.0057 5.70486 2.42909 6.49812C2.85247 7.29139 3.46544 7.96765 4.21341 8.4667C3.34405 8.44305 2.49326 8.20977 1.73341 7.7867V7.85337C1.73993 9.11322 2.18141 10.3322 2.98317 11.304C3.78493 12.2758 4.89776 12.9409 6.13341 13.1867C5.65776 13.3315 5.16391 13.4078 4.66675 13.4134C4.3226 13.4093 3.97931 13.3781 3.64008 13.32C3.99196 14.4038 4.67291 15.3509 5.58818 16.0296C6.50345 16.7083 7.60752 17.0848 8.74675 17.1067C6.82302 18.6204 4.44793 19.4465 2.00008 19.4534C1.5544 19.4548 1.10906 19.4281 0.666748 19.3734C3.16599 20.987 6.0785 21.8437 9.05342 21.84C11.1063 21.8614 13.1429 21.4734 15.0442 20.6988C16.9455 19.9242 18.6734 18.7785 20.127 17.3287C21.5806 15.8788 22.7307 14.1539 23.5102 12.2546C24.2896 10.3553 24.6828 8.31967 24.6667 6.2667C24.6667 6.04003 24.6667 5.80004 24.6667 5.56004C25.713 4.77978 26.6154 3.82327 27.3334 2.73337Z"
                fill="#1DA1F2"
              />
            </svg>
          </div>
          <span data-cy="unretweet-header" class="text-2xl font-bold">Conditional retweets</span>
        </div>
        <button data-cy="unretweet-close" @click="$emit('close')">
          <inline-svg src="/img/icons/close.svg" />
        </button>
      </div>
      <div class="row">
        <div class="flex items-center justify-between">
          <div class="flex items-center font-medium leading-none">
            <span>Enable Tweet Booster</span>
            <info-tooltip
              content="Do you want to give this tweet a little boost a few hours after it is posted?
            Or do want to share it with your followers from the other side of the planet?"
            ></info-tooltip>
          </div>
          <div class="flex space-x-2">
            <base-switch
              data-cy="retweet-switch"
              class="align-middle"
              v-model="isConditionalRetweetEnabled"
            ></base-switch>
          </div>
        </div>
      </div>
      <div class="mt-3 space-y-2" v-if="isConditionalRetweetEnabled">
        <div class="form-group lg:flex lg:items-center lg:justify-between">
          <label for="delayForRetweet" class="form-control-label text-dark-mode-15">Delay</label>
          <div class="relative mt-2 lg:mt-0 lg:w-1/2">
            <select
              class="form-control"
              data-toggle="select"
              id="delayForRetweet"
              v-model="delayForRetweet"
              data-cy="retweet-delay-select"
            >
              <option v-for="option in delayForRetweetOptions" :key="option" :value="option">
                {{ option }}
              </option>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-primary"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  class="h-4 w-4 fill-current"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  ></path>
                </svg>
              </div>
            </select>
          </div>
        </div>
        <div class="form-group lg:flex lg:items-center lg:justify-between">
          <label
            for="conditionForRetweet"
            class="form-control-label flex items-center text-dark-mode-15"
          >
            Delay Until
            <info-tooltip
              content="Choose whether to delay your retweet until this tweet reaches a minimum number of likes or retweets."
            ></info-tooltip>
          </label>
          <div class="relative mt-2 flex items-center space-x-2 lg:mt-0 lg:w-1/2">
            <div class="w-1/2">
              <base-input
                v-if="conditionForRetweet === 'Retweets'"
                type="number"
                min="1"
                v-model="minRetweetsThreshold"
                name="minRetweetsThreshold"
                data-cy="retweet-retweets-input"
              ></base-input>
              <base-input
                v-else
                type="number"
                min="1"
                v-model="minFavoritesThreshold"
                name="minFavoritesThreshold"
                data-cy="retweet-likes-input"
              ></base-input>
            </div>
            <div class="relative w-1/2">
              <select
                class="form-control"
                data-toggle="select"
                id="conditionForRetweet"
                v-model="conditionForRetweet"
                data-cy="retweet-condition-select"
              >
                <option v-for="option in conditionForRetweetOptions" :key="option" :value="option">
                  {{ option }}
                </option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-primary"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  class="h-4 w-4 fill-current"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end">
        <button
          class="custom-button button small-button"
          :disabled="!canSaveConditionalRetweetSettings"
          @click="saveThenClose"
          data-cy="retweet-save"
        >
          Save
        </button>
      </div>
    </div>
  </modal>
</template>
<script>
  import lodash from 'lodash';
  import { mapGetters } from 'vuex';
  export default {
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      originalThread: {
        type: Object,
        default: null,
      },
    },
    watch: {
      originalThread(thread) {
        if (thread) {
          this.updateValuesFromThread(thread);
        }
      },
      minFavoritesThreshold(newVal) {
        //Ensure that the value is at least 1 without modifying any preexisting values
        if (newVal <= 0) {
          this.minFavoritesThreshold = 1;
        }
      },
      minRetweetsThreshold(newVal) {
        //Ensure that the value is at least 1 without modifying any preexisting values
        if (newVal <= 0) {
          this.minRetweetsThreshold = 1;
        }
      },
    },
    data() {
      return {
        minFavoritesThreshold: 1,
        minRetweetsThreshold: 1,
        isConditionalRetweetEnabled: false,
        delayForRetweet: '12 hours',
        delayForRetweetOptions: [
          '30 minutes',
          '1 hour',
          '3 hours',
          '6 hours',
          '9 hours',
          '12 hours',
          '18 hours',
          '24 hours',
          '36 hours',
          '48 hours',
        ],
        conditionForRetweet: null,
        conditionForRetweetOptions: ['Retweets', 'Likes'],
      };
    },
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
      canSaveConditionalRetweetSettings() {
        return (
          (!lodash.isNil(this.minFavoritesThreshold) && Number(this.minFavoritesThreshold) >= 1) ||
          (!lodash.isNil(this.minRetweetsThreshold) && Number(this.minRetweetsThreshold) >= 1)
        );
      },
    },
    mounted() {
      this.updateValuesFromUserProfile();
    },
    methods: {
      updateValuesFromUserProfile() {
        if (!this.userProfile.conditionalRetweetsConditions) {
          this.isConditionalRetweetEnabled = false;
          return;
        }

        const delayForRetweet = this.userProfile.conditionalRetweetsConditions.delayForRetweet;
        if (delayForRetweet) {
          this.delayForRetweet = delayForRetweet;
        }
        this.isConditionalRetweetEnabled =
          this.userProfile.conditionalRetweetsConditions.showConditionalRetweetBlock ||
          this.isConditionalRetweetEnabled;
        this.minFavoritesThreshold =
          this.userProfile.conditionalRetweetsConditions.minFavoritesThreshold ||
          this.minFavoritesThreshold;
        this.minRetweetsThreshold =
          this.userProfile.conditionalRetweetsConditions.minRetweetsThreshold ||
          this.minRetweetsThreshold;
        this.conditionForRetweet = this.userProfile.conditionalRetweetsConditions
          .minFavoritesThreshold
          ? 'Likes'
          : 'Retweets';
        this.updateParentValues();
      },
      updateValuesFromThread(thread) {
        if (!thread.conditionalRetweetsConditions) {
          this.updateValuesFromUserProfile();
          this.isConditionalRetweetEnabled = false;
        } else {
          this.isConditionalRetweetEnabled = true;
          this.delayForRetweet = thread.conditionalRetweetsConditions.delayForRetweet;
          this.minRetweetsThreshold = thread.conditionalRetweetsConditions.minRetweetsThreshold;
          this.minFavoritesThreshold = thread.conditionalRetweetsConditions.minFavoritesThreshold;
          this.conditionForRetweet = thread.conditionalRetweetsConditions.minFavoritesThreshold
            ? 'Likes'
            : 'Retweets';
        }
        this.updateParentValues();
      },
      updateParentValues() {
        this.resetConditionalRetweetsMinRTAndMinLikesIfEmpty();
        this.$emit('values-set', {
          isConditionalRetweetEnabled: this.isConditionalRetweetEnabled,
          delayForRetweet: this.delayForRetweet ? this.delayForRetweet : '1 hour',
          minRetweetsThreshold:
            this.conditionForRetweet === 'Likes' ? null : this.minRetweetsThreshold,
          minFavoritesThreshold:
            this.conditionForRetweet === 'Likes' ? this.minFavoritesThreshold : null,
          conditionalRetweetType: this.conditionForRetweet,
        });
      },
      resetConditionalRetweetsMinRTAndMinLikesIfEmpty() {
        if (!this.minRetweetsThreshold) this.minRetweetsThreshold = 1;
        if (!this.minFavoritesThreshold) this.minFavoritesThreshold = 1;
      },
      saveThenClose() {
        this.updateParentValues();
        this.$emit('close');
      },
    },
  };
</script>
