<template>
  <div>
    <div class="flex items-center gap-x-1">
      <span class="h-4 w-4 rounded-full bg-gray-60" />
      <input
        data-cy="new-category-name-input"
        type="text"
        v-model="category.name"
        class="dark-mode-text-white h-auto border-0 text-main-color-100 placeholder-gray-20"
        placeholder="New category..."
        @keydown.enter.prevent="saveCategory"
        @input="isNameNotUnique ? resetIsNameNotUnique() : undefined"
      />
    </div>
    <div>
      <p v-if="isCategoryNameTooLong" class="mt-2 text-alert-danger">Please use a shorter name.</p>
      <p v-if="isNameNotUnique" class="mt-2 text-alert-danger">
        Please use a name you have not already used.
      </p>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import dao from '@/dao';
  import ValidateCategoryMixin from '@/views/Mixins/ValidateCategoryMixin';

  export default {
    props: {
      show: Boolean,
      canForceShow: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser' }),
      shouldShow() {
        return this.show || this.forceShow;
      },
    },
    data() {
      return {
        colors: [
          '#CBD5E0',
          '#718096',
          '#F56565',
          '#ED8936',
          '#DD6B20',
          '#ECC94B',
          '#48BB78',
          '#38B2AC',
          '#667EEA',
          '#9F7AEA',
        ],
        isSaving: false,
        forceShow: false,
        category: {
          name: '',
          color: '',
        },
      };
    },
    mounted() {
      this.category.color = this.getRandomColor();
    },
    mixins: [ValidateCategoryMixin],
    methods: {
      reset() {
        this.forceShow = false;
        this.category.name = '';
      },
      getRandomColor() {
        return this.colors[(this.colors.length * Math.random()) | 0];
      },
      async saveCategory() {
        this.category.color = this.getRandomColor();
        if (!this.canFormBeSubmitted) {
          return;
        }
        const that = this;
        this.isSaving = true;
        if (await this.categoryWithSameNameExists(this.category.name)) {
          this.isSaving = false;
          return;
        }

        const shouldEnableCategory = !this.didUserReachCategoriesLimit;

        dao.categories
          .create(
            this.category.name.trim(),
            this.category.color,
            shouldEnableCategory,
            this.userProfile.uid,
          )
          .then((response) => {
            that.isSaving = false;
            that.reset();
            that.$emit('new-category-added', response.data());
          });
      },
      setForceShow(value) {
        this.forceShow = value;
      },
    },
  };
</script>
