<template>
  <modal :show="show" @close="$emit('close')" :class="[{ 'overlay--on': !tweets }, 'relative']">
    <template #newHeader>
      <div class="bg-modal sticky top-0 z-40 flex w-full items-center justify-between p-6">
        <div class="flex items-center gap-4">
          <div>
            <inline-svg src="/img/icons/twitter.svg" class="h-8 w-8 text-main-color-40" />
          </div>
          <span data-cy="tweetshots-header" class="text-2xl font-bold">Select Your Tweetshots</span>
        </div>
        <button data-cy="tweetshots-close" @click="$emit('close')">
          <inline-svg src="/img/icons/close.svg" />
        </button>
      </div>
    </template>

    <template #default>
      <div class="overlay" v-if="!tweets">
        <div class="loader">
          <span class="loading loading-lg"
            ><inline-svg src="/img/icons/loading.svg"></inline-svg
          ></span>
        </div>
      </div>
      <div class="flex flex-col items-center">
        <div v-if="tweets && tweets.length">
          <tweet-display
            v-for="tweet in tweets"
            :key="tweet.tweetId"
            @click="setTweetshotSettings(tweet)"
            class="my-6 max-w-lg cursor-pointer"
            :profilePictureURL="userProfile.photoURL"
            :tweet="tweet.status"
            :userDisplayName="userProfile.name"
            :username="userProfile.username"
            :selected="
              tweetshotContent.findIndex((content) => content.tweetId === tweet.tweetId) >= 0
            "
          >
            <template #footer>
              <div class="flex w-full items-center gap-x-2">
                <div class="flex items-center gap-x-1">
                  <inline-svg src="/img/icons/retweet.svg" class="h-3 w-3"></inline-svg>
                  <span class="text-xs">{{ tweet.retweetCount }}</span>
                </div>
                <div class="flex items-center gap-x-1">
                  <inline-svg src="/img/icons/like.svg" class="h-3 w-3"></inline-svg>
                  <span class="text-xs">{{ tweet.favoriteCount }}</span>
                </div>
              </div>
            </template>
          </tweet-display>
        </div>
        <div v-else>
          <span>No posts were found</span>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="p-6">
        <new-button class="ml-auto" @click="$emit('close')">Done</new-button>
      </div>
    </template>
  </modal>
</template>

<script>
  import TweetshotMixin from '@/views/Mixins/TweetshotMixin.vue';
  import TweetDisplay from '../../TweetDisplay.vue';
  export default {
    components: { TweetDisplay },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      tweetshotContentFromParent: {
        type: Array,
        default: () => [],
      },
    },
    methods: {
      saveThenClose() {
        this.$emit('update-tweetshot-content', this.tweetshotContent);
        this.$emit('close');
      },
    },
    watch: {
      show(isShowing) {
        if (isShowing) {
          this.tweetshotContent = this.tweetshotContentFromParent;
        }
      },
    },
    mixins: [TweetshotMixin],
  };
</script>import { template } from 'lodash';

