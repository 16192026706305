<template>
  <div>
    <div
      :class="{
        'mb-20': isMobileKeyboardVisible,
      }"
      class="styled-scrollbars overscroll-behavior-contain flex max-w-3xl flex-col justify-start gap-y-6 overflow-y-auto"
    >
      <!-- Input and Tweet Preview -->
      <div data-cy="new-reply-composer">
        <input
          class="form-field"
          placeholder="URL of the tweet"
          type="text"
          @input="handleInput"
          data-cy="reply-url-input"
        />
      </div>
      <hr class="border-gray-90" />
      <!-- Post Composer -->
      <div
        class="styled-scrollbars max-h-composer-sm min-h-composer-sm w-full pr-4 lg:max-h-composer lg:min-h-composer"
        data-cy="composer-twitter"
      >
        <new-composer-text-area
          :tweet="reply"
          :canAddMoreVideos="canAddMoreVideos"
          :tweetIndexToFocus="tweetIndexToFocus"
          :mentionedUserLength="mentionedUserLength"
          :isCategoriesVisible="false"
          :hasStartedEditing="hasStartedEditing"
          :isReply="true"
          :uploadingMedia="uploadingMedia"
          @status-updated="updateStatus"
          @quote-tweet-data-updated="updateQuoteTweetData"
          @removed-quote-tweet="removeQuoteTweetData"
          @update-poll="updatePoll"
          @cleared-media="clearMediaSelectionAtIndex"
          @append-send-us-dm-button="addSendUsDMButton()"
          @selected-gif="addGifToTweet"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapState, mapMutations } from 'vuex';
  import { store } from '@/store';
  import lodash from 'lodash';
  import moment from 'moment';
  import 'moment-timezone';
  import NewComposerTextArea from '@/components/NewComposer/NewComposerTextArea.vue';
  import QuoteTweetMixin from '@/views/Mixins/QuoteTweetMixin.vue';
  import { countTweetLength } from '@/../functions/src/util/countTweetLength';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import UploadContainerMixin from '@/views/Mixins/UploadContainerMixin.vue';
  import { getUnfinishedPost } from '@/util/unfinishedPosts';
  import NewThreadMixin from '@/views/Mixins/NewThreadMixin.vue';
  import { DraftThread } from '@/models/DraftThread';
  import controller from '@/controller';
  import { formatTweet } from '@/util/formatTweet';
  import BreakpointsMixin from '@/views/Mixins/BreakpointsMixin.vue';
  import MobileMixin from '@/views/Mixins/MobileMixin.vue';
  import { validateLink } from '@/util/validateLink';
  import { EventBus } from '@/event-bus';
  import storage from '@/storage';

  const config = require('@/config');
  const fb = require('@/firebase');

  export default {
    name: 'new-reply-composer',
    components: {
      NewComposerTextArea,
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      ...mapState(['schedule', 'userBestTweetsCategory']),
      ...mapMutations(['setReplyToTweetUrl']),
      commandOrControlIcon() {
        return navigator.platform.includes('Win') ? 'Ctrl' : '⌘';
      },
      canAddMoreVideos() {
        return (
          Array.isArray(this.tweets) &&
          this.tweets.filter((t) => t.mediaFile[0] && t.mediaFile[0].type.includes('video'))
            .length < 10
        );
      },
      isMobileKeyboardVisible() {
        return this.isMobile && this.isComposerFocused && this.isKeyboardVisible;
      },
      isReplyValid() {
        const lengthOfUsernameToReplyTo =
          this.userProfile.username !== this.originalTweetUsername
            ? this.originalTweetUsername.length + 2
            : 0;
        return (
          (countTweetLength(this.reply.status.trim()) > 0 &&
            countTweetLength(this.reply.status) + lengthOfUsernameToReplyTo <= 280) ||
          this.reply.mediaFile.length > 0 ||
          this.reply.quoteTweetData
        );
      },
      mentionedUserLength() {
        const isUserNotMentionedYet = this.originalTweetUsername.length === 0;
        const mentionedUserLength =
          !(this.currentUserName === this.originalTweetUsername) && !isUserNotMentionedYet
            ? this.originalTweetUsername.length + 2
            : 0;
        return mentionedUserLength;
      },
    },
    data() {
      return {
        drafts: [],
        postType: 'twitter',
        wasUpdated: false,
        initialComposerPosition: 0,
        isComposerFocused: false,
        ...this.initialState(),
      };
    },
    mounted() {
      if (this.postType === 'twitter') {
        this.fetchDrafts();
      }

      if (this.threadFromParent) {
        if (['twitter'].includes(this.postType)) {
          this.updateComposerFromThread(this.threadFromParent);
        }
      }

      if (lodash.has(this.$route.query, 'content')) {
        this.tweets[0].status = this.$route.query.content;
      }

      this.setTweetIndexToFocus(this.tweets.length - 1);

      this.time = this.timeFromParent ? this.timeFromParent.format('HH:mm') : null;
      this.setTweetIndexToFocus(0);

      if (this.threadToEdit) {
        // We need to watch the thread to edit in case it gets scheduled
        this.unsubscribeFromThreadToWatch = fb.threadsCollection
          .doc(this.threadToEdit.id)
          .onSnapshot((doc) => {
            this.threadToWatch = doc.data();
          });

        const newTweet = lodash.cloneDeep(this.threadToEdit.tweets[0]);

        if (newTweet.media) {
          newTweet.mediaFile = [];
          newTweet.media.forEach((media, i) => {
            const mediaName = media.name;
            const mediaType = media.type;
            const mediaAltText = media.altText;
            newTweet.mediaFile[i] = {
              name: mediaName,
              type: this.getMediaType(mediaType),
              mimeType: mediaType,
              url: config.buildStorageMediaURL(mediaName),
              altText: mediaAltText,
              thumbnail: media.thumbnail,
            };
            newTweet.fileList = [];
          });
        }
        this.reply = newTweet;
        this.isDraft = this.threadToEdit instanceof DraftThread ? true : false;
        store.commit('setReplyToTweetUrl', this.threadToEdit.replyToTweetId);
        this.time = moment(this.threadToEdit.time).format('YYYY-MM-DDTHH:mm');
      } else {
        this.getLinkFromTheClipboard();
      }
    },
    methods: {
      formatTweet,
      // TODO: Move this to mixin
      async getLinkFromTheClipboard() {
        if (!lodash.get(navigator, 'clipboard.readText')) return;
        const text = await navigator.clipboard.readText();
        const { link, isValid } = validateLink(text);

        if (!isValid) return;

        this.$nextTick(() => {
          this.link = this.formatTweetLink(link);
        });
      },
      // TODO: Move to util and re-use in both new Reply/Retweet modals
      formatTweetLink(link) {
        return link.split('?')[0];
      },
      updateStatus(value) {
        this.reply = {
          ...this.reply,
          status: value,
        };
        this.updateParentValues();
      },
      removeQuoteTweetData() {
        this.reply = { ...this.reply, quoteTweetData: null };
      },
      updateQuoteTweetData(quoteURL) {
        this.reply = { ...this.reply, quoteTweetData: quoteURL };
      },
      addSendUsDMButton() {
        this.reply.status = `${this.reply.status}\nhttps://twitter.com/messages/compose?recipient_id=${this.userProfile.twitterId}`;
      },
      initialState() {
        return {
          reply: this.emptyTweet(0),
          isGifSelectorVisible: false,
          originalTweetUsername: '',
          error: null,
          isLoadingPreview: false,
          tweet: null,
          link: '',
          time: null,
          isSubmitting: false,
          threadToWatch: null,
          unsubscribeFromThreadToWatch: null,
          showCategoriesModal: false,
          tweetIndexToSelectGifFor: null,
          tweets: [this.emptyTweet(0)],
          isFavorite: getUnfinishedPost() ? getUnfinishedPost().isFavorite : false,
          isDraft: false,
          threadToReplace: null,
        };
      },
      resetComposer(time) {
        Object.assign(this.$data, this.initialState());
        this.setTweetIndexToFocus(0);
        this.setTimeToNextTimeSlot(time);
        this.mediaToDelete = [];

        if (this.unsubscribeFromThreadToWatch) {
          this.unsubscribeFromThreadToWatch();
        }
      },
      canAddGif(tweet) {
        return lodash.get(tweet, 'mediaFile', []).length === 0;
      },
      async addGifToTweet(gifURL) {
        if (!this.canAddGif(this.reply)) {
          return;
        }

        const image = await controller.proxyFile(this.currentUser, gifURL);
        this.reply = {
          ...this.reply,
          mediaFile: [{ url: gifURL, type: 'gif' }],
        };

        const f = new File([image], 'tenor.gif', { type: 'image/gif' });
        EventBus.$emit('set-uploading-media', 0);
        const { file, thumbnail } = await storage.uploadNewMedia(f);
        this.reply = {
          ...this.reply,
          media: [{
            name: file.metadata.name,
            type: file.metadata.contentType,
            size: file.metadata.size,
            ...(thumbnail ? { thumbnail } : {}),
          }],
          mediaFile: [{ url: gifURL, type: 'gif' }],
        };
        EventBus.$emit('unset-uploading-media', 0);
      },
      clearMediaSelectionAtIndex(tweet, i) {
        if (this.threadToEdit) {
          const mediaName = tweet.mediaFile[i].name;
          const mediaThumbnail = tweet.mediaFile[i].thumbnail;
          this.setMediaToDelete(mediaName, mediaThumbnail);
          const newMediaFile = tweet.mediaFile.filter((media) => media.name !== mediaName);
          const newFileList = tweet.fileList.filter((media) => media.name !== mediaName);
          const newMedia = tweet.media.filter((media) => media.name !== mediaName);
          this.reply = {
            ...this.reply,
            mediaFile: newMediaFile,
            fileList: newFileList,
            media: newMedia,
          };
        } else {
          this.reply = {
            ...this.reply,
            mediaFile: this.reply.mediaFile.filter((_, index) => index !== i),
            fileList: this.reply.fileList.filter((_, index) => index !== i),
          };
        }
      },
      updateParentValues() {
        this.$emit('values-set', {
          tweets: [this.reply],
        });
      },
      updatePoll(poll, tweetId) {
        this.tweets = this.tweets.map((tweet) =>
          tweet.guid === tweetId ? { ...tweet, poll } : tweet,
        );
      },
      // TODO for Mahdi: Add pagination on scroll for later
      fetchDrafts() {
        const unsubscribe = fb.threadsCollection
          .where('deleted', '==', false)
          .where('user', '==', fb.usersCollection.doc(this.userProfile.uid))
          .where('time', '==', null)
          .orderBy('created_at', 'desc')
          .onSnapshot((snapshot) => {
            this.drafts = snapshot.docs
              .filter(
                (d) =>
                  !d.data().isRecurrentPost &&
                  (d.data().source !== 'ghostwriting' ||
                    (d.data().source === 'ghostwriting' && d.data().ghostwritingStatus === null)),
              )
              .map((doc) => DraftThread.buildFromFirestore(doc));
          });

        store.dispatch('addFirestoreListener', unsubscribe);
      },
      handleComposerFocus: lodash.debounce(
        function (isFocus) {
          if (isFocus === this.isComposerFocused) return;
          this.isComposerFocused = isFocus;
        },
        300,
        { maxWait: 500 },
      ),
      handleInput: lodash.debounce(function (event) {
        const value = event.target.value;

        if (!value && value.length <= 0 && this.threadToEdit) {
          store.commit('setReplyToTweetUrl', this.threadToEdit.replyToTweetId);
          return;
        }
        store.commit('setReplyToTweetUrl', value);
      }, 700),
    },
    watch: {
      postType(type) {
        if (type === 'recurrent') {
          this.selectedTab = 'twitter';
        } else {
          this.selectedTab = type;
        }
      },
      threadToWatch(thread) {
        const wasThreadScheduled = thread && thread.scheduled;
        if (wasThreadScheduled) {
          this.swalModal({
            text: `This post has been scheduled for publishing.\nYou can't edit it anymore.`,
            type: 'warning',
          });
          this.resetComposer();
        }
      },
      reply() {
        this.updateParentValues();
      },
    },
    beforeDestroy() {
      if (this.unsubscribeFromThreadToWatch) {
        this.unsubscribeFromThreadToWatch();
      }
    },
    props: {
      showComposer: {
        type: Boolean,
        default: false,
      },
      timeFromParent: {
        type: Object,
        default: null,
      },
      threadToEdit: {
        type: Object,
        default: null,
      },
      replyTweetInfo: {
        type: Object,
        default: null,
      },
      hasStartedEditing: {
        type: Boolean,
        default: true,
      },
      uploadingMedia: Array,
    },
    mixins: [
      QuoteTweetMixin,
      SwalModalMixin,
      UploadContainerMixin,
      NewThreadMixin,
      BreakpointsMixin,
      MobileMixin,
    ],
  };
</script>

<style lang="scss" scoped>
  .preview-height {
    height: calc(100% - 50px);
    @include lg {
      height: 100%;
    }
  }
</style>
